import {Service} from "./service";
import {
    CrossCutDescription,
    EmergencyStopDescription,
    EscapeGalleryDescription,
    LatitudinalDirectedDescription,
    TunnelConstructionsDescriptionsApiFp,
    ZoneDescription
} from "../generated";

class TunnelConstructionsDescriptionsProvider extends Service {
    zoneDescriptions: ZoneDescription[] = [];
    crossCutDescriptions: CrossCutDescription[] = [];
    escapeGalleriesDescriptions: EscapeGalleryDescription[] = [];
    ventilationShaftsDescriptions: LatitudinalDirectedDescription[] = [];
    portalDescriptions: LatitudinalDirectedDescription[] = [];
    emergencyStopDescription: EmergencyStopDescription[] = [];

    async init(): Promise<void> {
        this.zoneDescriptions = await TunnelConstructionsDescriptionsApiFp().tunnelConstructionsDescriptionsGetZoneDescriptionsAndGetData();
        this.crossCutDescriptions = await TunnelConstructionsDescriptionsApiFp().tunnelConstructionsDescriptionsGetCrossCutDescriptionsAndGetData();
        this.escapeGalleriesDescriptions = await TunnelConstructionsDescriptionsApiFp().tunnelConstructionsDescriptionsGetEscapeGalleryDescriptionsAndGetData();
        this.ventilationShaftsDescriptions = await TunnelConstructionsDescriptionsApiFp().tunnelConstructionsDescriptionsGetVentilationShaftDescriptionsAndGetData();
        this.portalDescriptions = await TunnelConstructionsDescriptionsApiFp().tunnelConstructionsDescriptionsGetPortalDescriptionsAndGetData();
        this.emergencyStopDescription = await TunnelConstructionsDescriptionsApiFp().tunnelConstructionsDescriptionsGetEmergencyStopDescriptionAndGetData();
    }
}

export const tunnelConstructionService = new TunnelConstructionsDescriptionsProvider();