import {
    getGridDateOperators,
    GridColDef,
    GridFilterOperator,
    GridRenderCellParams,
    GridRowSpacingParams,
    useGridApiContext,
} from '@mui/x-data-grid'
import {Alert, Box, Collapse, IconButton, Select, Snackbar, Stack, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {WorkRegistrationDataGridArgs} from "../utils/InterfaceProps";
import React, {useState} from "react";
import {
    ColumnHeaderService,
    ERROR_MESSAGE_SNACKBAR,
    NA_PLACEHOLDER,
    NOTES_MAX_CHAR_COUNT,
    SUCCESS_MESSAGE_SNACKBAR, THIRD_LEVEL_OUTLINE_WIDTH,
} from "../constants";
import {
    AreaFilteringPanel,
    contractorSelectionOperator,
    objectStringOperators,
    statusStringOperators,
    stringFilterOperators
} from "../utils/FilterOperators";
import {
    KoralmLocation,
    LatitudinalDirectedOpenLineConstruction,
    LatitudinalDirectedOutdoorConstructionWorkRegistration,
    LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp,
    LatitudinalDirectedTunnelWorkRegistration,
    LatitudinalDirectedTunnelWorkRegistrationApiFp,
    OutdoorUniqueConstructionWorkRegistrationApiFp,
    OutdoorWorkRegistration,
    OutdoorWorkRegistrationApiFp,
    ProcessStatus,
    TunnelObject,
    TunnelWorkRegistrationWithNumericLocationID,
    TunnelWorkRegistrationWithNumericLocationIDApiFp,
    UniqueOpenLineConstruction,
    UniqueOutdoorConstructionWorkRegistration,
    ZoneBasedTunnelTubeWorkRegistration,
    ZoneBasedTunnelTubeWorkRegistrationApiFp
} from "../generated";
import {rgbaToHex} from "../utils/Colors";
import {getWorkRegistrationObjectType, getWorkRegistrationObjectTypeInRow, WorkRegistration} from "../utils/Types";
import {LocalizationService} from "../utils/Localization";
import {PermissionManager} from "../utils/PermissionManager";
import {GetThirdLevelOutline, StatusMenuItems, ThirdLevelOutlineMenuItems} from "../utils/Exports";
import DefaultDataGrid from "./DefaultDataGrid/DefaultDataGrid";
import CustomStyledSwitch from "../utils/CustomStyledSwitch";
import UserService from "../services/UserService";
import {contractorService} from "../services/contractorProvider";
import {tunnelConstructionService} from "../services/tunnelConstructionsDescriptionsProvider";
import {de} from "date-fns/locale";
import {format, formatISO, isValid, max, min, parseISO, isAfter, isBefore} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {FilterType} from "../utils/FilterTypes";
import {GridPreProcessEditCellProps} from "@mui/x-data-grid/models/params/gridCellParams";

function WorkRegistrationDataGrid(props: WorkRegistrationDataGridArgs) {
    const {showButtons = true} = props;

    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    function tryAddObjectInfo(params: any) {
        const infos = [];
        infos.push(<Typography key={"CollapseSpacing"} sx={{pt: 2}}></Typography>)
        if (params.row.LocationDescription.ObjectDescription.Tube) {
            infos.push(<Typography
                key={"TubeCollapse"}>Tunnel: {LocalizationService.Tube(params.row.LocationDescription.ObjectDescription.Tube)}</Typography>)
        }
        if (params.row.WorkDirection) {
            infos.push(<Typography
                key={"WorkDirectionCollapse"}>Richtung: {LocalizationService.WorkDirection(params.row.WorkDirection)}</Typography>)
        }
        if (params.row.LocationDescription.RailNumber) {
            infos.push(<Typography
                key={"RailNumberCollapse"}>{LocalizationService.RailNumber(params.row.LocationDescription.RailNumber)}</Typography>)
        }
        if (params.row.RailTrackStatus) {
            infos.push(<Typography
                key={"RailTrackStatusCollapse"}>Status: {LocalizationService.RailTrackStatus(params.row.RailTrackStatus)}</Typography>)
        }
        return infos
    }

    function handleContractorColorBox(contractorId: any) {
        const contractor = contractorService.contractors.find(id => id.ID === contractorId);
        if (contractor !== undefined) {
            return <Box sx={{
                bgcolor: rgbaToHex(contractor.Color.R, contractor.Color.G, contractor.Color.B, contractor.Color.A),
                width: 15,
                height: 15,
                borderRadius: "50%",
                marginRight: 2,
                marginTop: 0.3,
            }}/>
        }
        return <div/>
    }

    type DateCellRangeRelationType = { otherColumn: string, relation: 'maxDate' | 'minDate' };

    function DateEditInputCell(props: GridRenderCellParams & DateCellRangeRelationType) {
        const initialDate = new Date(props.value)
        const {id, value, field, otherColumn, relation, row} = props;
        const apiRef = useGridApiContext();
        const toCompareWith = new Date(row[otherColumn]);
        const comparedValues = [toCompareWith, new Date(value)];
        const otherArg: any = {};
        otherArg[relation] = (relation === 'maxDate' ? max : min)(comparedValues);
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <DatePicker
                    {...otherArg}
                    value={(relation === 'maxDate' ? min : max)(comparedValues)}
                    onChange={(newValue) => {
                        const isValidValue = isValid(newValue);
                        if (newValue
                            && isValidValue
                            && (relation === 'maxDate' ? isAfter : isBefore)(new Date(newValue as Date), toCompareWith)) {
                            setSnackbarOpen(true);
                            setSnackbarMessage(
                                `Der eigegebene Wert darf nicht ${relation === 'maxDate' ? 'größer' : 'kleiner'} sein als der ${format(toCompareWith, 'dd.MM.yyyy', {locale: de})}`
                            );
                        } else {
                            apiRef.current.setEditCellValue({
                                id,
                                field,
                                value: newValue === null ? initialDate : isValidValue ? newValue : initialDate
                            });
                        }
                    }}
                />
            </LocalizationProvider>
        );
    }

    function StatusEditInputCell(props: GridRenderCellParams) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        return (
            <Select
                name="StatusSelection"
                value={value}
                fullWidth={true}
                color="secondary"
                onChange={(event) => apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: event.target.value as ProcessStatus
                })}
                sx={{
                    bgcolor: "secondary.main"
                }}
            >
                {StatusMenuItems(false)}
            </Select>
        );
    }

    function renderDateEditInputCell(otherField: DateCellRangeRelationType) {
        return (params: GridRenderCellParams) => {
            return <DateEditInputCell {...params} {...otherField} />;
        }
    }

    const renderStatusEditInputCell: GridColDef['renderCell'] = (params) => {
        return <StatusEditInputCell {...params} />;
    };

    const displayedNotesChars = NOTES_MAX_CHAR_COUNT / 4;
    let columns = [
        {
            field: ColumnHeaderService.GetIDValue(),
            headerName: ColumnHeaderService.GetIDValue(true),
            disableColumnMenu: true,
            filterOperators: stringFilterOperators,
            filterable: false,
            sortable: false,
            width: 60,
            renderCell: (cellValues: GridRenderCellParams<number>) => {
                return (
                    <Box>
                        <IconButton onClick={() => {
                            props.changedGridRow(cellValues)
                        }
                        }>{cellValues.value === props.clickedIndex ? <KeyboardArrowUp/> :
                            <KeyboardArrowDown/>}</IconButton>
                    </Box>
                )
            }
        },
        {
            field: ColumnHeaderService.GetContractorValue(false),
            headerName: ColumnHeaderService.GetContractorValue(true),
            filterOperators: contractorSelectionOperator,
            width: 200,
            sortable: PermissionManager.ListView.sorting,
            filterable: props.filter === KoralmLocation.OpenLine || props.filter === KoralmLocation.Tunnel,
            valueGetter: (cellValues: any) => {
                return cellValues.row.ContractorId ? cellValues.row.ContractorId : NA_PLACEHOLDER;
            },
            renderCell: (cellValues: GridRenderCellParams<string>) => {
                return <Stack direction="row" alignItems="center">
                    {handleContractorColorBox(cellValues.value)}
                    {cellValues.value}
                </Stack>
            },
        },
        {
            field: ColumnHeaderService.GetAreaDescriptionValue(false),
            headerName: ColumnHeaderService.GetAreaDescriptionValue(true),
            width: 125,
            sortable: PermissionManager.ListView.sorting,
            filterable: props.filter !== KoralmLocation.OpenLine,
            filterOperators: AreaFilteringPanel(props.filter) as GridFilterOperator<any, string | number | null, any>[],
            valueGetter: (params: any) => {
                const type = getWorkRegistrationObjectTypeInRow(params);

                switch (type) {
                    case TunnelObject.Tube:
                        return LocalizationService.TubeId(params.row.LocationDescription.ObjectDescription.AreaDescription.Start, true)
                            + " - " +
                            LocalizationService.TubeId(params.row.LocationDescription.ObjectDescription.AreaDescription.End, false);
                    case KoralmLocation.OpenLine:
                    case LatitudinalDirectedOpenLineConstruction:
                    case UniqueOpenLineConstruction:
                        return params.row.LocationDescription.ObjectDescription.AreaDescription.Start
                            ? params.row.LocationDescription.ObjectDescription.AreaDescription.Start.siValue + " - " + params.row.LocationDescription.ObjectDescription.AreaDescription.End.siValue
                            : "NA";
                    case TunnelObject.EmergencyStop:
                        return tunnelConstructionService.emergencyStopDescription.find(item => item.ID === Number(params.row.LocationDescription.ObjectDescription.AreaDescription.ID))?.Name || "";
                    case TunnelObject.EscapeGallery:
                        return LocalizationService.EscapeGallery(params.row.LocationDescription.ObjectDescription.AreaDescription.ID)
                    default:
                        return LocalizationService.ZoneSelection(params.row.LocationDescription.ObjectDescription.AreaDescription.ID);
                }
            },
        },
        {
            field: ColumnHeaderService.GetNameValue(false),
            headerName: ColumnHeaderService.GetNameValue(true),
            editable: true,
            sortable: PermissionManager.ListView.sorting,
            type: 'string',
            filterOperators: stringFilterOperators,
            valueGetter: (params: any) => {
                return params.row.Name ? params.row.Name : NA_PLACEHOLDER;
            },
            renderCell: (cellValues: any) => {
                return (
                    <Box>
                        <Typography sx={{pt: 1, fontSize: 15}}>{cellValues.row.Name}</Typography>
                        <Collapse in={cellValues.id === props.clickedIndex}>
                            <Typography sx={{pt: 2}}>
                                Ansprechpartner: {!cellValues.row.ContactPerson ? NA_PLACEHOLDER : cellValues.row.ContactPerson.FirstName + " " + cellValues.row.ContactPerson.LastName}
                            </Typography>
                            <Typography>
                                Telefonnummer: {!cellValues.row.ContactPerson ? NA_PLACEHOLDER : cellValues.row.ContactPerson.PhoneNumber}
                            </Typography>
                            <Typography sx={{pb: 1}}>
                                E-Mail: {!cellValues.row.ContactPerson ? NA_PLACEHOLDER : cellValues.row.ContactPerson.EMailAddress}
                            </Typography>
                        </Collapse></Box>
                )
            },
            width: 300
        },
        {
            field: ColumnHeaderService.GetStatusValue(false),
            headerName: ColumnHeaderService.GetStatusValue(true),
            width: 200,
            sortable: PermissionManager.ListView.sorting,
            editable: true,
            type: 'singleSelect',
            valueOptions:
                Object.keys(ProcessStatus)
                    .map((key) => ({
                        value: key,
                        label: LocalizationService.Status(ProcessStatus[key as ProcessStatus]),
                    })),
            renderEditCell: renderStatusEditInputCell,
            filterOperators: statusStringOperators,
            valueFormatter: (params: any) => {
                return LocalizationService.Status(params.value)
            },
        },
        {
            field: ColumnHeaderService.GetStartValue(false),
            headerName: ColumnHeaderService.GetStartValue(true),
            filterOperators: getGridDateOperators(),
            editable: true,
            sortable: PermissionManager.ListView.sorting,
            type: 'date',
            renderEditCell: renderDateEditInputCell({
                otherColumn: ColumnHeaderService.GetEndValue(false),
                relation: 'maxDate'
            }),
            renderCell: (params: any) => {
                try {
                    return <Typography
                        fontSize={14}>{format(new Date(params.row.Start), 'dd.MM.yyyy', {locale: de})}</Typography>
                } catch (error) {
                    console.error("error: ", error)
                    return <Typography>NA</Typography>
                }
            },
            width: 125
        },
        {
            field: ColumnHeaderService.GetEndValue(false),
            headerName: ColumnHeaderService.GetEndValue(true),
            filterOperators: getGridDateOperators(),
            editable: true,
            sortable: PermissionManager.ListView.sorting,
            type: 'date',
            renderEditCell: renderDateEditInputCell({
                otherColumn: ColumnHeaderService.GetStartValue(false),
                relation: 'minDate'
            }),
            renderCell: (params: any) => {
                try {
                    return <Typography
                        fontSize={14}>{format(new Date(params.row.End), 'dd.MM.yyyy', {locale: de})}</Typography>
                } catch (error) {
                    console.error("error: ", error)
                    return <Typography>NA</Typography>
                }
            },
            width: 125
        },
        {
            field: ColumnHeaderService.GetPersonCountValue(false),
            headerName: ColumnHeaderService.GetPersonCountValue(true),
            width: 100,
            editable: true,
            sortable: PermissionManager.ListView.sorting,
            valueParser: (newValue: any) => {
                const parsedValue = parseInt(newValue, 10);
                return isNaN(parsedValue) || parsedValue < 0 ? null : parsedValue;
            },
        },
        {
            field: ColumnHeaderService.GetObjectDescriptionValue(false, props.filter),
            headerName: ColumnHeaderService.GetObjectDescriptionValue(true, props.filter),
            filterOperators: objectStringOperators(props.filter),
            filterable: props.filter !== FilterType.Outdoor,
            width: 175,
            sortable: PermissionManager.ListView.sorting,
            valueGetter: (params: any) => {
                const type = getWorkRegistrationObjectTypeInRow(params);

                switch (type) {
                    case KoralmLocation.OpenLine:
                    case LatitudinalDirectedOpenLineConstruction:
                    case UniqueOpenLineConstruction:
                        return LocalizationService.KoralmLocation(params.row.LocationDescription.Location);
                    case TunnelObject.Tube:
                        return LocalizationService.Tube(params.row.LocationDescription.ObjectDescription.Tube)
                    case TunnelObject.CrossCut:
                    case TunnelObject.EscapeGallery:
                    case TunnelObject.EmergencyStop:
                    case TunnelObject.Portal:
                        return LocalizationService.TunnelObject(params.row.LocationDescription.ObjectDescription.TunnelObject);
                    case TunnelObject.VentilationShaft:
                        return LocalizationService.OuterConstruction(params.row.LocationDescription.ObjectDescription.IsOnOuterConstruction);
                    default:
                        return "";
                }
            }

            ,
            renderCell: (params: any) => {
                return (
                    <Box>
                        <Typography sx={{pt: 1, fontSize: 15}}>{params.value}</Typography>
                        <Collapse in={params.id === props.clickedIndex}>
                            {tryAddObjectInfo(params)}
                        </Collapse>
                    </Box>
                )
            }
        },
        {
            field: ColumnHeaderService.GetButtonValue(false),
            headerName: ColumnHeaderService.GetButtonValue(true),
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            disableExport: true,
            hide: !showButtons,
            renderCell: (cellValues: any) => {
                const contractorId = cellValues.row.ContractorId;
                return (
                    <Stack direction='row'>
                        {PermissionManager.ListView.deleting(contractorId) && <IconButton
                            onClick={(event) => {
                                void handleDeleteClick(event, cellValues);
                            }}
                            sx={{color: '#000000'}}> <DeleteIcon fontSize="large"/>
                        </IconButton>}
                        {PermissionManager.ListView.editButton(contractorId) && <IconButton
                            onClick={() => props.changedSelectedWorkRegistration(cellValues.row as WorkRegistration)}
                            sx={{color: '#000000'}}> <EditIcon fontSize="large"/>
                        </IconButton>}
                    </Stack>
                );
            }, width: 120
        },
        {
            field: ColumnHeaderService.GetOutlineLevelValue(false),
            headerName: ColumnHeaderService.GetOutlineLevelValue(true),
            editable: false,
            width: 250,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: (params: any) => {
                return params.row.ThirdLevelOutline ? GetThirdLevelOutline(params.row.ThirdLevelOutline)?.DisplayName : '';
            },
            renderCell: (cellValues: any) => {
                return (
                    <Stack>
                        <Stack paddingTop={1} paddingLeft={1} paddingBottom={1}>
                            <Select value={cellValues.row.ThirdLevelOutline ? cellValues.row.ThirdLevelOutline : ''}
                                    disabled={!PermissionManager.ListView.quickThirdLevelOutlineButton(
                                        (cellValues.row as WorkRegistration).ContractorId)}
                                    onChange={(event) => {
                                        const val = event.target.value;
                                        if (val) {
                                            const newRow: WorkRegistration = cellValues.row
                                            newRow.ThirdLevelOutline = val
                                            processRowUpdate(newRow, cellValues.row as WorkRegistration).then(() => props.onDataUpdate())
                                        }
                                    }}
                                    name="ThirdLevelOutlineSelection"
                                    sx={{width: THIRD_LEVEL_OUTLINE_WIDTH, height: 50}}>
                                {ThirdLevelOutlineMenuItems()}
                            </Select>
                        </Stack>
                    </Stack>
                )
            }
        },
        {
            field: ColumnHeaderService.GetNotesValue(false),
            headerName: ColumnHeaderService.GetNotesValue(true),
            editable: true,
            width: displayedNotesChars * 8,
            filterable: true,
            sortable: PermissionManager.ListView.sorting,
            disableColumnMenu: false,
            valueGetter: (params: any) => {
                return params.row.Notes ? params.row.Notes : 'keine';
            },
            renderCell: (params: any) => {
                const hasValue = params.value ? typeof params.value === 'string' : false;
                const isExpanded = params.id === props.clickedIndex;
                const value = hasValue ? params.value : 'keine';
                return (
                    <Box>
                        {!isExpanded &&
                            <Typography sx={{pt: 1, fontSize: 15}}>
                                {value.length <= displayedNotesChars ? value : value.substring(0, displayedNotesChars) + ".."}
                            </Typography>
                        }
                        <Collapse in={isExpanded}>
                            <Box sx={{overflowWrap: "break-word", hyphens: "auto"}}>
                                {value}
                            </Box>
                        </Collapse>
                    </Box>
                )
            }
        }
    ] as GridColDef[];

    function validateDataInput(hasToBeSmallerThanOther: boolean) {
        return (params: GridPreProcessEditCellProps) => {
            return params.props;
        };
    }

    if (PermissionManager.ListView.quickWeekProgramButton()) {
        columns.splice(columns.length - 1, 0, {
            field: ColumnHeaderService.GetWeekProgramValue(false),
            headerName: ColumnHeaderService.GetWeekProgramValue(true),
            editable: false,
            width: 200,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: (params: any) => {
                return params.row.PartOfWeekProgram ? LocalizationService.Boolean(params.row.PartOfWeekProgram) : LocalizationService.Boolean(false);
            },
            renderCell: (cellValues: any) => {
                return (
                    <Stack sx={{width: '100%', height: '100%'}} alignItems="center" justifyContent="center">
                        <CustomStyledSwitch
                            checked={cellValues.row.PartOfWeekProgram || false}
                            onChange={(event: { target: { checked: boolean; }; }) => {
                                const newRow: WorkRegistration = cellValues.row
                                newRow.PartOfWeekProgram = event.target.checked
                                processRowUpdate(newRow, cellValues.row as WorkRegistration).then(() => props.onDataUpdate())
                            }}
                            name="partOfWeekProgram"
                        />
                    </Stack>);
            }
        })
    }
    if (PermissionManager.ListView.quickIsMilestoneButton()) {
        columns.splice(columns.length - 1, 0, {
            field: ColumnHeaderService.GetIsMilestoneValue(false),
            headerName: ColumnHeaderService.GetIsMilestoneValue(true),
            editable: false,
            width: 125,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: (params: any) => {
                return params.row.IsMilestone ? LocalizationService.Boolean(params.row.IsMilestone) : LocalizationService.Boolean(false);
            },
            renderCell: (cellValues: any) => {
                return (
                    <Stack sx={{width: '100%', height: '100%'}} alignItems="center" justifyContent="center">
                        <CustomStyledSwitch
                            disabled={!PermissionManager.WorkRegistrationEditing.isMilestone}
                            checked={cellValues.row.IsMilestone || false}
                            onChange={(event: { target: { checked: boolean; }; }) => {
                                const newRow: WorkRegistration = cellValues.row
                                newRow.IsMilestone = event.target.checked
                                processRowUpdate(newRow, cellValues.row as WorkRegistration).then(() => props.onDataUpdate())
                            }}
                            name="partOfWeekProgram"
                        />
                    </Stack>);
            }
        })
    }
    if (props.showEditorEmail) {
        columns.splice(columns.length - 1, 0, {
            field: ColumnHeaderService.GetEditorEmail(false),
            headerName: ColumnHeaderService.GetEditorEmail(true),
            editable: false,
            width: 250,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: (params: any) => {
                return params.row.EditorEmail ? params.row.EditorEmail : NA_PLACEHOLDER;
            },
            renderCell: (cellValues: any) => {
                const emailParts = cellValues.row.EditorEmail.split('@');
                const emailUsername = emailParts[0];
                const wrappedUsername = emailUsername.length > 16 ? emailUsername.substring(0, 16) + '\n' + emailUsername.substring(16) : emailUsername;
                return (
                    <Stack sx={{width: '100%', height: '100%'}} alignItems="center" justifyContent="center">
                        <Typography>
                            {wrappedUsername}@{emailParts[1]}
                        </Typography>
                    </Stack>
                );
            }
        });
    }
    if (PermissionManager.ListView.showUuId() && !props.showEditorEmail) {
        columns.splice(columns.length - 1, 0, {
            field: "UuId",
            headerName: "UuId",
            editable: false,
            width: 240,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: (params: any) => {
                return params.row.UUID;
            },
            renderCell: (cellValues: any) => {
                return <Typography>{cellValues.row.UUID}</Typography>;
            }
        });
    }

    const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const handleSnackbarMessage = (message: string) => {
        setSnackbarMessage(message)
        setSnackbarOpen(true)
    }

    const handleDeleteClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, cellValue: any) => {
        const deleteElement = async (deletePromise: Promise<number>) => {
            const numberElements = await deletePromise;
            handleSnackbarMessage("Ein Element (Änderungsanzahl: " + numberElements + ") wurde gelöscht.")
        };

        switch (getWorkRegistrationObjectTypeInRow(cellValue)) {
            case TunnelObject.VentilationShaft:
            case TunnelObject.Portal:
                await deleteElement(LatitudinalDirectedTunnelWorkRegistrationApiFp().latitudinalDirectedTunnelWorkRegistrationDeleteByUuidAndGetData(cellValue.row.UUID));
                break;
            case TunnelObject.EmergencyStop:
            case TunnelObject.EscapeGallery:
            case TunnelObject.CrossCut:
                await deleteElement(TunnelWorkRegistrationWithNumericLocationIDApiFp().tunnelWorkRegistrationWithNumericLocationIDDeleteByUuidAndGetData(cellValue.row.UUID));
                break;
            case TunnelObject.Tube:
                await deleteElement(ZoneBasedTunnelTubeWorkRegistrationApiFp().zoneBasedTunnelTubeWorkRegistrationDeleteByUuidAndGetData(cellValue.row.UUID));
                break;
            case KoralmLocation.OpenLine:
                await deleteElement(OutdoorWorkRegistrationApiFp().outdoorWorkRegistrationDeleteByUuidAndGetData(cellValue.row.UUID));
                break;
            case UniqueOpenLineConstruction:
                await deleteElement(OutdoorUniqueConstructionWorkRegistrationApiFp().outdoorUniqueConstructionWorkRegistrationDeleteByUuidAndGetData(cellValue.row.UUID))
                break;
            case LatitudinalDirectedOpenLineConstruction:
                await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp().latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuidAndGetData(cellValue.row.UUID)
                break;
            default:
                console.error("unknown type");
                handleSnackbarMessage(ERROR_MESSAGE_SNACKBAR)
        }
        props.onDataUpdate();
    }

    const processRowUpdate = React.useCallback(async (newRow: WorkRegistration, oldRow: WorkRegistration) => {
        const saveWorkRegistration = async (newRow: WorkRegistration) => {
            if (oldRow.Status === newRow.Status) {
                if (PermissionManager.ListView.autoApprovedStatusChange()) {
                    newRow.Status = ProcessStatus.ChangeApproved;
                } else {
                    newRow.Status = ProcessStatus.ChangeSubmitted;
                }
            }

            newRow.PersonCount = Math.abs(newRow.PersonCount)
            if (newRow.Name.length === 0) newRow.Name = NA_PLACEHOLDER

            newRow.EditorEmail = UserService.getEmail();

            if (isValid(new Date(newRow.Start)) && isValid(new Date(newRow.End))) {
                const updatedStartDate = parseISO(`${formatISO(new Date(newRow.Start), {representation: 'date'})}T${formatISO(new Date(oldRow.Start), {representation: 'time'})}`)
                const updatedEndDate = parseISO(`${formatISO(new Date(newRow.End), {representation: 'date'})}T${formatISO(new Date(oldRow.End), {representation: 'time'})}`)

                newRow.Start = formatISO(updatedStartDate)
                newRow.End = formatISO(updatedEndDate)
            } else {
                throw new Error(`Cannot parse new Date: ${newRow}`);
            }

            switch (getWorkRegistrationObjectType(newRow)) {
                case TunnelObject.VentilationShaft:
                case TunnelObject.Portal:
                    return await LatitudinalDirectedTunnelWorkRegistrationApiFp().latitudinalDirectedTunnelWorkRegistrationSaveAndGetData(newRow as LatitudinalDirectedTunnelWorkRegistration);
                case TunnelObject.EmergencyStop:
                case TunnelObject.EscapeGallery:
                case TunnelObject.CrossCut:
                    return await TunnelWorkRegistrationWithNumericLocationIDApiFp().tunnelWorkRegistrationWithNumericLocationIDSaveAndGetData(newRow as TunnelWorkRegistrationWithNumericLocationID);
                case TunnelObject.Tube:
                    return await ZoneBasedTunnelTubeWorkRegistrationApiFp().zoneBasedTunnelTubeWorkRegistrationSaveAndGetData(newRow as ZoneBasedTunnelTubeWorkRegistration);
                case KoralmLocation.OpenLine:
                    return await OutdoorWorkRegistrationApiFp().outdoorWorkRegistrationSaveAndGetData(newRow as OutdoorWorkRegistration);
                case UniqueOpenLineConstruction:
                    return await OutdoorUniqueConstructionWorkRegistrationApiFp().outdoorUniqueConstructionWorkRegistrationSaveAndGetData(newRow as UniqueOutdoorConstructionWorkRegistration);
                case LatitudinalDirectedOpenLineConstruction:
                    return await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp().latitudinalDirectedOutdoorConstructionWorkRegistrationSaveAndGetData(newRow as LatitudinalDirectedOutdoorConstructionWorkRegistration);
                default:
                    throw new Error(`Unknown work registration object type: ${getWorkRegistrationObjectType(newRow)}`);
            }
        };

        try {
            await saveWorkRegistration(newRow);
            handleSnackbarMessage(SUCCESS_MESSAGE_SNACKBAR)
        } catch (error) {
            handleSnackbarMessage(ERROR_MESSAGE_SNACKBAR)
        }

        return newRow;
    }, []);

    return (
        <Stack sx={{width: '100%', height: '100%'}}>
            <DefaultDataGrid
                columns={columns}
                isCellEditable={(params: any) => PermissionManager.ListView.canEditCurrentLine(params)}
                rows={props.gridData}
                getRowSpacing={getRowSpacing}
                disableColumnFilter={!PermissionManager.ListView.filtering}
                loading={props.isLoading}
                rowCount={props.total}
                pageSize={props.pageSize}
                page={props.page}
                onPageSizeChange={(newPageSize) => props.changedPageSize(newPageSize)}
                onPageChange={(newPage) => {
                    if (PermissionManager.ListView.newPage) {
                        props.changedPage(newPage)
                    }
                }}
                mode={props.mode}
                onFilterModelChange={(newFilterMode) => props.changedFilter(newFilterMode)}
                onSortModelChange={(newSortMode) => props.changedSorting(newSortMode)}
                processRowUpdate={processRowUpdate}
                filterModel={props.filterModel}
                sortModel={props.sortModel}
            />
            <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="info"
                       sx={{width: '100%'}}>{snackbarMessage}</Alert>
            </Snackbar>
        </Stack>
    );
}

export default WorkRegistrationDataGrid;
