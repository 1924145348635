import {Alert, Box, Button, CircularProgress, DialogActions, IconButton, Stack} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import React, {useEffect, useState} from "react";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {de} from "date-fns/locale";
import {TunnelPersonLogExportApiAxiosParamCreator, TunnelPersonLogExportApiFp} from "../../generated";
import {PERSON_COUNT_FILE_NAME} from "../../constants";
import {handleDialogExportDownload} from "../../utils/Exports";
import {ExportButtonProps} from "../../utils/InterfaceProps";
import excelImg from "../../img/microsoft-excel.png";
import {addDays, formatISO} from "date-fns";
import CloseIcon from "@mui/icons-material/Close";

function PersonCountExport(props: ExportButtonProps) {
    const [personDate, setPersonDate] = useState<Date>(new Date())
    const [minDate, setMinDate] = useState<Date | undefined>(undefined)
    const [maxDate, setMaxDate] = useState<Date | undefined>(undefined)
    const [isFetching, setIsFetching] = React.useState(false)
    const [errorToastOpen, setErrorToastOpen] = React.useState(false);

    const handlePersonCountParams = async () => {
        const startDate = new Date(personDate)
        startDate.setHours(0, 0, 0)

        const endDate = addDays(new Date(personDate), 1)
        endDate.setHours(0, 0, 0)

        return await TunnelPersonLogExportApiAxiosParamCreator().tunnelPersonLogExportGetPersonCountLogInTunnelInInterval(
            formatISO(startDate),
            formatISO(endDate),
        )
    }

    useEffect(() => {
        void handleDialogOpen()
    }, [])

    const handleDialogOpen = async () => {
        await TunnelPersonLogExportApiFp().tunnelPersonLogExportGetTimeSeriesBoundariesAndGetData().then((value) => {
            setMinDate(new Date(value.Min))
            setMaxDate(new Date(value.Max))
        })
    }

    const renderDatePicker = () => {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <DatePicker
                    label="Tagesauswahl"
                    value={personDate}
                    onChange={(newValue) => setPersonDate(newValue ?? new Date())}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </LocalizationProvider>
        )
    }

    return (
        maxDate === undefined || minDate === undefined ? <CircularProgress/> :
            <Stack padding={5}>
                {renderDatePicker()}
                <DialogActions>
                    <Button onClick={props.handleCancelButton} variant='contained'
                            color="secondary">Abbrechen</Button>
                    <Box sx={{margin: 1, position: 'relative'}}>
                        <Button
                            onClick={async () => {
                                try {
                                    setIsFetching(true)
                                    await handleDialogExportDownload(handlePersonCountParams(), PERSON_COUNT_FILE_NAME);
                                    return setIsFetching(false);
                                } catch (e) {
                                    setErrorToastOpen(true)
                                    return setIsFetching(false)
                                }
                            }}
                            variant='contained'
                            disabled={isFetching}
                            color="primary"><img src={excelImg}
                                                 alt={"Microsoft Excel Logo"}/> Download<FileDownloadOutlinedIcon/></Button>
                        {isFetching &&
                            <CircularProgress
                                size={24} sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                            }}/>}
                    </Box>
                </DialogActions>
                {errorToastOpen && <Alert severity="error"
                                          action={
                                              <IconButton
                                                  aria-label="close"
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                      setErrorToastOpen(false);
                                                  }}>
                                                  <CloseIcon fontSize="inherit"/>
                                              </IconButton>
                                          }>
                    Download fehlgeschlagen!
                </Alert>}
            </Stack>
    );
}

export default PersonCountExport