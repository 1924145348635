import {Group, Layer, Rect, Stage, Text} from "react-konva";
import React, {useEffect, useRef, useState} from "react";
import {Dialog, DialogContent, DialogTitle, Stack} from "@mui/material";
import {KoralmLocation, LatitudinalDirection, RailNumber, RailTrackStatus, TunnelObject,} from "../../generated";
import {getIbnOrRailTrackColor, RailTrackPrimaryColor, RailTrackSecondaryColor,} from "../../utils/Colors";
import {LocalizationService} from "../../utils/Localization";
import {OutdoorWorkRegistrationsAndConstructions} from "../../utils/Types";
import {railDescriptionService} from "../../services/railDescriptionsProvider";
import Konva from "konva";
import WeekOverviewGrid from "../WeekOverviewPage/WeekOverviewGrid";
import useImage from "use-image";
import StripesBlocked from "../../img/StripesBlocked.png";
import StripesBlockedSideWork from "../../img/BlockedSideWork.png";
import StripesFreeBlocked from "../../img/FreeBlocked.png";
import StripesFreeSideWork from "../../img/FreeSideWork.png";
import StripesFree from "../../img/StripesFree.png";
import StripesSideWork from "../../img/StripesSideWork.png";
import Blocked from "../../img/Blocked.png";
import FreeSideBlocked from "../../img/FreeSideBlocked.png";
import StripesIbn from "../../img/StripesIbn.png";
import IbnFree from "../../img/IbnFree.png";
import IbnSideWork from "../../img/IbnSideWork.png";
import IbnBlocked from "../../img/IbnBlocked.png";
import FreeBlockedIbn from "../../img/FreeBlockedIbn.png";
import FreeSideWorkBlockedIbn from "../../img/FreeSideWorkBlockedIbn.png";
import FreeSideWorkIbn from "../../img/FreeSideWorkIbn.png";
import SideWorkBlockedIbn from "../../img/SideWorkBlockedIbn.png";
import {translationsService} from "../../services/translationsProvider";
import {useCurrentWindowSize} from "../../utils/Exports";

interface OutdoorRailsVisualizationProps {
    outdoorWorkRegistration: Array<OutdoorWorkRegistrationsAndConstructions>;
    openLineLocation: LatitudinalDirection;
    changedStageRef: (value: Konva.Stage | null) => void;
}

export function OutdoorVisualization(props: OutdoorRailsVisualizationProps) {
    const windowSize = useCurrentWindowSize();
    const start = props.outdoorWorkRegistration.map(value => value.LocationDescription.ObjectDescription.AreaDescription.Start.siValue);
    const end = props.outdoorWorkRegistration.map(value => value.LocationDescription.ObjectDescription.AreaDescription.End.siValue);
    const flipSides = props.openLineLocation === LatitudinalDirection.East;

    const sectionLength = 500;
    const railRectHeight = 10;
    const textFontSize = 12;
    const upperRailY = 25;
    const lowerRail1Y = upperRailY + 50;
    const railOneDescription = railDescriptionService.railSectionDescriptions[flipSides ? 0 : 1].RailAreaDescription;
    const railTwoDescription = railDescriptionService.railSectionDescriptions[flipSides ? 2 : 3].RailAreaDescription;
    const swapRailEnds = railOneDescription.End.siValue <= railOneDescription.Start.siValue;

    const railOneEndValue = Math.round(railOneDescription.End.siValue / 100) * 100
    const railOneStartValue = Math.round(railOneDescription.Start.siValue / 100) * 100
    const railTwoEndValue = Math.round(railTwoDescription.End.siValue / 100) * 100
    const railTwoStartValue = Math.round(railTwoDescription.Start.siValue / 100) * 100

    const railOneLength = railOneDescription.Start.siValue <= railOneDescription.End.siValue ? railOneEndValue - railOneStartValue : railOneStartValue - railOneEndValue;
    const railTwoLength = railTwoDescription.Start.siValue <= railTwoDescription.End.siValue ? railTwoEndValue - railTwoStartValue : railTwoStartValue - railTwoEndValue;
    const maxRailLength = railOneLength <= railTwoLength ? railTwoLength : railOneLength;

    const sectionRectScale = Math.round(maxRailLength / 1800);
    const renderedRailLength = Math.ceil(maxRailLength / sectionLength) * sectionLength / sectionRectScale

    const canvasId = "outdoor-visualization-canvas";
    const canvasPadding = 50
    const railOne: JSX.Element[] = [];
    const railTwo: JSX.Element[] = [];
    const selectedRail: JSX.Element[] = [];
    const eastPortal: JSX.Element[] = [];

    const [conflictRail, setConflictRail] = useState(RailNumber.Eins);
    const [conflictSectionIndex, setConflictSectionIndex] = useState(0);
    const [conflictDialogOpen, setConflictDialogOpen] = useState(false);
    const railOneConflictWorkRegistrations: OutdoorWorkRegistrationsAndConstructions[][] = new Array(Math.ceil(maxRailLength / sectionLength)).fill(undefined);
    const railTwoConflictWorkRegistrations: OutdoorWorkRegistrationsAndConstructions[][] = new Array(Math.ceil(maxRailLength / sectionLength));

    const [StripesBlockedImage] = useImage(StripesBlocked);
    const [StripesBlockedSideWorkImage] = useImage(StripesBlockedSideWork);
    const [StripesFreeBlockedImage] = useImage(StripesFreeBlocked);
    const [StripesFreeSideWorkImage] = useImage(StripesFreeSideWork);
    const [StripesFreeImage] = useImage(StripesFree);
    const [StripesSideWorkImage] = useImage(StripesSideWork);
    const [BlockedImage] = useImage(Blocked);
    const [FreeSideBlockedImage] = useImage(FreeSideBlocked);
    const [StripesIbnImage] = useImage(StripesIbn);
    const [IbnFreeImage] = useImage(IbnFree);
    const [IbnSideWorkImage] = useImage(IbnSideWork);
    const [IbnBlockedImage] = useImage(IbnBlocked);

    const [FreeBlockedIbnImage] = useImage(FreeBlockedIbn);
    const [FreeSideWorkBlockedIbnImage] = useImage(FreeSideWorkBlockedIbn);
    const [FreeSideWorkIbnImage] = useImage(FreeSideWorkIbn);
    const [SideWorkBlockedIbnImage] = useImage(SideWorkBlockedIbn);

    const localStageRef = useRef<Konva.Stage>(null);
    useEffect(() => {
        if (localStageRef.current) {
            props.changedStageRef(localStageRef.current);
        } else {
            props.changedStageRef(null);
        }
    }, [localStageRef])

    function getKilometerText(value: number) {
        const valueSplit = (value / 1000).toFixed(1).split('.')
        return valueSplit[0] + "," + valueSplit[1]
    }

    function renderTelemetryText(position: number, counter: number, isRailOne: boolean, currentRailPosition: number) {
        return counter % 2 === 0 ?
            <Text
                key={"TextRender" + counter}
                x={flipSides ? position - 30 : position + 6}
                y={(isRailOne ? upperRailY : lowerRail1Y) + 15}
                fontSize={textFontSize}
                text={getKilometerText(currentRailPosition)}
            /> :
            <Text key={"EmptyText" + position}></Text>
    }

    function renderRailNumberText() {
        return ([RailNumber.Eins, RailNumber.Zwei]).map((key, index) => {
            return <Text
                key={"RailNumberText" + key}
                x={flipSides ? 20 : 70}
                y={upperRailY + index * 50 - 20}
                fontSize={textFontSize + 3}
                text={LocalizationService.RailNumberWithLongitudinalDirection(key as RailNumber)}
            />
        })
    }

    function renderRail(isRailOne: boolean) {
        const currentRailStartPoint = isRailOne ? swapRailEnds ? flipSides ? railOneEndValue : railOneStartValue : flipSides ? railOneStartValue : railOneEndValue : swapRailEnds ? flipSides ? railTwoEndValue : railTwoStartValue : flipSides ? railTwoStartValue : railTwoEndValue;

        let currentRail = isRailOne ? railOne : railTwo
        let currentRailPosition = isRailOne ? swapRailEnds ? flipSides ? railOneStartValue : railOneEndValue : flipSides ? railOneEndValue : railOneStartValue : swapRailEnds ? flipSides ? railTwoStartValue : railTwoEndValue : flipSides ? railTwoEndValue : railTwoStartValue;
        let sectionNumber = 0;
        while (flipSides ? currentRailPosition >= currentRailStartPoint : currentRailPosition <= currentRailStartPoint) {
            const positionX = flipSides ? (renderedRailLength - sectionLength / sectionRectScale * sectionNumber) : (sectionLength / sectionRectScale * sectionNumber) + canvasPadding;
            currentRail.push(
                <Rect
                    x={positionX}
                    y={isRailOne ? upperRailY : lowerRail1Y}
                    width={(((currentRailPosition - currentRailStartPoint) <= sectionLength ? currentRailPosition - currentRailStartPoint : sectionLength) / sectionRectScale) * -1}
                    height={railRectHeight}
                    fill={RailTrackSecondaryColor}
                    key={"Rect2" + sectionNumber}
                    stroke="black"
                    strokeWidth={0.5}
                />,
                renderTelemetryText(positionX, sectionNumber, isRailOne, currentRailPosition)
            )

            sectionNumber++;
            flipSides ? currentRailPosition -= sectionLength : currentRailPosition += sectionLength;
        }
    }

    function renderSelectedRailSections() {
        let sortedOutdoorWorkRegistration = [...props.outdoorWorkRegistration]
            .sort((a, b) => Object.values(RailTrackStatus).indexOf(a.RailTrackStatus) - Object.values(RailTrackStatus).indexOf(b.RailTrackStatus))
        let sortedStatusColor = sortedOutdoorWorkRegistration.map(value => getIbnOrRailTrackColor(value.ThirdLevelOutline, value.RailTrackStatus));

        sortedOutdoorWorkRegistration.forEach((workObject, index) => {
            const railMaxValue = (workObject.LocationDescription.RailNumber === RailNumber.Eins ? flipSides ? railOneStartValue : railOneEndValue : flipSides ? railTwoStartValue : railTwoEndValue);
            const railMinValue = (workObject.LocationDescription.RailNumber === RailNumber.Eins ? flipSides ? railOneEndValue : railOneStartValue : flipSides ? railTwoEndValue : railTwoStartValue);
            const workObjectStartValue = workObject.LocationDescription.ObjectDescription.AreaDescription.Start.siValue;
            const workObjectEndValue = workObject.LocationDescription.ObjectDescription.AreaDescription.End.siValue;
            const workObjectStartSectionIndex = Math.floor((workObjectStartValue - railMinValue) / sectionLength);
            const workObjectEndSectionIndex = Math.floor((workObjectEndValue - railMinValue) / sectionLength);

            let currentSectionIndex = workObjectStartSectionIndex;
            do {
                if (workObject.LocationDescription.RailNumber === RailNumber.Eins ? !railOneConflictWorkRegistrations[currentSectionIndex] : !railTwoConflictWorkRegistrations[currentSectionIndex])
                    workObject.LocationDescription.RailNumber === RailNumber.Eins ? railOneConflictWorkRegistrations[currentSectionIndex] = [] : railTwoConflictWorkRegistrations[currentSectionIndex] = []
                workObject.LocationDescription.RailNumber === RailNumber.Eins ? railOneConflictWorkRegistrations[currentSectionIndex].push(workObject) : railTwoConflictWorkRegistrations[currentSectionIndex].push(workObject)
                const sectionStartValue = railMinValue + (sectionLength * currentSectionIndex);
                const sectionEndValue = railMinValue + (sectionLength * currentSectionIndex) + sectionLength;

                const renderedStartValue = workObjectStartValue > sectionStartValue ? workObjectStartValue : sectionStartValue;
                const renderedEndValue = workObjectEndValue > sectionEndValue ? sectionEndValue : workObjectEndValue;

                const startPosition = renderedRailLength - (railMaxValue - renderedStartValue) / sectionRectScale + (workObject.LocationDescription.RailNumber === RailNumber.Eins && !flipSides ? -25 : 0);
                const endPosition = renderedRailLength - (railMaxValue - renderedEndValue) / sectionRectScale + (workObject.LocationDescription.RailNumber === RailNumber.Eins && !flipSides ? -25 : 0);

                const isOverlapping = (workObject.LocationDescription.RailNumber === RailNumber.Eins ? railOneConflictWorkRegistrations[currentSectionIndex] : railTwoConflictWorkRegistrations[currentSectionIndex]).length > 1;

                ((capturedSectionIndex: number) => {

                    const railTrackStatusList = Array.from(new Set((workObject.LocationDescription.RailNumber === RailNumber.Eins ? railOneConflictWorkRegistrations[capturedSectionIndex] : railTwoConflictWorkRegistrations[capturedSectionIndex]).map(element => translationsService.thirdLevelOutline.some(item => item.ID === element.ThirdLevelOutline && item.IsPartOfCommissioning) ? true : element.RailTrackStatus)));
                    let patternImage = BlockedImage;

                    switch (railTrackStatusList.length) {
                        case 1:
                            switch (railTrackStatusList[0]) {
                                case RailTrackStatus.Free:
                                    patternImage = StripesFreeImage;
                                    break;
                                case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                    patternImage = StripesSideWorkImage;
                                    break;
                                case RailTrackStatus.Blocked:
                                    patternImage = StripesBlockedImage;
                                    break;
                                case true:
                                    patternImage = StripesIbnImage;
                                    break;
                            }
                            break;
                        case 2:
                            switch (railTrackStatusList[0]) {
                                case RailTrackStatus.Free:
                                    switch (railTrackStatusList[1]) {
                                        case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                            patternImage = StripesFreeSideWorkImage;
                                            break;
                                        case RailTrackStatus.Blocked:
                                            patternImage = StripesFreeBlockedImage;
                                            break;
                                        case true:
                                            patternImage = IbnBlockedImage;
                                            break;
                                    }
                                    break;
                                case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                    switch (railTrackStatusList[1]) {
                                        case RailTrackStatus.Free:
                                            patternImage = StripesFreeSideWorkImage;
                                            break;
                                        case RailTrackStatus.Blocked:
                                            patternImage = StripesBlockedSideWorkImage;
                                            break;
                                        case true:
                                            patternImage = IbnSideWorkImage;
                                            break;
                                    }
                                    break;
                                case RailTrackStatus.Blocked:
                                    switch (railTrackStatusList[1]) {
                                        case RailTrackStatus.Free:
                                            patternImage = StripesFreeBlockedImage;
                                            break;
                                        case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                            patternImage = StripesBlockedSideWorkImage;
                                            break;
                                        case true:
                                            patternImage = IbnBlockedImage;
                                            break;
                                    }
                                    break;
                                case true:
                                    switch (railTrackStatusList[1]) {
                                        case RailTrackStatus.Free:
                                            patternImage = IbnFreeImage;
                                            break;
                                        case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                            patternImage = IbnSideWorkImage;
                                            break;
                                        case RailTrackStatus.Blocked:
                                            patternImage = IbnBlockedImage;
                                            break;
                                    }
                                    break;
                            }
                            break;
                        case 3:
                            if (railTrackStatusList.some(status => status === true)) {
                                if (railTrackStatusList.some(status => status === RailTrackStatus.Free) && railTrackStatusList.some(status => status === RailTrackStatus.Blocked)) {
                                    patternImage = FreeBlockedIbnImage;
                                }
                                if (railTrackStatusList.some(status => status === RailTrackStatus.Free) && railTrackStatusList.some(status => status === RailTrackStatus.WorkInRailTrackAreaPeriphery)) {
                                    patternImage = FreeSideWorkIbnImage;
                                }
                                if (railTrackStatusList.some(status => status === RailTrackStatus.WorkInRailTrackAreaPeriphery) && railTrackStatusList.some(status => status === RailTrackStatus.Blocked)) {
                                    patternImage = SideWorkBlockedIbnImage;
                                }
                            } else {
                                patternImage = FreeSideBlockedImage;
                            }
                            break;
                        case 4:
                        default:
                            patternImage = FreeSideWorkBlockedIbnImage;
                    }

                    selectedRail.push(
                        <Rect
                            x={startPosition}
                            y={workObject.LocationDescription.RailNumber === RailNumber.Eins ? upperRailY : lowerRail1Y}
                            width={endPosition - startPosition}
                            height={railRectHeight}
                            fill={isOverlapping ? undefined : sortedStatusColor[index]}
                            fillPatternImage={isOverlapping ? patternImage : undefined}
                            key={"selectedRailRect_" + index + capturedSectionIndex}
                            onClick={() => {
                                setConflictRail(workObject.LocationDescription.RailNumber);
                                setConflictSectionIndex(capturedSectionIndex);
                                setConflictDialogOpen(true);
                            }}
                            onMouseEnter={(e) => e.target.getStage()!.container().style.cursor = 'pointer'}
                            onMouseLeave={(e) => e.target.getStage()!.container().style.cursor = 'default'}
                            stroke="black"
                            strokeWidth={0.5}
                        />
                    )
                })(currentSectionIndex);

                currentSectionIndex++;
            } while (currentSectionIndex <= workObjectEndSectionIndex);
        });
    }

    function conflictWorkRegistrationsDialog(): JSX.Element {
        const selectedSectionStart = (conflictRail === RailNumber.Eins ? Math.min(railOneStartValue, railOneEndValue) : Math.min(railTwoStartValue, railTwoEndValue)) + (sectionLength * conflictSectionIndex);
        return (
            <Dialog
                open={conflictDialogOpen}
                onClose={() => setConflictDialogOpen(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "1700px",
                        },
                    },
                }}>
                <DialogTitle>Arbeiten zwischen {selectedSectionStart}-{selectedSectionStart+sectionLength} auf Gleis {LocalizationService.RailNumber(conflictRail)}</DialogTitle>
                <DialogContent>
                    <WeekOverviewGrid
                        changedSelectedWorkRegistration={() => {}}
                        view={KoralmLocation.Tunnel}
                        startDate={new Date()}
                        endDate={new Date()}
                        changedOutdoorWorkRegistration={() => {}}
                        changedTunnelWorkRegistration={() => {}}
                        showButtons={false}
                        tunnelWorkRegistration={conflictRail === RailNumber.Eins ? railOneConflictWorkRegistrations[conflictSectionIndex] : railTwoConflictWorkRegistrations[conflictSectionIndex]}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    function renderPortal() {
        const position = flipSides ? renderedRailLength : canvasPadding
        return eastPortal.push(
            <Rect
                x={position}
                y={upperRailY - 15}
                width={flipSides ? -5 : 5}
                height={lowerRail1Y + 15}
                fill={RailTrackPrimaryColor}
                key={"EastPortalRect"}
                stroke="black"
                strokeWidth={0.5}
            />,
            <Text
                key={"EastPortalText"}
                x={position - 35}
                y={105}
                fontSize={textFontSize + 3}
                text={LocalizationService.LatitudinalDirection(flipSides ? LatitudinalDirection.East : LatitudinalDirection.West) + " " + LocalizationService.TunnelObject(TunnelObject.Portal)}
            />
        )
    }

    function CalculateX(value: number) {
        return renderedRailLength - (railOneStartValue - value) / sectionLength * 100
    }

    renderRail(true)
    renderRail(false)
    renderSelectedRailSections()
    renderPortal()

    useEffect(() => {
        const canvasContainer = document.getElementById(canvasId);
        if (canvasContainer !== undefined && canvasContainer !== null) {
            canvasContainer.scrollTo(CalculateX(start[0] * 1000) - windowSize.width / 3, 0);
        }
    }, [start[0]])

    useEffect(() => {
        const canvasContainer = document.getElementById(canvasId);
        if (canvasContainer !== undefined && canvasContainer !== null) {
            canvasContainer.scrollTo(CalculateX(end[0] * 1000) - windowSize.width / 3, 0);
        }
    }, [end[0]])

    return (
        <Stack
            id={canvasId}
            justifyContent="center"
            maxWidth={windowSize.width}
            bgcolor="warning"
            style={{overflow: "auto"}}
            key={"OutdoorRailsVisualizationStack"}>
            {conflictDialogOpen && conflictWorkRegistrationsDialog()}
            <Stage
                ref={localStageRef}
                width={Math.round(maxRailLength / sectionRectScale + canvasPadding * 2)}
                height={140}
                key={"OutdoorStageRenderer"}>
                <Layer>
                    <Group>
                        {renderRailNumberText()}
                        {railOne}
                        {railTwo}
                        {selectedRail}
                    </Group>
                    {eastPortal}
                </Layer>
            </Stage>
        </Stack>
    )
}