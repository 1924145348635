import {pageStateType, pageStateWithHeaderToolbarProps} from "../utils/Types";
import {FilterOperation, Order} from "../generated";
import {FilterType} from "../utils/FilterTypes";
import UserService from "./UserService";
import {DEFAULT_DATA_GRID_PAGE_SIZE} from "../constants";

class PageFilterProvider {
    initialSelectedContractors = UserService.tryGetCurrentContractorOrMappedCompanies();
    pageFilterWithHeaderToolbarState: pageStateWithHeaderToolbarProps = {
        isLoading: false,
        total: 0,
        page: 0,
        pageSize: DEFAULT_DATA_GRID_PAGE_SIZE,
        sortingProperty: "Start",
        sortingValue: Order.Ascending,
        filterProperty: "undefined",
        filterValues: [],
        filterOperator: FilterOperation.Equals,
        selectFilter: FilterType.CrossCut,
        selectedContractors: this.initialSelectedContractors ? this.initialSelectedContractors : [],
    };

    pageFilterState: pageStateType = {
        isLoading: false,
        total: 0,
        page: 0,
        pageSize: DEFAULT_DATA_GRID_PAGE_SIZE,
        sortingProperty: "Start",
        sortingValue: Order.Ascending,
        filterProperty: "undefined",
        filterValues: [""],
        filterOperator: FilterOperation.Equals,
    };
}

export const pageFilterProvider = new PageFilterProvider();