import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, IconButton,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DateRangeButton from "./DateRangeButton";
import ExcelExportButton from "./ExcelExportButton";
import {PermissionManager} from "../../utils/PermissionManager";
import PersonCountExport from "./PersonCountExport";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import excelImg from "../../img/microsoft-excel.png"
import {handleDialogExportDownload, handleHistoryExcelParams} from "../../utils/Exports";
import {EXCEL_FILE} from "../../constants";
import LogisticExportTab from "./LogisticExportTab";
import CloseIcon from "@mui/icons-material/Close";

function ExportManager() {
    const initialTab = "Wochenprogramm"
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedExportTab, setSelectedExportTab] = useState<string>(initialTab);
    const [isFetching, setIsFetching] = React.useState(false)
    const [errorToastOpen, setErrorToastOpen] = React.useState(false);

    const renderCombinedHistoryTab = () => {
        return (
            <Stack spacing={5} padding={5}>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} variant='contained'
                            color="secondary">Abbrechen</Button>
                    <Box sx={{margin: 1, position: 'relative'}}>
                        <Button
                            onClick={async () => {
                                try {
                                    setIsFetching(true)
                                    await handleDialogExportDownload(handleHistoryExcelParams(), "Gesamthistorie" + EXCEL_FILE);
                                    return setIsFetching(false);
                                } catch (e) {
                                    setErrorToastOpen(true)
                                    return setIsFetching(false)
                                }
                            }}
                            variant='contained'
                            disabled={isFetching}
                            color="primary"><img src={excelImg}
                                                 alt={"Microsoft Excel Logo"}/> Download<FileDownloadOutlinedIcon/></Button>
                        {isFetching &&
                            <CircularProgress
                                size={24} sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                            }}/>}
                    </Box>
                </DialogActions>
                {errorToastOpen && <Alert severity="error"
                                          action={
                                              <IconButton
                                                  aria-label="close"
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                      setErrorToastOpen(false);
                                                  }}>
                                                  <CloseIcon fontSize="inherit"/>
                                              </IconButton>
                                          }>
                    Download fehlgeschlagen!
                </Alert>}
            </Stack>
        )
    }

    const tabDefinitions = [
        {
            dialog: initialTab,
            component: <DateRangeButton usingPostItView={false} handleCancelButton={() => setOpenDialog(false)}/>,
            permission: PermissionManager.Universal.pdfExport,
        },
        {
            dialog: "Lean Board",
            component: <DateRangeButton usingPostItView={true} handleCancelButton={() => setOpenDialog(false)}/>,
            permission: PermissionManager.Universal.excelLeanBoardExport,
        },
        {
            dialog: "Personenanzahl",
            component: <PersonCountExport loadsPersonData={true} handleCancelButton={() => setOpenDialog(false)}/>,
            permission: PermissionManager.Universal.personCountExport,
        },
        {
            dialog: "Logbuch",
            component: <ExcelExportButton loadsPersonData={false} handleCancelButton={() => setOpenDialog(false)}/>,
            permission: PermissionManager.Universal.logbookExport,
        },
        {
            dialog: "Logistik",
            component: <LogisticExportTab handleCancelButton={() => setOpenDialog(false)}/>,
            permission: PermissionManager.Universal.logisticExport,
        },
        {
            dialog: "Gesamthistorie",
            component: renderCombinedHistoryTab(),
            permission: PermissionManager.Universal.combinedHistoryExport,
        },
    ]

    return (
        <div>
            <Button
                variant='contained'
                color="secondary"
                onClick={() => setOpenDialog(true)}
                style={{
                    width: 150,
                    height: 45,
                    fontSize: 16,
                    padding: '0px 0px 5px'
                }}
            >
                <Stack direction="row" alignItems="center">
                    <PictureAsPdfOutlinedIcon
                        style={{
                            fontSize: '30px',
                            padding: '5px 0px 0px'
                        }}/>
                    <img src={excelImg} alt={"Microsoft Excel Logo"}/>
                    <Typography>Exporte</Typography>
                    <FileDownloadOutlinedIcon
                        style={{fontSize: '30px'}}/>
                </Stack>
            </Button>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogContent>
                    <Tabs
                        value={selectedExportTab}
                        onChange={(event, value) => setSelectedExportTab(value)}
                        aria-label="auto export tabs"
                        TabIndicatorProps={{sx: {display: 'none'}}}
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                        }}
                    >
                        {tabDefinitions.map(({dialog, component, permission}, index) => (
                            permission && (
                                <Tab
                                    key={dialog + component + permission + index}
                                    value={dialog}
                                    label={dialog}
                                />
                            )
                        ))}
                    </Tabs>
                    {tabDefinitions.map(({dialog, component}) => (
                        dialog === selectedExportTab && (
                            <div key={dialog}>
                                {component}
                            </div>
                        )
                    ))}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ExportManager;