import React from "react";
import {
    Alert,
    Dialog,
    DialogContent,
    FormControlLabel,
    IconButton, Select,
    Snackbar,
    Stack,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {Warehouse} from "@mui/icons-material";
import {
    HEADER_BAR_ICON_BUTTON_HEIGHT,
    HEADER_BAR_ICON_FONT_SIZE,
    HEADER_BAR_ICON_PADDING,
    POP_UP_CONTENT_HEIGHT
} from "../../styling-constants";
import DialogTitleWithCloseButton from "./DialogTitleWithCloseButton";
import DefaultDataGrid from "../DefaultDataGrid/DefaultDataGrid";
import {GridColumns} from "@mui/x-data-grid/models/colDef/gridColDef";
import {
    FilterDescriptionCombinator,
    FilterOperation,
    Length,
    Mass,
    Order,
    StorageData,
    StorageDataApiFp,
    StorageDataStatus,
    WebFilterDescription
} from "../../generated";
import {
    ColumnHeaderService,
    DEFAULT_DATA_GRID_PAGE_SIZE,
    ERROR_MESSAGE_SNACKBAR,
    NA_PLACEHOLDER,
    SUCCESS_MESSAGE_SNACKBAR
} from "../../constants";
import {GridColDef, GridRenderCellParams, GridRowSpacingParams, useGridApiContext} from "@mui/x-data-grid";
import {stringFilterOperators} from "../../utils/FilterOperators";
import {getGridDateOperators} from "@mui/x-data-grid/colDef/gridDateOperators";
import UserService from "../../services/UserService";
import {storageDataMappingProvider} from "../../services/storage-data-mapping-provider";
import {GridValueFormatterParams, GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {format} from "date-fns";
import {ParseFilterOperator} from "../../utils/FilterTypes";
import EditIcon from '@mui/icons-material/Edit';
import {translationsService} from "../../services/translationsProvider";
import {PermissionManager} from "../../utils/PermissionManager";
import {de} from "date-fns/locale";
import {ContractorMenuItems} from "../../utils/Exports";
import {SELECTED_MENU_ITEM} from "../../utils/Colors";


function MaterialLogisticsPopUp() {
    const DeliveryDateAtStorageProperty = 'DeliveryDateAtStorage';
    const ContractorIdProperty = ColumnHeaderService.GetContractorValue(false);
    const CurrentLocationIdProperty = 'CurrentLocationId';

    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(DEFAULT_DATA_GRID_PAGE_SIZE);
    const [storageDatums, setStorageDatums] = React.useState<StorageData[]>([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [sortProperty, setSortProperty] = React.useState(DeliveryDateAtStorageProperty);
    const [order, setOrder] = React.useState(Order.Descending);
    const [showUnloadedData, setShowUnloadedData] = React.useState(false);

    const currentContractorId = UserService.tryGetCurrentContractorId();
    const defaultContractorFilter: WebFilterDescription = {
        FilterOperation: currentContractorId ? FilterOperation.OneOf : FilterOperation.NotEquals,
        FilterPropertyName: ContractorIdProperty,
        FilterPropertyValues: currentContractorId
            ? Object.entries(storageDataMappingProvider.contractorAliases)
                .filter(kv => kv[0] === currentContractorId)
                .flatMap(kv => kv[1] as string[]).concat([currentContractorId])
            : ['']
    };
    const [contractorFilter, setContractorFilter] = React.useState<WebFilterDescription>(defaultContractorFilter);
    const [optionalFilter, setOptionalFilter] = React.useState<(WebFilterDescription | null)>(null);

    const title = "Materialabruf";
    const contractorEditText = PermissionManager.ListView.canEditMaterialLogisticContractor() ?  " oder der Firma " : ""

    const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const statusName = 'Status';


    React.useEffect(() => {
        const getData = async () => {
            const dataPage = await StorageDataApiFp().storageDataGetPagedWithCombinedFilterAndGetData(pageSize, pageIndex, sortProperty, order, {
                Combinator: FilterDescriptionCombinator.And,
                ChildDescriptions: [],
                LeafDescriptions: [{
                    FilterOperation: FilterOperation.NotEquals,
                    FilterPropertyName: 'OrderPicking',
                    FilterPropertyValues: [true.toString()]
                },
                    contractorFilter,
                    ...!showUnloadedData ? [{
                        FilterOperation: FilterOperation.NotEquals,
                        FilterPropertyName: statusName,
                        FilterPropertyValues: [StorageDataStatus.Unloded]
                    }] : [],
                    ...optionalFilter === null ? [] : [optionalFilter]]
            });
            setStorageDatums(dataPage.Data);
            setRowCount(dataPage.ItemCount);
        }

        if (open) {
            if (!loading) {
                setLoading(true);
            }
            void getData();
            if (loading) {
                setLoading(false);
            }
        }
    }, [pageSize, pageIndex, open, sortProperty, order, contractorFilter, optionalFilter, showUnloadedData]);

    const preferredDeliveryDateDelimiter = '-';
    const lengthFormatter = (params: GridValueFormatterParams<(number | null)>) =>
        params.value ? params.value + " m" : NA_PLACEHOLDER
    const unitOfMeasureValueGetter = (params: GridValueGetterParams<(Mass | Length), StorageData>) =>
        params.value ? params.value.siValue : null;

    const preferredDeliveryDateName = "Gewünschtes Lieferdatum";
    const tooltipText = "Doppelt klicken zum Editieren";

    const renderContractorInputCell: GridColDef['renderCell'] = (params) => {
        return <ContractorInputCell {...params} />;
    };

    function ContractorInputCell(props: GridRenderCellParams) {
        const {id, value, field} = props;
        const apiRef = useGridApiContext();

        return (
          <Select
              name="MaterialLogistic-ContractorEditSelection"
              value={value}
              fullWidth={true}
              color="secondary"
              onChange={(event) => apiRef.current.setEditCellValue({
                  id, field, value: event.target.value as string
              })}
              MenuProps={{
                  sx: {
                      "&& .Mui-selected": {
                          backgroundColor: SELECTED_MENU_ITEM
                      }
                  }
              }}
          >
              {ContractorMenuItems()}
          </Select>
        );
    }

    const columns: GridColumns<StorageData> = [
        {
            field: ColumnHeaderService.GetIDValue(),
            headerName: "Artikel ID",
            filterOperators: stringFilterOperators,
            filterable: true,
            width: 140
        },
        {
            field: 'ArticleName',
            headerName: "Artkelname",
            filterOperators: stringFilterOperators.filter(({value}) => value === 'contains'),
            filterable: true,
            valueFormatter: params => {
                const name = params.value
                if (name) {
                    const delimiterChar = '_';
                    return name.includes(delimiterChar) ? name.split(delimiterChar)[1] : name;
                }
                return NA_PLACEHOLDER;
            },
            width: 180
        },
        {
            field: DeliveryDateAtStorageProperty,
            headerName: "Lieferdatum BE-Fläche",
            filterOperators: getGridDateOperators(false),
            filterable: true,
            type: 'date',
            valueGetter: params => new Date(params.value),
            width: 155
        },
        {
            field: CurrentLocationIdProperty,
            headerName: "Standort",
            filterOperators: stringFilterOperators,
            filterable: true,
            width: 150,
            valueGetter: params => params.value === 'Block' && params.row.BlockIdStart && params.row.BlockIdEnd
                ? `${params.value} ${params.row.BlockIdStart}-${params.row.BlockIdEnd}`
                : storageDataMappingProvider.locationIdToNameMapping.has(params.value)
                    ? storageDataMappingProvider.locationIdToNameMapping.get(params.value) as string
                    : params.value
        },
        {
            field: 'PreferredDeliveryDate',
            headerName: preferredDeliveryDateName,
            filterable: false,
            editable: true,
            type: 'date',
            valueGetter: params => params.value ? new Date(params.value as string) : null,
            valueSetter: params => {
                const row = {...params.row};
                const newDate = params.value;
                if (newDate instanceof Date) {
                    const zeroPad = (num: number) => String(num).padStart(2, '0');
                    row.PreferredDeliveryDate =
                        `${newDate.getFullYear()}${preferredDeliveryDateDelimiter}${zeroPad(newDate.getMonth() + 1)}${preferredDeliveryDateDelimiter}${zeroPad(newDate.getDate())}`;
                } else {
                    row.PreferredDeliveryDate = null;
                }
                return row;
            },
            renderCell: params =>
                <Tooltip title={params.isEditable ? <Typography>{tooltipText}</Typography> : ''}>
                    <Stack direction="row" width={'100%'} justifyContent="space-between">
                        {params.formattedValue}
                        {params.cellMode === 'view' && params.isEditable && <EditIcon/>}
                    </Stack>
                </Tooltip>,
            width: 170
        },
        {
            field: 'Destination',
            headerName: "Zielort",
            filterOperators: stringFilterOperators,
            filterable: true,
            width: 150
        },
        {
            field: statusName,
            headerName: statusName,
            filterable: false,
            width: 100,
            renderCell: params => translationsService.storageDataStatus.get(params.value)
        },
        ...PermissionManager.Universal.showMaterialPopUpContractor ? [{
            field: ContractorIdProperty,
            headerName: ColumnHeaderService.GetContractorValue(true),
            filterOperators: stringFilterOperators.filter(({value}) => value === 'equals'),
            filterable: true,
            width: 120,
            editable: PermissionManager.ListView.canEditMaterialLogisticContractor(),
            renderEditCell: renderContractorInputCell,
            valueGetter: (params: GridValueGetterParams<any, StorageData>) => {
                if (!params.value) {
                    return null;
                }
                const alias = Object.entries(storageDataMappingProvider.contractorAliases).filter(kv => kv[1].includes(params.value)).map(kv => kv[0]);
                return alias.length === 0 ? params.value : alias[0];
            },
            renderCell: (params: any) => {
                return <Tooltip title={params.isEditable ? <Typography>{tooltipText}</Typography> : ''}>
                    <Stack direction="row" width={'100%'} justifyContent="space-between">
                        {params.formattedValue}
                        {params.cellMode === 'view' && params.isEditable && <EditIcon/>}
                    </Stack>
                </Tooltip>;
            },
        }] : [],
        {
            field: 'Quantity',
            headerName: "Anzahl",
            filterable: false,
            width: 120,
            type: "number"
        },
        {
            field: 'Type',
            headerName: "Gebinde",
            filterable: false,
            width: 200
        },
        {
            headerName: "Gewicht",
            field: 'Weight',
            filterable: false,
            type: 'number',
            width: 120,
            valueGetter: unitOfMeasureValueGetter,
            valueFormatter: params => params.value ? params.value + ' kg' : NA_PLACEHOLDER
        },
        {
            headerName: "Länge",
            field: 'Length',
            filterable: false,
            type: 'number',
            width: 105,
            valueGetter: unitOfMeasureValueGetter,
            valueFormatter: lengthFormatter
        },
        {
            headerName: "Breite",
            field: 'Width',
            filterable: false,
            type: 'number',
            width: 105,
            valueGetter: unitOfMeasureValueGetter,
            valueFormatter: lengthFormatter
        },
    ];

    columns.forEach(c => {
        c.sortable = true;
        if (!c.valueFormatter) {
            if (c.type === 'date') {
                c.valueFormatter = (params) => params.value ? format(params.value, "dd.MM.yyyy", {locale: de}) : NA_PLACEHOLDER
            } else {
                c.valueFormatter = (params) => params.value ? params.value : NA_PLACEHOLDER;
            }
        }
    })

    const handleSnackbarMessage = (message: string) => {
        setSnackbarMessage(message)
        setSnackbarOpen(true)
    }

    return (
        <div>
            <Tooltip title={<Typography>{title}</Typography>}>
                <IconButton
                    onClick={() => setOpen(true)} style={{color: "white", height: HEADER_BAR_ICON_BUTTON_HEIGHT}}
                >
                    <Warehouse style={{fontSize: HEADER_BAR_ICON_FONT_SIZE, padding: HEADER_BAR_ICON_PADDING}}/>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={false}>
                <DialogTitleWithCloseButton title={
                    <Stack direction="row" justifyContent='space-between'>
                        <Stack spacing={1} direction="row">
                            <Typography fontWeight={'bold'}>{title}</Typography>
                            <Typography>{`Editieren des gewünschten Lieferdatums ${contractorEditText} mit Doppelklick`}</Typography>
                        </Stack>
                        <FormControlLabel control={
                            <Switch onChange={(event, checked) => setShowUnloadedData(checked)}
                                    checked={showUnloadedData}/>
                        } label={"Entladene Artikel anzeigen"}/>
                    </Stack>} onClose={() => setOpen(false)}/>
                <DialogContent style={{height: POP_UP_CONTENT_HEIGHT}}>
                    <DefaultDataGrid
                        mode={'server'}
                        loading={loading}
                        page={pageIndex}
                        pageSize={pageSize}
                        onPageChange={newPage => setPageIndex(newPage)}
                        onPageSizeChange={setPageSize}
                        rows={storageDatums}
                        rowCount={rowCount}
                        getRowSpacing={getRowSpacing}
                        isCellEditable={params => (params.row.Status !== StorageDataStatus.Unloded) && PermissionManager.ListView.canEditCurrentLine(params)}
                        processRowUpdate={async (newRow: StorageData, oldRow: StorageData) => {
                            try {
                                let saved;
                                if (newRow.ContractorId !== oldRow.ContractorId) {
                                    saved = await StorageDataApiFp().storageDataSaveStorageContractorIdUpdateAndGetData({
                                        ArticleId: newRow.ID,
                                        ContractorId: newRow.ContractorId,
                                    });
                                } else {
                                    saved = await StorageDataApiFp()
                                        .storageDataSaveStorageDataDeliveryDateUpdateAndGetData({
                                            ArticleId: newRow.ID,
                                            Date: newRow.PreferredDeliveryDate as string
                                        });
                                }
                                if (saved) {
                                    handleSnackbarMessage(SUCCESS_MESSAGE_SNACKBAR);
                                    return newRow;
                                } else {
                                    handleSnackbarMessage(ERROR_MESSAGE_SNACKBAR);
                                    return oldRow;
                                }
                            } catch (error) {
                                console.error(error);
                                handleSnackbarMessage(ERROR_MESSAGE_SNACKBAR);
                                return oldRow;
                            }
                        }}
                        columns={columns}
                        sortModel={[{
                            field: sortProperty,
                            sort: order === Order.Ascending ? 'asc' : 'desc'
                        }]}
                        onSortModelChange={(sortModel) => {
                            const sortItem = sortModel[0];
                            if (sortItem) {
                                setSortProperty(sortItem.field);
                            }
                            setOrder((oldValue: Order) => oldValue === Order.Ascending ? Order.Descending : Order.Ascending);
                            setPageIndex(0);
                        }}
                        onFilterModelChange={(filterModel) => {
                            const currentFilter = filterModel.items[0];
                            if (currentFilter && currentFilter.value) {
                                const operator = ParseFilterOperator(currentFilter.operatorValue);
                                if (currentFilter.columnField === ContractorIdProperty) {
                                    setOptionalFilter(null);
                                    setContractorFilter({
                                        FilterOperation: operator,
                                        FilterPropertyName: ContractorIdProperty,
                                        FilterPropertyValues: [currentFilter.value]
                                    });
                                } else if (currentFilter.columnField === CurrentLocationIdProperty) {
                                    setContractorFilter(defaultContractorFilter);
                                    setOptionalFilter({
                                        FilterOperation: FilterOperation.OneOf,
                                        FilterPropertyName: CurrentLocationIdProperty,
                                        FilterPropertyValues: Array.from(storageDataMappingProvider
                                            .locationIdToNameMapping.entries()).filter(
                                            m => operator === FilterOperation.RegexOnString
                                                ? m[1].includes(currentFilter.value)
                                                : m[1] === currentFilter.value).map(m => m[0])
                                    });
                                } else {
                                    setContractorFilter(defaultContractorFilter);
                                    setOptionalFilter({
                                        FilterOperation: operator,
                                        FilterPropertyName: currentFilter.columnField,
                                        FilterPropertyValues: [currentFilter.value]
                                    });
                                }
                            } else {
                                setOptionalFilter(null);
                                setContractorFilter(defaultContractorFilter)
                            }
                        }}
                    />
                    <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={() => setSnackbarOpen(false)}>
                        <Alert onClose={() => setSnackbarOpen(false)} severity="info"
                               sx={{width: '100%'}}>{snackbarMessage}</Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default MaterialLogisticsPopUp;