import React, {useEffect, useState} from 'react';
import {Grid, Input, Select, Slider, Stack, TextField, Typography} from '@mui/material';
import {ContractorOverviewArgs} from "../../utils/InterfaceProps";
import {ContractorPlaceholder, DEFAULT_IBN_CONTRACTOR, MAX_PERSON_COUNT, NOTES_MAX_CHAR_COUNT} from "../../constants";
import {PermissionManager} from "../../utils/PermissionManager";
import {ContractorMenuItems, GetAvailableContractors, ThirdLevelOutlineMenuItems} from "../../utils/Exports";
import {contractorService} from "../../services/contractorProvider";
import {translationsService} from "../../services/translationsProvider";

function ContractorMask(props: ContractorOverviewArgs) {
    const styling = {
        container: {
            marginBlockEnd: 1,
            paddingRight: 1,
            paddingBottom: 1
        }
    }
    const [characterCount, setCharacterCount] = useState(0);

    const handleCharacterCount = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCharacterCount(event.target.value.length)
        props.changedNotesTextField(event.target.value as string)
    }

    useEffect(() => {
        if (props.notes) {
            setCharacterCount(props.notes.length);
        }

    }, [props.notes])

    const isCompanyNameUnavailable = () => {
        for (let contractor of contractorService.contractors) {
            if (props.company === contractor.ID) return false
        }
        return props.company !== ContractorPlaceholder;
    }

    const canEditThirdLevelOutline = PermissionManager.WorkRegistrationEditing.changeThirdLevelOutline();

    return (
        <Grid sx={styling.container}
              container
              alignItems={"center"}
        >
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} padding={2} margin={2}
                       sx={{bgcolor: 'secondary.main', borderRadius: '5px'}}>
                    <Stack direction="column" spacing={2} padding={2}>
                        <Typography> Firmenname:</Typography>
                        <Typography> Ansprechpartner:</Typography>
                        <Typography> Telefonnummer:</Typography>
                        <Typography> E-Mail:</Typography>
                    </Stack>
                    <Stack direction="column" paddingTop={1}>
                        <Stack direction="row">
                            <Select
                                name="ContractorSelection"
                                disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.companyFreeSelection : !PermissionManager.WorkRegistrationEditing.companyFreeSelection}
                                labelId="contractor-id-selection-label"
                                id="contractor-id-selection"
                                value={props.company}
                                onChange={(event) => props.changedCompanyTextField(event.target.value)}
                                sx={{minWidth: 400, height: 40}}
                                error={!props.company}
                            >
                                {ContractorMenuItems(PermissionManager.NewWorkRegistration.limitToOebbCompanySelection, PermissionManager.NewWorkRegistration.limitToMappedCompanies)}
                            </Select>
                            {isCompanyNameUnavailable() && <Typography paddingTop={1} paddingLeft={2} color="red">Fehlerhafter
                                Firmenname</Typography>}
                        </Stack>
                        <Stack direction="row">
                            <TextField
                                sx={{maxWidth: 200}}
                                size="small"
                                placeholder="Max"
                                defaultValue={props.firstName}
                                onChange={(event) => props.changedFirstNameTextField(event.target.value)}
                            >
                            </TextField>
                            <TextField
                                sx={{maxWidth: 200}}
                                size="small"
                                placeholder="Musterman"
                                defaultValue={props.lastName}
                                onChange={(event) => props.changedLastNameTextField(event.target.value)}
                            >
                            </TextField>
                        </Stack>
                        <TextField
                            size="small"
                            placeholder="+43 XXXX XXXX XXXX"
                            defaultValue={props.telephone}
                            onChange={(event) => props.changedTelephoneTextField(event.target.value)}
                            sx={{maxWidth: 400}}
                        ></TextField>
                        <TextField
                            size="small"
                            placeholder="musterfirma@email.de"
                            defaultValue={props.mail}
                            onChange={(event) => props.changedMailTextField(event.target.value)}
                            sx={{maxWidth: 400}}
                        ></TextField>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} padding={2}>
                    <Stack alignItems="center" direction="row">
                        Arbeitsschritt
                    </Stack>
                    <TextField
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.workingStep : !PermissionManager.WorkRegistrationEditing.workingStep}
                        fullWidth
                        placeholder='Arbeitsschritt angeben'
                        defaultValue={props.workStep}
                        onChange={(event) => props.changedWorkStepTextField(event.target.value)}
                        error={!props.workStep}
                    >
                    </TextField>
                </Stack>
            </Grid>
            <Grid item xs={true}>
                <Stack direction={"row"} paddingLeft={2} spacing={3}>
                    <Typography paddingTop={1}>Gliederungsebene Wochenprogramm:</Typography>
                    <Select value={props.thirdLevelOutline ? props.thirdLevelOutline : ''}
                            name="ThirdLevelOutlineSelection"
                            onChange={(event) => {
                                props.changedThirdLevelOutline(event.target.value);
                                if ((event.target.value as string | null)
                                    && translationsService.thirdLevelOutline.some(item => item.ID === (event.target.value as string | null) && item.IsPartOfCommissioning)
                                    && GetAvailableContractors(PermissionManager.NewWorkRegistration.limitToOebbCompanySelection, PermissionManager.NewWorkRegistration.limitToMappedCompanies).some(contractor => contractor.DisplayName === DEFAULT_IBN_CONTRACTOR)) {
                                    props.changedCompanyTextField(DEFAULT_IBN_CONTRACTOR)
                                }
                            }}
                            disabled={!canEditThirdLevelOutline}
                            sx={{minWidth: 220, height: 50}}>
                        {ThirdLevelOutlineMenuItems()}
                    </Select>
                </Stack>
            </Grid>
            <Grid item xs={true} padding={2}>
                <Stack direction="row" spacing={2} paddingRight={2}>
                    <Typography paddingTop={1}>Personen:</Typography>
                    <Input
                        name="PersonCountInput"
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.personCount : !PermissionManager.WorkRegistrationEditing.personCount}
                        value={props.personCount}
                        size="medium"
                        onChange={(event) => props.changedPersonCount(event.target.value as unknown as number)}
                        aria-labelledby="personCountLabel"
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: MAX_PERSON_COUNT,
                            type: 'number',
                        }}
                        sx={{marginRight: 2, width: 140}}
                    />
                    <Slider
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.personCount : !PermissionManager.WorkRegistrationEditing.personCount}
                        getAriaLabel={() => 'Person Slider'}
                        value={props.personCount}
                        onChange={(event, value) => props.changedPersonCount(value as number)}
                        valueLabelDisplay="auto"
                        color="primary"
                        min={0}
                        max={MAX_PERSON_COUNT}
                        step={1}
                        aria-labelledby="personCountLabel"
                        sx={{
                            '& .MuiSlider-thumb': {
                                color: "#454545"
                            },
                            '& .MuiSlider-track': {
                                color: "#575757"
                            },
                            '& .MuiSlider-rail': {
                                color: "#5f5f5f"
                            },
                        }}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack padding={2}>
                    <label htmlFor="NotesForPlanerTextFiel">Notizen für die Planer</label>
                    <TextField
                        id="NotesForPlanerTextFiel"
                        name="NotesForPlanerTextFiel"
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.notes : !PermissionManager.WorkRegistrationEditing.notes}
                        multiline={true}
                        inputProps={{maxLength: NOTES_MAX_CHAR_COUNT}}
                        placeholder={""}
                        minRows={3}
                        defaultValue={props.notes}
                        onChange={handleCharacterCount}
                    >
                    </TextField>
                    <Typography>Zeichenlimit: {characterCount}/{NOTES_MAX_CHAR_COUNT}</Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default ContractorMask;