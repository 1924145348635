import {Service} from "./service";
import {Contractor, ContractorApiFp} from "../generated";

class ContractorProvider extends Service {
    contractors: Contractor[] = [];

    async init(): Promise<void> {
        this.contractors = await ContractorApiFp().contractorGetAllAndGetData();
    }
}

export const contractorService = new ContractorProvider();