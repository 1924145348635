/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
// @ts-ignore
import { TrainStationInTunnel } from '../model';
/**
 * TrainStationApi - axios parameter creator
 * @export
 */
export const TrainStationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationDeleteBy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('trainStationDeleteBy', 'id', id)
            const localVarPath = `/TrainStation/DeleteBy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TrainStation/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationGetInterval: async (startIncluding: string, endIncluding: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('trainStationGetInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('trainStationGetInterval', 'endIncluding', endIncluding)
            const localVarPath = `/TrainStation/GetInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}`
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TrainStationInTunnel} trainStationInTunnel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationSave: async (trainStationInTunnel: TrainStationInTunnel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainStationInTunnel' is not null or undefined
            assertParamExists('trainStationSave', 'trainStationInTunnel', trainStationInTunnel)
            const localVarPath = `/TrainStation/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainStationInTunnel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<TrainStationInTunnel>} trainStationInTunnel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationSaveMany: async (trainStationInTunnel: Array<TrainStationInTunnel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainStationInTunnel' is not null or undefined
            assertParamExists('trainStationSaveMany', 'trainStationInTunnel', trainStationInTunnel)
            const localVarPath = `/TrainStation/SaveMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainStationInTunnel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainStationApi - functional programming interface
 * @export
 */
export const TrainStationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainStationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainStationDeleteBy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainStationDeleteBy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async trainStationDeleteBySendRequest(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return (await this.trainStationDeleteBy(id,  options))();
        },
        async trainStationDeleteByAndGetData(id: string, options?: AxiosRequestConfig): Promise<boolean> {
            return (await this.trainStationDeleteBySendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainStationGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrainStationInTunnel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainStationGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async trainStationGetAllSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<TrainStationInTunnel>>> {
            return (await this.trainStationGetAll( options))();
        },
        async trainStationGetAllAndGetData(options?: AxiosRequestConfig): Promise<Array<TrainStationInTunnel>> {
            return (await this.trainStationGetAllSendRequest( options)).data;
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainStationGetInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrainStationInTunnel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainStationGetInterval(startIncluding, endIncluding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async trainStationGetIntervalSendRequest(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<TrainStationInTunnel>>> {
            return (await this.trainStationGetInterval(startIncluding, endIncluding,  options))();
        },
        async trainStationGetIntervalAndGetData(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<Array<TrainStationInTunnel>> {
            return (await this.trainStationGetIntervalSendRequest(startIncluding, endIncluding,  options)).data;
        },
        /**
         * 
         * @param {TrainStationInTunnel} trainStationInTunnel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainStationSave(trainStationInTunnel: TrainStationInTunnel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainStationInTunnel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainStationSave(trainStationInTunnel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async trainStationSaveSendRequest(trainStationInTunnel: TrainStationInTunnel, options?: AxiosRequestConfig): Promise<AxiosResponse<TrainStationInTunnel>> {
            return (await this.trainStationSave(trainStationInTunnel,  options))();
        },
        async trainStationSaveAndGetData(trainStationInTunnel: TrainStationInTunnel, options?: AxiosRequestConfig): Promise<TrainStationInTunnel> {
            return (await this.trainStationSaveSendRequest(trainStationInTunnel,  options)).data;
        },
        /**
         * 
         * @param {Array<TrainStationInTunnel>} trainStationInTunnel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainStationSaveMany(trainStationInTunnel: Array<TrainStationInTunnel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrainStationInTunnel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainStationSaveMany(trainStationInTunnel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async trainStationSaveManySendRequest(trainStationInTunnel: Array<TrainStationInTunnel>, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<TrainStationInTunnel>>> {
            return (await this.trainStationSaveMany(trainStationInTunnel,  options))();
        },
        async trainStationSaveManyAndGetData(trainStationInTunnel: Array<TrainStationInTunnel>, options?: AxiosRequestConfig): Promise<Array<TrainStationInTunnel>> {
            return (await this.trainStationSaveManySendRequest(trainStationInTunnel,  options)).data;
        },
    }
};

/**
 * TrainStationApi - factory interface
 * @export
 */
export const TrainStationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainStationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationDeleteBy(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.trainStationDeleteBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationGetAll(options?: any): AxiosPromise<Array<TrainStationInTunnel>> {
            return localVarFp.trainStationGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationGetInterval(startIncluding: string, endIncluding: string, options?: any): AxiosPromise<Array<TrainStationInTunnel>> {
            return localVarFp.trainStationGetInterval(startIncluding, endIncluding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TrainStationInTunnel} trainStationInTunnel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationSave(trainStationInTunnel: TrainStationInTunnel, options?: any): AxiosPromise<TrainStationInTunnel> {
            return localVarFp.trainStationSave(trainStationInTunnel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<TrainStationInTunnel>} trainStationInTunnel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainStationSaveMany(trainStationInTunnel: Array<TrainStationInTunnel>, options?: any): AxiosPromise<Array<TrainStationInTunnel>> {
            return localVarFp.trainStationSaveMany(trainStationInTunnel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainStationApi - interface
 * @export
 * @interface TrainStationApi
 */
export interface TrainStationApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApiInterface
     */
    trainStationDeleteBy(id: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApiInterface
     */
    trainStationGetAll(options?: AxiosRequestConfig): AxiosPromise<Array<TrainStationInTunnel>>;

    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApiInterface
     */
    trainStationGetInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): AxiosPromise<Array<TrainStationInTunnel>>;

    /**
     * 
     * @param {TrainStationInTunnel} trainStationInTunnel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApiInterface
     */
    trainStationSave(trainStationInTunnel: TrainStationInTunnel, options?: AxiosRequestConfig): AxiosPromise<TrainStationInTunnel>;

    /**
     * 
     * @param {Array<TrainStationInTunnel>} trainStationInTunnel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApiInterface
     */
    trainStationSaveMany(trainStationInTunnel: Array<TrainStationInTunnel>, options?: AxiosRequestConfig): AxiosPromise<Array<TrainStationInTunnel>>;

}

/**
 * TrainStationApi - object-oriented interface
 * @export
 * @class TrainStationApi
 * @extends {BaseAPI}
 */
export class TrainStationApi extends BaseAPI implements TrainStationApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApi
     */
    public trainStationDeleteBy(id: string, options?: AxiosRequestConfig) {
        return TrainStationApiFp(this.configuration).trainStationDeleteBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApi
     */
    public trainStationGetAll(options?: AxiosRequestConfig) {
        return TrainStationApiFp(this.configuration).trainStationGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApi
     */
    public trainStationGetInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig) {
        return TrainStationApiFp(this.configuration).trainStationGetInterval(startIncluding, endIncluding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TrainStationInTunnel} trainStationInTunnel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApi
     */
    public trainStationSave(trainStationInTunnel: TrainStationInTunnel, options?: AxiosRequestConfig) {
        return TrainStationApiFp(this.configuration).trainStationSave(trainStationInTunnel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<TrainStationInTunnel>} trainStationInTunnel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainStationApi
     */
    public trainStationSaveMany(trainStationInTunnel: Array<TrainStationInTunnel>, options?: AxiosRequestConfig) {
        return TrainStationApiFp(this.configuration).trainStationSaveMany(trainStationInTunnel, options).then((request) => request(this.axios, this.basePath));
    }
}
