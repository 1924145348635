import {API_URL, OBB_PAST_RESTRICTED_WEEKS, OBB_UPCOMING_RESTRICTED_WEEKS} from "../constants";
import {RequestArgs} from "../generated/base";
import {MenuItem} from "@mui/material";
import React, {useEffect, useState} from "react";
import {PermissionManager} from "./PermissionManager";
import {
    Contractor,
    LatitudinalDirectedOpenLineConstruction,
    LatitudinalDirection,
    PivotTableApiAxiosParamCreator,
    ProcessStatus,
    ThirdLevelOutlineDescription,
    Tube,
    UniqueOpenLineConstruction
} from "../generated";
import {LocalizationService} from "./Localization";
import {getMappedCompanies, getOebbSubContractors, OutdoorBuildingTypes} from "./Types";
import {translationsService} from "../services/translationsProvider";
import {contractorService} from "../services/contractorProvider";
import {tunnelConstructionService} from "../services/tunnelConstructionsDescriptionsProvider";
import {calculateNumberOfWeeksInYear, getStartDateOfWeek} from "./DateFormatting";
import {addWeeks, endOfWeek, startOfWeek, subWeeks} from "date-fns";
import UserService from "../services/UserService";
import {railDescriptionService} from "../services/railDescriptionsProvider";

export const handleWeekMenuItems = (currentYear: number) => {
    const menuItems: JSX.Element[] = [];
    for (let i = 1; i <= calculateNumberOfWeeksInYear(currentYear); i++) {
        const startDateString = getStartDateOfWeek(currentYear, i).toLocaleDateString('de-DE', {
            day: 'numeric',
            month: 'numeric',
        })
        menuItems.push(<MenuItem value={i} key={"weekSelection-" + i}>{"KW " + i + " ab " + startDateString}</MenuItem>)
    }
    return menuItems
};

export function CrossCutMenuItems(): JSX.Element[] {
    return tunnelConstructionService.crossCutDescriptions.map((value, index) =>
        <MenuItem
            value={value.ID}
            key={"CrossCutMenuItem" + value.ID + index}
        >
            {LocalizationService.ZoneSelection(value.ID as unknown as string)}
        </MenuItem>)
}

export function ZoneMenuItems(start: Boolean) {
    return tunnelConstructionService.zoneDescriptions.map((object, index) =>
        <MenuItem key={index} value={object.ID}>
            {start ? LocalizationService.ZoneSelection(object.FromCrossCutOrPortalId) : LocalizationService.ZoneSelection(object.ToCrossCutOrPortalId)}
        </MenuItem>
    );
}

export function EscapeGalleryMenuItems() {
    return tunnelConstructionService.escapeGalleriesDescriptions.map((object, index) => {
        return <MenuItem key={"EscapeGallery-" + index} value={object.ID as unknown as string}>{
            object.ID
        }
        </MenuItem>
    })
}

export function EmergencyStopMenuItems() {
    return tunnelConstructionService.emergencyStopDescription.map((object, index) => {
        return <MenuItem key={"EmergencyStop-" + index} value={object.ID as unknown as string}>
            {object.Name}
        </MenuItem>
    })
}

export function ThirdLevelOutlineMenuItems(): JSX.Element[] {
    const items: JSX.Element[] = []
    items.push(<MenuItem value={''} key={"ThirdLevelOutlineMenuItem undefined"} sx={{height: 25}}>{""}</MenuItem>)
    translationsService.thirdLevelOutline.sort((a, b) => a.Order - b.Order).forEach(value => {
        items.push(
            <MenuItem value={value.ID} key={"thirdLevelOutlineDescriptionKey " + value.ID}>
                {value.DisplayName}
            </MenuItem>
        )
    });
    return items;
}

export const GetThirdLevelOutline = (thirdLevelOutlineId: string): ThirdLevelOutlineDescription | undefined =>
    translationsService.thirdLevelOutline.find(element => element.ID === thirdLevelOutlineId);

export function StatusMenuItems(isNewWorkRegistration: boolean): JSX.Element[] {
    return Object.keys(ProcessStatus).map((value) => {
        return (
            <MenuItem value={value as ProcessStatus} key={"StatusFilter" + value}
                      disabled={isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.isPermissionGranted(value as ProcessStatus) : !PermissionManager.WorkRegistrationEditing.isPermissionGranted(value as ProcessStatus)}>
                {LocalizationService.Status(value as ProcessStatus)}
            </MenuItem>
        );
    });
}

export function TubeMenuItems(withRailNumberMapping: boolean = false): JSX.Element[] {
    return Object.keys(Tube).map((value) => {
        return (
            <MenuItem value={value as Tube} key={"TubeFilter" + value}>
                {LocalizationService.Tube(value as Tube) + (withRailNumberMapping ? " / " + LocalizationService.TubeLongitudinalDirectionToRailNumber(value as Tube) : "")}
            </MenuItem>
        );
    });
}

export function LatitudinalDirectionMenuItems(): JSX.Element[] {
    return Object.keys(LatitudinalDirection).map((value) => {
        return (
            <MenuItem value={value as LatitudinalDirection} key={"LatitudinalDirectionFilter" + value}>
                {LocalizationService.LatitudinalDirection(value as LatitudinalDirection)}
            </MenuItem>
        );
    });
}

export function ContractorMenuItems(onlyShowOebbSubContractors: boolean = false, onlyShowMappedCompanies: boolean = false): JSX.Element[] {
    return GetAvailableContractors(onlyShowOebbSubContractors, onlyShowMappedCompanies).map(contractor =>
        <MenuItem value={contractor.ID} key={"AvailableContractorNameSelection" + contractor.ID}>
            {contractor.DisplayName}
        </MenuItem>
    );
}

export function GetAvailableContractors(onlyShowOebbSubContractors: boolean = false, onlyShowMappedCompanies: boolean = false): Contractor[] {
    return onlyShowOebbSubContractors ? getOebbSubContractors() : onlyShowMappedCompanies ? getMappedCompanies() : contractorService.contractors;
}

export function OutdoorBuildingMenuItems(direction: LatitudinalDirection): JSX.Element[] {
    const outdoorBuildings = [
        ...Object.values(LatitudinalDirectedOpenLineConstruction),
        ...Object.values(UniqueOpenLineConstruction).filter((key) =>
            railDescriptionService.uniqueOpenLineConstructionToLatitudinalDirection[key] === direction)
    ]
    const items: JSX.Element[] = []
    items.push(<MenuItem value={""} key={"OutdoorBuildingsMenuItem undefined"} sx={{height: 25}}/>)
    outdoorBuildings.map((value) => (
        items.push(
            <MenuItem
                value={value as OutdoorBuildingTypes}
                key={"OutdoorBuildingsMenuItem " + value}>
                {(translationsService.latitudinalDirectedOpenLineConstructions as any)[value] || (translationsService.uniqueOpenLineConstructions as any)[value]}
            </MenuItem>)
    ))
    return items;
}

export const handleDialogExportDownload = async (downloadParamPromise: Promise<RequestArgs>, defaultDownloadFileName: string) => {
    const downloadParams = await downloadParamPromise
    const response = await fetch(API_URL + downloadParams.url, {
        method: 'GET',
        headers: {Authorization: `Bearer ${UserService.getToken()}`}
    })

    if (!response.ok) throw new Error(`${await response.clone().text()}`);

    const blob = await response.blob()
    const contentDisposition = response.headers.get('content-disposition');
    let filename = defaultDownloadFileName;

    if (contentDisposition) filename = contentDisposition.split(";")[1].split("=")[1]

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

export const getLimitedStartDate = () => {
    return startOfWeek(subWeeks(new Date(), OBB_PAST_RESTRICTED_WEEKS), {weekStartsOn: 1})
}

export const getLimitedEndDate = () => {
    return endOfWeek(addWeeks(new Date(), OBB_UPCOMING_RESTRICTED_WEEKS), {weekStartsOn: 1})
}

export const handleHistoryExcelParams = async () => {
    return await PivotTableApiAxiosParamCreator().pivotTableGetHistory();
};

export const isDateInOebbTimeFrame = (selectedDate: Date) => {
    return new Date(selectedDate) >= new Date(getLimitedStartDate()) && new Date(getLimitedEndDate()) >= new Date(selectedDate)
}

export const useCurrentWindowSize = () => {
    const [currentWindowSize, setCurrentWindowSize] = useState({ width: 0, height: 0});

    useEffect(() => {
        const updateWindowDimensions = () => setCurrentWindowSize({width: window.innerWidth, height: window.innerHeight});
        window.addEventListener("resize", updateWindowDimensions);
        updateWindowDimensions();
        return () => window.removeEventListener("resize", updateWindowDimensions);
    }, []);

    return currentWindowSize;
};