/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CombinedFilterDescription } from '../model';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
// @ts-ignore
import { FilterOperation } from '../model';
// @ts-ignore
import { LatitudinalDirectedTunnelWorkRegistration } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { PageOfLatitudinalDirectedTunnelWorkRegistration } from '../model';
// @ts-ignore
import { RangeOfDateTime } from '../model';
/**
 * LatitudinalDirectedTunnelWorkRegistrationApi - axios parameter creator
 * @export
 */
export const LatitudinalDirectedTunnelWorkRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationDeleteByUuid: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationDeleteByUuid', 'uuid', uuid)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/DeleteByUuid/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetBy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetBy', 'id', id)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetBy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetByUuIdLastChanged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetByUuIdLastChangedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetInInterval: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetInInterval', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetInInterval', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetInInterval', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetInInterval', 'sortOrder', sortOrder)
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetInInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetInInterval', 'endIncluding', endIncluding)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetInInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)))
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetLatestMatchingByUuId/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetLatestMatchingByUuIdWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetPaged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPaged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPaged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPaged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPaged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetPaged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetPagedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/GetTimeSeriesBoundaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LatitudinalDirectedTunnelWorkRegistration} latitudinalDirectedTunnelWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationSave: async (latitudinalDirectedTunnelWorkRegistration: LatitudinalDirectedTunnelWorkRegistration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitudinalDirectedTunnelWorkRegistration' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationSave', 'latitudinalDirectedTunnelWorkRegistration', latitudinalDirectedTunnelWorkRegistration)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(latitudinalDirectedTunnelWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LatitudinalDirectedTunnelWorkRegistration>} latitudinalDirectedTunnelWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationSaveMany: async (latitudinalDirectedTunnelWorkRegistration: Array<LatitudinalDirectedTunnelWorkRegistration>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitudinalDirectedTunnelWorkRegistration' is not null or undefined
            assertParamExists('latitudinalDirectedTunnelWorkRegistrationSaveMany', 'latitudinalDirectedTunnelWorkRegistration', latitudinalDirectedTunnelWorkRegistration)
            const localVarPath = `/WorkRegistration/Tunnel/LatitudinalDirectedTunnelWorkRegistration/SaveMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(latitudinalDirectedTunnelWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LatitudinalDirectedTunnelWorkRegistrationApi - functional programming interface
 * @export
 */
export const LatitudinalDirectedTunnelWorkRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LatitudinalDirectedTunnelWorkRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner( options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlanerAndGetData(options?: AxiosRequestConfig): Promise<number> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest( options)).data;
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationDeleteByUuidSendRequest(uuid: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationDeleteByUuidAndGetData(uuid: string, options?: AxiosRequestConfig): Promise<number> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationDeleteByUuidSendRequest(uuid,  options)).data;
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetBy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetBySendRequest(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<LatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetBy(id,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetByAndGetData(id: string, options?: AxiosRequestConfig): Promise<LatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetBySendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetInIntervalSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetInIntervalAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetInIntervalSendRequest(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetPagedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetPagedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetPagedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangeOfDateTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundariesSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<RangeOfDateTime>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries( options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundariesAndGetData(options?: AxiosRequestConfig): Promise<RangeOfDateTime> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundariesSendRequest( options)).data;
        },
        /**
         * 
         * @param {LatitudinalDirectedTunnelWorkRegistration} latitudinalDirectedTunnelWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration: LatitudinalDirectedTunnelWorkRegistration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatitudinalDirectedTunnelWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationSaveSendRequest(latitudinalDirectedTunnelWorkRegistration: LatitudinalDirectedTunnelWorkRegistration, options?: AxiosRequestConfig): Promise<AxiosResponse<LatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationSaveAndGetData(latitudinalDirectedTunnelWorkRegistration: LatitudinalDirectedTunnelWorkRegistration, options?: AxiosRequestConfig): Promise<LatitudinalDirectedTunnelWorkRegistration> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationSaveSendRequest(latitudinalDirectedTunnelWorkRegistration,  options)).data;
        },
        /**
         * 
         * @param {Array<LatitudinalDirectedTunnelWorkRegistration>} latitudinalDirectedTunnelWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration: Array<LatitudinalDirectedTunnelWorkRegistration>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LatitudinalDirectedTunnelWorkRegistration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedTunnelWorkRegistrationSaveManySendRequest(latitudinalDirectedTunnelWorkRegistration: Array<LatitudinalDirectedTunnelWorkRegistration>, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<LatitudinalDirectedTunnelWorkRegistration>>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration,  options))();
        },
        async latitudinalDirectedTunnelWorkRegistrationSaveManyAndGetData(latitudinalDirectedTunnelWorkRegistration: Array<LatitudinalDirectedTunnelWorkRegistration>, options?: AxiosRequestConfig): Promise<Array<LatitudinalDirectedTunnelWorkRegistration>> {
            return (await this.latitudinalDirectedTunnelWorkRegistrationSaveManySendRequest(latitudinalDirectedTunnelWorkRegistration,  options)).data;
        },
    }
};

/**
 * LatitudinalDirectedTunnelWorkRegistrationApi - factory interface
 * @export
 */
export const LatitudinalDirectedTunnelWorkRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LatitudinalDirectedTunnelWorkRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: any): AxiosPromise<number> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid: string, options?: any): AxiosPromise<number> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetBy(id: string, options?: any): AxiosPromise<LatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries(options?: any): AxiosPromise<RangeOfDateTime> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LatitudinalDirectedTunnelWorkRegistration} latitudinalDirectedTunnelWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration: LatitudinalDirectedTunnelWorkRegistration, options?: any): AxiosPromise<LatitudinalDirectedTunnelWorkRegistration> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<LatitudinalDirectedTunnelWorkRegistration>} latitudinalDirectedTunnelWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration: Array<LatitudinalDirectedTunnelWorkRegistration>, options?: any): AxiosPromise<Array<LatitudinalDirectedTunnelWorkRegistration>> {
            return localVarFp.latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LatitudinalDirectedTunnelWorkRegistrationApi - interface
 * @export
 * @interface LatitudinalDirectedTunnelWorkRegistrationApi
 */
export interface LatitudinalDirectedTunnelWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): AxiosPromise<LatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): AxiosPromise<RangeOfDateTime>;

    /**
     * 
     * @param {LatitudinalDirectedTunnelWorkRegistration} latitudinalDirectedTunnelWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration: LatitudinalDirectedTunnelWorkRegistration, options?: AxiosRequestConfig): AxiosPromise<LatitudinalDirectedTunnelWorkRegistration>;

    /**
     * 
     * @param {Array<LatitudinalDirectedTunnelWorkRegistration>} latitudinalDirectedTunnelWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApiInterface
     */
    latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration: Array<LatitudinalDirectedTunnelWorkRegistration>, options?: AxiosRequestConfig): AxiosPromise<Array<LatitudinalDirectedTunnelWorkRegistration>>;

}

/**
 * LatitudinalDirectedTunnelWorkRegistrationApi - object-oriented interface
 * @export
 * @class LatitudinalDirectedTunnelWorkRegistrationApi
 * @extends {BaseAPI}
 */
export class LatitudinalDirectedTunnelWorkRegistrationApi extends BaseAPI implements LatitudinalDirectedTunnelWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LatitudinalDirectedTunnelWorkRegistration} latitudinalDirectedTunnelWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration: LatitudinalDirectedTunnelWorkRegistration, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationSave(latitudinalDirectedTunnelWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<LatitudinalDirectedTunnelWorkRegistration>} latitudinalDirectedTunnelWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedTunnelWorkRegistrationApi
     */
    public latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration: Array<LatitudinalDirectedTunnelWorkRegistration>, options?: AxiosRequestConfig) {
        return LatitudinalDirectedTunnelWorkRegistrationApiFp(this.configuration).latitudinalDirectedTunnelWorkRegistrationSaveMany(latitudinalDirectedTunnelWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }
}
