import Keycloak from "keycloak-js";
import {CURRENT_ENVIRONMENT_NAME} from "./environment";
import {AUTH_URL, CLIENT_ID} from "./constants";

export const keycloakConfig = {
    realm: "frontendKoralm" + CURRENT_ENVIRONMENT_NAME,
    url: AUTH_URL,
    clientId: CLIENT_ID,
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
