/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * PostItViewApi - axios parameter creator
 * @export
 */
export const PostItViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {Array<string>} [contractors] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postItViewGetAsExcel: async (startIncluding: string, endIncluding: string, contractors?: Array<string>, oebbOnlyContrcators?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('postItViewGetAsExcel', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('postItViewGetAsExcel', 'endIncluding', endIncluding)
            const localVarPath = `/PostItView/GetAsExcel/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}`
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractors) {
                localVarQueryParameter['contractors'] = contractors;
            }

            if (oebbOnlyContrcators !== undefined) {
                localVarQueryParameter['oebbOnlyContrcators'] = oebbOnlyContrcators;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostItViewApi - functional programming interface
 * @export
 */
export const PostItViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostItViewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {Array<string>} [contractors] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postItViewGetAsExcel(startIncluding: string, endIncluding: string, contractors?: Array<string>, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postItViewGetAsExcel(startIncluding, endIncluding, contractors, oebbOnlyContrcators, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async postItViewGetAsExcelSendRequest(startIncluding: string, endIncluding: string, contractors?: Array<string>, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.postItViewGetAsExcel(startIncluding, endIncluding, contractors, oebbOnlyContrcators,  options))();
        },
        async postItViewGetAsExcelAndGetData(startIncluding: string, endIncluding: string, contractors?: Array<string>, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<File> {
            return (await this.postItViewGetAsExcelSendRequest(startIncluding, endIncluding, contractors, oebbOnlyContrcators,  options)).data;
        },
    }
};

/**
 * PostItViewApi - factory interface
 * @export
 */
export const PostItViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostItViewApiFp(configuration)
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {Array<string>} [contractors] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postItViewGetAsExcel(startIncluding: string, endIncluding: string, contractors?: Array<string>, oebbOnlyContrcators?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.postItViewGetAsExcel(startIncluding, endIncluding, contractors, oebbOnlyContrcators, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostItViewApi - interface
 * @export
 * @interface PostItViewApi
 */
export interface PostItViewApiInterface {
    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {Array<string>} [contractors] 
     * @param {boolean} [oebbOnlyContrcators] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostItViewApiInterface
     */
    postItViewGetAsExcel(startIncluding: string, endIncluding: string, contractors?: Array<string>, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): AxiosPromise<File>;

}

/**
 * PostItViewApi - object-oriented interface
 * @export
 * @class PostItViewApi
 * @extends {BaseAPI}
 */
export class PostItViewApi extends BaseAPI implements PostItViewApiInterface {
    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {Array<string>} [contractors] 
     * @param {boolean} [oebbOnlyContrcators] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostItViewApi
     */
    public postItViewGetAsExcel(startIncluding: string, endIncluding: string, contractors?: Array<string>, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig) {
        return PostItViewApiFp(this.configuration).postItViewGetAsExcel(startIncluding, endIncluding, contractors, oebbOnlyContrcators, options).then((request) => request(this.axios, this.basePath));
    }
}
