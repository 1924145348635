import {
    Alert,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    Stack,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import React, {useEffect, useState} from "react";
import {calculateNumberOfWeeksInYear, getStartDateOfWeek} from "../../utils/DateFormatting";
import {addWeeks, formatISO, getWeek, subSeconds} from "date-fns";
import {
    RangeOfDateTime,
    TunnelPersonLogExportApiAxiosParamCreator,
    VehicleLogExportApiAxiosParamCreator,
    VehicleLogExportApiFp
} from "../../generated";
import {handleDialogExportDownload, handleWeekMenuItems} from "../../utils/Exports";
import {PermissionManager} from "../../utils/PermissionManager";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {de} from "date-fns/locale";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {ExportButtonProps} from "../../utils/InterfaceProps";
import excelImg from "../../img/microsoft-excel.png";
import {EXCEL_FILE} from "../../constants";
import CloseIcon from '@mui/icons-material/Close';

function ExcelExportButton(props: ExportButtonProps) {
    const currentDate = new Date();

    const [currentWeek, setCurrentWeek] = useState(getWeek(currentDate))
    const [selectedDateYear, setSelectedDateYear] = useState(currentDate)
    const [vehicleLogExportDescriptions, setVehicleLogExportDescriptions] = useState<RangeOfDateTime>({
        Max: formatISO(new Date()),
        Min: "2020-12-31T23:00:00.000Z"
    })

    const [startDate, setStartDate] = useState(getStartDateOfWeek(selectedDateYear.getFullYear(), currentWeek))
    const [endDate, setEndDate] = useState(subSeconds(addWeeks(getStartDateOfWeek(selectedDateYear.getFullYear(), currentWeek), 1), 1))
    const [isFetching, setIsFetching] = React.useState(false)
    const [errorToastOpen, setErrorToastOpen] = React.useState(false);

    useEffect(() => {
        const startOfWeekDate = getStartDateOfWeek(selectedDateYear.getFullYear(), currentWeek)
        startOfWeekDate.setHours(0, 0, 0)

        const endOfWeekDate = addWeeks(getStartDateOfWeek(selectedDateYear.getFullYear(), currentWeek), 1)
        endOfWeekDate.setHours(0, 0, 0)

        setStartDate(startOfWeekDate)
        setEndDate(endOfWeekDate)
    }, [currentWeek, selectedDateYear])

    useEffect(() => {
        const fetchVehicleData = async () => {
            const vehicleData = await VehicleLogExportApiFp().vehicleLogExportGetTimeSeriesBoundariesAndGetData();
            setVehicleLogExportDescriptions(vehicleData)
        }
        void fetchVehicleData()
    }, []);

    const handleExcelParams = async () => {
        return props.loadsPersonData ?
            await TunnelPersonLogExportApiAxiosParamCreator()
                .tunnelPersonLogExportGetPersonCountLogInTunnelInInterval(formatISO(startDate), formatISO(endDate)) :
            await VehicleLogExportApiAxiosParamCreator()
                .vehicleLogExportGetVehicleLogTimeSeriesInInterval(formatISO(startDate), formatISO(endDate));
    };

    const handleYearChangeSelection = (newEventDate: Date | null) => {
        const newDate = newEventDate ? newEventDate : new Date()
        if (currentWeek === 53 && calculateNumberOfWeeksInYear(newDate?.getFullYear()) === 52) setCurrentWeek(52)
        newDate ? setSelectedDateYear(newDate) : new Date()
    }

    const renderWeekDateSelection = () => {
        return <Stack>
            <FormControl>
                <InputLabel id="rangeWeekDateSelectionLabel">Kalenderwoche</InputLabel>
                <Select
                    disabled={!PermissionManager.Overview.startWeek}
                    labelId="rangeWeekDateSelectionLabel"
                    label="Kalenderwoche"
                    id="rangeDateSelect"
                    onChange={(event) => setCurrentWeek(event.target.value as number)}
                    sx={{width: 200, marginTop: 1}}
                    value={currentWeek}
                >
                    {handleWeekMenuItems(selectedDateYear.getFullYear())}
                </Select>
            </FormControl>
        </Stack>
    }

    const renderYearDateSelection = () => {
        return <Stack spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <DatePicker
                    label="Jahr"
                    views={["year"]}
                    onChange={(newDate) => handleYearChangeSelection(newDate)}
                    value={selectedDateYear}
                    minDate={new Date(vehicleLogExportDescriptions.Min)}
                    maxDate={new Date(vehicleLogExportDescriptions.Max)}
                />
            </LocalizationProvider>
        </Stack>
    }

    return (
        <Stack spacing={5} padding={5}>
            {renderYearDateSelection()}
            {renderWeekDateSelection()}
            <DialogActions>
                <Button onClick={props.handleCancelButton} variant='contained'
                        color="secondary">Abbrechen</Button>
                <Box sx={{margin: 1, position: 'relative'}}>
                    <Button
                        onClick={async () => {
                            try {
                                setIsFetching(true)
                                await handleDialogExportDownload(handleExcelParams(), "Logbuch" + EXCEL_FILE);
                                return setIsFetching(false);
                            } catch (error) {
                                setErrorToastOpen(true)
                                return setIsFetching(false)
                            }
                        }}
                        variant='contained'
                        disabled={isFetching}
                        color="primary"><img src={excelImg}
                                             alt={"Microsoft Excel Logo"}/> Download<FileDownloadOutlinedIcon/></Button>
                    {isFetching &&
                        <CircularProgress
                            size={24} sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                        }}/>}
                </Box>
            </DialogActions>
            {errorToastOpen && <Alert severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setErrorToastOpen(false);
                                    }}>
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }>
                Download fehlgeschlagen!
            </Alert>}
        </Stack>
    )
}

export default ExcelExportButton;