import {LatitudinalDirection, RailDescriptionsApiFp, RailNumber, RailSectionDescription} from "../generated";
import {Service} from "./service";

class RailDescriptionsProvider extends Service {
    railSectionDescriptions: RailSectionDescription[] = [];
    railTubeMapping: { [key: string]: RailNumber } = {};
    uniqueOpenLineConstructionToLatitudinalDirection: { [key: string]: LatitudinalDirection } = {};

    async init(): Promise<void> {
        this.railSectionDescriptions = await RailDescriptionsApiFp().railDescriptionsGetRailSectionDescriptionsAndGetData();
        this.railTubeMapping = await RailDescriptionsApiFp().railDescriptionsGetTunnelTubeRailNumberMappingAndGetData();
        this.uniqueOpenLineConstructionToLatitudinalDirection = await RailDescriptionsApiFp().railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirectionAndGetData();
    }
}

export const railDescriptionService = new RailDescriptionsProvider();