import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    IconButton, MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {HexColorPicker} from "react-colorful";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {Contractor, ContractorApiFp} from "../../generated";
import {generateRandomColor, rgbaToHex} from "../../utils/Colors";
import {contractorService} from "../../services/contractorProvider";
import {ID_FROM_MSP} from "../../constants";
import CustomStyledSwitch from "../../utils/CustomStyledSwitch";

function ContractorEditor() {
    const [open, setOpen] = useState(false)
    const [dialogIconButton, setDialogIconButton] = useState(<FileUploadOutlinedIcon/>)
    const [actionButtonText, setActionButtonText] = useState("Anlegen")
    const [colorExpanded, setColorExpanded] = useState(false)
    const [currentContractors, setCurrentContractors] = useState<Contractor[]>([])

    const [selectedContractor, setSelectedContractor] = useState("")
    const [isOebb, setIsOebb] = useState<boolean>(false)
    const [newId, setNewId] = useState<string>("")
    const [newDisplayName, setNewDisplayName] = useState<string>("")
    const [newSiteManagerEmail, setNewSiteManagerEmail] = useState<string>("")
    const [color, setColor] = useState(generateRandomColor())

    const handleNewContractor = async () => {
        setDialogIconButton(<CheckOutlinedIcon/>)

        await ContractorApiFp().contractorSaveAndGetData(
            {
                ID: newId,
                SiteManagerEmail: newSiteManagerEmail,
                DisplayName: newDisplayName,
                IsOEBB: isOebb,
                Color: {
                    A: 255,
                    R: parseInt(color.slice(1, 3), 16),
                    G: parseInt(color.slice(3, 5), 16),
                    B: parseInt(color.slice(5, 7), 16),
                }
            }
        ).then(async () => {
            await contractorService.init();
            resetInputFields();
        })
    }

    const handleDeleteContractor = async () => {
        await ContractorApiFp().contractorDeleteByAndGetData(newId);
        setDialogIconButton(<DeleteOutlineOutlinedIcon/>)
        setActionButtonText("Gelöscht")
        resetInputFields()
        await contractorService.init();
    }

    const resetInputFields = () => {
        setSelectedContractor("");
        setIsOebb(false)
        setNewId("")
        setNewDisplayName("")
        setNewSiteManagerEmail("")
        setColor(generateRandomColor())
        setColorExpanded(false)
    }

    const handleSelectedContractor = async (event: SelectChangeEvent) => {
        const selectedID = event.target.value
        setSelectedContractor(selectedID)
        await ContractorApiFp().contractorGetAllAndGetData().then((contractors: Contractor[]) => {
            const selectedContractorInArray = contractors.find(contractor => contractor.ID === selectedID)
            if (selectedContractorInArray) {
                setIsOebb(selectedContractorInArray.IsOEBB)
                setNewId(selectedContractorInArray.ID)
                setNewDisplayName(selectedContractorInArray.DisplayName)
                setNewSiteManagerEmail(selectedContractorInArray.SiteManagerEmail)
                setColor(
                    rgbaToHex(
                        selectedContractorInArray.Color.R,
                        selectedContractorInArray.Color.G,
                        selectedContractorInArray.Color.B,
                        selectedContractorInArray.Color.A))
            }
        })
    }

    async function fetchContractorData() {
        await ContractorApiFp().contractorGetAllAndGetData().then((data) => setCurrentContractors(data))
    }

    useEffect(() => {
        void fetchContractorData()
        setDialogIconButton(<FileUploadOutlinedIcon/>)
        if (selectedContractor.length > 0) setActionButtonText("Aktualisieren")
        if (selectedContractor.length <= 0) setActionButtonText("Anlegen")
    }, [isOebb, newId, newDisplayName, newSiteManagerEmail, color, selectedContractor])

    return (
        <div>
            <Tooltip title={<Typography>Firma anlegen / bearbeiten</Typography>}>
                <IconButton
                    size="large"
                    sx={{color: "#ffffff"}}
                    onClick={() => setOpen(true)}>
                    <GroupAddOutlinedIcon fontSize="large"/>
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{selectedContractor.length > 0 ? "Firma bearbeiten" : "Neue Firma anlegen"}</DialogTitle>
                <DialogContent>
                    <Stack padding={5} spacing={2}>
                        <Stack direction="row">
                            <IconButton onClick={() => resetInputFields()}>
                                <RestartAltIcon/>
                            </IconButton>
                            <Select
                                name="ContractorEditorSelection"
                                labelId="contractorEditorLabelId"
                                id="contractorEditorId"
                                value={selectedContractor}
                                onChange={handleSelectedContractor}
                                fullWidth={true}
                                sx={{height: 40}}>
                                {currentContractors.map(value =>
                                    <MenuItem value={value.ID}
                                              key={"companyNameSelection" + value.ID}>{value.DisplayName}</MenuItem>
                                )}
                            </Select>
                        </Stack>
                        <Divider/>
                        <Stack direction="row" alignItems="center">
                            <Typography>NU ARGE</Typography>
                            <CustomStyledSwitch
                                checked={isOebb}
                                onChange={(event: { target: { checked: boolean; }; }) => setIsOebb(event.target.checked)}
                                name="isOebb"
                            />
                            <Typography>NU ÖBB</Typography>
                        </Stack>
                        <TextField
                            id="id"
                            name="ID"
                            label={ID_FROM_MSP}
                            multiline={false}
                            placeholder={""}
                            value={newId}
                            onChange={(event) => setNewId(event.target.value)}
                        />
                        <TextField
                            id="displayName"
                            name="DisplayName"
                            label="Anzeigename"
                            multiline={false}
                            placeholder={""}
                            value={newDisplayName}
                            onChange={(event) => setNewDisplayName(event.target.value)}
                        />
                        <TextField
                            id="siteManagerEmail"
                            name="SiteManagerEmail"
                            label="Bauleiter Mail"
                            multiline={false}
                            placeholder={""}
                            value={newSiteManagerEmail}
                            onChange={(event) => setNewSiteManagerEmail(event.target.value)}
                        />
                        <Stack direction="row" spacing={1}>
                            <Typography>Farbe:</Typography>
                            <div
                                style={{
                                    flex: 1,
                                    height: '30px',
                                    backgroundColor: color,
                                    cursor: 'pointer',
                                    borderRadius: '4px',
                                }}
                                onClick={() => setColorExpanded(!colorExpanded)}
                            />
                        </Stack>
                        {colorExpanded ? (
                            <HexColorPicker
                                style={{width: "225px"}}
                                color={color}
                                onChange={setColor}
                            />
                        ) : null}
                        <Button
                            disabled={newId.length <= 0}
                            variant="contained"
                            onClick={() => handleDeleteContractor()}
                            size="large">
                            {<DeleteOutlineOutlinedIcon/>} Firma Löschen
                        </Button>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained" color="secondary">Abbrechen</Button>
                    <Button onClick={handleNewContractor} variant="contained"
                            color="primary">{actionButtonText} {dialogIconButton}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ContractorEditor;