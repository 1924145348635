/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * LogisticLogExportApi - axios parameter creator
 * @export
 */
export const LogisticLogExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticLogExportGetLogisticLogInTunnelInInterval: async (startIncluding: string, endIncluding: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('logisticLogExportGetLogisticLogInTunnelInInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('logisticLogExportGetLogisticLogInTunnelInInterval', 'endIncluding', endIncluding)
            const localVarPath = `/LogisticLogExport/GetLogisticLogInTunnelInInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}`
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogisticLogExportApi - functional programming interface
 * @export
 */
export const LogisticLogExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogisticLogExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding, endIncluding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async logisticLogExportGetLogisticLogInTunnelInIntervalSendRequest(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding, endIncluding,  options))();
        },
        async logisticLogExportGetLogisticLogInTunnelInIntervalAndGetData(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<File> {
            return (await this.logisticLogExportGetLogisticLogInTunnelInIntervalSendRequest(startIncluding, endIncluding,  options)).data;
        },
    }
};

/**
 * LogisticLogExportApi - factory interface
 * @export
 */
export const LogisticLogExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogisticLogExportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding: string, endIncluding: string, options?: any): AxiosPromise<File> {
            return localVarFp.logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding, endIncluding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogisticLogExportApi - interface
 * @export
 * @interface LogisticLogExportApi
 */
export interface LogisticLogExportApiInterface {
    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticLogExportApiInterface
     */
    logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): AxiosPromise<File>;

}

/**
 * LogisticLogExportApi - object-oriented interface
 * @export
 * @class LogisticLogExportApi
 * @extends {BaseAPI}
 */
export class LogisticLogExportApi extends BaseAPI implements LogisticLogExportApiInterface {
    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticLogExportApi
     */
    public logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig) {
        return LogisticLogExportApiFp(this.configuration).logisticLogExportGetLogisticLogInTunnelInInterval(startIncluding, endIncluding, options).then((request) => request(this.axios, this.basePath));
    }
}
