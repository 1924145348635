/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * PivotTableApi - axios parameter creator
 * @export
 */
export const PivotTableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pivotTableGetHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PivotTable/GetHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PivotTableApi - functional programming interface
 * @export
 */
export const PivotTableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PivotTableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pivotTableGetHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pivotTableGetHistory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async pivotTableGetHistorySendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.pivotTableGetHistory( options))();
        },
        async pivotTableGetHistoryAndGetData(options?: AxiosRequestConfig): Promise<File> {
            return (await this.pivotTableGetHistorySendRequest( options)).data;
        },
    }
};

/**
 * PivotTableApi - factory interface
 * @export
 */
export const PivotTableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PivotTableApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pivotTableGetHistory(options?: any): AxiosPromise<File> {
            return localVarFp.pivotTableGetHistory(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PivotTableApi - interface
 * @export
 * @interface PivotTableApi
 */
export interface PivotTableApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PivotTableApiInterface
     */
    pivotTableGetHistory(options?: AxiosRequestConfig): AxiosPromise<File>;

}

/**
 * PivotTableApi - object-oriented interface
 * @export
 * @class PivotTableApi
 * @extends {BaseAPI}
 */
export class PivotTableApi extends BaseAPI implements PivotTableApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PivotTableApi
     */
    public pivotTableGetHistory(options?: AxiosRequestConfig) {
        return PivotTableApiFp(this.configuration).pivotTableGetHistory(options).then((request) => request(this.axios, this.basePath));
    }
}
