import {
    Alert,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    FormControlLabel, IconButton,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import {DateRange, Range} from "react-date-range";
import {de} from "date-fns/locale";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import React, {useState} from "react";
import {PdfApiAxiosParamCreator, PostItViewApiAxiosParamCreator} from "../../generated";
import {LEAN_BOARD_FILE_NAME, PDF_FILE_NAME} from "../../constants";
import {
    ContractorMenuItems,
    getLimitedEndDate,
    getLimitedStartDate,
    handleDialogExportDownload
} from "../../utils/Exports";
import {PermissionManager} from "../../utils/PermissionManager";
import {formatISO} from "date-fns";
import {ExportButtonProps} from "../../utils/InterfaceProps";
import UserService from "../../services/UserService";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import excelImg from "../../img/microsoft-excel.png";
import CloseIcon from "@mui/icons-material/Close";

function DateRangeButton(props: ExportButtonProps) {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 2);
    const initialRanges: Range[] = [{
        startDate,
        endDate
    }];

    const [selectedContractors, setSelectedContractors] = useState<string[]>(UserService.tryGetCurrentContractorOrMappedCompanies() ?? []);
    const [ibnSelection, setIbnSelection] = useState<boolean>(UserService.getIsIbn());
    const [dateRange, setDateRange] = React.useState(initialRanges)
    const [isFetching, setIsFetching] = React.useState(false)
    const [errorMessage, setErrorMessage] = useState<string>("Download fehlgeschlagen!")
    const [errorToastOpen, setErrorToastOpen] = React.useState(false);
    const handlePdfExportParams = async () => {
        const startDateString = dateRange[0].startDate === undefined ? formatISO(startDate, {representation: 'date'}) : formatISO(dateRange[0].startDate, {representation: 'date'});
        const endDateString = dateRange[0].endDate === undefined ? formatISO(endDate, {representation: 'date'}) : formatISO(dateRange[0].endDate, {representation: 'date'});

        if (props.usingPostItView) {
            return await PostItViewApiAxiosParamCreator().postItViewGetAsExcel(startDateString, endDateString, selectedContractors.length === 0 ? undefined : selectedContractors);
        } else {
            return await PdfApiAxiosParamCreator().pdfGeneratePdfForConstructionSupervisor(startDateString, endDateString, selectedContractors.length === 0 ? undefined : selectedContractors, ibnSelection)
        }
    };

    const renderContractorSelection = () => {
        return (
            <Stack spacing={1}>
                <Typography fontSize={14}>Optional: Filter nach Unternehmen</Typography>
                <Select
                    labelId="pdf-export-selection-label"
                    id="pdf-export-selection"
                    multiple
                    value={selectedContractors}
                    onChange={(event: SelectChangeEvent<typeof selectedContractors>) =>
                        setSelectedContractors(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
                    sx={{height: 40}}
                >
                    {ContractorMenuItems()}
                </Select>
            </Stack>
        )
    }

    const renderDateRangeSelection = () => {
        return (
            <DateRange
                ranges={dateRange}
                minDate={PermissionManager.Universal.limitedDatePickerSelection ? getLimitedStartDate() : undefined}
                maxDate={PermissionManager.Universal.limitedDatePickerSelection ? getLimitedEndDate() : undefined}
                dragSelectionEnabled={true}
                onChange={(ranges) => setDateRange(Object.values(ranges))}
                locale={de}
            />
        )
    }

    const renderIbnSelection = () => {
        return (
            <RadioGroup
                onChange={(event) => setIbnSelection(event.target.value as unknown as boolean)}
                value={ibnSelection}>
                <FormControlLabel
                    value={true}
                    control={<Radio color="default" required={true}/>}
                    label={"Gesondert für IBN"}/>
                <FormControlLabel
                    value={false}
                    control={<Radio color="default" required={true}/>}
                    label={PermissionManager.Universal.ibnSelectionName ? "Normal" : "Für den Bauherren"}/>
            </RadioGroup>
        )
    }

    return (
        <Stack spacing={2} padding={5}>
            {renderContractorSelection()}
            {renderDateRangeSelection()}
            {(PermissionManager.Universal.showWeekProgramIbnSelection || !props.usingPostItView) && renderIbnSelection()}
            <DialogActions>
                <Button onClick={props.handleCancelButton} variant='contained'
                        color="secondary">Abbrechen</Button>
                <Box sx={{margin: 1, position: 'relative'}}>
                    <Button
                        onClick={async () => {
                            try {
                                setIsFetching(true)
                                await handleDialogExportDownload(handlePdfExportParams(), props.usingPostItView ? LEAN_BOARD_FILE_NAME : PDF_FILE_NAME);
                                return setIsFetching(false);
                            } catch (error) {
                                setErrorMessage((error as Error).message)
                                setErrorToastOpen(true)
                                return setIsFetching(false)
                            }
                        }}
                        disabled={isFetching}
                        variant='contained'
                        color="primary">{props.usingPostItView ? <img src={excelImg} alt={"Microsoft Excel Logo"}/> :
                        <PictureAsPdfOutlinedIcon/>}Download<FileDownloadOutlinedIcon/>
                    </Button>
                    {isFetching &&
                        <CircularProgress
                            size={24} sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                        }}/>}
                </Box>
            </DialogActions>
            {errorToastOpen && <Alert severity="error"
                                      action={
                                          <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                  setErrorToastOpen(false);
                                              }}>
                                              <CloseIcon fontSize="inherit"/>
                                          </IconButton>
                                      }>
                {errorMessage}
            </Alert>}
        </Stack>
    )
}

export default DateRangeButton;