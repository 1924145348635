import {Service} from "./service";
import {StorageDataApiFp, StorageLocationDataApiFp} from "../generated";

class StorageDataMappingProvider extends Service {
    contractorAliases: { [key: string]: string[] } = {};
    locationIdToNameMapping: Map<string, string> = new Map<string, string>();

    async init(): Promise<void> {
        this.contractorAliases = await StorageDataApiFp().storageDataGetStorageDataCompanyNameAliasesAndGetData();
        for (const mapping of await StorageLocationDataApiFp().storageLocationDataGetAllAndGetData()) {
            this.locationIdToNameMapping.set(mapping.ID, mapping.Name);
        }
    }

}

export const storageDataMappingProvider = new StorageDataMappingProvider();