/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Contractor } from '../model';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
/**
 * ContractorApi - axios parameter creator
 * @export
 */
export const ContractorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorDeleteBy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contractorDeleteBy', 'id', id)
            const localVarPath = `/Contractor/DeleteBy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Contractor/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Contractor} contractor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorSave: async (contractor: Contractor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractor' is not null or undefined
            assertParamExists('contractorSave', 'contractor', contractor)
            const localVarPath = `/Contractor/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<Contractor>} contractor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorSaveMany: async (contractor: Array<Contractor>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractor' is not null or undefined
            assertParamExists('contractorSaveMany', 'contractor', contractor)
            const localVarPath = `/Contractor/SaveMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractorApi - functional programming interface
 * @export
 */
export const ContractorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractorDeleteBy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractorDeleteBy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async contractorDeleteBySendRequest(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return (await this.contractorDeleteBy(id,  options))();
        },
        async contractorDeleteByAndGetData(id: string, options?: AxiosRequestConfig): Promise<boolean> {
            return (await this.contractorDeleteBySendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractorGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contractor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractorGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async contractorGetAllSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Contractor>>> {
            return (await this.contractorGetAll( options))();
        },
        async contractorGetAllAndGetData(options?: AxiosRequestConfig): Promise<Array<Contractor>> {
            return (await this.contractorGetAllSendRequest( options)).data;
        },
        /**
         * 
         * @param {Contractor} contractor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractorSave(contractor: Contractor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contractor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractorSave(contractor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async contractorSaveSendRequest(contractor: Contractor, options?: AxiosRequestConfig): Promise<AxiosResponse<Contractor>> {
            return (await this.contractorSave(contractor,  options))();
        },
        async contractorSaveAndGetData(contractor: Contractor, options?: AxiosRequestConfig): Promise<Contractor> {
            return (await this.contractorSaveSendRequest(contractor,  options)).data;
        },
        /**
         * 
         * @param {Array<Contractor>} contractor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractorSaveMany(contractor: Array<Contractor>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contractor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractorSaveMany(contractor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async contractorSaveManySendRequest(contractor: Array<Contractor>, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Contractor>>> {
            return (await this.contractorSaveMany(contractor,  options))();
        },
        async contractorSaveManyAndGetData(contractor: Array<Contractor>, options?: AxiosRequestConfig): Promise<Array<Contractor>> {
            return (await this.contractorSaveManySendRequest(contractor,  options)).data;
        },
    }
};

/**
 * ContractorApi - factory interface
 * @export
 */
export const ContractorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractorApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorDeleteBy(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.contractorDeleteBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorGetAll(options?: any): AxiosPromise<Array<Contractor>> {
            return localVarFp.contractorGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Contractor} contractor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorSave(contractor: Contractor, options?: any): AxiosPromise<Contractor> {
            return localVarFp.contractorSave(contractor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<Contractor>} contractor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractorSaveMany(contractor: Array<Contractor>, options?: any): AxiosPromise<Array<Contractor>> {
            return localVarFp.contractorSaveMany(contractor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractorApi - interface
 * @export
 * @interface ContractorApi
 */
export interface ContractorApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApiInterface
     */
    contractorDeleteBy(id: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApiInterface
     */
    contractorGetAll(options?: AxiosRequestConfig): AxiosPromise<Array<Contractor>>;

    /**
     * 
     * @param {Contractor} contractor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApiInterface
     */
    contractorSave(contractor: Contractor, options?: AxiosRequestConfig): AxiosPromise<Contractor>;

    /**
     * 
     * @param {Array<Contractor>} contractor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApiInterface
     */
    contractorSaveMany(contractor: Array<Contractor>, options?: AxiosRequestConfig): AxiosPromise<Array<Contractor>>;

}

/**
 * ContractorApi - object-oriented interface
 * @export
 * @class ContractorApi
 * @extends {BaseAPI}
 */
export class ContractorApi extends BaseAPI implements ContractorApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public contractorDeleteBy(id: string, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).contractorDeleteBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public contractorGetAll(options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).contractorGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Contractor} contractor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public contractorSave(contractor: Contractor, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).contractorSave(contractor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<Contractor>} contractor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public contractorSaveMany(contractor: Array<Contractor>, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).contractorSaveMany(contractor, options).then((request) => request(this.axios, this.basePath));
    }
}
