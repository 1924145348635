import {
    getGridNumericOperators,
    getGridStringOperators,
    GridColDef,
    GridFilterInputValueProps,
    GridFilterItem,
    GridFilterOperator,
    GridRenderCellParams,
    SUBMIT_FILTER_STROKE_TIME,
    useGridApiContext,
} from "@mui/x-data-grid";
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    TextField,
    TextFieldProps
} from "@mui/material";
import React from "react";
import {KoralmLocation, LatitudinalDirection, ProcessStatus, Tube, TunnelObject} from "../generated";
import {FilterType} from "./FilterTypes";
import {LocalizationService} from "./Localization";
import {getEnumKeyByEnumValue} from "./Types";
import SyncIcon from '@mui/icons-material/Sync';
import {tunnelConstructionService} from "../services/tunnelConstructionsDescriptionsProvider";
import {MULTIPLE_VALUE_FILTER_SEPARATOR} from "../constants";
import {ContractorMenuItems, CrossCutMenuItems, LatitudinalDirectionMenuItems, TubeMenuItems} from "./Exports";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {de} from "date-fns/locale";
import {getGridDateOperators} from "@mui/x-data-grid/colDef/gridDateOperators";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

export const stringFilterOperators = getGridStringOperators().filter(({value}) => ['equals', 'contains'].includes(value))
export const numberFilterOperators = getGridNumericOperators().filter(
    (operator) => operator.value === '=' || operator.value === '!=' || operator.value === '>' || operator.value === '>=' || operator.value === '<' || operator.value === '<='
);

export const statusStringOperators = getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? StatusInputValue : undefined,
    }))

export function StatusInputValue(props: GridFilterInputValueProps) {
    const {item, applyValue} = props;

    const handleFilterChange = (event: SelectChangeEvent) => {
        applyValue({...item, value: event.target.value});
    };

    return (
        <Select
            name="status-filter-operator"
            placeholder="Filter value"
            value={String(item.value)}
            onChange={handleFilterChange}
            sx={{pt: "16px"}}
        >
            {
                Object.keys(ProcessStatus).map((value) => <MenuItem value={value} key={"StatusFilter" + value}>
                    {LocalizationService.Status(value as ProcessStatus)}
                </MenuItem>)
            }
            <MenuItem value={undefined} style={{display: "none"}}></MenuItem>
        </Select>
    );
}

export const renderQsEditInputCell: GridColDef['renderCell'] = (params) => {
    return <QsEditInputCell {...params} />;
};
export const renderTimeEditInputCell: GridColDef['renderCell'] = (params) => {
    return <TimeEditInputCell {...params} />;
};
export const renderTubeEditInputCell: GridColDef['renderCell'] = (params) => {
    return <TubeEditInputCell {...params} />;
};
export const renderLatitudinalDirectionEditInputCell: GridColDef['renderCell'] = (params) => {
    return <LatitudinalDirectionEditInputCell {...params} />;
};

function QsEditInputCell(props: GridRenderCellParams) {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();

    return (
        <Select
            name="QuerschlagSelection"
            value={value}
            fullWidth={true}
            color="secondary"
            onChange={(event) => {
                apiRef.current.setEditCellValue({id, field, value: event.target.value});
            }}
            sx={{
                bgcolor: "secondary.main"
            }}
        >
            {CrossCutMenuItems()}
        </Select>
    );
}

function TimeEditInputCell(props: GridRenderCellParams) {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();
    const dateValue = value ? new Date(`1970-01-01T${value}`) : null;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <TimePicker
                value={dateValue}
                onChange={(newValue) => {
                    const formattedValue = newValue ? newValue.toTimeString().slice(0, 8) : '';
                    apiRef.current.setEditCellValue({id, field, value: formattedValue});
                }}
            />
        </LocalizationProvider>
    );
}

function TubeEditInputCell(props: GridRenderCellParams) {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();

    return (
        <Select
            name="QuerschlagSelection"
            value={value}
            fullWidth={true}
            color="secondary"
            onChange={(event) => {
                apiRef.current.setEditCellValue({id, field, value: event.target.value as Tube});
            }}
            sx={{
                bgcolor: "secondary.main"
            }}
        >
            {TubeMenuItems(true)}
        </Select>
    );
}

function LatitudinalDirectionEditInputCell(props: GridRenderCellParams) {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();

    return (
        <Select
            name="LatitudinalDirectionSelection"
            value={value}
            fullWidth={true}
            color="secondary"
            onChange={(event) => {
                apiRef.current.setEditCellValue({id, field, value: event.target.value as LatitudinalDirection});
            }}
            sx={{
                bgcolor: "secondary.main"
            }}
        >
            {LatitudinalDirectionMenuItems()}
        </Select>
    );
}

export const contractorSelectionOperator = getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? ContractorInputValue : undefined,
    }))

export const getCustomGridDateOperators = getGridDateOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? GridInputValue : undefined,
    }))

export function GridInputValue(props: GridFilterInputValueProps) {
    const {item, applyValue} = props;

    const handleFilterChange = (newEventDate: Date | null) => {
        const newDate = newEventDate ? newEventDate : new Date();
        applyValue({...item, value: newDate});
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <DatePicker
                label="Tagesauswahl"
                value={item.value}
                onChange={handleFilterChange}
            />
        </LocalizationProvider>
    );
}

export function ContractorInputValue(props: GridFilterInputValueProps) {
    const {item, applyValue} = props;

    const handleFilterChange = (event: SelectChangeEvent) => {
        applyValue({...item, value: event.target.value});
    };

    return (
        <Select
            name="contractor-filter-operator"
            placeholder="Filter value"
            value={String(item.value)}
            onChange={handleFilterChange}
            sx={{pt: "16px"}}>
            {ContractorMenuItems()}
            <MenuItem value={undefined} style={{display: "none"}}></MenuItem>
        </Select>
    );
}

export const AreaFilteringPanel = (value: FilterType | KoralmLocation) => {
    if (value === FilterType.Tube) {
        return ZoneBetweenOperator().map(operator => ({
            ...operator,
            InputComponent: (props: GridFilterInputValueProps) => (
                <ZoneSelectIntervall {...props}/>
            ),
        }));
    } else if (value === KoralmLocation.Tunnel) {
        return [
            {
                ...getGridStringOperators().find(operator => operator.value === 'contains'),
                InputComponent: AreaInputValue(value),
            },
        ];
    } else if (value === FilterType.Outdoor) {
        return quantityOnlyOperators.map(operator => ({
            ...operator,
            InputComponent: InputNumberInterval,
        }));
    } else {
        return getGridNumericOperators()
            .filter((operator) => numberFilterOperators.some(filteredOperator => filteredOperator.value === operator.value))
            .map((operator) => ({
                ...operator,
                InputComponent: operator.InputComponent ? AreaInputValue(value) : undefined,
            }));
    }
};

export function AreaInputValue(type: FilterType | KoralmLocation) {
    return (props: GridFilterInputValueProps) => {
        const {item, applyValue} = props;

        const handleFilterChange = (event: SelectChangeEvent) => {
            applyValue({...item, value: event.target.value});
        };

        return (
            <Select
                name="area-filter-operator"
                placeholder="Filter value"
                value={String(item.value)}
                onChange={handleFilterChange}
                sx={{pt: "16px"}}
            >
                {HandleAreaMenuItems(type)}
            </Select>
        );
    }
}

function HandleAreaMenuItems(type: FilterType | KoralmLocation) {
    const createMenuItem = (key: string, value: any, label: string, prefix: string) => (
        <MenuItem key={key} value={prefix + MULTIPLE_VALUE_FILTER_SEPARATOR + value}>
            {label}
        </MenuItem>
    );

    const createMenuItemForTunnel = (key: string, value: any) => (
        <MenuItem key={key} value={value as string}>
            {value}
        </MenuItem>
    );

    const items = [
        <MenuItem value={undefined} style={{display: 'none'}} key={'UndefinedFilter'}/>,
    ];

    if (type === KoralmLocation.Tunnel) {
        tunnelConstructionService.crossCutDescriptions.forEach((object, index) => {
            items.push(createMenuItemForTunnel('CrossCut-' + index, LocalizationService.ZoneSelection(object.ID as unknown as string)));
        });
        tunnelConstructionService.escapeGalleriesDescriptions.forEach((object, index) => {
            items.push(createMenuItemForTunnel('EscapeGallery-' + index, LocalizationService.EscapeGallery(object.ID)));
        });
        tunnelConstructionService.emergencyStopDescription.forEach((object, index) => {
            items.push(createMenuItemForTunnel('EmergencyStopDescr' + index, tunnelConstructionService.emergencyStopDescription.find(item => item.ID === Number(object.ID))?.Name || ""))
        })
        tunnelConstructionService.portalDescriptions.forEach((object, index) => {
            items.push(createMenuItemForTunnel('Portal-' + index, LocalizationService.ZoneSelection(object.ID)));
        });
    }

    if (type === FilterType.CrossCut) {
        tunnelConstructionService.crossCutDescriptions.forEach((object, index) => {
            items.push(createMenuItem('CrossCut-' + index, object.ID, LocalizationService.ZoneSelection(object.ID as unknown as string), getEnumKeyByEnumValue(FilterType, FilterType.CrossCut)));
        });
    }

    if (
        type === FilterType.CrossCut ||
        type === FilterType.EscapeGallery ||
        type === FilterType.EmergencyStop
    ) {
        tunnelConstructionService.escapeGalleriesDescriptions.forEach((object, index) => {
            items.push(createMenuItem('EscapeGallery-' + index, object.ID, LocalizationService.EscapeGallery(object.ID), getEnumKeyByEnumValue(FilterType, FilterType.EscapeGallery)));
        });
        tunnelConstructionService.emergencyStopDescription.forEach((object, index) => {
            items.push(createMenuItem('EmergencyStopDescr' + index, object.ID, object.Name, getEnumKeyByEnumValue(FilterType, FilterType.EmergencyStop)))
        })
    }

    if (type === FilterType.Tube) {
        tunnelConstructionService.zoneDescriptions.forEach((object, index) => {
            items.push(createMenuItem('Zone-' + index, object.ID, LocalizationService.TubeId(object.ID, true), getEnumKeyByEnumValue(FilterType, FilterType.Tube)));
        });
        const lastObjectInZoneDescription = tunnelConstructionService.zoneDescriptions[tunnelConstructionService.zoneDescriptions.length - 1]
        items.push(createMenuItem('Zone-WestPortal', lastObjectInZoneDescription.ID + 1, LocalizationService.TubeId(lastObjectInZoneDescription.ID, false), getEnumKeyByEnumValue(FilterType, FilterType.Tube)))
    }

    if (type === FilterType.Portal) {
        tunnelConstructionService.portalDescriptions.forEach((object, index) => {
            items.push(createMenuItem('Portal-' + index, object.ID, 'Portal: ' + LocalizationService.ZoneSelection(object.ID), getEnumKeyByEnumValue(FilterType, FilterType.Portal)));
        });
        tunnelConstructionService.ventilationShaftsDescriptions.forEach((object, index) => {
            items.push(
                createMenuItem('Ventilation-' + index, object.ID, 'Lüftergebäude: ' + LocalizationService.ZoneSelection(object.ID), getEnumKeyByEnumValue(FilterType, FilterType.VentilationShaft)),
            );
        });
    }

    return items;
}

export function ZoneBetweenOperator(): GridFilterOperator<any, any, any>[] {
    return [{
        label: 'Zwischen',
        value: 'between',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
                return null;
            }
            if (filterItem.value[0] == null || filterItem.value[1] == null) {
                return null;
            }

            return ({value}) => {
                return (
                    value !== null &&
                    filterItem.value[0] <= value &&
                    value <= filterItem.value[1]
                );
            };
        },
        InputComponent: (props: GridFilterInputValueProps) => (
            <ZoneSelectIntervall {...props}/>
        ),
    }]
}

function ZoneSelectIntervall(props: GridFilterInputValueProps) {
    const {item, applyValue} = props;

    const filterTimeout = React.useRef<any>();
    const [filterValueState, setFilterValueState] = React.useState<[string, string]>(item.value ?? ['', '']);

    React.useEffect(() => {
        return () => {
            clearTimeout(filterTimeout.current);
        };
    }, []);

    React.useEffect(() => {
        const itemValue = item.value ?? ['', ''];
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (lowerBound: string, upperBound: string) => {
        clearTimeout(filterTimeout.current);
        setFilterValueState([lowerBound, upperBound]);

        if (lowerBound !== '' && upperBound !== '') {
            filterTimeout.current = setTimeout(() => {
                applyValue({...item, value: [lowerBound, upperBound]});
            }, SUBMIT_FILTER_STROKE_TIME);
        }
    };

    const handleUpperFilterChange: SelectProps['onChange'] = (event) => {
        const newUpperBound = event.target.value as string;
        updateFilterValue(filterValueState[0], newUpperBound);
    };

    const handleLowerFilterChange: SelectProps['onChange'] = (event) => {
        const newLowerBound = event.target.value as string;
        updateFilterValue(newLowerBound, filterValueState[1]);
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'end',
                height: 48,
                pl: '20px',
            }}
        >
            <FormControl>
                <Select
                    name="lower-bound-select"
                    label="From"
                    variant="standard"
                    value={filterValueState[0]}
                    onChange={handleLowerFilterChange}
                    sx={{mr: 2, width: "70px"}}
                >
                    {getZoneMenuItems(true)}
                </Select>
            </FormControl>
            <FormControl>
                <Select
                    name="upper-bound-select"
                    label="To"
                    variant="standard"
                    value={filterValueState[1]}
                    onChange={handleUpperFilterChange}
                    sx={{mr: 2, width: "70px"}}
                >
                    {getZoneMenuItems(false)}
                </Select>
            </FormControl>
        </Box>
    );
}

function getZoneMenuItems(start: Boolean) {
    const items = [
        <MenuItem value={undefined} style={{display: 'none'}} key={'UndefinedFilter'}/>,
    ];
    tunnelConstructionService.zoneDescriptions.map((object, index) => (
        items.push(
            <MenuItem key={index} value={start ? object.FromCrossCutOrPortalId : object.ToCrossCutOrPortalId}>
                {start ? object.FromCrossCutOrPortalId : object.ToCrossCutOrPortalId}
            </MenuItem>
        )
    ));
    return items
}

function InputNumberInterval(props: GridFilterInputValueProps) {
    const {item, applyValue, focusElementRef = null} = props;

    const filterTimeout = React.useRef<any>();
    const [filterValueState, setFilterValueState] = React.useState<[string, string]>(
        item.value ?? '',
    );
    const [applying, setIsApplying] = React.useState(false);

    React.useEffect(() => {
        return () => {
            clearTimeout(filterTimeout.current);
        };
    }, []);

    React.useEffect(() => {
        const itemValue = item.value ?? [undefined, undefined];
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (lowerBound: string, upperBound: string) => {
        clearTimeout(filterTimeout.current);
        setFilterValueState([lowerBound, upperBound]);

        setIsApplying(true);
        filterTimeout.current = setTimeout(() => {
            setIsApplying(false);
            applyValue({...item, value: [lowerBound, upperBound]});
        }, SUBMIT_FILTER_STROKE_TIME);
    };

    const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
        const newUpperBound = event.target.value;
        updateFilterValue(filterValueState[0], newUpperBound);
    };
    const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
        const newLowerBound = event.target.value;
        updateFilterValue(newLowerBound, filterValueState[1]);
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'end',
                height: 48,
                pl: '20px',
            }}
        >
            <TextField
                name="lower-bound-input"
                placeholder="Von"
                label="Von"
                variant="standard"
                value={filterValueState[0] !== undefined ? Number(filterValueState[0]) : ''}
                onChange={handleLowerFilterChange}
                type="number"
                inputRef={focusElementRef}
                sx={{mr: 2}}
            />
            <TextField
                name="upper-bound-input"
                placeholder="Zu"
                label="Zu"
                variant="standard"
                value={filterValueState[1] !== undefined ? Number(filterValueState[1]) : ''}
                onChange={handleUpperFilterChange}
                type="number"
                InputProps={applying ? {endAdornment: <SyncIcon/>} : {}}
            />
        </Box>
    );
}

const quantityOnlyOperators: GridFilterOperator[] = [
    {
        label: 'Zwischen',
        value: 'between',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
                return null;
            }
            if (filterItem.value[0] == null || filterItem.value[1] == null) {
                return null;
            }

            return ({value}) => {
                return (
                    value !== null &&
                    filterItem.value[0] <= value &&
                    value <= filterItem.value[1]
                );
            };
        },
        InputComponent: InputNumberInterval,
    },
];

export const objectStringOperators = (value: FilterType | KoralmLocation) => {
    return getGridStringOperators()
        .filter(({value}) => ['equals'].includes(value))
        .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent ? ObjectInputValue(value) : undefined,
        }))
}

export function ObjectInputValue(type: FilterType | KoralmLocation) {
    return (props: GridFilterInputValueProps) => {
        const {item, applyValue} = props;

        const handleFilterChange = (event: SelectChangeEvent) => {
            applyValue({...item, value: event.target.value});
        };

        return (
            <Select
                name="object-filter-operator"
                placeholder="Filter value"
                value={String(item.value)}
                onChange={handleFilterChange}
                sx={{pt: "16px"}}
            >
                {handleMenuItems(type)}
            </Select>
        );
    }
}

function handleMenuItems(type: FilterType | KoralmLocation) {
    const items = [<MenuItem value={undefined} style={{display: "none"}} key={"UndefinedFilter"}></MenuItem>]
    switch (type) {
        case KoralmLocation.Tunnel:
            items.push(
                <MenuItem value={LocalizationService.TunnelObject(TunnelObject.CrossCut)}
                          key={TunnelObject.CrossCut}>{LocalizationService.TunnelObject(TunnelObject.CrossCut)}</MenuItem>,
                <MenuItem value={LocalizationService.TunnelObject(TunnelObject.EmergencyStop)}
                          key={TunnelObject.EmergencyStop}>{LocalizationService.TunnelObject(TunnelObject.EmergencyStop)}</MenuItem>,
                <MenuItem value={LocalizationService.TunnelObject(TunnelObject.EscapeGallery)}
                          key={TunnelObject.EscapeGallery}>{LocalizationService.TunnelObject(TunnelObject.EscapeGallery)}</MenuItem>,
                <MenuItem value={LocalizationService.TunnelObject(TunnelObject.Tube)}
                          key={TunnelObject.Tube}>{LocalizationService.TunnelObject(TunnelObject.Tube)}</MenuItem>,
                <MenuItem value={LocalizationService.TunnelObject(TunnelObject.Portal)}
                          key={TunnelObject.Portal}>{LocalizationService.TunnelObject(TunnelObject.Portal)}</MenuItem>,
                <MenuItem value={LocalizationService.TunnelObject(TunnelObject.VentilationShaft)}
                          key={TunnelObject.VentilationShaft}>{LocalizationService.TunnelObject(TunnelObject.VentilationShaft)}</MenuItem>,
            )
            break;
        case FilterType.CrossCut:
        case FilterType.EmergencyStop:
        case FilterType.EscapeGallery:
            items.push(
                <MenuItem value={TunnelObject.CrossCut}
                          key={TunnelObject.CrossCut}>{LocalizationService.TunnelObject(TunnelObject.CrossCut)}</MenuItem>,
                <MenuItem value={TunnelObject.EmergencyStop}
                          key={TunnelObject.EmergencyStop}>{LocalizationService.TunnelObject(TunnelObject.EmergencyStop)}</MenuItem>,
                <MenuItem value={TunnelObject.EscapeGallery}
                          key={TunnelObject.EscapeGallery}>{LocalizationService.TunnelObject(TunnelObject.EscapeGallery)}</MenuItem>,
            )
            break;
        case FilterType.Tube:
            items.push(
                ...Object.entries(Tube)
                    .map(kv => <MenuItem value={kv[0]} key={kv[0]}>{LocalizationService.Tube(kv[1])}</MenuItem>)
            )
            break;
        case KoralmLocation.OpenLine:
        case FilterType.Outdoor:
            items.push(
                <MenuItem value={LocalizationService.KoralmLocation(KoralmLocation.Tunnel)}
                          key={KoralmLocation.Tunnel}>{LocalizationService.KoralmLocation(KoralmLocation.Tunnel)}</MenuItem>,
                <MenuItem value={LocalizationService.KoralmLocation(KoralmLocation.OpenLine)}
                          key={KoralmLocation.OpenLine}>{LocalizationService.KoralmLocation(KoralmLocation.OpenLine)}</MenuItem>,
            )
            break;
        case FilterType.Portal:
        case FilterType.VentilationShaft:
            items.push(
                <MenuItem value={TunnelObject.Portal}
                          key={TunnelObject.Portal}>{LocalizationService.TunnelObject(TunnelObject.Portal)}</MenuItem>,
                <MenuItem value={TunnelObject.VentilationShaft}
                          key={TunnelObject.VentilationShaft}>{LocalizationService.TunnelObject(TunnelObject.VentilationShaft)}</MenuItem>
            )
            break;
    }
    return items
}