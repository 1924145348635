import * as React from "react";
import {DateRangePicker, Range, RangeKeyDict} from "react-date-range";
import {Box, FormControlLabel, Stack, styled, ToggleButtonGroup, Typography} from "@mui/material"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRangePickerArgs} from "../../utils/InterfaceProps";
import {de} from "date-fns/locale";
import {
    addDays,
    differenceInCalendarDays,
    differenceInDays,
    endOfDay,
    endOfMonth,
    isSameDay,
    isSaturday,
    isSunday,
    lastDayOfWeek,
    setDefaultOptions,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subMonths,
    subWeeks
} from "date-fns";
import {PermissionManager} from "../../utils/PermissionManager";
import {ShiftSchemaDuringDay, WorkDuringWeekend} from "../../generated";
import MuiToggleButton from "@mui/material/ToggleButton";
import {HeaderBarNewRegistration} from "../../utils/Colors";
import CustomStyledSwitch from "../../utils/CustomStyledSwitch";
import {getLimitedEndDate, getLimitedStartDate} from "../../utils/Exports";
import {getShiftHours} from "../../utils/Types";

function DateRangePickerComponent(props: DateRangePickerArgs) {
    setDefaultOptions({locale: de})

    const DayShift = "Tagschicht";
    const NightShift = "Nachschicht";
    const DayAndNightShift = "Tag und Nachtschicht";

    const [range, setRange] = React.useState(props.dateRange);

    function adjustDateTimeToShift(date: Date, start: boolean) {
        date.setHours(start ? getShiftHours(props.shiftSchemaDuringDay).start : getShiftHours(props.shiftSchemaDuringDay).end, 0, 0);
        return date;
    }

    function getWorkOnWeekendsUpdate(newRange: Range) {
        const startDate = newRange.startDate!;
        const durationInDays = Math.abs(differenceInDays(startDate, newRange.endDate!));
        return durationInDays === 1 && isSaturday(startDate)
            ? WorkDuringWeekend.AllDays
            : durationInDays === 0
                ? isSaturday(startDate)
                    ? WorkDuringWeekend.Saturday
                    : isSunday(startDate)
                        ? WorkDuringWeekend.Sunday
                        : null
                : null;
    }

    function handleSelect(ranges: RangeKeyDict) {
        const currentRange = Object.values(ranges)
        currentRange.forEach((value) => {
            value.startDate = adjustDateTimeToShift(value.startDate!, true);
            value.endDate = adjustDateTimeToShift(value.endDate!, false);
        })
        setRange(currentRange);
        const updateWorkOnWekend = getWorkOnWeekendsUpdate(currentRange[0]);
        if (updateWorkOnWekend !== props.continuesDuringWeekend) {
            props.changedContinuesDuringWeekend(updateWorkOnWekend);
        }
        props.changedDataRange(currentRange);
    }

    const staticDateRanges = [
        {
            label: 'Heute',
            hasCustomRendering: false,
            range: () => ({
                startDate: adjustDateTimeToShift(new Date(), true),
                endDate: adjustDateTimeToShift(new Date(), false),
            }),
            isSelected() {
                return false
            }
        },
        {
            label: 'Gestern',
            hasCustomRendering: false,
            range: () => ({
                startDate: adjustDateTimeToShift(new Date(Date.now() - 864e5), true),
                endDate: adjustDateTimeToShift(new Date(Date.now() - 864e5), false),
            }),
            isSelected() {
                return false
            }
        },
        {
            label: 'Diese Woche',
            hasCustomRendering: false,
            range: () => ({
                startDate: adjustDateTimeToShift(startOfWeek(new Date()), true),
                endDate: adjustDateTimeToShift(lastDayOfWeek(new Date()), false)
            }),
            isSelected() {
                return false
            }
        },
        {
            label: 'Letzte Woche',
            hasCustomRendering: false,
            range: () => ({
                startDate: adjustDateTimeToShift(startOfWeek(subWeeks(new Date(), 1)), true),
                endDate: adjustDateTimeToShift(lastDayOfWeek(subWeeks(new Date(), 1)), false)
            }),
            isSelected() {
                return false
            }
        },
        {
            label: 'Diesen Monat',
            hasCustomRendering: false,
            range: () => ({
                startDate: adjustDateTimeToShift(startOfMonth(new Date()), true),
                endDate: adjustDateTimeToShift(endOfMonth(new Date()), false)
            }),
            isSelected() {
                return false
            }
        },
        {
            label: 'Letzten Monat',
            hasCustomRendering: false,
            range: () => ({
                startDate: adjustDateTimeToShift(startOfMonth(subMonths(new Date(), 1)), true),
                endDate: adjustDateTimeToShift(endOfMonth(subMonths(new Date(), 1)), false)
            }),
            isSelected() {
                return false
            }
        },
    ]

    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: HeaderBarNewRegistration,
        }
    });

    const canEditWorkDuringWeekend = props.isNewWorkRegistration
        ? !PermissionManager.NewWorkRegistration.continuesDuringWeekend
        : !PermissionManager.WorkRegistrationEditing.continuesDuringWeekend;

    const workDuringWeekendPrefix = "Arbeit am ";

    return (
        <Stack direction="row" spacing={5}>
            <DateRangePicker
                calendarFocus="forwards"
                ranges={range}
                dragSelectionEnabled={true}
                onChange={handleSelect}
                locale={de}
                minDate={PermissionManager.Universal.limitedDatePickerSelection ? getLimitedStartDate() : new Date(2020, 1, 1)}
                dateDisplayFormat={"dd,MM,yyyy"}
                staticRanges={staticDateRanges}
                maxDate={PermissionManager.Universal.limitedDatePickerSelection ? getLimitedEndDate() : new Date(new Date().getFullYear() + 5, 12, 31)}
                inputRanges={[
                    {
                        label: 'Tage bis heute',
                        range(value) {
                            return {
                                startDate: adjustDateTimeToShift(addDays(startOfDay(new Date()), (Math.max(Number(value), 1) - 1) * -1), true),
                                endDate: adjustDateTimeToShift(endOfDay(new Date()), false),
                            }
                        },
                        getCurrentValue(range) {
                            if (!isSameDay(range.endDate!, endOfDay(new Date()))) return '-';
                            if (!range.startDate) return '∞';
                            return differenceInCalendarDays(endOfDay(new Date()), range.startDate) + 1;
                        },
                    },
                    {
                        label: 'Tage ab heute',
                        range(value) {
                            const today = new Date();
                            return {
                                startDate: adjustDateTimeToShift(today, true),
                                endDate: adjustDateTimeToShift(addDays(today, Math.max(Number(value), 1) - 1), false),
                            };
                        },
                        getCurrentValue(range) {
                            if (!isSameDay(range.startDate!, startOfDay(new Date()))) return '-';
                            if (!range.endDate) return '∞';
                            return differenceInCalendarDays(range.endDate, startOfDay(new Date())) + 1;
                        },
                    },
                ]}

            />
            <Stack spacing={2} paddingRight={2}>
                <ToggleButtonGroup
                    orientation="vertical"
                    exclusive
                    value={props.shiftSchemaDuringDay}
                    onChange={(event, value) => props.changedShiftSchemaDuringDay(value as ShiftSchemaDuringDay ?? props.shiftSchemaDuringDay)}
                >
                    <ToggleButton value={ShiftSchemaDuringDay.Day} style={{paddingBottom: 20, paddingTop: 20}}
                                  disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.dayShift : !PermissionManager.WorkRegistrationEditing.dayShift}>
                        <Typography>{DayShift}</Typography>
                    </ToggleButton>
                    <ToggleButton value={ShiftSchemaDuringDay.Night} style={{paddingBottom: 20, paddingTop: 20}}
                                  disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.nightShift : !PermissionManager.WorkRegistrationEditing.nightShift}>
                        <Typography>{NightShift}</Typography>
                    </ToggleButton>
                    <ToggleButton value={ShiftSchemaDuringDay.DayAndNight} style={{paddingBottom: 20, paddingTop: 20}}
                                  disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.dayAndNightShift : !PermissionManager.WorkRegistrationEditing.dayAndNightShift}>
                        <Typography>{DayAndNightShift}</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box sx={{backgroundColor: 'white', padding: 1}} borderRadius={1}>
                    <FormControlLabel
                        control={
                            <CustomStyledSwitch
                                checked={props.continuesDuringWeekend ? props.continuesDuringWeekend === WorkDuringWeekend.Saturday || props.continuesDuringWeekend === WorkDuringWeekend.AllDays : false}
                                onChange={(event: { target: { checked: any; }; }) => {
                                    let update = null;
                                    if (event.target.checked) {
                                        if (props.continuesDuringWeekend && props.continuesDuringWeekend === WorkDuringWeekend.Sunday) {
                                            update = WorkDuringWeekend.AllDays;
                                        } else {
                                            update = WorkDuringWeekend.Saturday;
                                        }
                                    } else if (props.continuesDuringWeekend && props.continuesDuringWeekend === WorkDuringWeekend.AllDays) {
                                        update = WorkDuringWeekend.Sunday;
                                    }
                                    props.changedContinuesDuringWeekend(update);
                                }}
                                name="continuesWeekendSaturday"
                                disabled={canEditWorkDuringWeekend}
                            />
                        }
                        label={workDuringWeekendPrefix + "Samstag"}
                    />
                    <FormControlLabel
                        control={
                            <CustomStyledSwitch
                                checked={props.continuesDuringWeekend ? props.continuesDuringWeekend === WorkDuringWeekend.Sunday || props.continuesDuringWeekend === WorkDuringWeekend.AllDays : false}
                                onChange={(event: { target: { checked: any; }; }) => {
                                    let update = null;
                                    if (event.target.checked) {
                                        if (props.continuesDuringWeekend && props.continuesDuringWeekend === WorkDuringWeekend.Saturday) {
                                            update = WorkDuringWeekend.AllDays;
                                        } else {
                                            update = WorkDuringWeekend.Sunday;
                                        }
                                    } else if (props.continuesDuringWeekend && props.continuesDuringWeekend === WorkDuringWeekend.AllDays) {
                                        update = WorkDuringWeekend.Saturday;
                                    }
                                    props.changedContinuesDuringWeekend(update);
                                }}
                                name="continuesWeekendSunday"
                                disabled={canEditWorkDuringWeekend}
                            />
                        }
                        label={workDuringWeekendPrefix + "Sonntag"}
                    />
                </Box>
                <Stack direction="row" spacing={2}>
                    {(props.isNewWorkRegistration ? PermissionManager.NewWorkRegistration.partOfWeekProgram : PermissionManager.WorkRegistrationEditing.partOfWeekProgram) &&
                        <FormControlLabel
                            control={
                                <CustomStyledSwitch checked={props.partOfWeekProgram}
                                                    onChange={(event: { target: { checked: boolean; }; }) => props.changedPartOfWeekProgram(event.target.checked)}
                                                    name="partOfWeekProgram"
                                />
                            }
                            label="Teil des Wochenprogramms"
                        />}
                    {(props.isNewWorkRegistration ? PermissionManager.NewWorkRegistration.isMilestone : PermissionManager.WorkRegistrationEditing.isMilestone) &&
                        <FormControlLabel
                            control={
                                <CustomStyledSwitch checked={props.isMilestone}
                                                    onChange={(event: { target: { checked: boolean; }; }) => props.changedIsMilestone(event.target.checked)}
                                                    name="isMilestone"
                                />
                            }
                            label="Ist ein Meilenstein"
                        />
                    }
                </Stack>
            </Stack>
        </Stack>
    )


}

export default DateRangePickerComponent