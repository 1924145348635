import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Select,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {HeaderBarNewRegistration} from "../../utils/Colors";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {de} from "date-fns/locale";
import {LocalizationProvider, TimeField} from "@mui/x-date-pickers";
import {CrossCutMenuItems, TubeMenuItems} from "../../utils/Exports";
import {format, formatISO} from "date-fns";
import {LatitudinalDirection, TrainStationApiFp, TrainStationInTunnel, Tube} from "../../generated";
import {ColumnHeaderService, EMPTY_STOP_NOTE, NOTES_MAX_CHAR_COUNT} from "../../constants";
import DefaultDataGrid from "../DefaultDataGrid/DefaultDataGrid";
import {GridRowSpacingParams} from "@mui/x-data-grid";
import {LocalizationService} from "../../utils/Localization";
import {tunnelConstructionService} from "../../services/tunnelConstructionsDescriptionsProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {DateRange, Range} from "react-date-range";
import {formatDateStringToGermanDateFormat} from "../../utils/DateFormatting";
import {trainStationCallbackProvider} from "../../services/trainStationService";
import {
    renderLatitudinalDirectionEditInputCell,
    renderQsEditInputCell,
    renderTimeEditInputCell,
    renderTubeEditInputCell
} from "../../utils/FilterOperators";

function TrainStationButton() {
    const [open, setOpen] = useState(false)
    const [trainStationData, setTrainStationData] = useState<TrainStationInTunnel[]>([]);

    const DialogOptions = {
        NEW: "Neue Haltestelle anlegen",
        DELETE_EDIT: "Haltestelle bearbeiten / löschen",
    };

    const startDate = new Date();
    startDate.setDate(startDate.getDate());

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);
    const initialRanges: Range[] = [{
        startDate,
        endDate
    }];
    const [dateRange, setDateRange] = React.useState(initialRanges)

    const [selectedTime, setSelectedTime] = useState(new Date())
    const [crossCutId, setCrossCutId] = useState(1)
    const [selectedTube, setSelectedTube] = useState(Tube.North)
    const [finalDestination, setFinalDestination] = useState(LatitudinalDirection.East)
    const [notes, setNotes] = useState("")
    const [dialogIconButton, setDialogIconButton] = useState(<FileUploadOutlinedIcon/>)
    const [selectedTab, setSelectedTab] = useState(DialogOptions.NEW);

    async function fetchTrainStationData() {
        if (dateRange[0].startDate && dateRange[0].endDate) {
            await TrainStationApiFp().trainStationGetIntervalAndGetData(
                dateRange[0].startDate.toDateString(),
                dateRange[0].endDate.toDateString(),
            ).then((data) => {
                setTrainStationData(data)
            })
        }

    }

    useEffect(() => {
        void fetchTrainStationData()
        setDialogIconButton(<FileUploadOutlinedIcon/>)
    }, [dateRange, selectedTab, open]);

    const columns = [
        {field: 'ID', headerName: 'ID', hide: true, filterable: false, sortable: false},
        {
            field: 'Departure',
            headerName: 'Uhrzeit',
            width: 110,
            editable: true,
            filterable: false,
            sortable: false,
            renderEditCell: renderTimeEditInputCell,
            renderCell: (params: any) => (
                <div style={{padding: '5px'}}>{params.value ? params.value.slice(0, 5) : ''}</div>
            ),
        },
        {
            field: 'Start',
            headerName: 'Start',
            width: 110,
            editable: true,
            filterable: false,
            sortable: false,
            type: 'date',
            renderCell: (params: any) => (
                <div
                    style={{padding: '5px'}}>{params.value ? formatDateStringToGermanDateFormat(params.value) : ''}</div>
            ),
        },
        {
            field: 'End',
            headerName: 'Ende',
            width: 110,
            editable: true,
            filterable: false,
            sortable: false,
            type: 'date',
            renderCell: (params: any) => (
                <div
                    style={{padding: '5px'}}>{params.value ? formatDateStringToGermanDateFormat(params.value) : ''}</div>
            ),
        },
        {
            field: 'CrossCutId',
            headerName: 'Ort',
            type: 'singleSelect',
            valueOptions:
                tunnelConstructionService.crossCutDescriptions.map((description) => ({
                    value: description.ID,
                    label: LocalizationService.ZoneSelection(description.ID as unknown as string)
                })),
            renderEditCell: renderQsEditInputCell,
            width: 80,
            editable: true,
            filterable: false,
            sortable: false,
            valueFormatter: (params: any) => {
                return LocalizationService.ZoneSelection(params.value)
            },
        },
        {
            field: 'Tube',
            headerName: 'Tunnel / Gleis',
            type: 'singleSelect',
            valueOptions:
                Object.keys(Tube)
                    .map((key) => ({
                        value: key,
                        label: LocalizationService.Tube(Tube[key as Tube]),
                    })),
            renderEditCell: renderTubeEditInputCell,
            width: 160,
            editable: true,
            filterable: false,
            sortable: false,
            valueFormatter: (params: any) => {
                return LocalizationService.Tube(params.value) + " / " + LocalizationService.TubeLongitudinalDirectionToRailNumber(params.value)
            },
        },
        {
            field: 'FinalDestination',
            headerName: 'Fahrtrichtung',
            type: 'singleSelect',
            valueOptions:
                Object.keys(LatitudinalDirection)
                    .map((key) => ({
                        value: key,
                        label: LocalizationService.LatitudinalDirection(LatitudinalDirection[key as LatitudinalDirection]),
                    })),
            renderEditCell: renderLatitudinalDirectionEditInputCell,
            width: 130,
            editable: true,
            filterable: false,
            sortable: false,
            valueFormatter: (params: any) => {
                return LocalizationService.LatitudinalDirection(params.value)
            },
        },
        {
            field: 'Notes',
            headerName: 'Notizen',
            type: 'string',
            width: 250,
            editable: true,
            filterable: false,
            sortable: false,
            renderCell: (cellValues: any) => {
                return (
                    <Typography>{(cellValues.row.Notes as string).length > 0 ? cellValues.row.Notes : EMPTY_STOP_NOTE}</Typography>
                );
            },
        },
        {
            field: ColumnHeaderService.GetButtonValue(false),
            headerName: ColumnHeaderService.GetButtonValue(true),
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            disableExport: true,
            renderCell: (cellValues: any) => {
                return (
                    <Stack direction='row'>
                        <IconButton
                            onClick={(event) => {
                                void handleDeleteClick(event, cellValues);
                            }}
                            sx={{color: '#000000'}}> <DeleteIcon fontSize="large"/>
                        </IconButton>
                    </Stack>
                );
            }, width: 80
        },
    ];

    const handleDeleteClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, cellValue: any) => {
        await TrainStationApiFp().trainStationDeleteByAndGetData(cellValue.row.ID).then(async () => {
            await trainStationCallbackProvider.callback();
            return void fetchTrainStationData();
        })
    }

    const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    async function SaveData(data: TrainStationInTunnel) {
        const result = await TrainStationApiFp().trainStationSaveAndGetData(data);
        await trainStationCallbackProvider.callback();
        return result;
    }

    const handleNewTrainStation = async () => {
        const startDate = dateRange[0].startDate ? dateRange[0].startDate : new Date()
        const endDate = dateRange[0].endDate ? dateRange[0].endDate : new Date()
        await SaveData({
            Tube: selectedTube,
            Start: formatISO(startDate, {representation: 'date'}),
            End: formatISO(endDate, {representation: 'date'}),
            CrossCutId: crossCutId,
            Notes: notes,
            Departure: format(selectedTime, "HH:mm:ss"),
            FinalDestination: finalDestination,
        });
        setDialogIconButton(<CheckOutlinedIcon/>)
    }

    const renderDateSelection = () => {
        return (
            <DateRange
                ranges={dateRange}
                dragSelectionEnabled={true}
                onChange={(ranges) => setDateRange(Object.values(ranges))}
                locale={de}
            />
        );
    }

    const renderTrainStationDialogContent = () => {
        return (
            <Stack padding={5} spacing={3}>
                {renderDateSelection()}
                <Stack direction="row" spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                        <TimeField
                            label="Abfahrtszeit"
                            onChange={(newValue) => newValue ? setSelectedTime(newValue) : setSelectedTime(new Date())}
                            value={selectedTime}
                        />
                    </LocalizationProvider>
                    <FormControl>
                        <InputLabel id="TubeSelectionLabel">Tunnel / Gleis</InputLabel>
                        <Select
                            name="TubeSelection"
                            label="Tunnel / Gleis"
                            labelId="TubeSelectionLabel"
                            id="TubeSelection"
                            value={selectedTube}
                            color="secondary"
                            sx={{width: 260}}
                            onChange={(event) => setSelectedTube(event.target.value as Tube)}
                        >
                            {TubeMenuItems(true)}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <FormControl>
                        <InputLabel id="CrossCutElementSelectionLabel">Abfahrtsort</InputLabel>
                        <Select
                            label="Abfahrtsort"
                            labelId="CrossCutElementSelectionLabel"
                            id="CrossCutElementSelection"
                            onChange={(event) => setCrossCutId(event.target.value as number)}
                            sx={{width: 225, marginTop: 1}}
                            value={crossCutId}
                        >
                            {CrossCutMenuItems()}
                        </Select>
                    </FormControl>
                    <Stack direction="row" component="label" alignItems="center" justifyContent="center">
                        <Typography>{LocalizationService.LatitudinalDirection(LatitudinalDirection.East)}</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    value={finalDestination}
                                    onChange={(event) => setFinalDestination(event.target.value as LatitudinalDirection)}
                                />}
                            label="Fahrtrichtung"
                            labelPlacement="top"
                        />
                        <Typography>{LocalizationService.LatitudinalDirection(LatitudinalDirection.West)}</Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <TextField
                        id="NotesForTrainStation"
                        name="NotesForTrainStation"
                        label="Notizen"
                        multiline={true}
                        inputProps={{maxLength: NOTES_MAX_CHAR_COUNT}}
                        placeholder={""}
                        minRows={3}
                        defaultValue={notes}
                        onChange={(event) => setNotes(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") event.preventDefault()
                        }}
                    >
                    </TextField>
                    <Typography>Zeichenlimit: {notes.length}/{NOTES_MAX_CHAR_COUNT}</Typography>
                </Stack>
            </Stack>
        );
    }

    const renderTrainStationDataGridDialogContent = () => {
        return (
            <Stack padding={5} spacing={3} style={{height: "450px"}} direction="row">
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                    {renderDateSelection()}
                    <Stack width="100%" alignItems="center">
                        <Typography fontStyle="italic">Editieren der Haltestelle mit Doppelklick auf das Gewünschte
                            Element</Typography>
                        <DefaultDataGrid
                            columns={columns}
                            rows={trainStationData}
                            mode="client"
                            getRowSpacing={getRowSpacing}
                            processRowUpdate={async (newRow: TrainStationInTunnel, oldRow: TrainStationInTunnel) => {
                                // @ts-ignore
                                let updatedStartDate = newRow.Start
                                let updatedEndDate = newRow.End
                                // @ts-ignore
                                if (newRow.Start instanceof Date) updatedStartDate = formatISO(newRow.Start, {representation: 'date'})
                                // @ts-ignore
                                if (newRow.End instanceof Date) updatedEndDate = formatISO(newRow.End, {representation: 'date'})
                                try {
                                    await SaveData({
                                        Tube: newRow.Tube,
                                        Start: updatedStartDate,
                                        End: updatedEndDate,
                                        CrossCutId: newRow.CrossCutId,
                                        Notes: newRow.Notes,
                                        Departure: newRow.Departure,
                                        ID: newRow.ID,
                                        FinalDestination: newRow.FinalDestination,
                                    });
                                    return newRow;
                                } catch (error) {
                                    console.error(error);
                                    return oldRow;
                                }
                            }}
                        />
                    </Stack>
                </LocalizationProvider>
            </Stack>
        );
    }

    return (
        <div>
            <Button
                variant="contained"
                onClick={() => setOpen(true)}
                size="large"
                style={{backgroundColor: HeaderBarNewRegistration}}
                sx={{width: 150, height: 45}}>
                {"Haltestellen"}
            </Button>

            <Dialog open={open} onClose={() => {
                setOpen(false);
            }} fullWidth={selectedTab === DialogOptions.DELETE_EDIT}
                    maxWidth={selectedTab === DialogOptions.DELETE_EDIT ? "xl" : false}>
                <DialogTitle>
                    <Tabs
                        value={selectedTab}
                        onChange={(event, value) => setSelectedTab(value)}>
                        <Tab value={DialogOptions.NEW} label={DialogOptions.NEW}/>
                        <Tab value={DialogOptions.DELETE_EDIT} label={DialogOptions.DELETE_EDIT}/>
                    </Tabs>
                </DialogTitle>
                <DialogContent>
                    {
                        selectedTab === DialogOptions.NEW ? renderTrainStationDialogContent() : renderTrainStationDataGridDialogContent()
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant='contained' color="secondary">Abbrechen</Button>
                    {selectedTab === DialogOptions.NEW &&
                        <Button onClick={handleNewTrainStation} variant='contained'
                                color="primary">Anlegen {dialogIconButton}</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TrainStationButton;