import HeaderBar from "../HeaderBar/HeaderBar";
import ContractorMask from "./ContractorMask";
import DateRangePickerComponent from "./DateRangePickerComponent";
import LengthSelectors from "./LengthSelectors";
import {Grid, Stack, Typography,} from "@mui/material";
import React from "react";
import SaveButtons from "./SaveButtons";
import {
    KoralmLocation,
    LatitudinalDirectedOpenLineConstruction,
    LatitudinalDirectedOutdoorConstructionWorkRegistration,
    LatitudinalDirectedTunnelWorkRegistration,
    LatitudinalDirection,
    OutdoorWorkRegistration,
    ProcessStatus,
    RailNumber,
    ShiftSchemaDuringDay,
    Tube,
    TunnelObject,
    TunnelWorkRegistrationWithNumericLocationID,
    UniqueOpenLineConstruction,
    UniqueOutdoorConstructionWorkRegistration,
    ZoneBasedTunnelTubeWorkRegistration,
} from "../../generated";
import {OutdoorVisualization} from "../RailVisualization/OutdoorVisualization";
import {OutdoorWorkRegistrationsAndConstructions, TunnelWorkRegistration, WebAppPage} from "../../utils/Types";
import {TunnelVisualization} from "../RailVisualization/TunnelVisualization";
import UserService from "../../services/UserService";
import {SortingGridOverviewArgs} from "../../utils/InterfaceProps";
import {tunnelConstructionService} from "../../services/tunnelConstructionsDescriptionsProvider";
import {railDescriptionService} from "../../services/railDescriptionsProvider";

function SortingGrid(props: SortingGridOverviewArgs) {

    function handleVisualization(isOutdoor: boolean) {
        const placeholderOutdoor: OutdoorWorkRegistration = {
            IsMilestone: false,
            EditorEmail: UserService.getEmail(),
            IsDeleted: false,
            ContinuesDuringWeekend: undefined,
            PersonCount: props.contractorArgs.personCount,
            ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
            Start: "",
            End: "",
            WorkDirection: props.lengthSelectorArgs.isGoingWest,
            RailTrackStatus: props.lengthSelectorArgs.railTrackStatus,
            Status: ProcessStatus.Done,
            ContractorId: "",
            Name: "",
            PartOfWeekProgram: false,
            ThirdLevelOutline: props.contractorArgs.thirdLevelOutline !== null ? props.contractorArgs.thirdLevelOutline : undefined,
            LocationDescription: {
                Location: props.lengthSelectorArgs.locationType,
                RailNumber: props.lengthSelectorArgs.selectedObjectType as RailNumber,
                ObjectDescription: {
                    AreaDescription: {
                        Start: {siValue: props.lengthSelectorArgs.unitInputFieldSecondLevel as unknown as number},
                        End: {siValue: props.lengthSelectorArgs.unitInputFieldFirstLevel as unknown as number}
                    }
                }
            }
        }
        const placeholderUniqueOutdoor: UniqueOutdoorConstructionWorkRegistration = {
            IsMilestone: false,
            EditorEmail: UserService.getEmail(),
            IsDeleted: false,
            ContinuesDuringWeekend: undefined,
            PersonCount: props.contractorArgs.personCount,
            ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
            Start: "",
            End: "",
            WorkDirection: props.lengthSelectorArgs.isGoingWest,
            RailTrackStatus: props.lengthSelectorArgs.railTrackStatus,
            Status: ProcessStatus.Done,
            ContractorId: "",
            Name: "",
            PartOfWeekProgram: false,
            ThirdLevelOutline: props.contractorArgs.thirdLevelOutline !== null ? props.contractorArgs.thirdLevelOutline : undefined,
            LocationDescription: {
                Location: props.lengthSelectorArgs.locationType,
                RailNumber: props.lengthSelectorArgs.selectedObjectType as RailNumber,
                ObjectDescription: {
                    AreaDescription: {
                        Start: {siValue: props.lengthSelectorArgs.unitInputFieldSecondLevel as unknown as number},
                        End: {siValue: props.lengthSelectorArgs.unitInputFieldFirstLevel as unknown as number}
                    },
                },
            },
            Construction: props.lengthSelectorArgs.outdoorBuilding as UniqueOpenLineConstruction,
        }
        const placeholderLatitudinalOutdoor: LatitudinalDirectedOutdoorConstructionWorkRegistration = {
            IsMilestone: false,
            EditorEmail: UserService.getEmail(),
            IsDeleted: false,
            ContinuesDuringWeekend: undefined,
            PersonCount: props.contractorArgs.personCount,
            ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
            Start: "",
            End: "",
            WorkDirection: props.lengthSelectorArgs.isGoingWest,
            RailTrackStatus: props.lengthSelectorArgs.railTrackStatus,
            Status: ProcessStatus.Done,
            ContractorId: "",
            Name: "",
            PartOfWeekProgram: false,
            ThirdLevelOutline: props.contractorArgs.thirdLevelOutline !== null ? props.contractorArgs.thirdLevelOutline : undefined,
            LocationDescription: {
                Location: props.lengthSelectorArgs.locationType,
                RailNumber: props.lengthSelectorArgs.selectedObjectType as RailNumber,
                ObjectDescription: {
                    AreaDescription: {
                        Start: {siValue: props.lengthSelectorArgs.unitInputFieldSecondLevel as unknown as number},
                        End: {siValue: props.lengthSelectorArgs.unitInputFieldFirstLevel as unknown as number}
                    },
                },
            },
            Construction: props.lengthSelectorArgs.outdoorBuilding as LatitudinalDirectedOpenLineConstruction,
            LatitudinalDirection: props.lengthSelectorArgs.latitudinalDirectionConstruction,
        }

        const placeholderTunnel: TunnelWorkRegistrationWithNumericLocationID = {
            IsMilestone: false,
            EditorEmail: UserService.getEmail(),
            IsDeleted: false,
            ContinuesDuringWeekend: undefined,
            RailTrackStatus: props.lengthSelectorArgs.railTrackStatus,
            PersonCount: props.contractorArgs.personCount,
            ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
            Start: "",
            End: "",
            Name: "",
            ContractorId: "",
            Status: ProcessStatus.Done,
            PartOfWeekProgram: false,
            ThirdLevelOutline: props.contractorArgs.thirdLevelOutline !== null ? props.contractorArgs.thirdLevelOutline : undefined,
            LocationDescription: {
                Location: KoralmLocation.Tunnel,
                ObjectDescription: {
                    TunnelObject: props.lengthSelectorArgs.selectedObjectType as TunnelObject,
                    AreaDescription: {
                        ID: props.lengthSelectorArgs.unitInputFieldFirstLevel as unknown as number,
                    }
                }
            }
        }
        const placeholderZone: ZoneBasedTunnelTubeWorkRegistration = {
            IsMilestone: false,
            EditorEmail: UserService.getEmail(),
            IsDeleted: false,
            ContinuesDuringWeekend: undefined,
            PersonCount: props.contractorArgs.personCount,
            ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
            Start: "",
            End: "",
            Name: "",
            ContractorId: "",
            Status: ProcessStatus.Done,
            WorkDirection: props.lengthSelectorArgs.isGoingWest,
            RailTrackStatus: props.lengthSelectorArgs.railTrackStatus,
            PartOfWeekProgram: false,
            ThirdLevelOutline: props.contractorArgs.thirdLevelOutline !== null ? props.contractorArgs.thirdLevelOutline : undefined,
            LocationDescription: {
                Location: KoralmLocation.Tunnel,
                ObjectDescription: {
                    TunnelObject: TunnelObject.Tube,
                    Tube: props.lengthSelectorArgs.selectedObjectType as Tube,
                    AreaDescription: {
                        Start: props.lengthSelectorArgs.unitInputFieldSecondLevel as unknown as number,
                        End: props.lengthSelectorArgs.unitInputFieldFirstLevel as unknown as number,
                    }
                }
            }
        }
        const placeholderLatitudinalPortal: LatitudinalDirectedTunnelWorkRegistration = {
            IsMilestone: false,
            EditorEmail: UserService.getEmail(),
            IsDeleted: false,
            ContinuesDuringWeekend: undefined,
            PersonCount: props.contractorArgs.personCount,
            ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
            Start: "",
            End: "",
            Name: "",
            ContractorId: "",
            Status: ProcessStatus.Done,
            RailTrackStatus: props.lengthSelectorArgs.railTrackStatus,
            PartOfWeekProgram: false,
            ThirdLevelOutline: props.contractorArgs.thirdLevelOutline !== null ? props.contractorArgs.thirdLevelOutline : undefined,
            LocationDescription: {
                Location: KoralmLocation.Tunnel,
                ObjectDescription: {
                    TunnelObject: TunnelObject.Portal,
                    AreaDescription: {
                        ID: props.lengthSelectorArgs.unitInputFieldFirstLevel as LatitudinalDirection
                    }
                }
            }
        }
        const placeholderLatitudinalVentilationShaft: LatitudinalDirectedTunnelWorkRegistration = {
            IsMilestone: false,
            EditorEmail: UserService.getEmail(),
            IsDeleted: false,
            ContinuesDuringWeekend: undefined,
            PersonCount: props.contractorArgs.personCount,
            ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
            Start: "",
            End: "",
            Name: "",
            ContractorId: "",
            Status: ProcessStatus.Done,
            PartOfWeekProgram: false,
            RailTrackStatus: props.lengthSelectorArgs.railTrackStatus,
            ThirdLevelOutline: props.contractorArgs.thirdLevelOutline !== null ? props.contractorArgs.thirdLevelOutline : undefined,
            LocationDescription: {
                Location: KoralmLocation.Tunnel,
                ObjectDescription: {
                    TunnelObject: TunnelObject.VentilationShaft,
                    AreaDescription: {
                        ID: props.lengthSelectorArgs.unitInputFieldFirstLevel as LatitudinalDirection
                    }
                }
            }
        }
        const placeholderTunnelWorkRegistration = () => {
            switch (props.lengthSelectorArgs.selectedObjectType) {
                case Tube.North:
                case Tube.South:
                    return placeholderZone
                case TunnelObject.VentilationShaft:
                    return placeholderLatitudinalVentilationShaft
                case TunnelObject.Portal:
                    return placeholderLatitudinalPortal
                case TunnelObject.Tube:
                    return placeholderZone
                case TunnelObject.CrossCut:
                case TunnelObject.EscapeGallery:
                case TunnelObject.EmergencyStop:
                    return placeholderTunnel
            }
        }

        const placeholderOutdoorWorkRegistration = () => {
            switch (props.lengthSelectorArgs.outdoorBuilding) {
                case "":
                    return placeholderOutdoor;
                case UniqueOpenLineConstruction.Admin:
                case UniqueOpenLineConstruction.TrainStationWesternStyria:
                case UniqueOpenLineConstruction.TrainStationLavantValey:
                case UniqueOpenLineConstruction.ElectricalSubstationWestStyria:
                case UniqueOpenLineConstruction.ElectricalSubstationLavantValey:
                    return placeholderUniqueOutdoor;
                case LatitudinalDirectedOpenLineConstruction.ConstructionFacilities:
                case LatitudinalDirectedOpenLineConstruction.WatercourseProtectionFacilities:
                    return placeholderLatitudinalOutdoor;
            }
        }

        return isOutdoor ?
            <OutdoorVisualization
                outdoorWorkRegistration={[placeholderOutdoorWorkRegistration() as OutdoorWorkRegistrationsAndConstructions]}
                openLineLocation={props.lengthSelectorArgs.latitudinalDirectionConstruction}
                changedStageRef={() => {}}
            /> :
            <TunnelVisualization
                tunnelWorkRegistration={[placeholderTunnelWorkRegistration() as TunnelWorkRegistration]}
                trainStationInTunnelData={props.trainStationsData}
                changedStageRef={() => {}}
            />
    }

    return (
        <Stack>
            <HeaderBar
                changedSelectedWorkRegistration={() => {
                }}
                isInDataTable={false}
                selectedPage={WebAppPage.WorkRegistration}
                changedSelectedPage={props.changedSelectedPage}
                isNewWorkRegistration={props.contractorArgs.isNewWorkRegistration}
                newNotification={false}
            />
            <Stack direction="row">
                <Grid container>
                    <Grid item>
                        <ContractorMask
                            changedCompanyTextField={(value) => props.contractorArgs.changedCompanyTextField(value)}
                            changedMailTextField={(value) => props.contractorArgs.changedMailTextField(value)}
                            changedNotesTextField={(value) => props.contractorArgs.changedNotesTextField(value)}
                            changedFirstNameTextField={(value) => props.contractorArgs.changedFirstNameTextField(value)}
                            changedLastNameTextField={(value) => props.contractorArgs.changedLastNameTextField(value)}
                            changedTelephoneTextField={(value) => props.contractorArgs.changedTelephoneTextField(value)}
                            changedWorkStepTextField={(value) => props.contractorArgs.changedWorkStepTextField(value)}
                            company={props.contractorArgs.company}
                            mail={props.contractorArgs.mail}
                            notes={props.contractorArgs.notes}
                            firstName={props.contractorArgs.firstName}
                            lastName={props.contractorArgs.lastName}
                            telephone={props.contractorArgs.telephone}
                            workStep={props.contractorArgs.workStep}
                            personCount={props.contractorArgs.personCount}
                            changedPersonCount={props.contractorArgs.changedPersonCount}
                            thirdLevelOutline={props.contractorArgs.thirdLevelOutline}
                            changedThirdLevelOutline={props.contractorArgs.changedThirdLevelOutline}
                            isNewWorkRegistration={props.contractorArgs.isNewWorkRegistration}
                        />
                        <SaveButtons
                            changedStatus={props.saveButtonArgs.changedStatus}
                            status={props.saveButtonArgs.status}
                            changedSaveButton={props.saveButtonArgs.changedSaveButton}
                            saveValue={props.saveButtonArgs.saveValue}
                            wasEdited={props.saveButtonArgs.wasEdited}
                            isNewWorkRegistration={props.saveButtonArgs.isNewWorkRegistration}
                            location={props.lengthSelectorArgs.locationType}
                            openLineLocation={props.lengthSelectorArgs.latitudinalDirectionConstruction}
                            range={props.datePickerArgs.dateRange}
                            object={props.lengthSelectorArgs.selectedObjectType}
                            area={props.lengthSelectorArgs.unitInputFieldFirstLevel}
                            area2={props.lengthSelectorArgs.unitInputFieldSecondLevel}
                            uuId={props.saveButtonArgs.uuId}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack
                            direction="column"
                            spacing={0.5}
                            padding={2}
                            marginTop={2}
                            marginLeft={2}
                            marginRight={2}
                            alignItems="center"
                            sx={{bgcolor: 'secondary.main', borderRadius: '5px'}}>
                            <Typography>Zeitraum:</Typography>
                            <DateRangePickerComponent
                                dateRange={props.datePickerArgs.dateRange}
                                changedDataRange={(value) => props.datePickerArgs.changedDataRange(value)}
                                isNewWorkRegistration={props.datePickerArgs.isNewWorkRegistration}
                                continuesDuringWeekend={props.datePickerArgs.continuesDuringWeekend}
                                changedContinuesDuringWeekend={props.datePickerArgs.changedContinuesDuringWeekend}
                                shiftSchemaDuringDay={props.datePickerArgs.shiftSchemaDuringDay}
                                changedShiftSchemaDuringDay={props.datePickerArgs.changedShiftSchemaDuringDay}
                                partOfWeekProgram={props.datePickerArgs.partOfWeekProgram}
                                changedPartOfWeekProgram={props.datePickerArgs.changedPartOfWeekProgram}
                                isMilestone={props.datePickerArgs.isMilestone}
                                changedIsMilestone={props.datePickerArgs.changedIsMilestone}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <LengthSelectors
                            locationType={props.lengthSelectorArgs.locationType}
                            selectedObjectType={props.lengthSelectorArgs.selectedObjectType}
                            unitInputFieldSecondLevel={props.lengthSelectorArgs.unitInputFieldSecondLevel}
                            unitInputFieldFirstLevel={props.lengthSelectorArgs.unitInputFieldFirstLevel}
                            isGoingWest={props.lengthSelectorArgs.isGoingWest}
                            railTrackStatus={props.lengthSelectorArgs.railTrackStatus}
                            outdoorBuilding={props.lengthSelectorArgs.outdoorBuilding}
                            isOnOuterConstruction={props.lengthSelectorArgs.isOnOuterConstruction}
                            latitudinalDirectionConstruction={props.lengthSelectorArgs.latitudinalDirectionConstruction}
                            changedLocationType={(value) => props.lengthSelectorArgs.changedLocationType(value)}
                            changedSelectedObjectType={(value) => props.lengthSelectorArgs.changedSelectedObjectType(value)}
                            changedUnitInputFieldLeft={(value) => props.lengthSelectorArgs.changedUnitInputFieldLeft(value)}
                            changedUnitInputFieldRight={(value) => props.lengthSelectorArgs.changedUnitInputFieldRight(value)}
                            changedIsGoingWest={(value) => props.lengthSelectorArgs.changedIsGoingWest(value)}
                            changedRailTrackStatus={(value) => props.lengthSelectorArgs.changedRailTrackStatus(value)}
                            changedOutdoorBuilding={(value) => props.lengthSelectorArgs.changedOutdoorBuilding(value)}
                            changedIsOnOuterConstruction={(value) => props.lengthSelectorArgs.changedIsOnOuterConstruction(value)}
                            changedLatitudinalDirectionConstruction={(value) => props.lengthSelectorArgs.changedLatitudinalDirectionConstruction(value)}
                            isNewWorkRegistration={props.lengthSelectorArgs.isNewWorkRegistration}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <Stack marginLeft={2} marginRight={2} paddingTop={2} paddingBottom={2} bgcolor='secondary.main'
                   borderRadius="5px">
                {
                    railDescriptionService.railSectionDescriptions[0] &&
                    tunnelConstructionService.zoneDescriptions[0] &&
                    tunnelConstructionService.crossCutDescriptions[0] &&
                    tunnelConstructionService.escapeGalleriesDescriptions[0] &&
                    tunnelConstructionService.ventilationShaftsDescriptions[0] &&
                    tunnelConstructionService.portalDescriptions[0] &&
                    tunnelConstructionService.emergencyStopDescription[0] &&
                    handleVisualization((props.lengthSelectorArgs.selectedObjectType === RailNumber.Eins || props.lengthSelectorArgs.selectedObjectType === RailNumber.Zwei))}
            </Stack>
        </Stack>
    )
}

export default SortingGrid;

