import {RailTrackStatus} from "../generated";
import {translationsService} from "../services/translationsProvider";

export function rgbaToHex(r: number, g: number, b: number, a: number) {
    const toHex = (value: number) => value.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(Math.round(a * 255)).substring(0, 2)}`;
}

export const RailTrackPrimaryColor = "#d5d5d5"
export const RailTrackSecondaryColor = "#ffffff"
export const RailTrackStatusFree = "#51dbcb"
export const RailTrackStatusSideWork = "#fdc434"
export const RailTrackStatusBlocked = "#C31313"
export const IbnColor = "#1976D2FF"
export const CrossCutColor = "#808080"
export const HeaderBarDefault = "#20222a"
export const HeaderBarNewRegistration = "#666a80"
export const SELECTED_MENU_ITEM = "#AAB4BEFF"

export const railTrackStatusToColors = (status: RailTrackStatus) => {
    const statusColorMap: Record<RailTrackStatus, string> = {
        [RailTrackStatus.Free]: RailTrackStatusFree,
        [RailTrackStatus.WorkInRailTrackAreaPeriphery]: RailTrackStatusSideWork,
        [RailTrackStatus.Blocked]: RailTrackStatusBlocked,
    };
    return statusColorMap[status];
}

export const getIbnOrRailTrackColor = (value: string | null | undefined, status: RailTrackStatus) => {
    if (!value) return railTrackStatusToColors(status)
    return translationsService.thirdLevelOutline.some(item => item.ID === value && item.IsPartOfCommissioning) ? IbnColor : railTrackStatusToColors(status)
}

export const generateRandomColor = () => {
    const hexValue = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += hexValue[Math.floor(Math.random() * 16)]
    }
    return color;
}