/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RangeOfDateTime } from '../model';
/**
 * VehicleLogExportApi - axios parameter creator
 * @export
 */
export const VehicleLogExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleLogExportGetTimeSeriesBoundaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/VehicleLogExport/GetTimeSeriesBoundaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleLogExportGetVehicleLogTimeSeriesInInterval: async (startIncluding: string, endIncluding: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('vehicleLogExportGetVehicleLogTimeSeriesInInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('vehicleLogExportGetVehicleLogTimeSeriesInInterval', 'endIncluding', endIncluding)
            const localVarPath = `/VehicleLogExport/GetVehicleLogTimeSeriesInInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}`
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleLogExportApi - functional programming interface
 * @export
 */
export const VehicleLogExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleLogExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleLogExportGetTimeSeriesBoundaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangeOfDateTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleLogExportGetTimeSeriesBoundaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async vehicleLogExportGetTimeSeriesBoundariesSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<RangeOfDateTime>> {
            return (await this.vehicleLogExportGetTimeSeriesBoundaries( options))();
        },
        async vehicleLogExportGetTimeSeriesBoundariesAndGetData(options?: AxiosRequestConfig): Promise<RangeOfDateTime> {
            return (await this.vehicleLogExportGetTimeSeriesBoundariesSendRequest( options)).data;
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding, endIncluding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async vehicleLogExportGetVehicleLogTimeSeriesInIntervalSendRequest(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding, endIncluding,  options))();
        },
        async vehicleLogExportGetVehicleLogTimeSeriesInIntervalAndGetData(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): Promise<File> {
            return (await this.vehicleLogExportGetVehicleLogTimeSeriesInIntervalSendRequest(startIncluding, endIncluding,  options)).data;
        },
    }
};

/**
 * VehicleLogExportApi - factory interface
 * @export
 */
export const VehicleLogExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleLogExportApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleLogExportGetTimeSeriesBoundaries(options?: any): AxiosPromise<RangeOfDateTime> {
            return localVarFp.vehicleLogExportGetTimeSeriesBoundaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding: string, endIncluding: string, options?: any): AxiosPromise<File> {
            return localVarFp.vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding, endIncluding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleLogExportApi - interface
 * @export
 * @interface VehicleLogExportApi
 */
export interface VehicleLogExportApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleLogExportApiInterface
     */
    vehicleLogExportGetTimeSeriesBoundaries(options?: AxiosRequestConfig): AxiosPromise<RangeOfDateTime>;

    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleLogExportApiInterface
     */
    vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig): AxiosPromise<File>;

}

/**
 * VehicleLogExportApi - object-oriented interface
 * @export
 * @class VehicleLogExportApi
 * @extends {BaseAPI}
 */
export class VehicleLogExportApi extends BaseAPI implements VehicleLogExportApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleLogExportApi
     */
    public vehicleLogExportGetTimeSeriesBoundaries(options?: AxiosRequestConfig) {
        return VehicleLogExportApiFp(this.configuration).vehicleLogExportGetTimeSeriesBoundaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleLogExportApi
     */
    public vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding: string, endIncluding: string, options?: AxiosRequestConfig) {
        return VehicleLogExportApiFp(this.configuration).vehicleLogExportGetVehicleLogTimeSeriesInInterval(startIncluding, endIncluding, options).then((request) => request(this.axios, this.basePath));
    }
}
