import {
    StorageDataStatus,
    ThirdLevelOutlineDescription,
    ThirdLevelOutlineDescriptionApiFp,
    TranslationApiFp
} from "../generated";
import {Service} from "./service";

class TranslationsProvider extends Service {
    thirdLevelOutline: ThirdLevelOutlineDescription[] = [];
    storageDataStatus: Map<StorageDataStatus, string> = new Map<StorageDataStatus, string>();
    latitudinalDirectedOpenLineConstructions: { [key: string]: string } = {};
    uniqueOpenLineConstructions: { [key: string]: string } = {};

    async init(): Promise<void> {
        this.thirdLevelOutline = await ThirdLevelOutlineDescriptionApiFp().thirdLevelOutlineDescriptionGetAllAndGetData()
        for (const storageDataStatus of Object.entries(await TranslationApiFp()
            .translationGetStorageDataStatusAndGetData())) {
            this.storageDataStatus.set(storageDataStatus[0] as StorageDataStatus, storageDataStatus[1]);
        }
        this.latitudinalDirectedOpenLineConstructions = await TranslationApiFp().translationGetLatitudinalDirectedOpenLineConstructionsAndGetData();
        this.uniqueOpenLineConstructions = await TranslationApiFp().translationGetUniqueOpenLineConstructionsAndGetData();
    }
}

export const translationsService = new TranslationsProvider();