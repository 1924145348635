import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CustomStyledSwitch from "../../utils/CustomStyledSwitch";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {ThirdLevelOutlineDescription, ThirdLevelOutlineDescriptionApiFp} from "../../generated";
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import {translationsService} from "../../services/translationsProvider";
import {ID_FROM_MSP, THIRD_LEVEL_OUTLINE_WIDTH} from "../../constants";

function ThirdLevelOutlineButton() {
    const [open, setOpen] = useState(false)
    const [dialogIconButton, setDialogIconButton] = useState(<FileUploadOutlinedIcon/>)
    const [thirdLevelOutlineDescriptionData, setThirdLevelOutlineDescriptionData] = useState<ThirdLevelOutlineDescription[]>([])

    const [id, setId] = useState("")
    const [displayName, setDisplayName] = useState("")
    const [isPartOfCommissioning, setIsPartOfCommissioning] = useState(false)
    const [orderNumber, setOrderNumber] = useState(0)

    const checkForExistingItem = ():boolean =>
        thirdLevelOutlineDescriptionData.some(thirdLevel => thirdLevel.ID === id)

    const resetInputFields = () => {
        setId("")
        setDisplayName("")
        setIsPartOfCommissioning(false)
        setOrderNumber(0)
    }

    const handleIbnEdit = async (item: ThirdLevelOutlineDescription) => {
        setId(item.ID);
        setDisplayName(item.DisplayName);
        setIsPartOfCommissioning(item.IsPartOfCommissioning);
        setOrderNumber(item.Order);
    }

    const handleIbnDelete = async (item: ThirdLevelOutlineDescription) => {
        await ThirdLevelOutlineDescriptionApiFp().thirdLevelOutlineDescriptionDeleteByAndGetData(item.ID);
        resetInputFields();
        void fetchThirdLevelOutlineDescriptionData();
        await translationsService.init();
    }

    const handleNewIbn = async () => {
        setDialogIconButton(<CheckOutlinedIcon/>)
        await ThirdLevelOutlineDescriptionApiFp().thirdLevelOutlineDescriptionSaveAndGetData({
            ID: id,
            DisplayName: displayName,
            Order: orderNumber,
            IsPartOfCommissioning: isPartOfCommissioning,
        })
        resetInputFields();

        void fetchThirdLevelOutlineDescriptionData()
        await translationsService.init()
    }

    async function fetchThirdLevelOutlineDescriptionData() {
        await ThirdLevelOutlineDescriptionApiFp().thirdLevelOutlineDescriptionGetAllAndGetData().then((data) => setThirdLevelOutlineDescriptionData(data))
    }

    useEffect(() => {
        void fetchThirdLevelOutlineDescriptionData()
    }, [])

    useEffect(() => {
        setDialogIconButton(<FileUploadOutlinedIcon/>)
    }, [id, displayName, isPartOfCommissioning, orderNumber])

    const handleOrderInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputNumber = Number(event.target.value as unknown as number)

        if (!isNaN(inputNumber)) {
            setOrderNumber(findNextUnusedNumber(inputNumber, inputNumber >= orderNumber))
        }
    }

    const findNextUnusedNumber = (inputNumber: number, increased: boolean) => {
        const existingOrderNumbers = thirdLevelOutlineDescriptionData.map(item => item.Order);
        let nextNumber = inputNumber;
        while (existingOrderNumbers.includes(nextNumber)) {
            increased ? nextNumber++ : nextNumber--
        }
        return nextNumber;
    }

    return (
        <div>
            <Tooltip title={<Typography>Gliederungsebene anlegen / bearbeiten</Typography>}>
                <IconButton
                    size="large"
                    sx={{color: "#ffffff"}}
                    onClick={() => setOpen(true)}>
                    <FormatListNumberedOutlinedIcon fontSize="large"/>
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>Neue Gliederungsebene anlegen</DialogTitle>
                <DialogContent>
                    <Stack padding={5} spacing={3} direction="row" style={{display: 'flex'}}>
                        <Stack spacing={3}>
                            <TextField
                                id="id"
                                name="ID"
                                label={ID_FROM_MSP}
                                multiline={false}
                                value={id}
                                onChange={(event) => setId(event.target.value)}
                            />
                            <TextField
                                id="displayName"
                                name="Anzeigename"
                                label="Anzeigename"
                                multiline={false}
                                value={displayName}
                                sx={{width: THIRD_LEVEL_OUTLINE_WIDTH}}
                                onChange={(event) => setDisplayName(event.target.value)}
                            />
                            <Stack direction="row">
                                <Typography paddingTop={0.6}>
                                    IBN-Prozess
                                </Typography>
                                <CustomStyledSwitch
                                    checked={isPartOfCommissioning}
                                    onChange={(event) => setIsPartOfCommissioning(event.target.checked)}
                                    name="IsPartOfCommissioning"
                                />
                            </Stack>
                            <Stack direction="row" spacing={3}>
                                <Typography paddingTop={0.6}>
                                    Reihenfolge
                                </Typography>
                                <Input
                                    value={orderNumber}
                                    size="medium"
                                    onChange={handleOrderInputChange}
                                    disabled={checkForExistingItem()}
                                    inputProps={{
                                        type: 'number',
                                        min: 0,
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Divider orientation="vertical" flexItem/>
                        <Stack height="400px" style={{overflowY: 'auto', flexGrow: 1}}>
                            <List>
                                {thirdLevelOutlineDescriptionData.sort((a, b) => a.Order - b.Order).map(item => {
                                    return (
                                        <Stack key={"StackListItem: " + item.ID + " + " + item.DisplayName}>
                                            <ListItem key={"ListItem: " + item.ID + " + " + item.DisplayName}>
                                                <Stack direction="column">
                                                    <ListItemButton onClick={() => handleIbnEdit(item)}>
                                                        <EditOutlinedIcon/>
                                                    </ListItemButton>
                                                    <ListItemButton onClick={() => handleIbnDelete(item)}>
                                                        <DeleteOutlineOutlinedIcon/>
                                                    </ListItemButton>
                                                </Stack>
                                                <ListItemText primary={item.DisplayName}
                                                              secondary={"IBN-Prozess: " + (item.IsPartOfCommissioning ? "Ja" : "Nein") + " Reihenfolge: " + item.Order}
                                                              key={"ListItemText: " + item.ID + " + " + item.DisplayName}/>
                                            </ListItem>
                                            <Divider/>
                                        </Stack>
                                    )
                                })}
                            </List>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained" color="secondary">Abbrechen</Button>
                    <Button onClick={handleNewIbn} variant="contained"
                            color="primary">{checkForExistingItem() ? "Editieren" : "Anlegen"} {dialogIconButton}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ThirdLevelOutlineButton;