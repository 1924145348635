import {Button} from "@mui/material";
import React from "react";
import {NEW_WORK_REGISTRATION} from "../../constants";
import {HeaderBarNewRegistration} from "../../utils/Colors";
import {WebAppPage} from "../../utils/Types";

interface NewWorkRegistrationButtonProps {
    changedSelectedPage: (value: WebAppPage) => void;
}

function NewWorkRegistrationButton(props: NewWorkRegistrationButtonProps) {

    return (
        <Button
            variant='contained'
            style={{backgroundColor: HeaderBarNewRegistration}}
            size="large"
            onClick={() => props.changedSelectedPage(WebAppPage.WorkRegistration)}
            sx={{width: 215, height: 45}}
        >
            {NEW_WORK_REGISTRATION}
        </Button>
    )
}

export default NewWorkRegistrationButton;