import {Alert, Box, Button, CircularProgress, DialogActions, IconButton, Stack} from "@mui/material";
import {handleDialogExportDownload} from "../../utils/Exports";
import {de} from "date-fns/locale";
import {DateRange, Range} from "react-date-range";
import React from "react";
import {EXCEL_FILE} from "../../constants";
import excelImg from "../../img/microsoft-excel.png";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {ExportButtonProps} from "../../utils/InterfaceProps";
import {formatISO} from "date-fns";
import {LogisticLogExportApiAxiosParamCreator} from "../../generated";
import CloseIcon from "@mui/icons-material/Close";

function LogisticExportTab(props: ExportButtonProps) {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 2);
    const initialRanges: Range[] = [{
        startDate,
        endDate
    }];
    const [dateRange, setDateRange] = React.useState(initialRanges)
    const [isFetching, setIsFetching] = React.useState(false)
    const [errorToastOpen, setErrorToastOpen] = React.useState(false);

    const handleLogisticExportParams = async () => {
        return await LogisticLogExportApiAxiosParamCreator().logisticLogExportGetLogisticLogInTunnelInInterval(
            formatISO(dateRange[0].startDate!, {representation: 'date'}),
            formatISO(dateRange[0].endDate!, {representation: 'date'}),
        );
    }

    return (
        <Stack>
            <DateRange
                ranges={dateRange}
                dragSelectionEnabled={true}
                onChange={(ranges) => setDateRange(Object.values(ranges))}
                locale={de}
            />
            <DialogActions>
                <Button onClick={props.handleCancelButton} variant='contained'
                        color="secondary">Abbrechen</Button>
                <Box sx={{margin: 1, position: 'relative'}}>
                    <Button
                        onClick={async () => {
                            try {
                                setIsFetching(true)
                                await handleDialogExportDownload(handleLogisticExportParams(), "Logistik" + EXCEL_FILE);
                                return setIsFetching(false);
                            } catch (e) {
                                setErrorToastOpen(true)
                                return setIsFetching(false)
                            }
                        }}
                        variant='contained'
                        disabled={isFetching}
                        color="primary">{<img src={excelImg}
                                              alt={"Microsoft Excel Logo"}/>}Download<FileDownloadOutlinedIcon/></Button>
                    {isFetching &&
                        <CircularProgress
                            size={24} sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                        }}/>}
                </Box>
            </DialogActions>
            {errorToastOpen && <Alert severity="error"
                                      action={
                                          <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="small"
                                              onClick={() => {
                                                  setErrorToastOpen(false);
                                              }}>
                                              <CloseIcon fontSize="inherit"/>
                                          </IconButton>
                                      }>
                Download fehlgeschlagen!
            </Alert>}
        </Stack>
    );
}

export default LogisticExportTab;