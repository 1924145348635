import {CURRENT_ENVIRONMENT, Environment} from "./environment";
import {DataGridFilterArgs} from "./utils/InterfaceProps";
import {FilterType} from "./utils/FilterTypes";
import {KoralmLocation} from "./generated";

const HTTPS_PREFIX = 'https://';
const KORALM_URL_SUFFIX = '.koralm.bii-gmbh.com';
export const API_URL = `${HTTPS_PREFIX}api${CURRENT_ENVIRONMENT === Environment.Production ? '' : '-' + (CURRENT_ENVIRONMENT === Environment.Development ? 'dev' : CURRENT_ENVIRONMENT)}${KORALM_URL_SUFFIX}`;

export const AUTH_URL = HTTPS_PREFIX + "auth" + KORALM_URL_SUFFIX;
export const CLIENT_ID = "webapp";

export const ID_FROM_MSP = "MS Project ID";

class ColumnHeader {
    GetIDValue(displayName: boolean = false) {
        return displayName ? "" : "ID"
    }

    GetContractorValue(displayName: boolean = false) {
        return displayName ? "Firma" : "ContractorId"
    }

    GetNameValue(displayName: boolean) {
        return displayName ? "Arbeitsname" : "Name"
    }

    GetStatusValue(displayName: boolean) {
        return displayName ? "Status" : "Status"
    }

    GetStartValue(displayName: boolean) {
        return displayName ? "Start" : "Start"
    }

    GetEndValue(displayName: boolean) {
        return displayName ? "Ende" : "End"
    }

    GetObjectDescriptionValue(displayName: boolean, filter: DataGridFilterArgs) {
        if (displayName) {
            return "Objekt"
        } else {
            if (filter === KoralmLocation.OpenLine) {
                return "LocationDescription.Location";
            } else {
                if (filter === FilterType.Tube) {
                    return "LocationDescription.ObjectDescription.Tube"
                } else {
                    return "LocationDescription.ObjectDescription.TunnelObject"
                }
            }
        }
    }

    GetAreaDescriptionValue(displayName: boolean) {
        return displayName ? "Bereich" : "LocationDescription.ObjectDescription.AreaDescription.ID"
    }

    GetButtonValue(displayName: boolean) {
        return displayName ? "" : "Funktionen"
    }

    GetWeekProgramValue(displayName: boolean) {
        return displayName ? "Wochenprogramm" : "PartOfWeekProgram"
    }

    GetIsMilestoneValue(displayName: boolean) {
        return displayName ? "Meilenstein" : "isMilestone"
    }

    GetOutlineLevelValue(displayName: boolean) {
        return displayName ? "Gliederungsebene" : "ThirdLevelOutline"
    }

    GetPersonCountValue(displayName: boolean) {
        return displayName ? "Personen" : "PersonCount"
    }

    GetEditorEmail(displayName: boolean) {
        return displayName ? "Bearbeiter" : "EditorEmail"
    }

    GetNotesValue(displayName: boolean) {
        return displayName ? "Notizen" : "Notes"
    }
}

export const ColumnHeaderService = new ColumnHeader();
export const NA_PLACEHOLDER = 'NA';
export const PDF_FILE = ".pdf"
export const EXCEL_FILE = ".xlsx"
export const PDF_FILE_NAME = "Wochenprogramm" + PDF_FILE
export const LEAN_BOARD_FILE_NAME = "Leanboard" + EXCEL_FILE
export const PERSON_COUNT_FILE_NAME = "Personenanzahl" + EXCEL_FILE
export const EMPTY_STRING_PLACEHOLDER = ""
export const ERROR_MESSAGE_SNACKBAR = "Diese Aktion konnte nicht ausgeführt werden"
export const SUCCESS_MESSAGE_SNACKBAR = "Ein Element wurde erfolgreich geändert."
export const NEW_WORK_REGISTRATION = "Neue Arbeitsanmeldung"
export const CREATE = " erstellen"
export const SAFE_BUTTON_TEXT = "Änderungen Speichern"
export const ContractorPlaceholder = "";
export const UNKNOWN_ROUTE = "Unbekannte Seite, leite weiter"
export const MAX_PERSON_COUNT = 146;
export const LOG_IN_EVENT_FLAG = "newlyLoggedIn";
export const NOTES_MAX_CHAR_COUNT = 500;
export const DEFAULT_DATA_GRID_PAGE_SIZE = 100;
export const MULTIPLE_VALUE_FILTER_SEPARATOR = ";";
export const EMPTY_STOP_NOTE = "Keine weiteren Informationen"
export const DEFAULT_IBN_CONTRACTOR = "ARGE GU2-TA"
export const OBB_UPCOMING_RESTRICTED_WEEKS = 3;
export const OBB_PAST_RESTRICTED_WEEKS = 0;
export const THIRD_LEVEL_OUTLINE_WIDTH = 225;