import {DataGrid, deDE} from "@mui/x-data-grid";
import {LinearProgress, Stack} from "@mui/material";
import {CustomDataGridToolbar} from "./CustomDataGridToolbar";
import React from "react";
import {GridValidRowModel} from "@mui/x-data-grid/models/gridRows";
import {DataGridProps} from "@mui/x-data-grid/models/props/DataGridProps";

type DefaultDataGridProps<R extends GridValidRowModel = any> = Pick<DataGridProps<R>, "columns" | "disableColumnFilter"
    | "onFilterModelChange" | "filterModel" | "onSortModelChange" | "sortModel" | "isCellEditable" | "rows" | "rowCount"
    | "processRowUpdate" | "getRowSpacing" | "loading" | "page" | "pageSize" | "onPageSizeChange" | "onPageChange"> &
    { mode: 'server' | 'client'};


function DefaultDataGrid(props: DefaultDataGridProps) {
    return (
        <Stack sx={{width: '100%', height: '100%'}}>
            <DataGrid
                sx={{
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                        "& .MuiDataGrid-row": {
                            backgroundColor: "rgba(236, 236, 236, 1)"
                        }
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "rgba(255,255,255,0.6)",
                        color: "rgba(0,0,0,1)",
                        fontSize: 20,
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: 'none',
                    },
                    "& .MuiDataGrid-root": {
                        border: 'none',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    // got the styling afterwards from here: https://github.com/mui/mui-x/issues/898#issuecomment-1598899893
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'break-spaces',
                        lineHeight: 1,
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
                        pl: 1,
                    },
                    border: 0,
                }}
                columns={props.columns}
                isCellEditable={props.isCellEditable}
                rows={props.rows}
                getRowId={(row) => row.ID}
                rowHeight={100}
                pagination={true}
                getRowHeight={() => 'auto'}
                hideFooterSelectedRowCount={true}
                getRowSpacing={props.getRowSpacing}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                disableColumnFilter={props.disableColumnFilter}
                loading={props.loading}
                paginationMode={props.mode}
                rowsPerPageOptions={[10, 25, 50, 75, 100]}
                rowCount={props.mode === 'server' ? props.rowCount : undefined}
                pageSize={props.pageSize}
                page={props.page}
                onPageSizeChange={props.onPageSizeChange}
                onPageChange={props.onPageChange}
                components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: () => CustomDataGridToolbar({page: props.page})
                }}
                componentsProps={{
                    toolbar: {
                        csvOptions: {disableToolbarButton: false},
                        printOptions: {disableToolbarButton: true},
                    },
                }}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                filterModel={props.filterModel}
                onFilterModelChange={props.onFilterModelChange}
                sortingMode={props.mode}
                filterMode={props.mode}
                sortModel={props.sortModel}
                onSortModelChange={props.onSortModelChange}
                processRowUpdate={props.processRowUpdate}
                experimentalFeatures={{newEditingApi: true}}
                onProcessRowUpdateError={(error) => {
                    console.error(error);
                }}
            />
        </Stack>
    );
}

export default DefaultDataGrid;