import React, {useEffect, useState} from 'react';
import './App.css';
import {colors, createTheme, ThemeProvider} from "@mui/material";
import WorkRegistrationEditor from "./components/WorkRegistrationPage/WorkRegistrationEditor";
import ListViewEditor from "./components/DataGridPage/ListViewEditor";
import WeekOverviewEditor from "./components/WeekOverviewPage/WeekOverviewEditor";
import {filterArgumentTypesWithCombinedFilterDescription, WebAppPage, WorkRegistration} from "./utils/Types";
import {
    LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp,
    LatitudinalDirectedTunnelWorkRegistrationApiFp,
    OutdoorUniqueConstructionWorkRegistrationApiFp,
    OutdoorWorkRegistrationApiFp,
    TunnelWorkRegistrationWithNumericLocationIDApiFp,
    ZoneBasedTunnelTubeWorkRegistrationApiFp
} from "./generated";
import {createPopUpArgs} from "./utils/FilterHelpers";
import {formatISO} from "date-fns";
import UnknownRoute from "./utils/UnknownRoute";

const theme = createTheme({
    palette: {
        secondary: {
            main: colors.grey[300],
            dark: colors.grey[400],
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
})

function App() {
    const [selectedWebAppPage, setSelectedWebAppPage] = useState(WebAppPage.ListView)
    const [selectedWorkObject, setSelectedWorkObject] = useState<WorkRegistration>()

    const [newNotification, setNewNotification] = useState(false)
    const startDate = new Date()
    const endDate = new Date()
    endDate.setDate(startDate.getDate() + 4 * 7)

    useEffect(() => {
        const fetchApiData = async () => {
            try {
                let filterArgs: filterArgumentTypesWithCombinedFilterDescription = createPopUpArgs(formatISO(startDate), formatISO(endDate));
                let outdoorResponse = await OutdoorWorkRegistrationApiFp()
                    .outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                let outdoorUniqueResponse = await OutdoorUniqueConstructionWorkRegistrationApiFp()
                    .outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                let latitudinalOutdoorResponse = await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp()
                    .latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                let crossCutResponse = await TunnelWorkRegistrationWithNumericLocationIDApiFp()
                    .tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                let zoneResponse = await ZoneBasedTunnelTubeWorkRegistrationApiFp()
                    .zoneBasedTunnelTubeWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                let latitudinalResponse = await LatitudinalDirectedTunnelWorkRegistrationApiFp()
                    .latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                if (outdoorResponse.ItemCount > 0 || outdoorUniqueResponse.ItemCount > 0 || latitudinalOutdoorResponse.ItemCount > 0 || crossCutResponse.ItemCount > 0 || zoneResponse.ItemCount > 0 || latitudinalResponse.ItemCount > 0) {
                    setNewNotification(true);
                }
            } catch (error) {
                console.error('Error fetching APi data: ', error)
            }
        }
        void fetchApiData();
    }, [])

    const handleChangedWebAppPage = (page: WebAppPage) => {
        if (page !== WebAppPage.WorkRegistration) setSelectedWorkObject(undefined)
        setSelectedWebAppPage(page);
    }

    const handleChangedWorkObject = (workObject: WorkRegistration) => {
        setSelectedWorkObject(workObject)
        setSelectedWebAppPage(WebAppPage.WorkRegistration)
    }

    const pageRender = () => {
        switch (selectedWebAppPage) {
            case WebAppPage.ListView:
                return <ListViewEditor
                    changedSelectedWorkRegistration={handleChangedWorkObject}
                    selectedPage={selectedWebAppPage}
                    changedSelectedPage={handleChangedWebAppPage}
                    newNotification={newNotification}
                />
            case WebAppPage.WeekView:
                return <WeekOverviewEditor
                    changedSelectedWorkRegistration={handleChangedWorkObject}
                    selectedPage={selectedWebAppPage}
                    changedSelectedPage={handleChangedWebAppPage}
                    newNotification={newNotification}
                />
            case WebAppPage.WorkRegistration:
                return <WorkRegistrationEditor
                    workObject={selectedWorkObject}
                    changedSelectedPage={handleChangedWebAppPage}
                />
            default:
                return <UnknownRoute
                    changedSelectedPage={handleChangedWebAppPage}
                />
        }
    }

    return (
        <ThemeProvider theme={theme}>
            {pageRender()}
        </ThemeProvider>
    );
}

export default App;
