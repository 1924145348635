/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CombinedFilterDescription } from '../model';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
// @ts-ignore
import { FilterOperation } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { PageOfTunnelWorkRegistrationWithNumericLocationID } from '../model';
// @ts-ignore
import { RangeOfDateTime } from '../model';
// @ts-ignore
import { TunnelWorkRegistrationWithNumericLocationID } from '../model';
/**
 * TunnelWorkRegistrationWithNumericLocationIDApi - axios parameter creator
 * @export
 */
export const TunnelWorkRegistrationWithNumericLocationIDApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid', 'uuid', uuid)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/DeleteByUuid/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetBy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetBy', 'id', id)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetBy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetByUuIdLastChanged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetByUuIdLastChangedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetInInterval: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetInInterval', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetInInterval', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetInInterval', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetInInterval', 'sortOrder', sortOrder)
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetInInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetInInterval', 'endIncluding', endIncluding)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetInInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)))
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetLatestMatchingByUuId/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetLatestMatchingByUuIdWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetPaged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPaged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPaged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPaged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPaged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetPaged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetPagedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/GetTimeSeriesBoundaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TunnelWorkRegistrationWithNumericLocationID} tunnelWorkRegistrationWithNumericLocationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDSave: async (tunnelWorkRegistrationWithNumericLocationID: TunnelWorkRegistrationWithNumericLocationID, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tunnelWorkRegistrationWithNumericLocationID' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDSave', 'tunnelWorkRegistrationWithNumericLocationID', tunnelWorkRegistrationWithNumericLocationID)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tunnelWorkRegistrationWithNumericLocationID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<TunnelWorkRegistrationWithNumericLocationID>} tunnelWorkRegistrationWithNumericLocationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDSaveMany: async (tunnelWorkRegistrationWithNumericLocationID: Array<TunnelWorkRegistrationWithNumericLocationID>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tunnelWorkRegistrationWithNumericLocationID' is not null or undefined
            assertParamExists('tunnelWorkRegistrationWithNumericLocationIDSaveMany', 'tunnelWorkRegistrationWithNumericLocationID', tunnelWorkRegistrationWithNumericLocationID)
            const localVarPath = `/WorkRegistration/Tunnel/TunnelWorkRegistrationWithNumericLocationID/SaveMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tunnelWorkRegistrationWithNumericLocationID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TunnelWorkRegistrationWithNumericLocationIDApi - functional programming interface
 * @export
 */
export const TunnelWorkRegistrationWithNumericLocationIDApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TunnelWorkRegistrationWithNumericLocationIDApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlanerSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner( options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlanerAndGetData(options?: AxiosRequestConfig): Promise<number> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlanerSendRequest( options)).data;
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDDeleteByUuidSendRequest(uuid: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDDeleteByUuidAndGetData(uuid: string, options?: AxiosRequestConfig): Promise<number> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDDeleteByUuidSendRequest(uuid,  options)).data;
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetBy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetBy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetBySendRequest(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetBy(id,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetByAndGetData(id: string, options?: AxiosRequestConfig): Promise<TunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetBySendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetInIntervalSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetInIntervalAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetInIntervalSendRequest(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetPagedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetPagedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetPagedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfTunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangeOfDateTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundariesSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<RangeOfDateTime>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries( options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundariesAndGetData(options?: AxiosRequestConfig): Promise<RangeOfDateTime> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundariesSendRequest( options)).data;
        },
        /**
         * 
         * @param {TunnelWorkRegistrationWithNumericLocationID} tunnelWorkRegistrationWithNumericLocationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID: TunnelWorkRegistrationWithNumericLocationID, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TunnelWorkRegistrationWithNumericLocationID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDSaveSendRequest(tunnelWorkRegistrationWithNumericLocationID: TunnelWorkRegistrationWithNumericLocationID, options?: AxiosRequestConfig): Promise<AxiosResponse<TunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDSaveAndGetData(tunnelWorkRegistrationWithNumericLocationID: TunnelWorkRegistrationWithNumericLocationID, options?: AxiosRequestConfig): Promise<TunnelWorkRegistrationWithNumericLocationID> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDSaveSendRequest(tunnelWorkRegistrationWithNumericLocationID,  options)).data;
        },
        /**
         * 
         * @param {Array<TunnelWorkRegistrationWithNumericLocationID>} tunnelWorkRegistrationWithNumericLocationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID: Array<TunnelWorkRegistrationWithNumericLocationID>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TunnelWorkRegistrationWithNumericLocationID>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelWorkRegistrationWithNumericLocationIDSaveManySendRequest(tunnelWorkRegistrationWithNumericLocationID: Array<TunnelWorkRegistrationWithNumericLocationID>, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<TunnelWorkRegistrationWithNumericLocationID>>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID,  options))();
        },
        async tunnelWorkRegistrationWithNumericLocationIDSaveManyAndGetData(tunnelWorkRegistrationWithNumericLocationID: Array<TunnelWorkRegistrationWithNumericLocationID>, options?: AxiosRequestConfig): Promise<Array<TunnelWorkRegistrationWithNumericLocationID>> {
            return (await this.tunnelWorkRegistrationWithNumericLocationIDSaveManySendRequest(tunnelWorkRegistrationWithNumericLocationID,  options)).data;
        },
    }
};

/**
 * TunnelWorkRegistrationWithNumericLocationIDApi - factory interface
 * @export
 */
export const TunnelWorkRegistrationWithNumericLocationIDApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TunnelWorkRegistrationWithNumericLocationIDApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner(options?: any): AxiosPromise<number> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid: string, options?: any): AxiosPromise<number> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetBy(id: string, options?: any): AxiosPromise<TunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries(options?: any): AxiosPromise<RangeOfDateTime> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TunnelWorkRegistrationWithNumericLocationID} tunnelWorkRegistrationWithNumericLocationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID: TunnelWorkRegistrationWithNumericLocationID, options?: any): AxiosPromise<TunnelWorkRegistrationWithNumericLocationID> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<TunnelWorkRegistrationWithNumericLocationID>} tunnelWorkRegistrationWithNumericLocationID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID: Array<TunnelWorkRegistrationWithNumericLocationID>, options?: any): AxiosPromise<Array<TunnelWorkRegistrationWithNumericLocationID>> {
            return localVarFp.tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TunnelWorkRegistrationWithNumericLocationIDApi - interface
 * @export
 * @interface TunnelWorkRegistrationWithNumericLocationIDApi
 */
export interface TunnelWorkRegistrationWithNumericLocationIDApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetBy(id: string, options?: AxiosRequestConfig): AxiosPromise<TunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfTunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries(options?: AxiosRequestConfig): AxiosPromise<RangeOfDateTime>;

    /**
     * 
     * @param {TunnelWorkRegistrationWithNumericLocationID} tunnelWorkRegistrationWithNumericLocationID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID: TunnelWorkRegistrationWithNumericLocationID, options?: AxiosRequestConfig): AxiosPromise<TunnelWorkRegistrationWithNumericLocationID>;

    /**
     * 
     * @param {Array<TunnelWorkRegistrationWithNumericLocationID>} tunnelWorkRegistrationWithNumericLocationID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApiInterface
     */
    tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID: Array<TunnelWorkRegistrationWithNumericLocationID>, options?: AxiosRequestConfig): AxiosPromise<Array<TunnelWorkRegistrationWithNumericLocationID>>;

}

/**
 * TunnelWorkRegistrationWithNumericLocationIDApi - object-oriented interface
 * @export
 * @class TunnelWorkRegistrationWithNumericLocationIDApi
 * @extends {BaseAPI}
 */
export class TunnelWorkRegistrationWithNumericLocationIDApi extends BaseAPI implements TunnelWorkRegistrationWithNumericLocationIDApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDDeleteAllTasksCreatedByPlaner(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid: string, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDDeleteByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetBy(id: string, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries(options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDGetTimeSeriesBoundaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TunnelWorkRegistrationWithNumericLocationID} tunnelWorkRegistrationWithNumericLocationID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID: TunnelWorkRegistrationWithNumericLocationID, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDSave(tunnelWorkRegistrationWithNumericLocationID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<TunnelWorkRegistrationWithNumericLocationID>} tunnelWorkRegistrationWithNumericLocationID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelWorkRegistrationWithNumericLocationIDApi
     */
    public tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID: Array<TunnelWorkRegistrationWithNumericLocationID>, options?: AxiosRequestConfig) {
        return TunnelWorkRegistrationWithNumericLocationIDApiFp(this.configuration).tunnelWorkRegistrationWithNumericLocationIDSaveMany(tunnelWorkRegistrationWithNumericLocationID, options).then((request) => request(this.axios, this.basePath));
    }
}
