/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LatitudinalDirection } from '../model';
// @ts-ignore
import { RailNumber } from '../model';
// @ts-ignore
import { RailSectionDescription } from '../model';
/**
 * RailDescriptionsApi - axios parameter creator
 * @export
 */
export const RailDescriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railDescriptionsGetRailSectionDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RailDescriptions/GetRailSectionDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railDescriptionsGetTunnelTubeRailNumberMapping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RailDescriptions/GetTunnelTubeRailNumberMapping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RailDescriptions/GetUniqueOpenLineConstructionToLatitudinalDirection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RailDescriptionsApi - functional programming interface
 * @export
 */
export const RailDescriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RailDescriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railDescriptionsGetRailSectionDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RailSectionDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railDescriptionsGetRailSectionDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async railDescriptionsGetRailSectionDescriptionsSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<RailSectionDescription>>> {
            return (await this.railDescriptionsGetRailSectionDescriptions( options))();
        },
        async railDescriptionsGetRailSectionDescriptionsAndGetData(options?: AxiosRequestConfig): Promise<Array<RailSectionDescription>> {
            return (await this.railDescriptionsGetRailSectionDescriptionsSendRequest( options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railDescriptionsGetTunnelTubeRailNumberMapping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: RailNumber; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railDescriptionsGetTunnelTubeRailNumberMapping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async railDescriptionsGetTunnelTubeRailNumberMappingSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<{ [key: string]: RailNumber; }>> {
            return (await this.railDescriptionsGetTunnelTubeRailNumberMapping( options))();
        },
        async railDescriptionsGetTunnelTubeRailNumberMappingAndGetData(options?: AxiosRequestConfig): Promise<{ [key: string]: RailNumber; }> {
            return (await this.railDescriptionsGetTunnelTubeRailNumberMappingSendRequest( options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: LatitudinalDirection; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirectionSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<{ [key: string]: LatitudinalDirection; }>> {
            return (await this.railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection( options))();
        },
        async railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirectionAndGetData(options?: AxiosRequestConfig): Promise<{ [key: string]: LatitudinalDirection; }> {
            return (await this.railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirectionSendRequest( options)).data;
        },
    }
};

/**
 * RailDescriptionsApi - factory interface
 * @export
 */
export const RailDescriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RailDescriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railDescriptionsGetRailSectionDescriptions(options?: any): AxiosPromise<Array<RailSectionDescription>> {
            return localVarFp.railDescriptionsGetRailSectionDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railDescriptionsGetTunnelTubeRailNumberMapping(options?: any): AxiosPromise<{ [key: string]: RailNumber; }> {
            return localVarFp.railDescriptionsGetTunnelTubeRailNumberMapping(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection(options?: any): AxiosPromise<{ [key: string]: LatitudinalDirection; }> {
            return localVarFp.railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RailDescriptionsApi - interface
 * @export
 * @interface RailDescriptionsApi
 */
export interface RailDescriptionsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailDescriptionsApiInterface
     */
    railDescriptionsGetRailSectionDescriptions(options?: AxiosRequestConfig): AxiosPromise<Array<RailSectionDescription>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailDescriptionsApiInterface
     */
    railDescriptionsGetTunnelTubeRailNumberMapping(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: RailNumber; }>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailDescriptionsApiInterface
     */
    railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: LatitudinalDirection; }>;

}

/**
 * RailDescriptionsApi - object-oriented interface
 * @export
 * @class RailDescriptionsApi
 * @extends {BaseAPI}
 */
export class RailDescriptionsApi extends BaseAPI implements RailDescriptionsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailDescriptionsApi
     */
    public railDescriptionsGetRailSectionDescriptions(options?: AxiosRequestConfig) {
        return RailDescriptionsApiFp(this.configuration).railDescriptionsGetRailSectionDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailDescriptionsApi
     */
    public railDescriptionsGetTunnelTubeRailNumberMapping(options?: AxiosRequestConfig) {
        return RailDescriptionsApiFp(this.configuration).railDescriptionsGetTunnelTubeRailNumberMapping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailDescriptionsApi
     */
    public railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection(options?: AxiosRequestConfig) {
        return RailDescriptionsApiFp(this.configuration).railDescriptionsGetUniqueOpenLineConstructionToLatitudinalDirection(options).then((request) => request(this.axios, this.basePath));
    }
}
