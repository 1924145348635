/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CombinedFilterDescription } from '../model';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
// @ts-ignore
import { FilterOperation } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { OutdoorWorkRegistration } from '../model';
// @ts-ignore
import { PageOfOutdoorWorkRegistration } from '../model';
// @ts-ignore
import { RangeOfDateTime } from '../model';
/**
 * OutdoorWorkRegistrationApi - axios parameter creator
 * @export
 */
export const OutdoorWorkRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationDeleteByUuid: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('outdoorWorkRegistrationDeleteByUuid', 'uuid', uuid)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/DeleteByUuid/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetBy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetBy', 'id', id)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetBy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetByUuIdLastChanged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChanged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChanged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChanged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChanged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetByUuIdLastChanged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetByUuIdLastChangedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetInInterval: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetInInterval', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetInInterval', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetInInterval', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetInInterval', 'sortOrder', sortOrder)
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetInInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetInInterval', 'endIncluding', endIncluding)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetInInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)))
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetLatestMatchingByUuId: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuId', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuId', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuId', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuId', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetLatestMatchingByUuId/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetLatestMatchingByUuIdWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetPaged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPaged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPaged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPaged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPaged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetPaged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetPagedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPagedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPagedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPagedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPagedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('outdoorWorkRegistrationGetPagedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetPagedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetTimeSeriesBoundaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/GetTimeSeriesBoundaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OutdoorWorkRegistration} outdoorWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationSave: async (outdoorWorkRegistration: OutdoorWorkRegistration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'outdoorWorkRegistration' is not null or undefined
            assertParamExists('outdoorWorkRegistrationSave', 'outdoorWorkRegistration', outdoorWorkRegistration)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(outdoorWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<OutdoorWorkRegistration>} outdoorWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationSaveMany: async (outdoorWorkRegistration: Array<OutdoorWorkRegistration>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'outdoorWorkRegistration' is not null or undefined
            assertParamExists('outdoorWorkRegistrationSaveMany', 'outdoorWorkRegistration', outdoorWorkRegistration)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorWorkRegistration/SaveMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(outdoorWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutdoorWorkRegistrationApi - functional programming interface
 * @export
 */
export const OutdoorWorkRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutdoorWorkRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner( options))();
        },
        async outdoorWorkRegistrationDeleteAllTasksCreatedByPlanerAndGetData(options?: AxiosRequestConfig): Promise<number> {
            return (await this.outdoorWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest( options)).data;
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationDeleteByUuid(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationDeleteByUuidSendRequest(uuid: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.outdoorWorkRegistrationDeleteByUuid(uuid,  options))();
        },
        async outdoorWorkRegistrationDeleteByUuidAndGetData(uuid: string, options?: AxiosRequestConfig): Promise<number> {
            return (await this.outdoorWorkRegistrationDeleteByUuidSendRequest(uuid,  options)).data;
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetBy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetBySendRequest(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<OutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetBy(id,  options))();
        },
        async outdoorWorkRegistrationGetByAndGetData(id: string, options?: AxiosRequestConfig): Promise<OutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetBySendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfOutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetByUuIdLastChangedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfOutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorWorkRegistrationGetByUuIdLastChangedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfOutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetByUuIdLastChangedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfOutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfOutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfOutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfOutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetInIntervalSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfOutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorWorkRegistrationGetInIntervalAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfOutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetInIntervalSendRequest(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfOutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetLatestMatchingByUuIdSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfOutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorWorkRegistrationGetLatestMatchingByUuIdAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfOutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetLatestMatchingByUuIdSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfOutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfOutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfOutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfOutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetPagedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfOutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorWorkRegistrationGetPagedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfOutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetPagedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfOutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetPagedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfOutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async outdoorWorkRegistrationGetPagedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfOutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationGetPagedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangeOfDateTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationGetTimeSeriesBoundaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationGetTimeSeriesBoundariesSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<RangeOfDateTime>> {
            return (await this.outdoorWorkRegistrationGetTimeSeriesBoundaries( options))();
        },
        async outdoorWorkRegistrationGetTimeSeriesBoundariesAndGetData(options?: AxiosRequestConfig): Promise<RangeOfDateTime> {
            return (await this.outdoorWorkRegistrationGetTimeSeriesBoundariesSendRequest( options)).data;
        },
        /**
         * 
         * @param {OutdoorWorkRegistration} outdoorWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationSave(outdoorWorkRegistration: OutdoorWorkRegistration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutdoorWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationSave(outdoorWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationSaveSendRequest(outdoorWorkRegistration: OutdoorWorkRegistration, options?: AxiosRequestConfig): Promise<AxiosResponse<OutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationSave(outdoorWorkRegistration,  options))();
        },
        async outdoorWorkRegistrationSaveAndGetData(outdoorWorkRegistration: OutdoorWorkRegistration, options?: AxiosRequestConfig): Promise<OutdoorWorkRegistration> {
            return (await this.outdoorWorkRegistrationSaveSendRequest(outdoorWorkRegistration,  options)).data;
        },
        /**
         * 
         * @param {Array<OutdoorWorkRegistration>} outdoorWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorWorkRegistrationSaveMany(outdoorWorkRegistration: Array<OutdoorWorkRegistration>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutdoorWorkRegistration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorWorkRegistrationSaveMany(outdoorWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorWorkRegistrationSaveManySendRequest(outdoorWorkRegistration: Array<OutdoorWorkRegistration>, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<OutdoorWorkRegistration>>> {
            return (await this.outdoorWorkRegistrationSaveMany(outdoorWorkRegistration,  options))();
        },
        async outdoorWorkRegistrationSaveManyAndGetData(outdoorWorkRegistration: Array<OutdoorWorkRegistration>, options?: AxiosRequestConfig): Promise<Array<OutdoorWorkRegistration>> {
            return (await this.outdoorWorkRegistrationSaveManySendRequest(outdoorWorkRegistration,  options)).data;
        },
    }
};

/**
 * OutdoorWorkRegistrationApi - factory interface
 * @export
 */
export const OutdoorWorkRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutdoorWorkRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: any): AxiosPromise<number> {
            return localVarFp.outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationDeleteByUuid(uuid: string, options?: any): AxiosPromise<number> {
            return localVarFp.outdoorWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetBy(id: string, options?: any): AxiosPromise<OutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfOutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfOutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfOutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfOutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfOutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfOutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfOutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationGetTimeSeriesBoundaries(options?: any): AxiosPromise<RangeOfDateTime> {
            return localVarFp.outdoorWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OutdoorWorkRegistration} outdoorWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationSave(outdoorWorkRegistration: OutdoorWorkRegistration, options?: any): AxiosPromise<OutdoorWorkRegistration> {
            return localVarFp.outdoorWorkRegistrationSave(outdoorWorkRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<OutdoorWorkRegistration>} outdoorWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorWorkRegistrationSaveMany(outdoorWorkRegistration: Array<OutdoorWorkRegistration>, options?: any): AxiosPromise<Array<OutdoorWorkRegistration>> {
            return localVarFp.outdoorWorkRegistrationSaveMany(outdoorWorkRegistration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutdoorWorkRegistrationApi - interface
 * @export
 * @interface OutdoorWorkRegistrationApi
 */
export interface OutdoorWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): AxiosPromise<OutdoorWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfOutdoorWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfOutdoorWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfOutdoorWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfOutdoorWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfOutdoorWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfOutdoorWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfOutdoorWorkRegistration>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): AxiosPromise<RangeOfDateTime>;

    /**
     * 
     * @param {OutdoorWorkRegistration} outdoorWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationSave(outdoorWorkRegistration: OutdoorWorkRegistration, options?: AxiosRequestConfig): AxiosPromise<OutdoorWorkRegistration>;

    /**
     * 
     * @param {Array<OutdoorWorkRegistration>} outdoorWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApiInterface
     */
    outdoorWorkRegistrationSaveMany(outdoorWorkRegistration: Array<OutdoorWorkRegistration>, options?: AxiosRequestConfig): AxiosPromise<Array<OutdoorWorkRegistration>>;

}

/**
 * OutdoorWorkRegistrationApi - object-oriented interface
 * @export
 * @class OutdoorWorkRegistrationApi
 * @extends {BaseAPI}
 */
export class OutdoorWorkRegistrationApi extends BaseAPI implements OutdoorWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OutdoorWorkRegistration} outdoorWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationSave(outdoorWorkRegistration: OutdoorWorkRegistration, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationSave(outdoorWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<OutdoorWorkRegistration>} outdoorWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorWorkRegistrationApi
     */
    public outdoorWorkRegistrationSaveMany(outdoorWorkRegistration: Array<OutdoorWorkRegistration>, options?: AxiosRequestConfig) {
        return OutdoorWorkRegistrationApiFp(this.configuration).outdoorWorkRegistrationSaveMany(outdoorWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }
}
