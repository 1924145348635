/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CombinedFilterDescription } from '../model';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
// @ts-ignore
import { FilterOperation } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { PageOfUniqueOutdoorConstructionWorkRegistration } from '../model';
// @ts-ignore
import { RangeOfDateTime } from '../model';
// @ts-ignore
import { UniqueOutdoorConstructionWorkRegistration } from '../model';
/**
 * OutdoorUniqueConstructionWorkRegistrationApi - axios parameter creator
 * @export
 */
export const OutdoorUniqueConstructionWorkRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationDeleteByUuid: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationDeleteByUuid', 'uuid', uuid)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/DeleteByUuid/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetBy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetBy', 'id', id)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetBy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetByUuIdLastChanged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetByUuIdLastChangedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetInInterval: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetInInterval', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetInInterval', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetInInterval', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetInInterval', 'sortOrder', sortOrder)
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetInInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetInInterval', 'endIncluding', endIncluding)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetInInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)))
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetLatestMatchingByUuId/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetLatestMatchingByUuIdWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetPaged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPaged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPaged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPaged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPaged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetPaged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetPagedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/GetTimeSeriesBoundaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UniqueOutdoorConstructionWorkRegistration} uniqueOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationSave: async (uniqueOutdoorConstructionWorkRegistration: UniqueOutdoorConstructionWorkRegistration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueOutdoorConstructionWorkRegistration' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationSave', 'uniqueOutdoorConstructionWorkRegistration', uniqueOutdoorConstructionWorkRegistration)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uniqueOutdoorConstructionWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UniqueOutdoorConstructionWorkRegistration>} uniqueOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationSaveMany: async (uniqueOutdoorConstructionWorkRegistration: Array<UniqueOutdoorConstructionWorkRegistration>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueOutdoorConstructionWorkRegistration' is not null or undefined
            assertParamExists('outdoorUniqueConstructionWorkRegistrationSaveMany', 'uniqueOutdoorConstructionWorkRegistration', uniqueOutdoorConstructionWorkRegistration)
            const localVarPath = `/WorkRegistration/Outdoor/OutdoorUniqueConstructionWorkRegistration/SaveMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uniqueOutdoorConstructionWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutdoorUniqueConstructionWorkRegistrationApi - functional programming interface
 * @export
 */
export const OutdoorUniqueConstructionWorkRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutdoorUniqueConstructionWorkRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner( options))();
        },
        async outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlanerAndGetData(options?: AxiosRequestConfig): Promise<number> {
            return (await this.outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest( options)).data;
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationDeleteByUuidSendRequest(uuid: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationDeleteByUuidAndGetData(uuid: string, options?: AxiosRequestConfig): Promise<number> {
            return (await this.outdoorUniqueConstructionWorkRegistrationDeleteByUuidSendRequest(uuid,  options)).data;
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetBy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetBySendRequest(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetBy(id,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetByAndGetData(id: string, options?: AxiosRequestConfig): Promise<UniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetBySendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetInIntervalSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetInIntervalAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetInIntervalSendRequest(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetPagedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetPagedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetPagedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfUniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangeOfDateTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundariesSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<RangeOfDateTime>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries( options))();
        },
        async outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundariesAndGetData(options?: AxiosRequestConfig): Promise<RangeOfDateTime> {
            return (await this.outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundariesSendRequest( options)).data;
        },
        /**
         * 
         * @param {UniqueOutdoorConstructionWorkRegistration} uniqueOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration: UniqueOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UniqueOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationSaveSendRequest(uniqueOutdoorConstructionWorkRegistration: UniqueOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): Promise<AxiosResponse<UniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationSaveAndGetData(uniqueOutdoorConstructionWorkRegistration: UniqueOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): Promise<UniqueOutdoorConstructionWorkRegistration> {
            return (await this.outdoorUniqueConstructionWorkRegistrationSaveSendRequest(uniqueOutdoorConstructionWorkRegistration,  options)).data;
        },
        /**
         * 
         * @param {Array<UniqueOutdoorConstructionWorkRegistration>} uniqueOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration: Array<UniqueOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniqueOutdoorConstructionWorkRegistration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async outdoorUniqueConstructionWorkRegistrationSaveManySendRequest(uniqueOutdoorConstructionWorkRegistration: Array<UniqueOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<UniqueOutdoorConstructionWorkRegistration>>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration,  options))();
        },
        async outdoorUniqueConstructionWorkRegistrationSaveManyAndGetData(uniqueOutdoorConstructionWorkRegistration: Array<UniqueOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): Promise<Array<UniqueOutdoorConstructionWorkRegistration>> {
            return (await this.outdoorUniqueConstructionWorkRegistrationSaveManySendRequest(uniqueOutdoorConstructionWorkRegistration,  options)).data;
        },
    }
};

/**
 * OutdoorUniqueConstructionWorkRegistrationApi - factory interface
 * @export
 */
export const OutdoorUniqueConstructionWorkRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutdoorUniqueConstructionWorkRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: any): AxiosPromise<number> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: any): AxiosPromise<number> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetBy(id: string, options?: any): AxiosPromise<UniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: any): AxiosPromise<RangeOfDateTime> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UniqueOutdoorConstructionWorkRegistration} uniqueOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration: UniqueOutdoorConstructionWorkRegistration, options?: any): AxiosPromise<UniqueOutdoorConstructionWorkRegistration> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UniqueOutdoorConstructionWorkRegistration>} uniqueOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration: Array<UniqueOutdoorConstructionWorkRegistration>, options?: any): AxiosPromise<Array<UniqueOutdoorConstructionWorkRegistration>> {
            return localVarFp.outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutdoorUniqueConstructionWorkRegistrationApi - interface
 * @export
 * @interface OutdoorUniqueConstructionWorkRegistrationApi
 */
export interface OutdoorUniqueConstructionWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): AxiosPromise<UniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfUniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): AxiosPromise<RangeOfDateTime>;

    /**
     * 
     * @param {UniqueOutdoorConstructionWorkRegistration} uniqueOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration: UniqueOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): AxiosPromise<UniqueOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {Array<UniqueOutdoorConstructionWorkRegistration>} uniqueOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApiInterface
     */
    outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration: Array<UniqueOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): AxiosPromise<Array<UniqueOutdoorConstructionWorkRegistration>>;

}

/**
 * OutdoorUniqueConstructionWorkRegistrationApi - object-oriented interface
 * @export
 * @class OutdoorUniqueConstructionWorkRegistrationApi
 * @extends {BaseAPI}
 */
export class OutdoorUniqueConstructionWorkRegistrationApi extends BaseAPI implements OutdoorUniqueConstructionWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UniqueOutdoorConstructionWorkRegistration} uniqueOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration: UniqueOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationSave(uniqueOutdoorConstructionWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UniqueOutdoorConstructionWorkRegistration>} uniqueOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutdoorUniqueConstructionWorkRegistrationApi
     */
    public outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration: Array<UniqueOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig) {
        return OutdoorUniqueConstructionWorkRegistrationApiFp(this.configuration).outdoorUniqueConstructionWorkRegistrationSaveMany(uniqueOutdoorConstructionWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }
}
