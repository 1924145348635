/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CombinedFilterDescription } from '../model';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
// @ts-ignore
import { FilterOperation } from '../model';
// @ts-ignore
import { LatitudinalDirectedOutdoorConstructionWorkRegistration } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration } from '../model';
// @ts-ignore
import { RangeOfDateTime } from '../model';
/**
 * LatitudinalDirectedOutdoorConstructionWorkRegistrationApi - axios parameter creator
 * @export
 */
export const LatitudinalDirectedOutdoorConstructionWorkRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid', 'uuid', uuid)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/DeleteByUuid/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy', 'id', id)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetBy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetByUuIdLastChanged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetByUuIdLastChangedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval', 'sortOrder', sortOrder)
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval', 'endIncluding', endIncluding)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetInInterval/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)))
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetLatestMatchingByUuId/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetLatestMatchingByUuIdWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged', 'sortOrder', sortOrder)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetPaged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetPagedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/GetTimeSeriesBoundaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LatitudinalDirectedOutdoorConstructionWorkRegistration} latitudinalDirectedOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationSave: async (latitudinalDirectedOutdoorConstructionWorkRegistration: LatitudinalDirectedOutdoorConstructionWorkRegistration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitudinalDirectedOutdoorConstructionWorkRegistration' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationSave', 'latitudinalDirectedOutdoorConstructionWorkRegistration', latitudinalDirectedOutdoorConstructionWorkRegistration)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(latitudinalDirectedOutdoorConstructionWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>} latitudinalDirectedOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany: async (latitudinalDirectedOutdoorConstructionWorkRegistration: Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitudinalDirectedOutdoorConstructionWorkRegistration' is not null or undefined
            assertParamExists('latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany', 'latitudinalDirectedOutdoorConstructionWorkRegistration', latitudinalDirectedOutdoorConstructionWorkRegistration)
            const localVarPath = `/WorkRegistration/Outdoor/LatitudinalDirectedOutdoorConstructionWorkRegistration/SaveMany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(latitudinalDirectedOutdoorConstructionWorkRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LatitudinalDirectedOutdoorConstructionWorkRegistrationApi - functional programming interface
 * @export
 */
export const LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LatitudinalDirectedOutdoorConstructionWorkRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner( options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlanerAndGetData(options?: AxiosRequestConfig): Promise<number> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlanerSendRequest( options)).data;
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuidSendRequest(uuid: string, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuidAndGetData(uuid: string, options?: AxiosRequestConfig): Promise<number> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuidSendRequest(uuid,  options)).data;
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetBySendRequest(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<LatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetByAndGetData(id: string, options?: AxiosRequestConfig): Promise<LatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetBySendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetInIntervalSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetInIntervalAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetInIntervalSendRequest(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RangeOfDateTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundariesSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<RangeOfDateTime>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries( options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundariesAndGetData(options?: AxiosRequestConfig): Promise<RangeOfDateTime> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundariesSendRequest( options)).data;
        },
        /**
         * 
         * @param {LatitudinalDirectedOutdoorConstructionWorkRegistration} latitudinalDirectedOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration: LatitudinalDirectedOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationSaveSendRequest(latitudinalDirectedOutdoorConstructionWorkRegistration: LatitudinalDirectedOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): Promise<AxiosResponse<LatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationSaveAndGetData(latitudinalDirectedOutdoorConstructionWorkRegistration: LatitudinalDirectedOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): Promise<LatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationSaveSendRequest(latitudinalDirectedOutdoorConstructionWorkRegistration,  options)).data;
        },
        /**
         * 
         * @param {Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>} latitudinalDirectedOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration: Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationSaveManySendRequest(latitudinalDirectedOutdoorConstructionWorkRegistration: Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration,  options))();
        },
        async latitudinalDirectedOutdoorConstructionWorkRegistrationSaveManyAndGetData(latitudinalDirectedOutdoorConstructionWorkRegistration: Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): Promise<Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return (await this.latitudinalDirectedOutdoorConstructionWorkRegistrationSaveManySendRequest(latitudinalDirectedOutdoorConstructionWorkRegistration,  options)).data;
        },
    }
};

/**
 * LatitudinalDirectedOutdoorConstructionWorkRegistrationApi - factory interface
 * @export
 */
export const LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: any): AxiosPromise<number> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: any): AxiosPromise<number> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id: string, options?: any): AxiosPromise<LatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: any): AxiosPromise<RangeOfDateTime> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LatitudinalDirectedOutdoorConstructionWorkRegistration} latitudinalDirectedOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration: LatitudinalDirectedOutdoorConstructionWorkRegistration, options?: any): AxiosPromise<LatitudinalDirectedOutdoorConstructionWorkRegistration> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>} latitudinalDirectedOutdoorConstructionWorkRegistration 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration: Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>, options?: any): AxiosPromise<Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>> {
            return localVarFp.latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LatitudinalDirectedOutdoorConstructionWorkRegistrationApi - interface
 * @export
 * @interface LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
 */
export interface LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig): AxiosPromise<LatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfLatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig): AxiosPromise<RangeOfDateTime>;

    /**
     * 
     * @param {LatitudinalDirectedOutdoorConstructionWorkRegistration} latitudinalDirectedOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration: LatitudinalDirectedOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig): AxiosPromise<LatitudinalDirectedOutdoorConstructionWorkRegistration>;

    /**
     * 
     * @param {Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>} latitudinalDirectedOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface
     */
    latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration: Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig): AxiosPromise<Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>>;

}

/**
 * LatitudinalDirectedOutdoorConstructionWorkRegistrationApi - object-oriented interface
 * @export
 * @class LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
 * @extends {BaseAPI}
 */
export class LatitudinalDirectedOutdoorConstructionWorkRegistrationApi extends BaseAPI implements LatitudinalDirectedOutdoorConstructionWorkRegistrationApiInterface {
    /**
     * 
     * @summary Deletes all WorkRegistration`3 with the ProcessStatus which are TaskCreatedByPlaner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteAllTasksCreatedByPlaner(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid: string, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationDeleteByUuid(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id: string, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetBy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChanged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size: number, index: number, sortPropertyName: string, sortOrder: Order, startIncluding: string, endIncluding: string, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetInInterval(size, index, sortPropertyName, sortOrder, startIncluding, endIncluding, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuId(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetLatestMatchingByUuIdWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries(options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationGetTimeSeriesBoundaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LatitudinalDirectedOutdoorConstructionWorkRegistration} latitudinalDirectedOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration: LatitudinalDirectedOutdoorConstructionWorkRegistration, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationSave(latitudinalDirectedOutdoorConstructionWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>} latitudinalDirectedOutdoorConstructionWorkRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LatitudinalDirectedOutdoorConstructionWorkRegistrationApi
     */
    public latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration: Array<LatitudinalDirectedOutdoorConstructionWorkRegistration>, options?: AxiosRequestConfig) {
        return LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp(this.configuration).latitudinalDirectedOutdoorConstructionWorkRegistrationSaveMany(latitudinalDirectedOutdoorConstructionWorkRegistration, options).then((request) => request(this.axios, this.basePath));
    }
}
