import WorkRegistrationDataGrid from "../WorkRegistrationDataGrid";
import React, {useCallback, useEffect, useState} from "react";
import {SelectChangeEvent, Stack} from "@mui/material";
import ListViewHeaderToolbar from "./ListViewHeaderToolbar";
import {
    CombinedFilterDescription,
    FilterOperation,
    LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp,
    LatitudinalDirectedTunnelWorkRegistrationApiFp,
    Order,
    OutdoorUniqueConstructionWorkRegistrationApiFp,
    OutdoorWorkRegistrationApiFp,
    TunnelWorkRegistrationWithNumericLocationIDApiFp,
    ZoneBasedTunnelTubeWorkRegistrationApiFp
} from "../../generated";
import HeaderBar from "../HeaderBar/HeaderBar";
import {FilterType, ParseFilterOperator} from "../../utils/FilterTypes";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {DataGridEditorArgs} from "../../utils/InterfaceProps";
import {ColumnHeaderService, MULTIPLE_VALUE_FILTER_SEPARATOR,} from "../../constants";
import {
    OutdoorWorkRegistrationsAndConstructions,
    pageStateWithHeaderToolbarProps
} from "../../utils/Types";
import {createEmptyCombinedFilterDescription, createListViewFilter} from "../../utils/FilterHelpers";
import {pageFilterProvider} from "../../services/pageFilterProvider";

type filterArgumentTypes = [number, number, string, Order, CombinedFilterDescription];

function ListViewEditor(props: DataGridEditorArgs) {
    const [tableData, setTableData] = useState<any>([])
    const [clickedIndex, setClickedIndex] = React.useState(-1)

    const [pageState, setPageState] = useState<pageStateWithHeaderToolbarProps>(pageFilterProvider.pageFilterWithHeaderToolbarState)

    const handleClickedRowCollapse = (row: any) => {
        if (clickedIndex === row.value) {
            setClickedIndex(-1)
        } else {
            setClickedIndex(row.value)
        }
    }

    const updatePageState = (property: keyof pageStateWithHeaderToolbarProps, value: any) => {
        setPageState(oldPageState => {
            const newPageState = {
                ...oldPageState,
                [property]: value,
            };
            pageFilterProvider.pageFilterWithHeaderToolbarState = newPageState;
            return newPageState;
        })
    }

    const handleSortingChange = async (sort: GridSortModel) => {
        let newSortingProperty = "Start";
        let newSortingValue = Order.Ascending;

        if (sort[0]) {
            newSortingProperty = sort[0].field;
            newSortingValue = sort[0].sort === "asc" ? Order.Ascending : Order.Descending;
        }

        updatePageState('sortingProperty', newSortingProperty);
        updatePageState('sortingValue', newSortingValue);
    };

    const handleGridFilterChange = async (filter: GridFilterModel) => {
        updatePageState('isLoading', true)

        if (filter.items && filter.items[0] && filter.items[0].value) {
            let field: string;
            if (pageState.selectFilter === FilterType.Tube) {
                switch (filter.items[0].columnField) {
                    case "LocationDescription.ObjectDescription.AreaDescription.ID":
                        field = "LocationDescription.ObjectDescription.AreaDescription.Start"
                        break;
                    default:
                        field = filter.items[0].columnField
                }
            } else if (pageState.selectFilter === FilterType.Outdoor) {
                switch (filter.items[0].columnField) {
                    case "LocationDescription.ObjectDescription.AreaDescription.ID":
                        field = "LocationDescription.ObjectDescription.AreaDescription.Start"
                        break;
                    case "LocationDescription.ObjectDescription.TunnelObject":
                        field = "LocationDescription.Location";
                        break;
                    default:
                        field = filter.items[0].columnField
                }
            } else {
                field = filter.items[0].columnField
            }

            updatePageState('filterProperty', field)
            updatePageState('filterValues', [filter.items[0].value])
            updatePageState('filterOperator', ParseFilterOperator(filter.items[0].operatorValue) as FilterOperation)
            updatePageState('isLoading', false)
        } else {
            updatePageState('filterProperty', undefined)
            updatePageState('filterValues', [])
            updatePageState('filterOperator', FilterOperation.Equals)
        }
    }

    const fetchDataGridData = useCallback(async () => {
        let filterArgs: filterArgumentTypes;
        if (pageState.filterProperty === "undefined" || !pageState.filterProperty) {
            filterArgs = [pageState.pageSize, pageState.page, pageState.sortingProperty, pageState.sortingValue, createEmptyCombinedFilterDescription(pageState.selectedContractors)];
        } else {
            let filterTypeForCreateListViewFilter: string | undefined;
            let filterValues: string[]
            if (pageState.filterValues && Array.isArray(pageState.filterValues[0])) {
                filterValues = pageState.filterValues[0]
                filterArgs = [
                    pageState.pageSize,
                    pageState.page,
                    pageState.sortingProperty,
                    pageState.sortingValue,
                    createListViewFilter(pageState.selectedContractors, pageState.filterOperator, pageState.filterProperty, filterValues, filterTypeForCreateListViewFilter, filterValues)
                ];
            } else {
                filterValues = pageState.filterValues!.map((filterValue: string) => {
                    let split = filterValue.split(MULTIPLE_VALUE_FILTER_SEPARATOR);
                    let prefix = split[0];
                    let value = split[1];
                    if (value === undefined) {
                        value = prefix;
                    } else {
                        filterTypeForCreateListViewFilter = prefix
                    }
                    return value;
                })
                filterArgs = [
                    pageState.pageSize,
                    pageState.page,
                    pageState.sortingProperty,
                    pageState.sortingValue,
                    createListViewFilter(pageState.selectedContractors, pageState.filterOperator, pageState.filterProperty, filterValues, filterTypeForCreateListViewFilter)
                ];
            }
        }

        updatePageState('isLoading', true)
        if (pageState.selectFilter === FilterType.Tube) {
            if (pageState.sortingProperty === ColumnHeaderService.GetAreaDescriptionValue(false)) {
                filterArgs[2] = "LocationDescription.ObjectDescription.AreaDescription.Start";
            }
            let response = await ZoneBasedTunnelTubeWorkRegistrationApiFp()
                .zoneBasedTunnelTubeWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            setTableData(response.Data)
            updatePageState('isLoading', false)
            updatePageState('total', response.ItemCount)
        } else if (pageState.selectFilter === FilterType.CrossCut) {
            let response = await TunnelWorkRegistrationWithNumericLocationIDApiFp()
                .tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
            setTableData(response.Data)
            updatePageState('isLoading', false)
            updatePageState('total', response.ItemCount)
        } else if (pageState.selectFilter === FilterType.Outdoor) {
            if (pageState.sortingProperty === ColumnHeaderService.GetAreaDescriptionValue(false)) {
                filterArgs[2] = "LocationDescription.ObjectDescription.AreaDescription.Start";
            }
            let responses: OutdoorWorkRegistrationsAndConstructions[] = []
            let outdoorResponse = await OutdoorWorkRegistrationApiFp()
                .outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            let uniqueOutdoorResponse = await OutdoorUniqueConstructionWorkRegistrationApiFp().outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            let latitudinalOutdoorResponse = await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp().latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            responses.push(...outdoorResponse.Data, ...uniqueOutdoorResponse.Data, ...latitudinalOutdoorResponse.Data)
            setTableData(responses)
            updatePageState('isLoading', false)
            updatePageState('total', outdoorResponse.ItemCount + latitudinalOutdoorResponse.ItemCount + latitudinalOutdoorResponse.ItemCount)
        } else if (pageState.selectFilter === FilterType.Portal) {
            let response = await LatitudinalDirectedTunnelWorkRegistrationApiFp()
                .latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            setTableData(response.Data)
            updatePageState('isLoading', false)
            updatePageState('total', response.ItemCount)
        } else {
            console.error("Can't find correct API")
            setTableData([])
            updatePageState('isLoading', false)
            updatePageState('total', 0)
        }
    }, [pageState.page, pageState.pageSize, pageState.sortingProperty, pageState.sortingValue, pageState.filterProperty, pageState.filterValues, pageState.selectedContractors, pageState.filterOperator, pageState.selectFilter])

    useEffect(() => {
        void fetchDataGridData()
    }, [fetchDataGridData, pageState.selectFilter])

    return (
        <Stack>
            <HeaderBar
                changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                isInDataTable={true}
                selectedPage={props.selectedPage}
                changedSelectedPage={props.changedSelectedPage}
                isNewWorkRegistration={false}
                newNotification={props.newNotification}
            />
            <Stack direction='column' display={"flex"} paddingLeft={3} paddingTop={1} paddingBottom={1} paddingRight={3}
            >
                <ListViewHeaderToolbar
                    Filter={pageState.selectFilter}
                    changedFilter={(event) => updatePageState('selectFilter', event.target.value as FilterType)}
                    contractors={pageState.selectedContractors}
                    changedContractors={(event: SelectChangeEvent<typeof pageState.selectedContractors>) => updatePageState('selectedContractors', typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
                    changedSelectedPage={props.changedSelectedPage}
                />
            </Stack>
            <Stack height="80vh" paddingLeft={3} paddingRight={3}>
                <WorkRegistrationDataGrid
                    changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                    changedGridRow={(value) => handleClickedRowCollapse(value)}
                    gridData={tableData}
                    clickedIndex={clickedIndex}
                    changedPage={(value) => updatePageState('page', value)}
                    changedPageSize={(value) => updatePageState('pageSize', value)}
                    isLoading={pageState.isLoading}
                    page={pageState.page}
                    pageSize={pageState.pageSize}
                    total={pageState.total}
                    filter={pageState.selectFilter}
                    changedFilter={(value) => handleGridFilterChange(value)}
                    changedSorting={(value) => handleSortingChange(value)}
                    mode="server"
                    onDataUpdate={() => fetchDataGridData()}
                    sortModel={[{
                        field: pageState.sortingProperty,
                        sort: pageState.sortingValue === Order.Ascending ? "asc" : "desc",
                    }]}
                />
            </Stack>
        </Stack>

    )
}

export default ListViewEditor;