/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CrossCutDescription } from '../model';
// @ts-ignore
import { EmergencyStopDescription } from '../model';
// @ts-ignore
import { EscapeGalleryDescription } from '../model';
// @ts-ignore
import { LatitudinalDirectedDescription } from '../model';
// @ts-ignore
import { ZoneDescription } from '../model';
/**
 * TunnelConstructionsDescriptionsApi - axios parameter creator
 * @export
 */
export const TunnelConstructionsDescriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetCrossCutDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TunnelConstructionsDescriptions/GetCrossCutDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetEmergencyStopDescription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TunnelConstructionsDescriptions/GetEmergencyStopDescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TunnelConstructionsDescriptions/GetEscapeGalleryDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetPortalDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TunnelConstructionsDescriptions/GetPortalDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetVentilationShaftDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TunnelConstructionsDescriptions/GetVentilationShaftDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetZoneDescriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TunnelConstructionsDescriptions/GetZoneDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TunnelConstructionsDescriptionsApi - functional programming interface
 * @export
 */
export const TunnelConstructionsDescriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TunnelConstructionsDescriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelConstructionsDescriptionsGetCrossCutDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CrossCutDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelConstructionsDescriptionsGetCrossCutDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelConstructionsDescriptionsGetCrossCutDescriptionsSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<CrossCutDescription>>> {
            return (await this.tunnelConstructionsDescriptionsGetCrossCutDescriptions( options))();
        },
        async tunnelConstructionsDescriptionsGetCrossCutDescriptionsAndGetData(options?: AxiosRequestConfig): Promise<Array<CrossCutDescription>> {
            return (await this.tunnelConstructionsDescriptionsGetCrossCutDescriptionsSendRequest( options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelConstructionsDescriptionsGetEmergencyStopDescription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmergencyStopDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelConstructionsDescriptionsGetEmergencyStopDescription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelConstructionsDescriptionsGetEmergencyStopDescriptionSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<EmergencyStopDescription>>> {
            return (await this.tunnelConstructionsDescriptionsGetEmergencyStopDescription( options))();
        },
        async tunnelConstructionsDescriptionsGetEmergencyStopDescriptionAndGetData(options?: AxiosRequestConfig): Promise<Array<EmergencyStopDescription>> {
            return (await this.tunnelConstructionsDescriptionsGetEmergencyStopDescriptionSendRequest( options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EscapeGalleryDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelConstructionsDescriptionsGetEscapeGalleryDescriptionsSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<EscapeGalleryDescription>>> {
            return (await this.tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions( options))();
        },
        async tunnelConstructionsDescriptionsGetEscapeGalleryDescriptionsAndGetData(options?: AxiosRequestConfig): Promise<Array<EscapeGalleryDescription>> {
            return (await this.tunnelConstructionsDescriptionsGetEscapeGalleryDescriptionsSendRequest( options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelConstructionsDescriptionsGetPortalDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LatitudinalDirectedDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelConstructionsDescriptionsGetPortalDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelConstructionsDescriptionsGetPortalDescriptionsSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<LatitudinalDirectedDescription>>> {
            return (await this.tunnelConstructionsDescriptionsGetPortalDescriptions( options))();
        },
        async tunnelConstructionsDescriptionsGetPortalDescriptionsAndGetData(options?: AxiosRequestConfig): Promise<Array<LatitudinalDirectedDescription>> {
            return (await this.tunnelConstructionsDescriptionsGetPortalDescriptionsSendRequest( options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelConstructionsDescriptionsGetVentilationShaftDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LatitudinalDirectedDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelConstructionsDescriptionsGetVentilationShaftDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelConstructionsDescriptionsGetVentilationShaftDescriptionsSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<LatitudinalDirectedDescription>>> {
            return (await this.tunnelConstructionsDescriptionsGetVentilationShaftDescriptions( options))();
        },
        async tunnelConstructionsDescriptionsGetVentilationShaftDescriptionsAndGetData(options?: AxiosRequestConfig): Promise<Array<LatitudinalDirectedDescription>> {
            return (await this.tunnelConstructionsDescriptionsGetVentilationShaftDescriptionsSendRequest( options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tunnelConstructionsDescriptionsGetZoneDescriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tunnelConstructionsDescriptionsGetZoneDescriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async tunnelConstructionsDescriptionsGetZoneDescriptionsSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ZoneDescription>>> {
            return (await this.tunnelConstructionsDescriptionsGetZoneDescriptions( options))();
        },
        async tunnelConstructionsDescriptionsGetZoneDescriptionsAndGetData(options?: AxiosRequestConfig): Promise<Array<ZoneDescription>> {
            return (await this.tunnelConstructionsDescriptionsGetZoneDescriptionsSendRequest( options)).data;
        },
    }
};

/**
 * TunnelConstructionsDescriptionsApi - factory interface
 * @export
 */
export const TunnelConstructionsDescriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TunnelConstructionsDescriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetCrossCutDescriptions(options?: any): AxiosPromise<Array<CrossCutDescription>> {
            return localVarFp.tunnelConstructionsDescriptionsGetCrossCutDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetEmergencyStopDescription(options?: any): AxiosPromise<Array<EmergencyStopDescription>> {
            return localVarFp.tunnelConstructionsDescriptionsGetEmergencyStopDescription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions(options?: any): AxiosPromise<Array<EscapeGalleryDescription>> {
            return localVarFp.tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetPortalDescriptions(options?: any): AxiosPromise<Array<LatitudinalDirectedDescription>> {
            return localVarFp.tunnelConstructionsDescriptionsGetPortalDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetVentilationShaftDescriptions(options?: any): AxiosPromise<Array<LatitudinalDirectedDescription>> {
            return localVarFp.tunnelConstructionsDescriptionsGetVentilationShaftDescriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tunnelConstructionsDescriptionsGetZoneDescriptions(options?: any): AxiosPromise<Array<ZoneDescription>> {
            return localVarFp.tunnelConstructionsDescriptionsGetZoneDescriptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TunnelConstructionsDescriptionsApi - interface
 * @export
 * @interface TunnelConstructionsDescriptionsApi
 */
export interface TunnelConstructionsDescriptionsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApiInterface
     */
    tunnelConstructionsDescriptionsGetCrossCutDescriptions(options?: AxiosRequestConfig): AxiosPromise<Array<CrossCutDescription>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApiInterface
     */
    tunnelConstructionsDescriptionsGetEmergencyStopDescription(options?: AxiosRequestConfig): AxiosPromise<Array<EmergencyStopDescription>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApiInterface
     */
    tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions(options?: AxiosRequestConfig): AxiosPromise<Array<EscapeGalleryDescription>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApiInterface
     */
    tunnelConstructionsDescriptionsGetPortalDescriptions(options?: AxiosRequestConfig): AxiosPromise<Array<LatitudinalDirectedDescription>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApiInterface
     */
    tunnelConstructionsDescriptionsGetVentilationShaftDescriptions(options?: AxiosRequestConfig): AxiosPromise<Array<LatitudinalDirectedDescription>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApiInterface
     */
    tunnelConstructionsDescriptionsGetZoneDescriptions(options?: AxiosRequestConfig): AxiosPromise<Array<ZoneDescription>>;

}

/**
 * TunnelConstructionsDescriptionsApi - object-oriented interface
 * @export
 * @class TunnelConstructionsDescriptionsApi
 * @extends {BaseAPI}
 */
export class TunnelConstructionsDescriptionsApi extends BaseAPI implements TunnelConstructionsDescriptionsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApi
     */
    public tunnelConstructionsDescriptionsGetCrossCutDescriptions(options?: AxiosRequestConfig) {
        return TunnelConstructionsDescriptionsApiFp(this.configuration).tunnelConstructionsDescriptionsGetCrossCutDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApi
     */
    public tunnelConstructionsDescriptionsGetEmergencyStopDescription(options?: AxiosRequestConfig) {
        return TunnelConstructionsDescriptionsApiFp(this.configuration).tunnelConstructionsDescriptionsGetEmergencyStopDescription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApi
     */
    public tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions(options?: AxiosRequestConfig) {
        return TunnelConstructionsDescriptionsApiFp(this.configuration).tunnelConstructionsDescriptionsGetEscapeGalleryDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApi
     */
    public tunnelConstructionsDescriptionsGetPortalDescriptions(options?: AxiosRequestConfig) {
        return TunnelConstructionsDescriptionsApiFp(this.configuration).tunnelConstructionsDescriptionsGetPortalDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApi
     */
    public tunnelConstructionsDescriptionsGetVentilationShaftDescriptions(options?: AxiosRequestConfig) {
        return TunnelConstructionsDescriptionsApiFp(this.configuration).tunnelConstructionsDescriptionsGetVentilationShaftDescriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TunnelConstructionsDescriptionsApi
     */
    public tunnelConstructionsDescriptionsGetZoneDescriptions(options?: AxiosRequestConfig) {
        return TunnelConstructionsDescriptionsApiFp(this.configuration).tunnelConstructionsDescriptionsGetZoneDescriptions(options).then((request) => request(this.axios, this.basePath));
    }
}
