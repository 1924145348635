/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * PdfApi - axios parameter creator
 * @export
 */
export const PdfApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {Array<string>} [contractors] 
         * @param {boolean} [filterForIbn] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfGeneratePdfForConstructionSupervisor: async (startIncluding: string, endIncluding: string, contractors?: Array<string>, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('pdfGeneratePdfForConstructionSupervisor', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('pdfGeneratePdfForConstructionSupervisor', 'endIncluding', endIncluding)
            const localVarPath = `/Pdf/GeneratePdfForConstructionSupervisor/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}`
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contractors) {
                localVarQueryParameter['contractors'] = contractors;
            }

            if (filterForIbn !== undefined) {
                localVarQueryParameter['filterForIbn'] = filterForIbn;
            }

            if (oebbOnlyContrcators !== undefined) {
                localVarQueryParameter['oebbOnlyContrcators'] = oebbOnlyContrcators;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {boolean} [filterForIbn] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfGeneratePdfWeekProgramForOeBB: async (startIncluding: string, endIncluding: string, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startIncluding' is not null or undefined
            assertParamExists('pdfGeneratePdfWeekProgramForOeBB', 'startIncluding', startIncluding)
            // verify required parameter 'endIncluding' is not null or undefined
            assertParamExists('pdfGeneratePdfWeekProgramForOeBB', 'endIncluding', endIncluding)
            const localVarPath = `/Pdf/GeneratePdfWeekProgramForOeBB/StartIncluding/{startIncluding}/EndIncluding/{endIncluding}`
                .replace(`{${"startIncluding"}}`, encodeURIComponent(String(startIncluding)))
                .replace(`{${"endIncluding"}}`, encodeURIComponent(String(endIncluding)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterForIbn !== undefined) {
                localVarQueryParameter['filterForIbn'] = filterForIbn;
            }

            if (oebbOnlyContrcators !== undefined) {
                localVarQueryParameter['oebbOnlyContrcators'] = oebbOnlyContrcators;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdfApi - functional programming interface
 * @export
 */
export const PdfApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdfApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {Array<string>} [contractors] 
         * @param {boolean} [filterForIbn] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfGeneratePdfForConstructionSupervisor(startIncluding: string, endIncluding: string, contractors?: Array<string>, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfGeneratePdfForConstructionSupervisor(startIncluding, endIncluding, contractors, filterForIbn, oebbOnlyContrcators, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async pdfGeneratePdfForConstructionSupervisorSendRequest(startIncluding: string, endIncluding: string, contractors?: Array<string>, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.pdfGeneratePdfForConstructionSupervisor(startIncluding, endIncluding, contractors, filterForIbn, oebbOnlyContrcators,  options))();
        },
        async pdfGeneratePdfForConstructionSupervisorAndGetData(startIncluding: string, endIncluding: string, contractors?: Array<string>, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<File> {
            return (await this.pdfGeneratePdfForConstructionSupervisorSendRequest(startIncluding, endIncluding, contractors, filterForIbn, oebbOnlyContrcators,  options)).data;
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {boolean} [filterForIbn] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfGeneratePdfWeekProgramForOeBB(startIncluding: string, endIncluding: string, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfGeneratePdfWeekProgramForOeBB(startIncluding, endIncluding, filterForIbn, oebbOnlyContrcators, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async pdfGeneratePdfWeekProgramForOeBBSendRequest(startIncluding: string, endIncluding: string, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.pdfGeneratePdfWeekProgramForOeBB(startIncluding, endIncluding, filterForIbn, oebbOnlyContrcators,  options))();
        },
        async pdfGeneratePdfWeekProgramForOeBBAndGetData(startIncluding: string, endIncluding: string, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): Promise<File> {
            return (await this.pdfGeneratePdfWeekProgramForOeBBSendRequest(startIncluding, endIncluding, filterForIbn, oebbOnlyContrcators,  options)).data;
        },
    }
};

/**
 * PdfApi - factory interface
 * @export
 */
export const PdfApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdfApiFp(configuration)
    return {
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {Array<string>} [contractors] 
         * @param {boolean} [filterForIbn] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfGeneratePdfForConstructionSupervisor(startIncluding: string, endIncluding: string, contractors?: Array<string>, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.pdfGeneratePdfForConstructionSupervisor(startIncluding, endIncluding, contractors, filterForIbn, oebbOnlyContrcators, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startIncluding 
         * @param {string} endIncluding 
         * @param {boolean} [filterForIbn] 
         * @param {boolean} [oebbOnlyContrcators] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfGeneratePdfWeekProgramForOeBB(startIncluding: string, endIncluding: string, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.pdfGeneratePdfWeekProgramForOeBB(startIncluding, endIncluding, filterForIbn, oebbOnlyContrcators, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdfApi - interface
 * @export
 * @interface PdfApi
 */
export interface PdfApiInterface {
    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {Array<string>} [contractors] 
     * @param {boolean} [filterForIbn] 
     * @param {boolean} [oebbOnlyContrcators] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApiInterface
     */
    pdfGeneratePdfForConstructionSupervisor(startIncluding: string, endIncluding: string, contractors?: Array<string>, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {boolean} [filterForIbn] 
     * @param {boolean} [oebbOnlyContrcators] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApiInterface
     */
    pdfGeneratePdfWeekProgramForOeBB(startIncluding: string, endIncluding: string, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig): AxiosPromise<File>;

}

/**
 * PdfApi - object-oriented interface
 * @export
 * @class PdfApi
 * @extends {BaseAPI}
 */
export class PdfApi extends BaseAPI implements PdfApiInterface {
    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {Array<string>} [contractors] 
     * @param {boolean} [filterForIbn] 
     * @param {boolean} [oebbOnlyContrcators] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public pdfGeneratePdfForConstructionSupervisor(startIncluding: string, endIncluding: string, contractors?: Array<string>, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).pdfGeneratePdfForConstructionSupervisor(startIncluding, endIncluding, contractors, filterForIbn, oebbOnlyContrcators, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startIncluding 
     * @param {string} endIncluding 
     * @param {boolean} [filterForIbn] 
     * @param {boolean} [oebbOnlyContrcators] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public pdfGeneratePdfWeekProgramForOeBB(startIncluding: string, endIncluding: string, filterForIbn?: boolean, oebbOnlyContrcators?: boolean, options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).pdfGeneratePdfWeekProgramForOeBB(startIncluding, endIncluding, filterForIbn, oebbOnlyContrcators, options).then((request) => request(this.axios, this.basePath));
    }
}
