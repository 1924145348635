import {Badge, Dialog, DialogContent, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {
    HEADER_BAR_ICON_BUTTON_HEIGHT,
    HEADER_BAR_ICON_FONT_SIZE,
    HEADER_BAR_ICON_PADDING
} from "../../styling-constants";
import DialogTitleWithCloseButton from "./DialogTitleWithCloseButton";
import React, {useCallback, useEffect} from "react";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import {LocalizationService} from "../../utils/Localization";
import {
    KoralmLocation,
    LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp,
    LatitudinalDirectedTunnelWorkRegistrationApiFp,
    LatitudinalDirection,
    OutdoorUniqueConstructionWorkRegistrationApiFp,
    OutdoorWorkRegistrationApiFp,
    TunnelWorkRegistrationWithNumericLocationIDApiFp,
    ZoneBasedTunnelTubeWorkRegistrationApiFp
} from "../../generated";
import WeekOverviewGrid from "../WeekOverviewPage/WeekOverviewGrid";
import {createUnfinishedWorkArgs} from "../../utils/FilterHelpers";
import {formatISO} from "date-fns";
import UserService from "../../services/UserService";
import {WorkRegistration} from "../../utils/Types";
import {PermissionManager} from "../../utils/PermissionManager";

interface UnfinishedWorkRegistrationProps {
    changedSelectedWorkRegistration: (value: WorkRegistration) => void;
}

function UnfinishedWorkRegistrations(props: UnfinishedWorkRegistrationProps) {
    const [open, setOpen] = React.useState(false);
    const [unfinishedWorkAmount, setUnfinishedWorkAmount] = React.useState(0);
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    const oldestDate = new Date(0)
    const contractorIds = PermissionManager.Universal.getContractorsOrCompanies ? UserService.tryGetMappedCompanies() : UserService.tryGetCurrentContractorId()

    const title = (
        <Typography fontSize={20}>
            Unabgeschlossene Arbeiten{' '}
            {contractorIds !== null && contractorIds && (<span>von{' '}
                    <Typography component="span" fontWeight="bold" fontSize={20}>
                        {typeof contractorIds === 'string' ? contractorIds : contractorIds.map((id, index) => (
                            <span key={index}>{index > 0 && ', '}{id}</span>))}
          </Typography>
        </span>
            )}{' '}
            bis zum{' '}
            <Typography component="span" fontWeight="bold" fontSize={20}>
                {yesterday.toLocaleDateString('de-DE', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                })}
            </Typography>
        </Typography>
    );

    useEffect(() => {
        void fetchUnfinishedWorkAmount()
    }, [])

    const fetchUnfinishedWorkAmount = useCallback(async () => {
        const filterArgs = createUnfinishedWorkArgs(formatISO(yesterday), contractorIds);
        let responseAmount: number;
        let zoneBaseResponse = await ZoneBasedTunnelTubeWorkRegistrationApiFp()
            .zoneBasedTunnelTubeWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
        let tunnelResponse = await TunnelWorkRegistrationWithNumericLocationIDApiFp()
            .tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
        let latitudinalResponse = await LatitudinalDirectedTunnelWorkRegistrationApiFp()
            .latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
        let outdoorResponse = await OutdoorWorkRegistrationApiFp()
            .outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
        let outdoorUniqueResponse = await OutdoorUniqueConstructionWorkRegistrationApiFp()
            .outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
        let latitudinalOutdoorResponse = await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp()
            .latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);

        responseAmount = zoneBaseResponse.ItemCount + tunnelResponse.ItemCount + latitudinalResponse.ItemCount + outdoorResponse.ItemCount + outdoorUniqueResponse.ItemCount + latitudinalOutdoorResponse.ItemCount;
        setUnfinishedWorkAmount(responseAmount)
    }, []);

    return (
        <div>
            <Tooltip title={
                <Typography>{contractorIds !== null ? `Unfertige Arbeiten ${contractorIds}` : 'Unfertige Arbeiten'}</Typography>}>
                <IconButton
                    aria-label="UnDoneNotification"
                    style={{color: "white", height: HEADER_BAR_ICON_BUTTON_HEIGHT}}
                    disabled={false}
                    onClick={() => setOpen(true)}>
                    <Badge badgeContent={unfinishedWorkAmount} color="error"
                           anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                           overlap="rectangular" max={9999}>
                        <UnpublishedIcon
                            style={{fontSize: HEADER_BAR_ICON_FONT_SIZE, padding: HEADER_BAR_ICON_PADDING}}/>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={false}>
                <DialogTitleWithCloseButton title={title} onClose={() => setOpen(false)}/>
                <DialogContent>
                    <Stack>
                        <Typography align="center"
                                    fontSize={20}>{LocalizationService.KoralmLocationWithLatitudinalDirection()}</Typography>
                        <WeekOverviewGrid
                            changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                            view={KoralmLocation.Tunnel}
                            startDate={oldestDate}
                            endDate={yesterday}
                            isPopUP={createUnfinishedWorkArgs(formatISO(yesterday), contractorIds)}
                            changedOutdoorWorkRegistration={() => {
                            }}
                            changedTunnelWorkRegistration={() => {
                            }}
                        />
                        <Typography align="center"
                                    fontSize={20}>{LocalizationService.KoralmLocationWithLatitudinalDirection(LatitudinalDirection.East)}</Typography>
                        <WeekOverviewGrid
                            changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                            view={LatitudinalDirection.East}
                            startDate={oldestDate}
                            endDate={yesterday}
                            isPopUP={createUnfinishedWorkArgs(formatISO(yesterday), contractorIds, LatitudinalDirection.East)}
                            changedOutdoorWorkRegistration={() => {
                                void fetchUnfinishedWorkAmount()
                            }}
                            changedTunnelWorkRegistration={() => {
                                void fetchUnfinishedWorkAmount()
                            }}
                        />
                        <Typography align="center"
                                    fontSize={20}>{LocalizationService.KoralmLocationWithLatitudinalDirection(LatitudinalDirection.West)}</Typography>
                        <WeekOverviewGrid
                            changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                            view={LatitudinalDirection.West}
                            startDate={oldestDate}
                            endDate={yesterday}
                            isPopUP={createUnfinishedWorkArgs(formatISO(yesterday), contractorIds, LatitudinalDirection.West)}
                            changedOutdoorWorkRegistration={() => {
                                void fetchUnfinishedWorkAmount()
                            }}
                            changedTunnelWorkRegistration={() => {
                                void fetchUnfinishedWorkAmount()
                            }}
                        />
                    </Stack>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default UnfinishedWorkRegistrations;