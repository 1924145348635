import {FilterOperation} from "../generated";

export enum FilterType {
    CrossCut = 'Querschlag',
    Tube = 'Tunnel (Zonen in der Röhre)',
    Outdoor = 'Freie Strecke',
    EmergencyStop = 'Nothaltestelle',
    Portal = 'Portal',
    EscapeGallery = 'Fluchtstollen',
    VentilationShaft = 'Lüftungsschacht / Lüftergebäude'
}
export const VENTILATIONSHAFT_SHAFT = "Lüftungsschacht";
export const VENTILATIONSHAFT_BUILDING = "Lüftergebäude";

export function ParseFilterOperator(operator: string | undefined) {
    const filterOperatorMap: Record<string, FilterOperation> = {
        "Equals": FilterOperation.Equals,
        "equals": FilterOperation.Equals,
        "contains": FilterOperation.RegexOnString,
        "is": FilterOperation.Equals,
        "not": FilterOperation.NotEquals,
        "after": FilterOperation.GreaterThan,
        "onOrAfter": FilterOperation.GreaterThanOrEqual,
        "before": FilterOperation.LessThan,
        "onOrBefore": FilterOperation.LessThanOrEqual,
        "=": FilterOperation.Equals,
        "!=": FilterOperation.NotEquals,
        ">": FilterOperation.GreaterThan,
        ">=": FilterOperation.GreaterThanOrEqual,
        "<": FilterOperation.LessThan,
        "<=": FilterOperation.LessThanOrEqual,
        "isAnyOf": FilterOperation.RegexOnString,
        "between": FilterOperation.OneOf,
    };

    if (operator && filterOperatorMap[operator]) {
        return filterOperatorMap[operator];
    } else {
        console.error("Disabled Filter detected: " + operator);
        return operator as FilterOperation;
    }
}