import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserService from "./services/UserService";
import {LOG_IN_EVENT_FLAG} from "./constants";
import {storageDataMappingProvider} from "./services/storage-data-mapping-provider";
import {LinearProgress} from "@mui/material";
import {translationsService} from "./services/translationsProvider";
import {tunnelConstructionService} from "./services/tunnelConstructionsDescriptionsProvider";
import {railDescriptionService} from "./services/railDescriptionsProvider";
import {contractorService} from "./services/contractorProvider";
import ErrorBoundary from "./utils/ErrorBoundary";

const renderApp = async () => {
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );
    root.render(<LinearProgress/>);
    await storageDataMappingProvider.init();
    await translationsService.init();
    await tunnelConstructionService.init();
    await railDescriptionService.init();
    await contractorService.init();
    localStorage.setItem(LOG_IN_EVENT_FLAG, "true");

    root.render(
        <React.StrictMode>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </React.StrictMode>
    );
    reportWebVitals();
};

UserService.initKeycloak(renderApp);
