import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {
    KoralmLocation,
    LatitudinalDirection,
    RailTrackStatus,
    TrainStationApiFp,
    TrainStationInTunnel
} from "../../generated";
import {OutdoorVisualization} from "../RailVisualization/OutdoorVisualization";
import HeaderBar from "../HeaderBar/HeaderBar";
import {OutdoorWorkRegistrationsAndConstructions, TunnelWorkRegistration, WebAppPage} from "../../utils/Types";
import WeekOverviewGrid from "./WeekOverviewGrid";
import {HeaderBarNewRegistration, IbnColor, railTrackStatusToColors, SELECTED_MENU_ITEM} from "../../utils/Colors";
import {addWeeks, getWeek, subSeconds} from "date-fns";
import {LocalizationService} from "../../utils/Localization";
import {calculateNumberOfWeeksInYear, getStartDateOfWeek} from "../../utils/DateFormatting";
import {handleWeekMenuItems} from "../../utils/Exports";
import {PermissionManager} from "../../utils/PermissionManager";
import {DataGridEditorArgs} from "../../utils/InterfaceProps";
import {TunnelVisualization} from "../RailVisualization/TunnelVisualization";
import MuiToggleButton from "@mui/material/ToggleButton";
import {styled} from "@mui/material/styles";
import {railDescriptionService} from "../../services/railDescriptionsProvider";
import {trainStationCallbackProvider} from "../../services/trainStationService";
import TrainStationButton from "../DataGridHeaderButtons/TrainStationButton";
import NewWorkRegistrationButton from "../DataGridHeaderButtons/NewWorkRegistrationButton";
import ExportManager from "../DataGridHeaderButtons/ExportManager";
import Konva from "konva";

function WeekOverviewEditor(props: DataGridEditorArgs) {
    const newDate = new Date()

    const [currentWeek, setCurrentWeek] = useState(getWeek(newDate))
    const [currentYear, setCurrentYear] = useState(newDate.getFullYear())

    const [startDate, setStartDate] = useState(getStartDateOfWeek(currentYear, currentWeek))
    const [endDate, setEndDate] = useState(subSeconds(addWeeks(getStartDateOfWeek(currentYear, currentWeek), 1), 1))
    const [trainStations, setTrainStations] = useState<TrainStationInTunnel[]>([])

    const [locationToggle, setLocationToggle] = useState<KoralmLocation.Tunnel | LatitudinalDirection>(KoralmLocation.Tunnel)

    const [outdoorData, setOutdoorData] = useState<Array<OutdoorWorkRegistrationsAndConstructions>>([])
    const [tunnelData, setTunnelData] = useState<Array<TunnelWorkRegistration>>([])

    const stageRef = useRef<Konva.Stage | null>(null);
    const timeRangeString = startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString();

    async function fetchTrainStations(trainStationStartDate: Date, trainStationEndDate: Date) {
        await TrainStationApiFp().trainStationGetIntervalAndGetData(
            trainStationStartDate.toDateString(),
            trainStationEndDate.toDateString()
        ).then((trainStations) => setTrainStations(trainStations));
    }

    trainStationCallbackProvider.registerChangeCallback(() => fetchTrainStations(
        getStartDateOfWeek(currentYear, currentWeek),
        subSeconds(addWeeks(getStartDateOfWeek(currentYear, currentWeek), 1), 1)));

    useEffect(() => {
        setStartDate(getStartDateOfWeek(currentYear, currentWeek))
        setEndDate(subSeconds(addWeeks(getStartDateOfWeek(currentYear, currentWeek), 1), 1))
        void fetchTrainStations(getStartDateOfWeek(currentYear, currentWeek), subSeconds(addWeeks(getStartDateOfWeek(currentYear, currentWeek), 1), 1))
    }, [currentWeek, currentYear])

    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: HeaderBarNewRegistration,
        }
    });

    function handleLocationSwitch(event: React.MouseEvent<HTMLElement>, value: KoralmLocation.Tunnel | LatitudinalDirection) {
        if (value !== null) {
            setLocationToggle(value)
        }
    }

    const renderHeaderBar = () => {
        return <HeaderBar
            changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
            isInDataTable={true}
            selectedPage={props.selectedPage}
            changedSelectedPage={props.changedSelectedPage}
            isNewWorkRegistration={false}
            newNotification={props.newNotification}
        />
    }
    const handleYearsMenuItems = () => {
        const minYear = new Date(2022, 1, 1).getFullYear();
        const maxYear = new Date().getFullYear() + 1;

        return Array.from({length: maxYear - minYear + 1}, (_, index) => minYear + index).map((year, index) => {
            return (
                <MenuItem key={"YearItem" + year + index} value={year}>
                    {year}
                </MenuItem>
            );
        });
    };

    const renderWeekDateSelection = () => {
        return <Stack paddingTop={1}>
            <FormControl>
                <InputLabel id="rangeWeekDateSelectionLabel">Start Woche</InputLabel>
                <Select
                    name="StartWeekSelection"
                    labelId="rangeWeekDateSelectionLabel"
                    label="Start Woche"
                    id="rangeDateSelect"
                    onChange={(event) => setCurrentWeek(event.target.value as number)}
                    sx={{width: 200, marginTop: 1}}
                    value={currentWeek}
                    MenuProps={{
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: SELECTED_MENU_ITEM
                            }
                        }
                    }}
                >
                    {handleWeekMenuItems(currentYear)}
                </Select>
            </FormControl>
        </Stack>
    }

    const handleYearChangeSelection = (event: SelectChangeEvent<number>) => {
        if (currentWeek === 53 && calculateNumberOfWeeksInYear(event.target.value as number) === 52) setCurrentWeek(52)
        setCurrentYear(event.target.value as number)
    }

    const renderYearDateSelection = () => {
        return <Stack paddingTop={1}>
            <FormControl>
                <InputLabel id="rangeYearDateSelectionLabel">Jahr</InputLabel>
                <Select
                    name="startYearSelection"
                    labelId="rangeYearDateSelectionLabel"
                    label="Jahr"
                    id="rangeYearSelect"
                    onChange={handleYearChangeSelection}
                    sx={{width: 100, marginTop: 1}}
                    value={currentYear}
                >
                    {handleYearsMenuItems()}
                </Select>
            </FormControl>
        </Stack>
    }

    const renderRailLegend = () => {
        const colorBoxSize = 20;

        return (
            <Stack direction="row" justifyContent="space-between" paddingTop={1}>
                <Stack direction="row">
                    <Typography>Zeitraum: </Typography>
                    <Typography>{timeRangeString}</Typography>
                    <Button
                        variant='outlined'
                        style={{borderColor: "black", color: "black"}}
                        size="large"
                        onClick={() => handleImageExport()}
                        sx={{width: 215, height: 20, marginLeft: 5}}
                    >{"Als Bild herunterladen"}</Button>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="end">
                    {Object.values(RailTrackStatus).map((status) => (
                        <Fragment key={status}>
                            <Typography>{LocalizationService.RailTrackStatus(status)}:</Typography>
                            <Box
                                sx={{
                                    bgcolor: railTrackStatusToColors(status as RailTrackStatus),
                                    borderRadius: '50%',
                                    height: colorBoxSize,
                                    width: colorBoxSize
                                }}
                            />
                        </Fragment>
                    ))}
                    <Typography>IBN:</Typography>
                    <Box
                        sx={{
                            bgcolor: IbnColor,
                            borderRadius: '50%',
                            height: colorBoxSize,
                            width: colorBoxSize
                        }}
                    />
                </Stack>
            </Stack>
        );
    };


    const renderLocationToggle = () => {
        return <Stack paddingTop={2}>
            <ToggleButtonGroup
                color="info"
                value={locationToggle}
                exclusive
                onChange={handleLocationSwitch}
                aria-label="WeekOverviewEditorLocationToggle"
                sx={{backgroundColor: "#ffffff"}}
            >
                <ToggleButton
                    value={LatitudinalDirection.East}>{LocalizationService.KoralmLocationWithLatitudinalDirection(LatitudinalDirection.East)}
                </ToggleButton>
                <ToggleButton
                    value={KoralmLocation.Tunnel}>{LocalizationService.KoralmLocationWithLatitudinalDirection()}
                </ToggleButton>
                <ToggleButton
                    value={LatitudinalDirection.West}>{LocalizationService.KoralmLocationWithLatitudinalDirection(LatitudinalDirection.West)}
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    }

    const handleRailVisualization = (isTunnelSelected: boolean) => {
        return isTunnelSelected ?
            <TunnelVisualization
                tunnelWorkRegistration={tunnelData}
                trainStationInTunnelData={trainStations}
                changedStageRef={value => stageRef.current = value}
            /> :
            <OutdoorVisualization
                outdoorWorkRegistration={outdoorData}
                openLineLocation={locationToggle as LatitudinalDirection}
                changedStageRef={value => stageRef.current = value}
            />
    }

    const handleImageExport = () => {
        if (stageRef.current) {
            const dataUrl = stageRef.current.toDataURL({
                pixelRatio: 2
            });
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `KoralmWebapp ${LocalizationService.KoralmLocationWithLatitudinalDirection(
                locationToggle === KoralmLocation.Tunnel ? undefined : locationToggle)}-Ansicht ${timeRangeString}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error("Stage reference is null")
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {renderHeaderBar()}
            </Grid>
            <Grid item xs={12}>
                <Box sx={{backgroundColor: 'secondary.main'}} padding={2} borderRadius={2} marginX={2}>
                    <Stack direction="row" marginBottom={2} spacing={4} borderRadius={2} justifyContent="space-between">
                        <Stack direction="row" spacing={4}>
                            {renderWeekDateSelection()}
                            {renderYearDateSelection()}
                            {PermissionManager.ListView.areaRestriction && renderLocationToggle()}
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            {PermissionManager.Overview.newStop && <TrainStationButton/>}
                            {PermissionManager.Universal.newWorkRegistrationButton && <NewWorkRegistrationButton
                                changedSelectedPage={() => props.changedSelectedPage(WebAppPage.WorkRegistration)}/>}
                            <ExportManager/>
                        </Stack>
                    </Stack>
                    <Stack>
                        {
                            railDescriptionService.railSectionDescriptions[0] &&
                            handleRailVisualization(locationToggle === KoralmLocation.Tunnel)}
                    </Stack>
                    {renderRailLegend()}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <WeekOverviewGrid
                    changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                    view={locationToggle}
                    startDate={startDate}
                    endDate={endDate}
                    changedOutdoorWorkRegistration={(value) => setOutdoorData(value)}
                    changedTunnelWorkRegistration={(value) => setTunnelData(value)}
                />
            </Grid>
        </Grid>
    )
}

export default WeekOverviewEditor;