import {MenuItem, Select, Stack,} from "@mui/material";
import {FilterType} from "../../utils/FilterTypes";
import React from "react";
import {DataGridToolbarArgs} from "../../utils/InterfaceProps";
import {PermissionManager} from "../../utils/PermissionManager";
import {SELECTED_MENU_ITEM} from "../../utils/Colors";
import TrainStationButton from "../DataGridHeaderButtons/TrainStationButton";
import NewWorkRegistrationButton from "../DataGridHeaderButtons/NewWorkRegistrationButton";
import {WebAppPage} from "../../utils/Types";
import ExportManager from "../DataGridHeaderButtons/ExportManager";
import {ContractorMenuItems} from "../../utils/Exports";
import {trainStationCallbackProvider} from "../../services/trainStationService";

function ListViewHeaderToolbar(props: DataGridToolbarArgs) {
    trainStationCallbackProvider.registerChangeCallback(async () => {});

    return (
        <Stack direction='row' paddingTop={1} spacing={1} alignItems="center" justifyContent="space-between">
            <Stack direction='row' spacing={1}>
                {PermissionManager.ListView.dropdownList && <Select
                    name="Object Selection"
                    onChange={props.changedFilter}
                    value={props.Filter}
                    style={{
                        width: 250,
                        height: 35,
                        backgroundColor: "#D9D9D9",
                        fontSize: 15
                    }}
                    MenuProps={{
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: SELECTED_MENU_ITEM
                            }
                        }
                    }}
                >
                    <MenuItem
                        value={FilterType.CrossCut}>{FilterType.CrossCut} / {FilterType.EscapeGallery} / {FilterType.EmergencyStop}</MenuItem>
                    <MenuItem value={FilterType.Tube}>{FilterType.Tube}</MenuItem>
                    <MenuItem value={FilterType.Outdoor}>{FilterType.Outdoor}</MenuItem>
                    <MenuItem value={FilterType.Portal}>{FilterType.Portal} / {FilterType.VentilationShaft}</MenuItem>
                </Select>}
                <Select
                    name="Contractor-Selection"
                    labelId="contractor-listview-selection-label"
                    id="contractor-listview-selection"
                    multiple
                    value={props.contractors}
                    onChange={props.changedContractors}
                    style={{
                        width: 250,
                        height: 35,
                        backgroundColor: "#D9D9D9",
                        fontSize: 15
                    }}
                    MenuProps={{
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: SELECTED_MENU_ITEM
                            }
                        }
                    }}
                >
                    {ContractorMenuItems()}
                </Select>
            </Stack>
            <Stack direction="row" spacing={2}>
                {PermissionManager.Overview.newStop && <TrainStationButton/>}
                {PermissionManager.Universal.newWorkRegistrationButton && <NewWorkRegistrationButton
                    changedSelectedPage={() => props.changedSelectedPage(WebAppPage.WorkRegistration)}/>}
                <ExportManager/>
            </Stack>
        </Stack>
    )
}

export default ListViewHeaderToolbar;