import React, {ReactNode, useEffect} from 'react';
import {Button, Divider, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import backgroundImage from "../img/koralm_panorama_bg.png";
import {formatISO} from "date-fns";
import UserService from "../services/UserService";

interface ErrorBoundaryProps {
    children: ReactNode;
}

function ErrorBoundary(props: ErrorBoundaryProps) {
    const [hasError, setHasError] = React.useState(false);
    const [error, setError] = React.useState<Error | null>(null);

    const unknownErrorMessage = "Unbekannter Fehler"
    const emailAddress = 'koralm-support@bii-gmbh.com'
    const errorEmailSubject = encodeURIComponent(`Koralm Webapp Technische Störung - ${formatISO(new Date(), {representation: 'date'})}`)
    const errorBodyText = encodeURIComponent(`Sehr geehrte Damen und Herren,\n\nHier ist die Fehlermeldung für das technische Problem:\n\n${error ? error.toString() : unknownErrorMessage}\n\nmit freundlichen Grüßen,\n\n${UserService.getUsername()}`)
    const errorHeaderMessage = "Aktuell treten technische Probleme auf. Bitte kontaktieren Sie den Support"

    const handleError = (event: ErrorEvent) => {
        setHasError(true);
        setError(event.error);
    };

    useEffect(() => {
        window.addEventListener('error', handleError);
        return () => window.removeEventListener('error', handleError);
    }, []);

    if (hasError) {
        return (
            <Stack
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Stack
                    textAlign="center"
                    padding={3}
                    border="1px solid #ccc"
                    borderRadius={4}
                    style={{backgroundColor: "white"}}
                    spacing={1}
                >
                    <Typography variant="h5">{errorHeaderMessage}</Typography>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <IconButton
                            onClick={() => window.location.href = `mailto:${emailAddress}?subject=${errorEmailSubject}&body=${errorBodyText}`}>
                            <EmailOutlinedIcon/>
                        </IconButton>
                        <Tooltip title="E-Mail-Adresse kopieren">
                            <Typography
                                onClick={() => navigator.clipboard.writeText(emailAddress)}
                                style={{cursor: 'pointer'}}
                            >
                                {emailAddress}
                            </Typography>
                        </Tooltip>
                    </Stack>
                    <Typography>Fehlermeldung:</Typography>
                    <Tooltip title="Fehlermeldung kopieren">
                        <Typography
                            variant="body1"
                            color="error"
                            onClick={() => navigator.clipboard.writeText(error ? error.toString() : unknownErrorMessage)}
                            style={{cursor: 'pointer'}}
                        >
                            {error ? error.toString() : unknownErrorMessage}
                        </Typography>
                    </Tooltip>
                    <Divider/>
                    <Button variant="contained" color="inherit" onClick={() => window.location.reload()}>
                        Seite neu laden
                    </Button>
                </Stack>
            </Stack>
        );
    }

    return <>{props.children}</>;
}

export default ErrorBoundary;