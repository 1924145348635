import React, {useCallback, useEffect, useState} from "react";
import {
    KoralmLocation,
    LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp,
    LatitudinalDirectedTunnelWorkRegistrationApiFp,
    LatitudinalDirection,
    OutdoorUniqueConstructionWorkRegistrationApiFp,
    OutdoorWorkRegistrationApiFp,
    Tube,
    TunnelObject,
    TunnelWorkRegistrationWithNumericLocationIDApiFp,
    ZoneBasedTunnelTubeWorkRegistrationApiFp
} from "../../generated";
import {Stack} from "@mui/material";
import WorkRegistrationDataGrid from "../WorkRegistrationDataGrid";
import {
    filterArgumentTypesWithCombinedFilterDescription, isCurrentUserOebbSub,
    OutdoorWorkRegistrationsAndConstructions,
    pageStateType,
    TunnelWorkRegistration
} from "../../utils/Types";
import {formatISO} from "date-fns";
import {WeekOverviewGridArgs} from "../../utils/InterfaceProps";
import {createWeekOverviewArgs} from "../../utils/FilterHelpers";
import {POP_UP_CONTENT_HEIGHT} from "../../styling-constants";
import {GridFilterModel} from "@mui/x-data-grid";
import {pageFilterProvider} from "../../services/pageFilterProvider";

function WeekOverviewGrid(props: WeekOverviewGridArgs) {
    const {showButtons = true} = props;
    const [overviewData, setOverviewData] = useState<any>([])
    const [clickedIndex, setClickedIndex] = React.useState(-1)

    const [pageState, setPageState] = useState<pageStateType>(pageFilterProvider.pageFilterState)

    const updatePageState = (property: keyof pageStateType, value: any) => {
        setPageState(oldPageState => {
            const newPageState = {
                ...oldPageState,
                [property]: value,
            };
            pageFilterProvider.pageFilterState = newPageState;
            return newPageState;
        })
    }

    const filterModel: GridFilterModel | undefined = pageState.filterProperty
        ? {
            items: [
                {
                    columnField: pageState.filterProperty,
                    operatorValue: pageState.filterOperator,
                    value: pageState.filterValues,
                },
            ],
        }
        : undefined;

    const fetchOutdoorData = useCallback(async (filterArgs: filterArgumentTypesWithCombinedFilterDescription) => {
        updatePageState('isLoading', true)
        const responses: OutdoorWorkRegistrationsAndConstructions[] = [];

        try {
            let outdoorResponse = await OutdoorWorkRegistrationApiFp().outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            let outdoorUniqueResponse = await OutdoorUniqueConstructionWorkRegistrationApiFp().outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            let latitudinalOutdoorResponse = await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp().latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs)
            if (outdoorResponse !== undefined && outdoorUniqueResponse !== undefined && latitudinalOutdoorResponse !== undefined) {
                responses.push(...outdoorResponse.Data)
                for (let i = 1; i <= outdoorResponse.MaxPageIndex; i++) {
                    let modifiedFilterArgs: filterArgumentTypesWithCombinedFilterDescription = [...filterArgs]
                    modifiedFilterArgs[1] = i
                    let outdoorResponseInLoop = await OutdoorWorkRegistrationApiFp().outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...modifiedFilterArgs)
                    responses.push(...outdoorResponseInLoop.Data)
                }
                responses.push(...outdoorUniqueResponse.Data)
                for (let i = 1; i <= outdoorUniqueResponse.MaxPageIndex; i++) {
                    let modifiedFilterArgs: filterArgumentTypesWithCombinedFilterDescription = [...filterArgs]
                    modifiedFilterArgs[1] = i
                    let outdoorUniqueResponseInLoop = await OutdoorWorkRegistrationApiFp().outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...modifiedFilterArgs)
                    responses.push(...outdoorUniqueResponseInLoop.Data)
                }
                responses.push(...latitudinalOutdoorResponse.Data)
                for (let i = 1; i <= latitudinalOutdoorResponse.MaxPageIndex; i++) {
                    let modifiedFilterArgs: filterArgumentTypesWithCombinedFilterDescription = [...filterArgs]
                    modifiedFilterArgs[1] = i
                    let latitudinalOutdoorResponseInLoop = await OutdoorWorkRegistrationApiFp().outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...modifiedFilterArgs)
                    responses.push(...latitudinalOutdoorResponseInLoop.Data)
                }

                const filteredOutdoorResponses = Array.from(new Set(responses.map(workItem => workItem.UUID)))
                    .map(UUID => responses.find(workItem => workItem.UUID === UUID))
                    .filter(workItem => workItem !== undefined) as OutdoorWorkRegistrationsAndConstructions[];

                setOverviewData(filteredOutdoorResponses)
                updatePageState('isLoading', false)
                updatePageState('total', filteredOutdoorResponses.length)
                props.changedOutdoorWorkRegistration(filteredOutdoorResponses)
            }
        } catch (error) {
            console.error(error)
        }
    }, [])

    const fetchTunnelData = useCallback(async (filterArgs: filterArgumentTypesWithCombinedFilterDescription) => {
        updatePageState('isLoading', true)
        const responses: TunnelWorkRegistration[] = []

        try {
            let crossCutResponse = await TunnelWorkRegistrationWithNumericLocationIDApiFp().tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
            let zoneResponse = await ZoneBasedTunnelTubeWorkRegistrationApiFp().zoneBasedTunnelTubeWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
            let latitudinalResponse = await LatitudinalDirectedTunnelWorkRegistrationApiFp().latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
            if (crossCutResponse !== undefined && zoneResponse !== undefined && latitudinalResponse !== undefined) {
                responses.push(...crossCutResponse.Data)
                for (let i = 1; i <= crossCutResponse.MaxPageIndex; i++) {
                    let modifiedFilterArgs: filterArgumentTypesWithCombinedFilterDescription = [...filterArgs]
                    modifiedFilterArgs[1] = i
                    let crossCutResponseInLoop = await TunnelWorkRegistrationWithNumericLocationIDApiFp().tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(...modifiedFilterArgs)
                    responses.push(...crossCutResponseInLoop.Data)
                }
                responses.push(...zoneResponse.Data)
                for (let i = 1; i <= zoneResponse.MaxPageIndex; i++) {
                    let modifiedFilterArgs: filterArgumentTypesWithCombinedFilterDescription = [...filterArgs]
                    modifiedFilterArgs[1] = i
                    let zoneResponseInLoop = await ZoneBasedTunnelTubeWorkRegistrationApiFp().zoneBasedTunnelTubeWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...modifiedFilterArgs)
                    responses.push(...zoneResponseInLoop.Data)
                }
                responses.push(...latitudinalResponse.Data)
                for (let i = 1; i <= latitudinalResponse.MaxPageIndex; i++) {
                    let modifiedFilterArgs: filterArgumentTypesWithCombinedFilterDescription = [...filterArgs]
                    modifiedFilterArgs[1] = i
                    let latitudinalResponseInLoop = await LatitudinalDirectedTunnelWorkRegistrationApiFp().latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...modifiedFilterArgs)
                    responses.push(...latitudinalResponseInLoop.Data)
                }

                const filteredTunnelResponses = Array.from(new Set(responses.map(workItem => workItem.UUID)))
                    .map(UUID => responses.find(workItem => workItem.UUID === UUID))
                    .filter(workItem => workItem !== undefined) as TunnelWorkRegistration[];

                setOverviewData(filteredTunnelResponses)
                updatePageState('isLoading', false)
                updatePageState('total', filteredTunnelResponses.length)
                props.changedTunnelWorkRegistration(filteredTunnelResponses)
            }
        } catch (error) {
            console.error(error)
        }
    }, []);

    const fetchTunnelDataConflictPopUp = useCallback(async (filterArgs: filterArgumentTypesWithCombinedFilterDescription) => {
        const combinedFilterDescriptionIndex = 4;
        const mainChildDescriptionIndex = 0;
        const objectTypeFilterIndex = 2;

        updatePageState('isLoading', true)
        let responses: TunnelWorkRegistration[] = []
        let itemCount: number = 0;
        // Note FS: Gets the object type
        switch (filterArgs[combinedFilterDescriptionIndex].ChildDescriptions[mainChildDescriptionIndex]
            .LeafDescriptions[objectTypeFilterIndex].FilterPropertyValues[0]) {
            case TunnelObject.Tube:
            case Tube.South:
            case Tube.North:
                let zoneBaseResponse = await ZoneBasedTunnelTubeWorkRegistrationApiFp()
                    .zoneBasedTunnelTubeWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                responses.push(...zoneBaseResponse.Data)
                itemCount += zoneBaseResponse.ItemCount
                break;
            case TunnelObject.EscapeGallery:
            case TunnelObject.CrossCut:
            case TunnelObject.EmergencyStop:
                let tunnelResponse = await TunnelWorkRegistrationWithNumericLocationIDApiFp()
                    .tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                responses.push(...tunnelResponse.Data)
                itemCount += tunnelResponse.ItemCount
                break;
            case TunnelObject.VentilationShaft:
            case TunnelObject.Portal:
                let latitudinalResponse = await LatitudinalDirectedTunnelWorkRegistrationApiFp()
                    .latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
                responses.push(...latitudinalResponse.Data)
                itemCount += latitudinalResponse.ItemCount
                break;
            default:
                console.error("Did not find a Work-Registration Object")
                break;
        }
        setOverviewData(responses)
        updatePageState('isLoading', false)
        updatePageState('total', itemCount)
        props.changedTunnelWorkRegistration(responses)
    }, []);

    function updateDataGrid(startDate: string, endDate: string) {
        if (props.isConflictTable) {
            props.view === LatitudinalDirection.East || props.view === LatitudinalDirection.West
                ? fetchOutdoorData(props.isConflictTable)
                : fetchTunnelDataConflictPopUp(props.isConflictTable);
        } else if (props.isPopUP) {
            props.view === LatitudinalDirection.East || props.view === LatitudinalDirection.West
                ? fetchOutdoorData(props.isPopUP)
                : fetchTunnelData(props.isPopUP);

        } else {
            const filterArgs = createWeekOverviewArgs(startDate, endDate, pageState.sortingProperty,
                pageState.sortingValue, props.view !== KoralmLocation.Tunnel ? props.view : undefined)
            if (props.tunnelWorkRegistration) {
                setOverviewData(props.tunnelWorkRegistration)
            } else {
                props.view === LatitudinalDirection.East || props.view === LatitudinalDirection.West
                    ? fetchOutdoorData(filterArgs)
                    : fetchTunnelData(filterArgs);
            }
        }

    }

    useEffect(() => {
        updateDataGrid(formatISO(props.startDate), formatISO(props.endDate))
    }, [props.view, props.startDate, props.endDate, pageState.pageSize, pageState.page])

    return (
        <Stack height={POP_UP_CONTENT_HEIGHT} paddingLeft={3} paddingRight={3}>
            <WorkRegistrationDataGrid
                changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                gridData={overviewData}
                clickedIndex={clickedIndex}
                changedGridRow={(value) => {
                    setClickedIndex(clickedIndex === value.value ? -1 : value.value)
                }}
                isLoading={pageState.isLoading}
                total={pageState.total}
                page={pageState.page}
                changedPage={(value) => updatePageState('page', value)}
                pageSize={pageState.pageSize}
                changedPageSize={(value) => updatePageState('pageSize', value)}
                filter={props.view === KoralmLocation.Tunnel ? KoralmLocation.Tunnel : KoralmLocation.OpenLine}
                mode="client"
                onDataUpdate={() => updateDataGrid(formatISO(props.startDate), formatISO(props.endDate))}
                filterModel={filterModel}
                changedFilter={(model) => {
                    if (model.items[0]) {
                        updatePageState('filterProperty', model.items[0].columnField)
                        updatePageState('filterValues', model.items[0].value)
                        updatePageState('filterOperator', model.items[0].operatorValue)
                    }
                }}
                changedSorting={() => {
                }}
                showButtons={!!showButtons}
                showEditorEmail={props.isPopUP !== undefined || props.isConflictTable !== undefined}
            />
        </Stack>
    )
}

export default WeekOverviewGrid;