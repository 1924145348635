import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {UNKNOWN_ROUTE} from "../constants";
import backgroundImage from "../img/koralm_panorama_bg.png";
import {WebAppPage} from "./Types";

interface UnknownPageProps {
    changedSelectedPage: (value: WebAppPage) => void;
}

const UnknownPage = (props: UnknownPageProps) => {
    const [dots, setDots] = useState('.');
    const delay = 3000;
    const interval = 500;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            props.changedSelectedPage(WebAppPage.ListView)
        }, delay);

        const intervalId = setInterval(() => {
            setDots(prevDots => {
                if (prevDots === '...') {
                    return '.';
                } else if (prevDots === '..') {
                    return '...';
                } else {
                    return '..';
                }
            });
        }, interval);

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Typography variant="h1">{UNKNOWN_ROUTE}{dots}</Typography>
        </Box>
    );
};

export default UnknownPage;
