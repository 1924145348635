import React, {useEffect, useState} from "react";
import {
    ChainageBasedOpenLineLocationDescription,
    ContactPerson,
    KoralmLocation,
    LatitudinalDirectedOpenLineConstruction,
    LatitudinalDirectedOutdoorConstructionWorkRegistration,
    LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp,
    LatitudinalDirectedTunnelObjectDescriptionWithId,
    LatitudinalDirectedTunnelWorkRegistration,
    LatitudinalDirectedTunnelWorkRegistrationApiFp,
    LatitudinalDirection,
    OutdoorUniqueConstructionWorkRegistrationApiFp,
    OutdoorWorkRegistration,
    OutdoorWorkRegistrationApiFp,
    ProcessStatus,
    RailAreaDescription,
    RailTrackStatus,
    ShiftSchemaDuringDay,
    TrainStationApiFp,
    TrainStationInTunnel,
    Tube,
    TunnelObject,
    TunnelObjectDescriptionWithIdWithNumericId,
    TunnelWorkRegistrationWithNumericLocationID,
    TunnelWorkRegistrationWithNumericLocationIDApiFp,
    UniqueOpenLineConstruction,
    UniqueOutdoorConstructionWorkRegistration,
    WorkDirection,
    WorkDuringWeekend,
    ZoneBasedTubeSections,
    ZoneBasedTunnelTubeWorkRegistration,
    ZoneBasedTunnelTubeWorkRegistrationApiFp,
} from "../../generated";
import SortingGrid from "./SortingGrid"
import {Range} from "react-date-range";
import {
    getShiftHours,
    ObjectType,
    OutdoorBuildingTypes,
    OutdoorWorkConstructions,
    TunnelWorkRegistration,
    WebAppPage,
    WorkRegistration
} from "../../utils/Types";
import {CREATE, EMPTY_STRING_PLACEHOLDER, NEW_WORK_REGISTRATION, SAFE_BUTTON_TEXT} from "../../constants";
import UserService from "../../services/UserService";
import {PermissionManager} from "../../utils/PermissionManager";
import {formatISO, min, max} from "date-fns";
import {railDescriptionService} from "../../services/railDescriptionsProvider";

interface WorkRegistrationProps {
    workObject?: WorkRegistration;
    changedSelectedPage: (value: WebAppPage) => void;
}

function WorkRegistrationEditor(props: WorkRegistrationProps) {
    const newStartDate = new Date();
    const newEndDate = new Date();
    newStartDate.setHours(getShiftHours(ShiftSchemaDuringDay.Day).start, 0, 0);
    newEndDate.setHours(getShiftHours(ShiftSchemaDuringDay.Day).end, 0, 0);

    const defaultWorkObject: TunnelWorkRegistrationWithNumericLocationID = {
        IsMilestone: false,
        EditorEmail: UserService.getEmail(),
        IsDeleted: false,
        ContractorId: "",
        End: formatISO(newStartDate),
        LocationDescription: {
            Location: KoralmLocation.Tunnel,
            ObjectDescription: {
                TunnelObject: TunnelObject.CrossCut,
                AreaDescription: {
                    ID: 1
                }
            }
        },
        Name: "",
        PartOfWeekProgram: false,
        PersonCount: 0,
        ShiftSchemaDuringDay: ShiftSchemaDuringDay.Day,
        Start: formatISO(newStartDate),
        Status: ProcessStatus.ChangeSubmitted,
        RailTrackStatus: RailTrackStatus.Free,
    }
    const [IsNewWorkRegistration, setIsNewWorkRegistration] = useState(!props.workObject)

    // Initial Values
    const initialWorkRegistration = props.workObject ? props.workObject : defaultWorkObject;
    let initialMenuSelection: ObjectType;
    if (Object.hasOwn(initialWorkRegistration.LocationDescription.ObjectDescription, 'Tube')) {
        initialMenuSelection = (initialWorkRegistration.LocationDescription.ObjectDescription as ZoneBasedTubeSections).Tube;
    } else if (Object.hasOwn(initialWorkRegistration.LocationDescription, 'RailNumber')) {
        initialMenuSelection = (initialWorkRegistration.LocationDescription as ChainageBasedOpenLineLocationDescription).RailNumber;
    } else {
        initialMenuSelection = (initialWorkRegistration.LocationDescription.ObjectDescription as TunnelObjectDescriptionWithIdWithNumericId).TunnelObject;
    }

    let initialValueLeft: any = 14;
    let initialValueRight: any = 15;
    let initialOutdoorConstruction: OutdoorBuildingTypes = "";
    let initialIsOnOuterConstruction: boolean = false;
    let initialLatitudinalDirectionConstruction: LatitudinalDirection = LatitudinalDirection.East;
    if (Object.hasOwn(initialWorkRegistration.LocationDescription.ObjectDescription, 'TunnelObject')) {
        if ((initialWorkRegistration as TunnelWorkRegistration).LocationDescription.ObjectDescription.TunnelObject === TunnelObject.Portal
            || (initialWorkRegistration as TunnelWorkRegistration).LocationDescription.ObjectDescription.TunnelObject === TunnelObject.VentilationShaft) {
            initialValueRight = (initialWorkRegistration.LocationDescription.ObjectDescription as LatitudinalDirectedTunnelObjectDescriptionWithId).AreaDescription.ID
            if ((initialWorkRegistration as TunnelWorkRegistration).LocationDescription.ObjectDescription.TunnelObject === TunnelObject.VentilationShaft) {
                initialIsOnOuterConstruction = !!(initialWorkRegistration.LocationDescription.ObjectDescription as LatitudinalDirectedTunnelObjectDescriptionWithId).IsOnOuterConstruction;
            }
        } else if ((initialWorkRegistration as TunnelWorkRegistration).LocationDescription.ObjectDescription.TunnelObject === TunnelObject.CrossCut
            || (initialWorkRegistration as TunnelWorkRegistration).LocationDescription.ObjectDescription.TunnelObject === TunnelObject.EscapeGallery
            || (initialWorkRegistration as TunnelWorkRegistration).LocationDescription.ObjectDescription.TunnelObject === TunnelObject.EmergencyStop) {
            initialValueRight = (initialWorkRegistration.LocationDescription.ObjectDescription as TunnelObjectDescriptionWithIdWithNumericId).AreaDescription.ID
        } else if ((initialWorkRegistration as TunnelWorkRegistration).LocationDescription.ObjectDescription.TunnelObject === TunnelObject.Tube) {
            initialValueRight = (initialWorkRegistration.LocationDescription.ObjectDescription as ZoneBasedTubeSections).AreaDescription.End
            initialValueLeft = (initialWorkRegistration.LocationDescription.ObjectDescription as ZoneBasedTubeSections).AreaDescription.Start
        } else {
            console.error("unknown TunnelObject")
        }
    } else if (Object.hasOwn(initialWorkRegistration.LocationDescription, 'RailNumber')) {
        initialValueLeft = (initialWorkRegistration.LocationDescription.ObjectDescription.AreaDescription as RailAreaDescription).Start.siValue
        initialValueRight = (initialWorkRegistration.LocationDescription.ObjectDescription.AreaDescription as RailAreaDescription).End.siValue
        if ((initialWorkRegistration as OutdoorWorkConstructions).Construction) {
            initialOutdoorConstruction = (initialWorkRegistration as OutdoorWorkConstructions).Construction;
            if ((initialWorkRegistration as LatitudinalDirectedOutdoorConstructionWorkRegistration).LatitudinalDirection) {
                initialLatitudinalDirectionConstruction = (initialWorkRegistration as LatitudinalDirectedOutdoorConstructionWorkRegistration).LatitudinalDirection
            } else {
                initialLatitudinalDirectionConstruction = railDescriptionService.uniqueOpenLineConstructionToLatitudinalDirection[(initialWorkRegistration as UniqueOutdoorConstructionWorkRegistration).Construction]
            }
        }
    }

    const [ls_Alignment, setLs_Alignment] = React.useState<KoralmLocation>(initialWorkRegistration.LocationDescription.Location);
    const [ls_SelectedMenuItemChanged, setLs_SelectedMenuItemChanged] = React.useState<ObjectType>(initialMenuSelection);
    const [ls_InputFieldLeftChanged, setLs_InputFieldLeftChanged] = React.useState(initialValueLeft.toString())
    const [ls_InputFieldRightChanged, setLs_InputFieldRightChanged] = React.useState(initialValueRight.toString())

    const [ls_isGoingWestChanged, setLs_isGoingWestChanged] = React.useState(Object.hasOwn(initialWorkRegistration, 'WorkDirection') ?
        (initialWorkRegistration as ZoneBasedTunnelTubeWorkRegistration | OutdoorWorkRegistration).WorkDirection : WorkDirection.East)
    const [ls_railTrackStatus, setLs_railTrackStatus] = React.useState<RailTrackStatus>(Object.hasOwn(initialWorkRegistration, 'RailTrackStatus') ?
        (initialWorkRegistration as WorkRegistration).RailTrackStatus : RailTrackStatus.Free)

    const [outdoorBuilding, setOutdoorBuilding] = useState<OutdoorBuildingTypes>(initialOutdoorConstruction)
    const [onOuterConstruction, setOnOuterConstruction] = useState(initialIsOnOuterConstruction);
    const [latitudinalDirectionConstruction, setLatitudinalDirectionConstruction] = useState(initialLatitudinalDirectionConstruction)

    //DateRange-Pickers
    const startDate = new Date(initialWorkRegistration.Start);
    const endDate = new Date(initialWorkRegistration.End);
    const initialRanges: Range[] = [{
        startDate: min([startDate, endDate]),
        endDate: max([startDate, endDate]),
    }];
    const [dp_dateRangePicker, setDp_dateRangePicker] = React.useState(initialRanges)

    // contractorOverviewComponents
    const contactPersonFromUser: ContactPerson = {
        FirstName: UserService.getFirstName() || "",
        LastName: UserService.getLastName() || "",
        EMailAddress: UserService.getEmail() || "",
        PhoneNumber: UserService.getPhoneNumber() || "",
    }

    const [trainStations, setTrainStations] = useState<TrainStationInTunnel[]>([])

    async function fetchTrainStations(trainStationStartDate: Date, trainStationEndDate: Date) {
        await TrainStationApiFp().trainStationGetIntervalAndGetData(
            trainStationStartDate.toDateString(),
            trainStationEndDate.toDateString()
        ).then((trainStations) => setTrainStations(trainStations));
    }

    useEffect(() => {
        void fetchTrainStations(
            dp_dateRangePicker[0].startDate instanceof Date && !isNaN(dp_dateRangePicker[0].startDate.valueOf()) ? dp_dateRangePicker[0].startDate : new Date(),
            dp_dateRangePicker[0].endDate instanceof Date && !isNaN(dp_dateRangePicker[0].endDate.valueOf()) ? dp_dateRangePicker[0].endDate : new Date()
        )
    }, [dp_dateRangePicker])

    useEffect(() => {
        setIsNewWorkRegistration(!props.workObject)
    }, [props.workObject, IsNewWorkRegistration])
    const [companyTextField, setCompanyTextField] = React.useState(IsNewWorkRegistration && PermissionManager.NewWorkRegistration.companyAuto ? UserService.getCompanyName() as string : initialWorkRegistration.ContractorId ? initialWorkRegistration.ContractorId : '');
    const [firstNameTextField, setFirstNameTextField] = React.useState(IsNewWorkRegistration ? contactPersonFromUser.FirstName : initialWorkRegistration.ContactPerson ? initialWorkRegistration.ContactPerson.FirstName : EMPTY_STRING_PLACEHOLDER);
    const [lastNameTextField, setLastNameTextField] = React.useState(IsNewWorkRegistration ? contactPersonFromUser.LastName : initialWorkRegistration.ContactPerson ? initialWorkRegistration.ContactPerson.LastName : EMPTY_STRING_PLACEHOLDER);
    const [telephoneTextField, setTelephoneTextField] = React.useState(IsNewWorkRegistration ? contactPersonFromUser.PhoneNumber : initialWorkRegistration.ContactPerson ? initialWorkRegistration.ContactPerson.PhoneNumber : EMPTY_STRING_PLACEHOLDER);
    const [mailTextField, setMailTextField] = React.useState(IsNewWorkRegistration ? contactPersonFromUser.EMailAddress : initialWorkRegistration.ContactPerson ? initialWorkRegistration.ContactPerson.EMailAddress : EMPTY_STRING_PLACEHOLDER);
    const [editorMailTextField, setEditorMailTextField] = React.useState(UserService.getEmail());
    const [workStepTextField, setWorkStepTextField] = React.useState(initialWorkRegistration ? initialWorkRegistration.Name : '');
    const [notesTextField, setNotesTextField] = React.useState(initialWorkRegistration ? initialWorkRegistration.Notes ? initialWorkRegistration.Notes : '' : '');

    // saveButtons
    const saveButtonText = IsNewWorkRegistration ? NEW_WORK_REGISTRATION + CREATE : SAFE_BUTTON_TEXT;
    const [saveButtonValue, setSaveButtonValue] = React.useState(saveButtonText)
    const [processStatus, setProcessStatus] = React.useState(initialWorkRegistration.Status)
    const [manualStatusChange, setManualStatusChange] = useState(false);
    const [wasEdited, setWasEdited] = React.useState(false);

    // new properties
    const [personCount, setPersonCount] = React.useState(initialWorkRegistration ? initialWorkRegistration.PersonCount : 0)
    const [shiftSchemaDuringDay, setShiftSchemaDuringDay] = React.useState(initialWorkRegistration ? initialWorkRegistration.ShiftSchemaDuringDay : ShiftSchemaDuringDay.Day)
    const [continuesDuringWeekend, setContinuesDuringWeekend] = React.useState<WorkDuringWeekend | null>(initialWorkRegistration ? initialWorkRegistration.ContinuesDuringWeekend ? initialWorkRegistration.ContinuesDuringWeekend : null : null)
    const [isPartOfWeekProgram, setIsPartOfWeekProgram] = React.useState<boolean>(initialWorkRegistration ? initialWorkRegistration.PartOfWeekProgram ? initialWorkRegistration.PartOfWeekProgram : !!PermissionManager.NewWorkRegistration.autoPartOfWeekProgram : !!PermissionManager.NewWorkRegistration.autoPartOfWeekProgram)
    const [thirdLevelOutline, setThirdLevelOutline] = React.useState<string | null>(initialWorkRegistration ? initialWorkRegistration.ThirdLevelOutline ? initialWorkRegistration.ThirdLevelOutline as string : null : null);
    const [isMilestone, setIsMilestone] = React.useState<boolean>(initialWorkRegistration ? initialWorkRegistration.IsMilestone : false);
    // initialStates
    const [initialLs_Alignment] = React.useState(ls_Alignment)
    const [initialLs_SelectedMenuItemChanged] = React.useState(ls_SelectedMenuItemChanged)
    const [initialLs_InputFieldLeftChanged] = React.useState(ls_InputFieldLeftChanged)
    const [initialLs_InputFieldRightChanged] = React.useState(ls_InputFieldRightChanged)
    const [initialLs_isGoingWestChanged] = React.useState(ls_isGoingWestChanged)
    const [initialLs_railTrackStatus] = React.useState(ls_railTrackStatus)
    const [initialDp_dateRangePicker] = React.useState(dp_dateRangePicker)
    const [initialCompanyTextField] = React.useState(companyTextField)
    const [initialFirstNameTextField] = React.useState(firstNameTextField)
    const [initialLastNameTextField] = React.useState(lastNameTextField)
    const [initialTelephoneTextField] = React.useState(telephoneTextField)
    const [initialMailTextField] = React.useState(mailTextField)
    const [initialEditorEmailTextField] = React.useState(editorMailTextField)
    const [initialWorkStepTextField] = React.useState(workStepTextField)
    const [initialNotesTextField] = React.useState(notesTextField)
    const [initialPersonCount] = React.useState(personCount)
    const [initialShiftSchemaDuringDay] = React.useState(shiftSchemaDuringDay)
    const [initialContinuesDuringWeekend] = React.useState(continuesDuringWeekend)
    const [initialIsPartOfWeekProgram] = React.useState(isPartOfWeekProgram)
    const [initialOutdoorBuilding] = React.useState(outdoorBuilding)
    const [initialOnOuterConstruction] = React.useState(onOuterConstruction)
    const [initialProcessStatus] = React.useState(processStatus)
    const [initialThirdLevelOutline] = React.useState(thirdLevelOutline)
    const [initialIsMilestone] = React.useState(isMilestone)

    const helper = (d: Date | undefined, o: Date) => d instanceof Date && !isNaN(d.valueOf()) ? d : o;
    const helperStart  = helper(dp_dateRangePicker[0].startDate, newStartDate) ;
    const helperEnd = helper(dp_dateRangePicker[0].endDate, newStartDate);

    const workRegistration: any = {
        Name: workStepTextField,
        ContractorId: companyTextField,
        ContactPerson: {
            FirstName: firstNameTextField,
            LastName: lastNameTextField,
            EMailAddress: mailTextField,
            PhoneNumber: telephoneTextField
        },
        Notes: notesTextField,
        Start: formatISO(min([helperStart, helperEnd])),
        End: formatISO(max([helperStart, helperEnd])),
        Status: processStatus,
        ContinuesDuringWeekend: continuesDuringWeekend,
        ShiftSchemaDuringDay: shiftSchemaDuringDay,
        PersonCount: personCount,
        PartOfWeekProgram: isPartOfWeekProgram,
        ThirdLevelOutline: thirdLevelOutline,
        isDeleted: false,
        isMilestone: isMilestone,
        EditorEmail: editorMailTextField,
    };

    async function onSaveButtonPressComputeSavingLogic() {
        if (initialWorkRegistration.UUID !== undefined) {
            workRegistration['UUID'] = initialWorkRegistration.UUID
        }
        if (ls_Alignment === KoralmLocation.Tunnel) {
            switch (ls_SelectedMenuItemChanged) {
                case Tube.North:
                case Tube.South:
                    workRegistration['LocationDescription'] =
                        {
                            Location: KoralmLocation.Tunnel,
                            ObjectDescription: {
                                Tube: ls_SelectedMenuItemChanged,
                                TunnelObject: TunnelObject.Tube,
                                AreaDescription: {
                                    Start: Number(ls_InputFieldLeftChanged),
                                    End: Number(ls_InputFieldRightChanged)
                                }
                            }
                        };
                    workRegistration['WorkDirection'] = ls_isGoingWestChanged;
                    workRegistration['RailTrackStatus'] = ls_railTrackStatus;
                    await ZoneBasedTunnelTubeWorkRegistrationApiFp()
                        .zoneBasedTunnelTubeWorkRegistrationSaveAndGetData(workRegistration as ZoneBasedTunnelTubeWorkRegistration);
                    break;
                case TunnelObject.CrossCut:
                    workRegistration['LocationDescription'] =
                        {
                            Location: KoralmLocation.Tunnel,
                            ObjectDescription: {
                                TunnelObject: TunnelObject.CrossCut,
                                AreaDescription: {
                                    ID: Number(ls_InputFieldRightChanged)
                                }
                            }
                        };
                    workRegistration['RailTrackStatus'] = ls_railTrackStatus;
                    await TunnelWorkRegistrationWithNumericLocationIDApiFp()
                        .tunnelWorkRegistrationWithNumericLocationIDSaveAndGetData(workRegistration as TunnelWorkRegistrationWithNumericLocationID);
                    break;
                case TunnelObject.EscapeGallery:
                    workRegistration['LocationDescription'] =
                        {
                            Location: KoralmLocation.Tunnel,
                            ObjectDescription: {
                                TunnelObject: TunnelObject.EscapeGallery,
                                AreaDescription: {
                                    ID: Number(ls_InputFieldRightChanged)
                                }
                            }
                        };
                    workRegistration['RailTrackStatus'] = RailTrackStatus.Free;
                    await TunnelWorkRegistrationWithNumericLocationIDApiFp()
                        .tunnelWorkRegistrationWithNumericLocationIDSaveAndGetData(workRegistration as TunnelWorkRegistrationWithNumericLocationID);
                    break;
                case TunnelObject.EmergencyStop:
                    workRegistration['LocationDescription'] =
                        {
                            Location: KoralmLocation.Tunnel,
                            ObjectDescription: {
                                TunnelObject: TunnelObject.EmergencyStop,
                                AreaDescription: {
                                    ID: Number(ls_InputFieldRightChanged)
                                }
                            }
                        };
                    workRegistration['RailTrackStatus'] = RailTrackStatus.Free;
                    await TunnelWorkRegistrationWithNumericLocationIDApiFp()
                        .tunnelWorkRegistrationWithNumericLocationIDSaveAndGetData(workRegistration as TunnelWorkRegistrationWithNumericLocationID);
                    break;
                case TunnelObject.VentilationShaft:
                    workRegistration['LocationDescription'] =
                        {
                            Location: KoralmLocation.Tunnel,
                            ObjectDescription: {
                                TunnelObject: TunnelObject.VentilationShaft,
                                IsOnOuterConstruction: onOuterConstruction,
                                AreaDescription: {
                                    ID: ls_InputFieldRightChanged
                                }
                            }
                        };
                    workRegistration['RailTrackStatus'] = ls_railTrackStatus;
                    await LatitudinalDirectedTunnelWorkRegistrationApiFp()
                        .latitudinalDirectedTunnelWorkRegistrationSaveAndGetData(workRegistration as LatitudinalDirectedTunnelWorkRegistration)
                    break;
                case TunnelObject.Portal:
                    workRegistration['LocationDescription'] =
                        {
                            Location: KoralmLocation.Tunnel,
                            ObjectDescription: {
                                TunnelObject: TunnelObject.Portal,
                                AreaDescription: {
                                    ID: ls_InputFieldRightChanged
                                }
                            }
                        };
                    workRegistration['RailTrackStatus'] = ls_railTrackStatus;
                    await LatitudinalDirectedTunnelWorkRegistrationApiFp()
                        .latitudinalDirectedTunnelWorkRegistrationSaveAndGetData(workRegistration as LatitudinalDirectedTunnelWorkRegistration)
                    break;
            }
        } else {
            workRegistration['RailTrackStatus'] = ls_railTrackStatus;
            workRegistration['LocationDescription'] =
                {
                    Location: KoralmLocation.OpenLine,
                    ObjectDescription: {
                        AreaDescription: {
                            Start: {
                                siValue: Number(ls_InputFieldLeftChanged)
                            },
                            End: {
                                siValue: Number(ls_InputFieldRightChanged)
                            }
                        }
                    },
                    RailNumber: ls_SelectedMenuItemChanged
                };
            workRegistration['WorkDirection'] = ls_isGoingWestChanged;
            if (outdoorBuilding === "") {
                await OutdoorWorkRegistrationApiFp().outdoorWorkRegistrationSaveAndGetData(workRegistration as OutdoorWorkRegistration);
            } else {
                workRegistration['Construction'] = outdoorBuilding;
                if (Object.hasOwn(UniqueOpenLineConstruction, outdoorBuilding)) {
                    await OutdoorUniqueConstructionWorkRegistrationApiFp().outdoorUniqueConstructionWorkRegistrationSaveAndGetData(workRegistration as UniqueOutdoorConstructionWorkRegistration)
                } else if (Object.hasOwn(LatitudinalDirectedOpenLineConstruction, outdoorBuilding)) {
                    workRegistration['LatitudinalDirection'] = latitudinalDirectionConstruction
                    await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp().latitudinalDirectedOutdoorConstructionWorkRegistrationSaveAndGetData(workRegistration as LatitudinalDirectedOutdoorConstructionWorkRegistration)
                }
            }
        }
        setSaveButtonValue("Gespeichert")
        setWasEdited(false)
    }

    useEffect(() => {
            if (initialWorkRegistration !== undefined && workStepTextField.length !== 0 && companyTextField.length !== 0) {
                if (initialLs_Alignment !== ls_Alignment
                    || initialLs_SelectedMenuItemChanged !== ls_SelectedMenuItemChanged
                    || (initialLs_InputFieldLeftChanged.toString() !== ls_InputFieldLeftChanged.toString())
                    || (initialLs_InputFieldRightChanged.toString() !== ls_InputFieldRightChanged.toString())
                    || initialLs_isGoingWestChanged !== ls_isGoingWestChanged || initialLs_railTrackStatus !== ls_railTrackStatus
                    || formatISO(initialDp_dateRangePicker[0].startDate!) !== formatISO(dp_dateRangePicker[0].startDate!)
                    || formatISO(initialDp_dateRangePicker[0].endDate!) !== formatISO(dp_dateRangePicker[0].endDate!)
                    || initialCompanyTextField !== companyTextField
                    || initialFirstNameTextField !== firstNameTextField
                    || initialLastNameTextField !== lastNameTextField
                    || initialMailTextField !== mailTextField
                    || initialEditorEmailTextField !== editorMailTextField
                    || initialTelephoneTextField !== telephoneTextField
                    || initialWorkStepTextField !== workStepTextField
                    || initialNotesTextField !== notesTextField
                    || initialPersonCount !== personCount
                    || initialShiftSchemaDuringDay !== shiftSchemaDuringDay
                    || initialContinuesDuringWeekend !== continuesDuringWeekend
                    || initialIsPartOfWeekProgram !== isPartOfWeekProgram
                    || initialOutdoorBuilding !== outdoorBuilding
                    || initialOnOuterConstruction !== onOuterConstruction
                    || initialIsMilestone !== isMilestone
                    || initialProcessStatus !== processStatus
                    || (initialThirdLevelOutline !== thirdLevelOutline && PermissionManager.WorkRegistrationEditing.changeThirdLevelOutline())
                    || initialLatitudinalDirectionConstruction !== latitudinalDirectionConstruction) {
                    if (!IsNewWorkRegistration) {
                        setEditorMailTextField(contactPersonFromUser.EMailAddress)
                        if (!manualStatusChange) {
                            if (PermissionManager.ListView.autoApprovedStatusChange()) {
                                setProcessStatus(ProcessStatus.ChangeApproved)
                            } else {
                                setProcessStatus(ProcessStatus.ChangeSubmitted)
                            }
                        }
                    }
                    setWasEdited(true)
                    setSaveButtonValue(saveButtonText)
                } else {
                    setWasEdited(false)
                    if (initialProcessStatus !== processStatus) setWasEdited(true)
                }
            } else {
                setWasEdited(false)
            }
        },
        [
            ls_Alignment,
            ls_SelectedMenuItemChanged,
            ls_InputFieldLeftChanged,
            ls_InputFieldRightChanged,
            ls_isGoingWestChanged,
            ls_railTrackStatus,
            dp_dateRangePicker,
            companyTextField,
            firstNameTextField,
            lastNameTextField,
            mailTextField,
            editorMailTextField,
            telephoneTextField,
            workStepTextField,
            notesTextField,
            processStatus,
            thirdLevelOutline,
            personCount,
            shiftSchemaDuringDay,
            continuesDuringWeekend,
            isPartOfWeekProgram,
            outdoorBuilding,
            onOuterConstruction,
            latitudinalDirectionConstruction,
            isMilestone,
        ]);


    return (
        <SortingGrid
            changedSelectedPage={props.changedSelectedPage}
            trainStationsData={trainStations}
            lengthSelectorArgs={{
                locationType: ls_Alignment,
                selectedObjectType: ls_SelectedMenuItemChanged,
                unitInputFieldSecondLevel: ls_InputFieldLeftChanged,
                unitInputFieldFirstLevel: ls_InputFieldRightChanged,
                isGoingWest: ls_isGoingWestChanged,
                railTrackStatus: ls_railTrackStatus,
                outdoorBuilding: outdoorBuilding,
                isNewWorkRegistration: IsNewWorkRegistration,
                isOnOuterConstruction: onOuterConstruction,
                latitudinalDirectionConstruction: latitudinalDirectionConstruction,
                changedLocationType: (value: KoralmLocation) => setLs_Alignment(value),
                changedSelectedObjectType: (value) => setLs_SelectedMenuItemChanged(value),
                changedUnitInputFieldLeft: (value) => setLs_InputFieldLeftChanged(value),
                changedUnitInputFieldRight: (value) => setLs_InputFieldRightChanged(value),
                changedIsGoingWest: (value) => setLs_isGoingWestChanged(value),
                changedRailTrackStatus: (value) => setLs_railTrackStatus(value),
                changedOutdoorBuilding: (value) => setOutdoorBuilding(value),
                changedIsOnOuterConstruction: (value) => setOnOuterConstruction(value),
                changedLatitudinalDirectionConstruction: (value) => setLatitudinalDirectionConstruction(value),
            }}
            datePickerArgs={{
                dateRange: dp_dateRangePicker,
                changedDataRange: (value) => setDp_dateRangePicker(value),
                continuesDuringWeekend: continuesDuringWeekend,
                changedShiftSchemaDuringDay: (value) => setShiftSchemaDuringDay(value),
                shiftSchemaDuringDay: shiftSchemaDuringDay,
                changedContinuesDuringWeekend: (value) => setContinuesDuringWeekend(value),
                isNewWorkRegistration: IsNewWorkRegistration,
                partOfWeekProgram: isPartOfWeekProgram ?? false,
                changedPartOfWeekProgram: (value) => setIsPartOfWeekProgram(value),
                isMilestone: isMilestone,
                changedIsMilestone: (value) => setIsMilestone(value),
            }}
            contractorArgs={{
                isNewWorkRegistration: IsNewWorkRegistration,
                company: companyTextField,
                changedCompanyTextField: (value) => setCompanyTextField(value),
                firstName: firstNameTextField,
                changedFirstNameTextField: (value) => setFirstNameTextField(value),
                lastName: lastNameTextField,
                changedLastNameTextField: (value) => setLastNameTextField(value),
                telephone: telephoneTextField,
                changedTelephoneTextField: (value) => setTelephoneTextField(value),
                mail: mailTextField,
                changedMailTextField: (value) => setMailTextField(value),
                workStep: workStepTextField,
                changedWorkStepTextField: (value) => setWorkStepTextField(value),
                notes: notesTextField,
                changedNotesTextField: (value) => setNotesTextField(value),
                personCount: personCount,
                changedPersonCount: (value) => setPersonCount(value),
                thirdLevelOutline: thirdLevelOutline,
                changedThirdLevelOutline: (value) => setThirdLevelOutline(value),
            }}
            saveButtonArgs={{
                saveValue: saveButtonValue,
                changedSaveButton: onSaveButtonPressComputeSavingLogic,
                status: processStatus,
                changedStatus: (status) => {
                    setProcessStatus(status);
                    setManualStatusChange(true);
                },
                wasEdited: wasEdited,
                isNewWorkRegistration: IsNewWorkRegistration,
                location: ls_Alignment,
                openLineLocation: latitudinalDirectionConstruction,
                range: dp_dateRangePicker,
                object: ls_SelectedMenuItemChanged,
                area: ls_InputFieldRightChanged,
                area2: ls_InputFieldLeftChanged,
                uuId: initialWorkRegistration.UUID ?? "",
            }}
        />
    );
}

export default WorkRegistrationEditor;