import {getWorkRegistrationObjectType, TunnelWorkRegistration} from "../../utils/Types";
import {
    CrossCutDescription,
    EmergencyStopDescription,
    KoralmLocation,
    LatitudinalDirectedDescription,
    LatitudinalDirectedOpenLineConstruction,
    LatitudinalDirectedTunnelWorkRegistration,
    LatitudinalDirection,
    OutdoorWorkRegistration,
    ProcessStatus,
    RailNumber,
    RailTrackStatus,
    ShiftSchemaDuringDay,
    TrainStationInTunnel,
    Tube,
    TunnelObject,
    TunnelWorkRegistrationWithNumericLocationID,
    UniqueOpenLineConstruction,
    WorkDirection,
    ZoneBasedTunnelTubeWorkRegistration,
    ZoneDescription
} from "../../generated";
import {Dialog, DialogContent, DialogTitle, Stack} from "@mui/material";
import {Circle, Group, Image, Layer, Rect, Stage, Text} from "react-konva";
import {
    CrossCutColor,
    getIbnOrRailTrackColor,
    RailTrackPrimaryColor,
    RailTrackSecondaryColor,
} from "../../utils/Colors";
import {LocalizationService} from "../../utils/Localization";
import React, {useEffect, useRef, useState} from "react";
import UserService from "../../services/UserService";
import {tunnelConstructionService} from "../../services/tunnelConstructionsDescriptionsProvider";
import {translationsService} from "../../services/translationsProvider";
import useImage from "use-image";
import {EMPTY_STOP_NOTE} from "../../constants";
import {formatDateStringToGermanDateFormat} from "../../utils/DateFormatting";
import Konva from "konva";
import WeekOverviewGrid from "../WeekOverviewPage/WeekOverviewGrid";
import tramStopSrc from '../../img/tram_stop.png';
import StripesBlocked from '../../img/StripesBlocked.png';
import StripesBlockedSideWork from '../../img/BlockedSideWork.png';
import StripesFreeBlocked from '../../img/FreeBlocked.png';
import StripesFreeSideWork from '../../img/FreeSideWork.png';
import StripesFree from '../../img/StripesFree.png';
import StripesSideWork from '../../img/StripesSideWork.png';
import Blocked from '../../img/Blocked.png';
import FreeSideBlocked from '../../img/FreeSideBlocked.png';
import StripesIbn from '../../img/StripesIbn.png';
import IbnFree from '../../img/IbnFree.png';
import IbnSideWork from '../../img/IbnSideWork.png';
import IbnBlocked from '../../img/IbnBlocked.png';
import FreeBlockedIbn from '../../img/FreeBlockedIbn.png';
import FreeSideWorkBlockedIbn from '../../img/FreeSideWorkBlockedIbn.png';
import FreeSideWorkIbn from '../../img/FreeSideWorkIbn.png';
import SideWorkBlockedIbn from '../../img/SideWorkBlockedIbn.png';
import {useCurrentWindowSize} from "../../utils/Exports";

interface TunnelVisualizationProps {
    tunnelWorkRegistration: Array<TunnelWorkRegistration>;
    trainStationInTunnelData: TrainStationInTunnel[];
    changedStageRef: (value: Konva.Stage | null) => void;
}

export function TunnelVisualization(props: TunnelVisualizationProps) {

    const [StripesBlockedImage] = useImage(StripesBlocked);
    const [StripesBlockedSideWorkImage] = useImage(StripesBlockedSideWork);
    const [StripesFreeBlockedImage] = useImage(StripesFreeBlocked);
    const [StripesFreeSideWorkImage] = useImage(StripesFreeSideWork);
    const [StripesFreeImage] = useImage(StripesFree);
    const [StripesSideWorkImage] = useImage(StripesSideWork);
    const [BlockedImage] = useImage(Blocked);
    const [FreeSideBlockedImage] = useImage(FreeSideBlocked);
    const [StripesIbnImage] = useImage(StripesIbn);
    const [IbnFreeImage] = useImage(IbnFree);
    const [IbnSideWorkImage] = useImage(IbnSideWork);
    const [IbnBlockedImage] = useImage(IbnBlocked);

    const [FreeBlockedIbnImage] = useImage(FreeBlockedIbn);
    const [FreeSideWorkBlockedIbnImage] = useImage(FreeSideWorkBlockedIbn);
    const [FreeSideWorkIbnImage] = useImage(FreeSideWorkIbn);
    const [SideWorkBlockedIbnImage] = useImage(SideWorkBlockedIbn);

    const placeholderWorkRegistration: OutdoorWorkRegistration = {
        IsMilestone: false,
        EditorEmail: UserService.getEmail(),
        IsDeleted: false,
        Start: "",
        End: "",
        ContinuesDuringWeekend: undefined,
        ShiftSchemaDuringDay: ShiftSchemaDuringDay.Night,
        ContractorId: "",
        WorkDirection: WorkDirection.East,
        PersonCount: 0,
        Status: ProcessStatus.Done,
        PartOfWeekProgram: false,
        LocationDescription: {
            Location: KoralmLocation.OpenLine,
            ObjectDescription: {
                AreaDescription: {
                    Start: {
                        siValue: 50,
                    },
                    End: {
                        siValue: 50,
                    },
                },
            },
            RailNumber: RailNumber.Eins,
        },
        RailTrackStatus: RailTrackStatus.Free,
        Name: ""
    }

    const canvasId: string = "tunnel-visualization-canvas";
    const localStageRef = useRef<Konva.Stage>(null);
    useEffect(() => {
        if (localStageRef.current) {
            props.changedStageRef(localStageRef.current);
        } else {
            props.changedStageRef(null);
        }
    }, [localStageRef])

    const tunnelDraw: JSX.Element[] = [];
    const [tramStop] = useImage(tramStopSrc);

    const [tooltipText, setTooltipText] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({x: 0, y: 0});

    const TunnelLength: number = (tunnelConstructionService.portalDescriptions[1].ChainageByTube as any).North.siValue - (tunnelConstructionService.portalDescriptions[0].ChainageByTube as any).North.siValue
    const TunnelStartByChainage: number = (tunnelConstructionService.portalDescriptions[0].ChainageByTube as any).North.siValue
    const TunnelScale: number = 5;
    const TunnelVisLength: number = Math.floor(TunnelLength / TunnelScale)
    const TunnelVisOffset: number = 25;

    const TunnelStartPosition: number = TunnelVisOffset;
    const RailNumberTextPosition: number = 40;
    const RailOneY: number = 55;
    const RailTwoY: number = 95;
    const RailWidth: number = 10;
    const FontSize: number = 15;

    const CrossCutWidth: number = 5;

    const EscapeHeight: number = 10;
    const canvasHeight: number = RailTwoY + 95;

    const sortedTunnelWorkRegistrations: TunnelWorkRegistration[] | OutdoorWorkRegistration[] = props.tunnelWorkRegistration[0] === undefined ? [placeholderWorkRegistration] : [...props.tunnelWorkRegistration]
        .sort((a: TunnelWorkRegistration, b: TunnelWorkRegistration): number => {
            const statusA: RailTrackStatus = a.RailTrackStatus !== undefined ? a.RailTrackStatus : RailTrackStatus.Free;
            const statusB: RailTrackStatus = b.RailTrackStatus !== undefined ? b.RailTrackStatus : RailTrackStatus.Free;

            const statusOrder: RailTrackStatus[] = [RailTrackStatus.Free, RailTrackStatus.WorkInRailTrackAreaPeriphery, RailTrackStatus.Blocked];

            const indexOfA: number = statusOrder.indexOf(statusA);
            const indexOfB: number = statusOrder.indexOf(statusB);

            const isInCommissioningA: boolean = a.ThirdLevelOutline !== undefined && translationsService.thirdLevelOutline.map(e => e.ID).includes(a.ThirdLevelOutline as string);
            const isInCommissioningB: boolean = b.ThirdLevelOutline !== undefined && translationsService.thirdLevelOutline.map(e => e.ID).includes(b.ThirdLevelOutline as string);

            if (isInCommissioningA && !isInCommissioningB) return -1;
            if (!isInCommissioningA && isInCommissioningB) return 1;

            return indexOfA - indexOfB;
        });

    const [conflictZoneIndex, setConflictZoneIndex] = useState<number>(0);
    const [conflictTube, setConflictTube] = useState<Tube>(Tube.North);
    const [conflictDialogOpen, setConflictDialogOpen] = useState<boolean>(false);
    const northConflictWorkRegistrations: TunnelWorkRegistration[][] = new Array(tunnelConstructionService.zoneDescriptions.length)
    const southConflictWorkRegistrations: TunnelWorkRegistration[][] = new Array(tunnelConstructionService.zoneDescriptions.length)

    function getCrossCutX(crossCutID: number): number {
        return Math.floor(((tunnelConstructionService.crossCutDescriptions[crossCutID].ChainageByTube as any).North.siValue - TunnelStartByChainage) / TunnelScale) + (crossCutID === 68 ? 5 : 0)
    }

    function getEscapeGalleryX(escapeGalleryId: number): number {
        let XOffset: number = 0;
        switch (escapeGalleryId) {
            case 5:
                XOffset = -2
                break;
            case 6:
                XOffset = -4;
                break;
            case 7:
                XOffset = -7;
                break;
            case 8:
                XOffset = +3
                break;
            case 9:
                XOffset = +1
        }
        return Math.floor((tunnelConstructionService.escapeGalleriesDescriptions[escapeGalleryId].Chainage.siValue - TunnelStartByChainage) / TunnelScale) + XOffset
    }

    function getEscapeGalleryY(escapeGalleryId: number): number {
        return tunnelConstructionService.escapeGalleriesDescriptions[escapeGalleryId].Tube === Tube.South ? RailOneY + EscapeHeight : RailTwoY - EscapeHeight;
    }

    function getEmergencyStopX(emergencyStopID: number): number {
        let position: number = 0;
        if (tunnelConstructionService.emergencyStopDescription[emergencyStopID].Start.TunnelObject === TunnelObject.CrossCut) {
            position = getCrossCutX(tunnelConstructionService.emergencyStopDescription[emergencyStopID].Start.ID - 1)
        } else if (tunnelConstructionService.emergencyStopDescription[emergencyStopID].Start.TunnelObject === TunnelObject.EscapeGallery) {
            position = getEscapeGalleryX(tunnelConstructionService.emergencyStopDescription[emergencyStopID].Start.ID - 1)
        }
        return position;
    }

    function getEmergencyStopEndingX(emergencyStopID: number): number {
        let position: number = 0;
        if (tunnelConstructionService.emergencyStopDescription[emergencyStopID].End.TunnelObject === TunnelObject.CrossCut) {
            position = getCrossCutX(tunnelConstructionService.emergencyStopDescription[emergencyStopID].End.ID - 1)
        } else if (tunnelConstructionService.emergencyStopDescription[emergencyStopID].End.TunnelObject === TunnelObject.EscapeGallery) {
            position = getEscapeGalleryX(tunnelConstructionService.emergencyStopDescription[emergencyStopID].End.ID - 1)
        }
        return position;
    }

    function getVentilationX(location: LatitudinalDirection): number {
        return Math.floor(((location === LatitudinalDirection.East ? (tunnelConstructionService.ventilationShaftsDescriptions[0].ChainageByTube as any).North.siValue : (tunnelConstructionService.ventilationShaftsDescriptions[1].ChainageByTube as any).North.siValue) - TunnelStartByChainage) / TunnelScale)
    }

    function getPortalX(location: LatitudinalDirection): number {
        return location === LatitudinalDirection.East ? TunnelStartPosition : TunnelVisLength - TunnelVisOffset;
    }

    function renderPortals(location: LatitudinalDirection, index: number, color: string): void {
        tunnelDraw.push(
            <Rect
                x={getPortalX(location)}
                y={RailOneY - CrossCutWidth}
                width={CrossCutWidth}
                height={(RailTwoY - RailOneY) + (2 * RailWidth)}
                fill={color}
                key={"PortalRect: " + index + color}
                stroke="black"
                strokeWidth={0.5}
            />
        )
    }

    function renderCrossCuts(index: number, color: string, key: any): void {
        tunnelDraw.push(
            <Rect
                x={getCrossCutX(index) - 2}
                y={RailOneY + RailWidth}
                width={CrossCutWidth}
                height={RailTwoY - RailOneY - RailWidth}
                fill={color}
                key={"CrossCutRect: " + index + " + " + color + " + " + key}
                stroke="black"
                strokeWidth={0.5}
            />
        )
    }

    function renderCrossCutTexts(index: number, color: string, key: any): void {
        tunnelDraw.push(
            <Text
                x={getCrossCutX(index) - 10}
                y={index === 30 ? (RailOneY - 25) : index === 68 ? (RailOneY - 25) : index === 32 ? (RailOneY - 25) : RailTwoY + 20}
                fontSize={FontSize}
                text={LocalizationService.ZoneSelection(index + 1 as unknown as string, false)}
                key={"CrossCutText: " + index + " + " + color + " + " + key}
            />,
        )
    }

    function renderCrossCutPersonCount(index: number, personCount: number): void {
        tunnelDraw.push(
            <Text
                x={getCrossCutX(index) + 13 - (index >= 29 && index <= 34 ? 23 : 0)}
                y={index >= 29 && index <= 34 ? RailTwoY + 35 : index % 2 === 1 ? RailTwoY - 29 : RailTwoY - 15}
                fontSize={FontSize}
                text={personCount ? "PA: " + personCount : ""}
                key={"CrossCutPersonCount: " + index + " + " + Math.random()}
            />
        )
    }

    function renderEmergencyStop(index: number, color: string, uuid?: string): void {
        tunnelDraw.push(
            <Rect
                x={getEmergencyStopX(index)}
                y={RailOneY + EscapeHeight + RailWidth}
                width={getEmergencyStopEndingX(index) - getEmergencyStopX(index)}
                height={EscapeHeight}
                fill={color}
                key={"EmergencyRect - UUID " + uuid + " + Index + Color: " + index + color}
                stroke="black"
                strokeWidth={0.5}
            />
        )
    }

    function renderVentilationShafts(location: LatitudinalDirection, index: number, color: string): void {
        tunnelDraw.push(
            <Rect
                x={getVentilationX(location)}
                y={RailOneY + RailWidth}
                width={CrossCutWidth}
                height={RailTwoY - RailOneY - RailWidth}
                fill={color}
                key={"VentilationRect: " + index + color}
                stroke="black"
                strokeWidth={0.2}
            />,
            <Circle
                x={getVentilationX(location) + CrossCutWidth / 2}
                y={(RailOneY + RailTwoY) / 2 + CrossCutWidth}
                width={CrossCutWidth * 2.5}
                height={CrossCutWidth * 2.5}
                fill={color}
                key={"VentilationCircle: " + index + color}
                stroke="black"
                strokeWidth={0.2}
            />
        )
    }

    function renderEscapeGalleries(index: number, color: string): void {
        tunnelDraw.push(
            <Rect
                x={getEscapeGalleryX(index)}
                y={getEscapeGalleryY(index)}
                width={CrossCutWidth}
                height={RailWidth}
                fill={color}
                key={"EscapeGalleryRect: " + index + color}
                stroke="black"
                strokeWidth={0.5}
            />
        )
    }

    function renderTrainStations(crossCutIndex: number, tube: Tube, departure: string, startDate: string, endDate: string, finalDestination: LatitudinalDirection, notes: string, key: any): void {
        const handleMouseEnter = (event: any) => {
            const pointerPosition = event.target.getAbsolutePosition();
            const adjustedXPositionX = pointerPosition.x > TunnelVisLength - 500 ? pointerPosition.x - 500 : pointerPosition.x
            const adjustedPositionY = pointerPosition.y > canvasHeight - 50 ? pointerPosition.y - 15 : pointerPosition.y < 15 ? pointerPosition.y + 15 : pointerPosition.y
            setTooltipPosition({
                x: adjustedXPositionX,
                y: adjustedPositionY,
            });
            setTooltipText(notes.length > 0 ? notes : EMPTY_STOP_NOTE);

            setTimeout(() => {
                setTooltipText('');
            }, 5000);
        };

        tunnelDraw.push(
            <Group
                onClick={handleMouseEnter}
                key={"TrainStationGroup: " + crossCutIndex + " + " + tube + " + " + departure + " + " + notes + " + " + key}>
                <Rect
                    x={getCrossCutX(crossCutIndex - 1) - 2}
                    y={tube === Tube.South ? RailOneY : RailTwoY + RailWidth}
                    width={CrossCutWidth}
                    height={tube === Tube.South ? -12 : 12}
                    fill={"green"}
                    key={"TrainStationRect: " + crossCutIndex + " + " + tube + " + " + departure + " + " + notes + " + " + key}
                />
                <Image
                    image={tramStop}
                    x={getCrossCutX(crossCutIndex === 69 ? crossCutIndex - 3 : crossCutIndex === 68 ? crossCutIndex - 2 : crossCutIndex - 1) - 10}
                    y={tube === Tube.South ? 0 : canvasHeight - 45}
                    width={25}
                    height={25}
                    key={"TramStopImageCanvas" + crossCutIndex + " + " + tube + " + " + departure + " + " + notes + " + " + key}
                />
                <Text
                    x={getCrossCutX(crossCutIndex === 69 ? crossCutIndex - 3 : crossCutIndex === 68 ? crossCutIndex - 2 : crossCutIndex - 1) + 20}
                    y={tube === Tube.South ? 0 : canvasHeight - 45}
                    fontSize={FontSize}
                    text={"Ausfahrzeit: " + departure.slice(0, 5)}
                    key={"TrainStationText:" + crossCutIndex + " + " + tube + " + " + departure + " + " + notes + " + " + key}
                />
                <Text
                    x={getCrossCutX(crossCutIndex === 69 ? crossCutIndex - 3 : crossCutIndex === 68 ? crossCutIndex - 2 : crossCutIndex - 1) + 20}
                    y={tube === Tube.South ? 15 : canvasHeight - 30}
                    fontSize={FontSize}
                    text={formatDateStringToGermanDateFormat(startDate) + " - " + formatDateStringToGermanDateFormat(endDate) + " | " + LocalizationService.LatitudinalDirection(finalDestination)}
                    key={"TrainStationDateText:" + crossCutIndex + " + " + tube + " + " + departure + " + " + finalDestination + " + " + notes + " + " + key}
                />
            </Group>
        )
    }

    function renderZones(tube: Tube, start: string, end: string, index: number, color: string, zoneObject?: ZoneBasedTunnelTubeWorkRegistration): void {
        const FisLatitudinalDirectionFrom: boolean = start === LatitudinalDirection.East || start === LatitudinalDirection.West
        const FisLatitudinalDirectionTo: boolean = end === LatitudinalDirection.East || end === LatitudinalDirection.West

        const startIndex = FisLatitudinalDirectionFrom ? (start as LatitudinalDirection) === LatitudinalDirection.East ? -1 : tunnelConstructionService.zoneDescriptions.length : (Number(start as unknown as number) - 1)
        const endIndex = FisLatitudinalDirectionTo ? (end as LatitudinalDirection) === LatitudinalDirection.East ? -1 : tunnelConstructionService.zoneDescriptions.length : (Number(end as unknown as number) - 2)
        const selectedZoneRange = [];
        for (let i = startIndex; i <= endIndex; i++) {
            selectedZoneRange.push(i);
        }

        selectedZoneRange.forEach(value => {
            const getStartPosition: number = value === -1 ? getPortalX(LatitudinalDirection.East) : getCrossCutX(value === tunnelConstructionService.zoneDescriptions.length ? tunnelConstructionService.crossCutDescriptions.length - 1 : value)
            const getEndPosition: number = value === tunnelConstructionService.zoneDescriptions.length ? getPortalX(LatitudinalDirection.West) : getCrossCutX((value + 1) < tunnelConstructionService.crossCutDescriptions.length ? (value + 1) : value)

            const conflictIndex = value;
            if (tube === Tube.North ? !northConflictWorkRegistrations[conflictIndex] : !southConflictWorkRegistrations[conflictIndex]) tube === Tube.North ? northConflictWorkRegistrations[conflictIndex] = [] : southConflictWorkRegistrations[conflictIndex] = [];
            const isOverlappingZone = tube === Tube.North ? northConflictWorkRegistrations[conflictIndex].length >= 1 : southConflictWorkRegistrations[conflictIndex].length >= 1;
            if (zoneObject) tube === Tube.North ? northConflictWorkRegistrations[conflictIndex].push(zoneObject) : southConflictWorkRegistrations[conflictIndex].push(zoneObject)

            const railTrackStatusList = Array.from(new Set((tube === Tube.North ? northConflictWorkRegistrations[conflictIndex] : southConflictWorkRegistrations[conflictIndex]).map(element => translationsService.thirdLevelOutline.some(item => item.ID === element.ThirdLevelOutline && item.IsPartOfCommissioning) ? true : element.RailTrackStatus)));
            let patternImage = BlockedImage;

            switch (railTrackStatusList.length) {
                case 1:
                    switch (railTrackStatusList[0]) {
                        case RailTrackStatus.Free:
                            patternImage = StripesFreeImage;
                            break;
                        case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                            patternImage = StripesSideWorkImage;
                            break;
                        case RailTrackStatus.Blocked:
                            patternImage = StripesBlockedImage;
                            break;
                        case true:
                            patternImage = StripesIbnImage;
                            break;
                    }
                    break;
                case 2:
                    switch (railTrackStatusList[0]) {
                        case RailTrackStatus.Free:
                            switch (railTrackStatusList[1]) {
                                case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                    patternImage = StripesFreeSideWorkImage;
                                    break;
                                case RailTrackStatus.Blocked:
                                    patternImage = StripesFreeBlockedImage;
                                    break;
                                case true:
                                    patternImage = IbnBlockedImage;
                                    break;
                            }
                            break;
                        case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                            switch (railTrackStatusList[1]) {
                                case RailTrackStatus.Free:
                                    patternImage = StripesFreeSideWorkImage;
                                    break;
                                case RailTrackStatus.Blocked:
                                    patternImage = StripesBlockedSideWorkImage;
                                    break;
                                case true:
                                    patternImage = IbnSideWorkImage;
                                    break;
                            }
                            break;
                        case RailTrackStatus.Blocked:
                            switch (railTrackStatusList[1]) {
                                case RailTrackStatus.Free:
                                    patternImage = StripesFreeBlockedImage;
                                    break;
                                case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                    patternImage = StripesBlockedSideWorkImage;
                                    break;
                                case true:
                                    patternImage = IbnBlockedImage;
                                    break;
                            }
                            break;
                        case true:
                            switch (railTrackStatusList[1]) {
                                case RailTrackStatus.Free:
                                    patternImage = IbnFreeImage;
                                    break;
                                case RailTrackStatus.WorkInRailTrackAreaPeriphery:
                                    patternImage = IbnSideWorkImage;
                                    break;
                                case RailTrackStatus.Blocked:
                                    patternImage = IbnBlockedImage;
                                    break;
                            }
                            break;
                    }
                    break;
                case 3:
                    if (railTrackStatusList.some(status => status === true)) {
                        if (railTrackStatusList.some(status => status === RailTrackStatus.Free) && railTrackStatusList.some(status => status === RailTrackStatus.Blocked)) {
                            patternImage = FreeBlockedIbnImage;
                        }
                        if (railTrackStatusList.some(status => status === RailTrackStatus.Free) && railTrackStatusList.some(status => status === RailTrackStatus.WorkInRailTrackAreaPeriphery)) {
                            patternImage = FreeSideWorkIbnImage;
                        }
                        if (railTrackStatusList.some(status => status === RailTrackStatus.WorkInRailTrackAreaPeriphery) && railTrackStatusList.some(status => status === RailTrackStatus.Blocked)) {
                            patternImage = SideWorkBlockedIbnImage;
                        }
                    } else {
                        patternImage = FreeSideBlockedImage;
                    }
                    break;
                case 4:
                default:
                    patternImage = FreeSideWorkBlockedIbnImage;
            }

            tunnelDraw.push(
                <Rect
                    x={getStartPosition}
                    y={tube === Tube.North ? RailTwoY : RailOneY}
                    width={getEndPosition - getStartPosition}
                    height={EscapeHeight}
                    fill={isOverlappingZone ? undefined : color}
                    fillPatternImage={isOverlappingZone ? patternImage : undefined}
                    key={"ZoneOneRect: " + index + color + tube + value + zoneObject}
                    stroke="black"
                    strokeWidth={0.5}
                    onClick={() => {
                        setConflictTube(tube);
                        setConflictZoneIndex(conflictIndex);
                        setConflictDialogOpen(true);
                    }}
                    onMouseEnter={(e) => e.target.getStage()!.container().style.cursor = 'pointer'}
                    onMouseLeave={(e) => e.target.getStage()!.container().style.cursor = 'default'}
                />
            )
        })
    }

    function conflictWorkRegistrationsDialog(): JSX.Element {
        return (
            <Dialog
                open={conflictDialogOpen}
                onClose={() => setConflictDialogOpen(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "1700px",
                        },
                    },
                }}>
                <DialogTitle>Arbeiten in der ausgewählten Zone im {LocalizationService.TubeLongitudinalDirection(conflictTube)} Tunnel</DialogTitle>
                <DialogContent>
                    <WeekOverviewGrid
                        changedSelectedWorkRegistration={() => {}}
                        view={KoralmLocation.Tunnel}
                        startDate={new Date()}
                        endDate={new Date()}
                        changedOutdoorWorkRegistration={() => {}}
                        changedTunnelWorkRegistration={() => {}}
                        showButtons={false}
                        tunnelWorkRegistration={(conflictTube === Tube.North ? northConflictWorkRegistrations[conflictZoneIndex] : southConflictWorkRegistrations[conflictZoneIndex])
                            .filter(w =>
                                (w as ZoneBasedTunnelTubeWorkRegistration).LocationDescription.ObjectDescription.Tube === conflictTube
                            )}
                    />
                </DialogContent>
            </Dialog>
        );
    }

    function renderTunnel() {
        props.trainStationInTunnelData.forEach((item: TrainStationInTunnel) => {
            if (item.CrossCutId) renderTrainStations(item.CrossCutId, item.Tube, item.Departure, item.Start, item.End, item.FinalDestination, item.Notes, item.ID);
        })
        tunnelConstructionService.portalDescriptions.forEach((element: LatitudinalDirectedDescription, index: number): void => {
            renderPortals(element.ID, index, RailTrackPrimaryColor)
        })
        tunnelConstructionService.crossCutDescriptions.forEach((element: CrossCutDescription, index: number): void => {
            renderCrossCuts(index, CrossCutColor, element.ID)
        })
        tunnelConstructionService.crossCutDescriptions.forEach((element: CrossCutDescription, index: number): void => {
            renderCrossCutTexts(index, CrossCutColor, element.ID)
        })
        tunnelConstructionService.emergencyStopDescription.forEach((element: EmergencyStopDescription, _): void => {
            renderEmergencyStop(element.ID - 1, RailTrackPrimaryColor, "123Test")
        })
        tunnelConstructionService.ventilationShaftsDescriptions.forEach((element: LatitudinalDirectedDescription, index: number): void => {
            renderVentilationShafts(element.ID, index, CrossCutColor)
        })
        tunnelConstructionService.zoneDescriptions.forEach((element: ZoneDescription, index: number): void => {
            renderZones(Tube.North, element.FromCrossCutOrPortalId, element.ToCrossCutOrPortalId, index, RailTrackSecondaryColor)
            renderZones(Tube.South, element.FromCrossCutOrPortalId, element.ToCrossCutOrPortalId, index, RailTrackSecondaryColor)
        })
        tunnelConstructionService.escapeGalleriesDescriptions.forEach((_, index: number): void => {
            renderEscapeGalleries(index, RailTrackPrimaryColor)
        })
    }

    function renderSelectedTunnelObjects(): void {
        let maxCrossCutPersonCount: number[] = Array(tunnelConstructionService.crossCutDescriptions.length).fill(0);
        sortedTunnelWorkRegistrations.forEach((tunnelObject, index): void => {
            switch (getWorkRegistrationObjectType(tunnelObject)) {
                case TunnelObject.CrossCut:
                    const crossCutID: number = (tunnelObject as TunnelWorkRegistrationWithNumericLocationID).LocationDescription.ObjectDescription.AreaDescription.ID;
                    maxCrossCutPersonCount[crossCutID - 1] += tunnelObject.PersonCount
                    renderCrossCuts(crossCutID - 1, getIbnOrRailTrackColor(tunnelObject.ThirdLevelOutline, tunnelObject.RailTrackStatus!), tunnelObject.UUID)
                    break;
                case TunnelObject.Tube:
                    const start: string = tunnelConstructionService.zoneDescriptions[(tunnelObject as ZoneBasedTunnelTubeWorkRegistration).LocationDescription.ObjectDescription.AreaDescription.Start - 1].FromCrossCutOrPortalId
                    const end: string = tunnelConstructionService.zoneDescriptions[(tunnelObject as ZoneBasedTunnelTubeWorkRegistration).LocationDescription.ObjectDescription.AreaDescription.End - 1].ToCrossCutOrPortalId
                    renderZones((tunnelObject as ZoneBasedTunnelTubeWorkRegistration).LocationDescription.ObjectDescription.Tube, start, end, index, getIbnOrRailTrackColor(tunnelObject.ThirdLevelOutline, tunnelObject.RailTrackStatus!), tunnelObject as ZoneBasedTunnelTubeWorkRegistration)
                    break;
                case TunnelObject.EscapeGallery:
                    renderEscapeGalleries((tunnelObject as TunnelWorkRegistrationWithNumericLocationID).LocationDescription.ObjectDescription.AreaDescription.ID - 1, getIbnOrRailTrackColor(tunnelObject.ThirdLevelOutline, RailTrackStatus.Free))
                    break;
                case TunnelObject.Portal:
                    renderPortals((tunnelObject as LatitudinalDirectedTunnelWorkRegistration).LocationDescription.ObjectDescription.AreaDescription.ID, index, getIbnOrRailTrackColor(tunnelObject.ThirdLevelOutline, tunnelObject.RailTrackStatus!))
                    break;
                case TunnelObject.VentilationShaft:
                    renderVentilationShafts((tunnelObject as LatitudinalDirectedTunnelWorkRegistration).LocationDescription.ObjectDescription.AreaDescription.ID, index, getIbnOrRailTrackColor(tunnelObject.ThirdLevelOutline, tunnelObject.RailTrackStatus!))
                    break;
                case TunnelObject.EmergencyStop:
                    renderEmergencyStop((tunnelObject as TunnelWorkRegistrationWithNumericLocationID).LocationDescription.ObjectDescription.AreaDescription.ID - 1, getIbnOrRailTrackColor(tunnelObject.ThirdLevelOutline, RailTrackStatus.Free), (tunnelObject as TunnelWorkRegistrationWithNumericLocationID).UUID)
                    break;
            }
        })
        maxCrossCutPersonCount.forEach((personCount: number, index: number): void => {
            renderCrossCutPersonCount(index, personCount)
        })
    }

    function renderRailNumberText(): JSX.Element[] {
        return ([RailNumber.Eins, RailNumber.Zwei]).map((railNumber: RailNumber, index: number) => {
            return <Text
                key={"RailNumberText" + railNumber + index}
                x={RailNumberTextPosition}
                y={railNumber === RailNumber.Eins ? RailOneY - 25 : RailTwoY + 35}
                fontSize={15}
                text={LocalizationService.RailNumberWithLongitudinalDirection(railNumber as RailNumber)}
            />
        })
    }

    function renderPortalText(): JSX.Element[] {
        return tunnelConstructionService.portalDescriptions.map((element: LatitudinalDirectedDescription, index: number) => {
            return <Text
                x={element.ID === LatitudinalDirection.East ? 15 : TunnelVisLength - 40}
                y={RailTwoY + 20}
                fontSize={FontSize}
                text={LocalizationService.LatitudinalDirection(element.ID)}
                key={"Ost-Portal-Text: " + index}
            />
        })
    }

    const firstLatitudinalDirectedTunnelWorkRegistrationElement: LatitudinalDirection = (sortedTunnelWorkRegistrations[0] as LatitudinalDirectedTunnelWorkRegistration).LocationDescription.ObjectDescription.AreaDescription.ID
    const firstZoneBasedTunnelTubeWorkRegistrationStartElement: number = (sortedTunnelWorkRegistrations[0] as ZoneBasedTunnelTubeWorkRegistration).LocationDescription.ObjectDescription.AreaDescription.Start
    const firstZoneBasedTunnelTubeWorkRegistrationEndElement: number = (sortedTunnelWorkRegistrations[0] as ZoneBasedTunnelTubeWorkRegistration).LocationDescription.ObjectDescription.AreaDescription.End
    const firstTunnelWorkRegistrationWithNumericLocationIDElement: number = (sortedTunnelWorkRegistrations[0] as TunnelWorkRegistrationWithNumericLocationID).LocationDescription.ObjectDescription.AreaDescription.ID
    const firstWorkRegistrationObjectType: KoralmLocation.OpenLine | TunnelObject | typeof LatitudinalDirectedOpenLineConstruction | typeof UniqueOpenLineConstruction | undefined = getWorkRegistrationObjectType(sortedTunnelWorkRegistrations[0])

    function calculateScrollPosition(): number {
        switch (firstWorkRegistrationObjectType) {
            case TunnelObject.CrossCut:
                return getCrossCutX(firstTunnelWorkRegistrationWithNumericLocationIDElement - 1)
            case TunnelObject.Tube:
                return getCrossCutX(firstZoneBasedTunnelTubeWorkRegistrationStartElement - 1)
            case TunnelObject.EscapeGallery:
                return getEscapeGalleryX(firstTunnelWorkRegistrationWithNumericLocationIDElement - 1)
            case TunnelObject.Portal:
                return getPortalX(firstLatitudinalDirectedTunnelWorkRegistrationElement)
            case TunnelObject.VentilationShaft:
                return getVentilationX(firstLatitudinalDirectedTunnelWorkRegistrationElement)
            case TunnelObject.EmergencyStop:
                return getEmergencyStopX(firstTunnelWorkRegistrationWithNumericLocationIDElement - 1)
            default:
                return 0;
        }
    }

    renderTunnel()
    renderSelectedTunnelObjects()

    useEffect((): void => {
        const canvasContainer: HTMLElement | null = document.getElementById(canvasId);
        if (canvasContainer !== undefined && canvasContainer !== null) {
            canvasContainer.scrollTo(calculateScrollPosition() - TunnelVisLength / TunnelScale / 2, 0);
        }
    }, [
        firstLatitudinalDirectedTunnelWorkRegistrationElement,
        firstZoneBasedTunnelTubeWorkRegistrationStartElement,
        firstZoneBasedTunnelTubeWorkRegistrationEndElement,
        firstTunnelWorkRegistrationWithNumericLocationIDElement,
        firstWorkRegistrationObjectType,
    ])

    return (
        <Stack id={canvasId} maxWidth={useCurrentWindowSize().width} bgcolor="warning" overflow={"scroll"}
               style={{overflowY: "hidden"}} justifyContent="center" key={"Stack" + canvasId} marginX={2}>
            {conflictDialogOpen && conflictWorkRegistrationsDialog()}
            <Stage ref={localStageRef} width={Math.floor(TunnelLength / TunnelScale)} height={canvasHeight}
                   key={"StageRender"}>
                <Layer>
                    {renderRailNumberText()}
                    {renderPortalText()}
                    {tunnelDraw}
                </Layer>
                <Layer>
                    {tooltipText && (
                        <Rect
                            x={tooltipPosition.x}
                            y={tooltipPosition.y}
                            width={tooltipText.length * 8}
                            height={30}
                            fill="grey"
                            cornerRadius={5}
                        />
                    )}
                    {tooltipText && (
                        <Text
                            x={tooltipPosition.x}
                            y={tooltipPosition.y}
                            text={tooltipText}
                            fill="black"
                            fontSize={15}
                            padding={5}
                        />
                    )}
                </Layer>
            </Stage>
        </Stack>
    )
}