/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CombinedFilterDescription } from '../model';
// @ts-ignore
import { FilterOperation } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { PageOfStorageData } from '../model';
// @ts-ignore
import { StorageDataDeliveryDateUpdate } from '../model';
// @ts-ignore
import { UpdateCompanyNameInput } from '../model';
/**
 * StorageDataApi - axios parameter creator
 * @export
 */
export const StorageDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataGetPaged: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('storageDataGetPaged', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('storageDataGetPaged', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('storageDataGetPaged', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('storageDataGetPaged', 'sortOrder', sortOrder)
            const localVarPath = `/StorageData/GetPaged/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPropertyName !== undefined) {
                localVarQueryParameter['FilterPropertyName'] = filterPropertyName;
            }

            if (filterPropertyValues) {
                localVarQueryParameter['FilterPropertyValues'] = filterPropertyValues;
            }

            if (filterOperation !== undefined) {
                localVarQueryParameter['FilterOperation'] = filterOperation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataGetPagedWithCombinedFilter: async (size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('storageDataGetPagedWithCombinedFilter', 'size', size)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('storageDataGetPagedWithCombinedFilter', 'index', index)
            // verify required parameter 'sortPropertyName' is not null or undefined
            assertParamExists('storageDataGetPagedWithCombinedFilter', 'sortPropertyName', sortPropertyName)
            // verify required parameter 'sortOrder' is not null or undefined
            assertParamExists('storageDataGetPagedWithCombinedFilter', 'sortOrder', sortOrder)
            // verify required parameter 'combinedFilterDescription' is not null or undefined
            assertParamExists('storageDataGetPagedWithCombinedFilter', 'combinedFilterDescription', combinedFilterDescription)
            const localVarPath = `/StorageData/GetPagedWithCombinedFilter/Size/{Size}/Index/{Index}/SortPropertyName/{SortPropertyName}/SortOrder/{SortOrder}`
                .replace(`{${"Size"}}`, encodeURIComponent(String(size)))
                .replace(`{${"Index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"SortPropertyName"}}`, encodeURIComponent(String(sortPropertyName)))
                .replace(`{${"SortOrder"}}`, encodeURIComponent(String(sortOrder)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedFilterDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataGetStorageDataCompanyNameAliases: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/StorageData/GetStorageDataCompanyNameAliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyNameInput} updateCompanyNameInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataSaveStorageContractorIdUpdate: async (updateCompanyNameInput: UpdateCompanyNameInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyNameInput' is not null or undefined
            assertParamExists('storageDataSaveStorageContractorIdUpdate', 'updateCompanyNameInput', updateCompanyNameInput)
            const localVarPath = `/StorageData/SaveStorageContractorIdUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyNameInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StorageDataDeliveryDateUpdate} storageDataDeliveryDateUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataSaveStorageDataDeliveryDateUpdate: async (storageDataDeliveryDateUpdate: StorageDataDeliveryDateUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storageDataDeliveryDateUpdate' is not null or undefined
            assertParamExists('storageDataSaveStorageDataDeliveryDateUpdate', 'storageDataDeliveryDateUpdate', storageDataDeliveryDateUpdate)
            const localVarPath = `/StorageData/SaveStorageDataDeliveryDateUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storageDataDeliveryDateUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageDataApi - functional programming interface
 * @export
 */
export const StorageDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDataGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfStorageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDataGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async storageDataGetPagedSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfStorageData>> {
            return (await this.storageDataGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options))();
        },
        async storageDataGetPagedAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): Promise<PageOfStorageData> {
            return (await this.storageDataGetPagedSendRequest(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation,  options)).data;
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDataGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfStorageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDataGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async storageDataGetPagedWithCombinedFilterSendRequest(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<AxiosResponse<PageOfStorageData>> {
            return (await this.storageDataGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options))();
        },
        async storageDataGetPagedWithCombinedFilterAndGetData(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): Promise<PageOfStorageData> {
            return (await this.storageDataGetPagedWithCombinedFilterSendRequest(size, index, sortPropertyName, sortOrder, combinedFilterDescription,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDataGetStorageDataCompanyNameAliases(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDataGetStorageDataCompanyNameAliases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async storageDataGetStorageDataCompanyNameAliasesSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<{ [key: string]: Array<string>; }>> {
            return (await this.storageDataGetStorageDataCompanyNameAliases( options))();
        },
        async storageDataGetStorageDataCompanyNameAliasesAndGetData(options?: AxiosRequestConfig): Promise<{ [key: string]: Array<string>; }> {
            return (await this.storageDataGetStorageDataCompanyNameAliasesSendRequest( options)).data;
        },
        /**
         * 
         * @param {UpdateCompanyNameInput} updateCompanyNameInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput: UpdateCompanyNameInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async storageDataSaveStorageContractorIdUpdateSendRequest(updateCompanyNameInput: UpdateCompanyNameInput, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return (await this.storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput,  options))();
        },
        async storageDataSaveStorageContractorIdUpdateAndGetData(updateCompanyNameInput: UpdateCompanyNameInput, options?: AxiosRequestConfig): Promise<boolean> {
            return (await this.storageDataSaveStorageContractorIdUpdateSendRequest(updateCompanyNameInput,  options)).data;
        },
        /**
         * 
         * @param {StorageDataDeliveryDateUpdate} storageDataDeliveryDateUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate: StorageDataDeliveryDateUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async storageDataSaveStorageDataDeliveryDateUpdateSendRequest(storageDataDeliveryDateUpdate: StorageDataDeliveryDateUpdate, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return (await this.storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate,  options))();
        },
        async storageDataSaveStorageDataDeliveryDateUpdateAndGetData(storageDataDeliveryDateUpdate: StorageDataDeliveryDateUpdate, options?: AxiosRequestConfig): Promise<boolean> {
            return (await this.storageDataSaveStorageDataDeliveryDateUpdateSendRequest(storageDataDeliveryDateUpdate,  options)).data;
        },
    }
};

/**
 * StorageDataApi - factory interface
 * @export
 */
export const StorageDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageDataApiFp(configuration)
    return {
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {string} [filterPropertyName] 
         * @param {Array<string>} [filterPropertyValues] 
         * @param {FilterOperation} [filterOperation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: any): AxiosPromise<PageOfStorageData> {
            return localVarFp.storageDataGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} index 
         * @param {string} sortPropertyName 
         * @param {Order} sortOrder 
         * @param {CombinedFilterDescription} combinedFilterDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: any): AxiosPromise<PageOfStorageData> {
            return localVarFp.storageDataGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataGetStorageDataCompanyNameAliases(options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.storageDataGetStorageDataCompanyNameAliases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyNameInput} updateCompanyNameInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput: UpdateCompanyNameInput, options?: any): AxiosPromise<boolean> {
            return localVarFp.storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorageDataDeliveryDateUpdate} storageDataDeliveryDateUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate: StorageDataDeliveryDateUpdate, options?: any): AxiosPromise<boolean> {
            return localVarFp.storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageDataApi - interface
 * @export
 * @interface StorageDataApi
 */
export interface StorageDataApiInterface {
    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApiInterface
     */
    storageDataGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig): AxiosPromise<PageOfStorageData>;

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApiInterface
     */
    storageDataGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig): AxiosPromise<PageOfStorageData>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApiInterface
     */
    storageDataGetStorageDataCompanyNameAliases(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: Array<string>; }>;

    /**
     * 
     * @param {UpdateCompanyNameInput} updateCompanyNameInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApiInterface
     */
    storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput: UpdateCompanyNameInput, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {StorageDataDeliveryDateUpdate} storageDataDeliveryDateUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApiInterface
     */
    storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate: StorageDataDeliveryDateUpdate, options?: AxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * StorageDataApi - object-oriented interface
 * @export
 * @class StorageDataApi
 * @extends {BaseAPI}
 */
export class StorageDataApi extends BaseAPI implements StorageDataApiInterface {
    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {string} [filterPropertyName] 
     * @param {Array<string>} [filterPropertyValues] 
     * @param {FilterOperation} [filterOperation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApi
     */
    public storageDataGetPaged(size: number, index: number, sortPropertyName: string, sortOrder: Order, filterPropertyName?: string, filterPropertyValues?: Array<string>, filterOperation?: FilterOperation, options?: AxiosRequestConfig) {
        return StorageDataApiFp(this.configuration).storageDataGetPaged(size, index, sortPropertyName, sortOrder, filterPropertyName, filterPropertyValues, filterOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} index 
     * @param {string} sortPropertyName 
     * @param {Order} sortOrder 
     * @param {CombinedFilterDescription} combinedFilterDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApi
     */
    public storageDataGetPagedWithCombinedFilter(size: number, index: number, sortPropertyName: string, sortOrder: Order, combinedFilterDescription: CombinedFilterDescription, options?: AxiosRequestConfig) {
        return StorageDataApiFp(this.configuration).storageDataGetPagedWithCombinedFilter(size, index, sortPropertyName, sortOrder, combinedFilterDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApi
     */
    public storageDataGetStorageDataCompanyNameAliases(options?: AxiosRequestConfig) {
        return StorageDataApiFp(this.configuration).storageDataGetStorageDataCompanyNameAliases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyNameInput} updateCompanyNameInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApi
     */
    public storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput: UpdateCompanyNameInput, options?: AxiosRequestConfig) {
        return StorageDataApiFp(this.configuration).storageDataSaveStorageContractorIdUpdate(updateCompanyNameInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageDataDeliveryDateUpdate} storageDataDeliveryDateUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDataApi
     */
    public storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate: StorageDataDeliveryDateUpdate, options?: AxiosRequestConfig) {
        return StorageDataApiFp(this.configuration).storageDataSaveStorageDataDeliveryDateUpdate(storageDataDeliveryDateUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
