import {
    KoralmLocation,
    LatitudinalDirection,
    ProcessStatus,
    RailNumber,
    RailTrackStatus,
    Tube,
    TunnelObject,
    WorkDirection
} from "../generated";
import {VENTILATIONSHAFT_BUILDING, VENTILATIONSHAFT_SHAFT} from "./FilterTypes";
import {railDescriptionService} from "../services/railDescriptionsProvider";
import {tunnelConstructionService} from "../services/tunnelConstructionsDescriptionsProvider";

class Localization {
    private openLine = "Freie Strecke"
    Boolean(bool: boolean) {
        return bool ? "Ja" : "Nein";
    }
    KoralmLocation(location: KoralmLocation) {
        return location === KoralmLocation.Tunnel ? "Tunnel" : this.openLine;
    }

    KoralmLocationWithLatitudinalDirection(direction?: LatitudinalDirection) {
        return !direction ? "Tunnel" : this.openLine + " " + this.LatitudinalDirection(direction);
    }


    TunnelObject(object: TunnelObject, isOnOuterConstruction: boolean = false): string {
        if (object === TunnelObject.VentilationShaft) {
            return LocalizationService.OuterConstruction(isOnOuterConstruction)
        }

        const mapping = new Map<TunnelObject, string>();
        mapping.set(TunnelObject.CrossCut, "Querschlag");
        mapping.set(TunnelObject.Tube, "Tunnel");
        mapping.set(TunnelObject.Portal, "Portal");
        mapping.set(TunnelObject.EmergencyStop, "Nothaltestelle");
        mapping.set(TunnelObject.EscapeGallery, "Fluchtstollen");
        return mapping.get(object) || object;
    }

    Tube(tube: Tube) {
        return this.TubeLongitudinalDirection(tube) + " Tunnel"
    }

    TubeLongitudinalDirection(tube: Tube) {
        return tube === Tube.South ? "Süd" : "Nord"
    }


    WorkDirection(direction: WorkDirection) {
        return direction === WorkDirection.West ? "Westen" : "Osten"
    }

    TubeLongitudinalDirectionToRailNumber(tube: Tube) {
        return tube === Tube.South ? this.RailNumber(RailNumber.Eins) : this.RailNumber(RailNumber.Zwei)
    }

    RailNumber(rail: RailNumber) {
        return rail === RailNumber.Eins ? "Gleis 1" : "Gleis 2"
    }

    RailNumberWithLongitudinalDirection(rail: RailNumber) {
        return this.RailNumber(rail) + " (" + this.TubeLongitudinalDirection(Object.entries(railDescriptionService.railTubeMapping)
            .filter(kv => kv[1] === rail).map(kv => kv[0])[0] as Tube) + ")";
    }

    Status(status: ProcessStatus) {
        const statusMap: Record<ProcessStatus, string> = {
            [ProcessStatus.Done]: "Abgeschlossen",
            [ProcessStatus.ChangeApproved]: "Änderung genehmigt",
            [ProcessStatus.ChangeSubmitted]: "Eingereichte Veränderung",
            [ProcessStatus.TaskCreatedByPlaner]: "Vom Planer angelegt",
            [ProcessStatus.ChangeRejected]: "Änderung abgelehnt",
        };
        return statusMap[status];
    }

    LatitudinalDirection(portal: LatitudinalDirection) {
        return portal === LatitudinalDirection.East ? "Ost" : "West"
    }

    ZoneSelection(value: string | LatitudinalDirection, withSpace = true) {
        let prefix = withSpace ? "QS " : "QS";
        if (value === LatitudinalDirection.East || value === LatitudinalDirection.West) {
            return this.LatitudinalDirection(value);
        } else {
            if (parseInt(value) < 10) {
                prefix += "0";
            }
            return prefix + value;
        }
    }

    RailTrackStatus(object: RailTrackStatus) {
        const railTrackStatusMap: Record<RailTrackStatus, string> = {
            [RailTrackStatus.Free]: "Gleis Frei",
            [RailTrackStatus.WorkInRailTrackAreaPeriphery]: "Randarbeit",
            [RailTrackStatus.Blocked]: "Gleis Gesperrt",
        };
        return railTrackStatusMap[object] || object;
    }

    TubeId(value: number, FromCrossCutOrPortalId: boolean) {
        const ZoneDescriptionId = tunnelConstructionService.zoneDescriptions.find((object) => object.ID === value);
        if (ZoneDescriptionId === undefined) return "undefined";
        const portalOrCrossCutId = FromCrossCutOrPortalId ?
            ZoneDescriptionId!.FromCrossCutOrPortalId :
            ZoneDescriptionId!.ToCrossCutOrPortalId;
        if (portalOrCrossCutId === LatitudinalDirection.East || portalOrCrossCutId === LatitudinalDirection.West) {
            return this.LatitudinalDirection(portalOrCrossCutId)
        }
        return portalOrCrossCutId
    }

    EscapeGallery(value: number) {
        return "FS: " + value
    }

    OuterConstruction(value: boolean) {
        return value ? VENTILATIONSHAFT_BUILDING : VENTILATIONSHAFT_SHAFT
    }
}

export const LocalizationService = new Localization();