import {Box, DialogTitle, IconButton} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import React from "react";

interface DialogTitleWithCloseButtonProps {
    title: React.ReactNode;
    onClose: () => void;
}

function DialogTitleWithCloseButton(props: DialogTitleWithCloseButtonProps) {
    return (
        <DialogTitle>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{props.title}</Box>
                <Box><IconButton onClick={props.onClose}><CloseOutlinedIcon/></IconButton></Box>
            </Box>
        </DialogTitle>
    );
}

export default DialogTitleWithCloseButton;