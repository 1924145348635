import {isLeapYear} from "date-fns";

export const calculateNumberOfWeeksInYear = (year: number) => {
    const currentStartDay = new Date(year, 0, 1)
    const dayOfWeek = currentStartDay.getDay()
    return dayOfWeek === 4 || (isLeapYear(currentStartDay) && dayOfWeek === 3) ? 53 : 52
}

export const getStartDateOfWeek = (currentYear: number, currentWeek: number) => {
    const initialStartDay = new Date(currentYear, 0, 1)
    const initialDayOfWeek = initialStartDay.getDay()
    initialStartDay.setDate((initialDayOfWeek <= 4 ? 2 : 9) - initialStartDay.getDay());
    initialStartDay.setDate(initialStartDay.getDate() + (currentWeek - 1) * 7)
    return initialStartDay;
}

export const formatDateStringToGermanDateFormat = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    return `${day}.${month}`
}