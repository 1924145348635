import {useGridApiContext} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import {PermissionManager} from "../../utils/PermissionManager";
import React from "react";

interface CustomDataGridToolbarProps {
    page: number | undefined;

}

export function CustomDataGridToolbar(props: CustomDataGridToolbarProps) {
    const apiRef = useGridApiContext();

    const handleExportCsv = () => apiRef.current.exportDataAsCsv({
        delimiter: ";",
        utf8WithBom: true,
        fileName: `Koralm-Webapp Datengitter${typeof props.page !== 'undefined' ? ` Seite-${props.page + 1}` : ""}`,
        includeHeaders: true,
    });

    return (
        <Button
            disabled={!PermissionManager.Universal.exportCSV}
            color="secondary"
            variant="contained"
            onClick={handleExportCsv}
            style={{
                width: 120,
                height: 30,
                fontSize: 16,
                padding: '0px 0px 2px'
            }}>
            Export CSV
        </Button>
    );
}